import { Injectable } from '@angular/core';
import { ConnectNftModalComponent } from '@components/connect-nft-modal/connect-nft-modal.component';
import * as fcl from '@onflow/fcl';
import { ParticleConnect } from '@particle-network/connect';
import { CommonService } from '@src/app/services/common.service';
import { SESSION_STORAGE_KEY_ENUM } from '@src/app/shared/types/general.type';
import { WalletEnum as NFTPlatform } from '@src/app/shared/types/wallet-chain.model';
import { encrypt } from '@src/app/shared/utils/pem';
import { environment } from '@src/environments/environment';
import { NFTWalletAccount } from '@type/nft.type';
import WalletConnectProvider from '@walletconnect/web3-provider';
import * as nearAPI from 'near-api-js';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WalletStatusService {
    // Selected Wallet
    selectedWallet$ = new BehaviorSubject<NFTPlatform>(window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.WALLET_TYPE) as NFTPlatform);
    // Dapper/Blocto wallet info
    currentFlowUser = new BehaviorSubject<NFTWalletAccount>(null);
    // Other wallet info
    selectedWalletAddr$ = new BehaviorSubject<string>(null);
    selectedWalletChainId: string = null;

    combinedWalletList$ = new BehaviorSubject<
        {
            walletType: NFTPlatform;
            walletAddr: string;
            encryptedWalletAddr: string;
        }[]
    >([]);
    pendingCombinedWallet$ = new BehaviorSubject<{ walletType: NFTPlatform; walletAddr: string }>(null);

    // Near Wallet 配置
    nearWallet: nearAPI.WalletConnection;

    // Wallet Connect 配置
    walletConnectProvider: WalletConnectProvider;

    // Particle Wallet 配置
    particleWalletConnect: ParticleConnect;

    // 当前 Address 的一些额外信息
    userInfo$ = new BehaviorSubject<{
        emailList: string[];
        referralLink: string;
        points: number;
        invitationCode: string;
        campaignEntryList: { campaignId: number; isSelected?: boolean }[];
        walletContactList: {
            contact: any;
            contactId: any;
            contactType: any;
        }[];
    }>(null);

    get userInfo() {
        return this.userInfo$.getValue();
    }

    get isLoggedIn() {
        const walletAddr = this.selectedWalletAddr$.getValue();
        const walletType = this.selectedWallet$.getValue();

        if (walletAddr && walletType) {
            return walletAddr;
        }

        return false;
    }

    get walletAddress() {
        return this.selectedWalletAddr$.getValue();
    }
    get walletType() {
        return this.selectedWallet$.getValue();
    }

    constructor(private screenService: CommonService, private modalService: NzModalService) {
        // Setup WalletAddress
        if (window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.ENCRYPTED_WALLET_ADDR)) {
            this.selectedWalletAddr$.next(window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.WALLET_ADDR));
        }

        this.combinedWalletList$.next(
            window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.COMBINE_WALLET_LIST)
                ? JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.COMBINE_WALLET_LIST))
                : []
        );

        this.setupWalletConnect();
    }

    setupWalletConnect() {
        // Dapper / Blocto
        fcl.currentUser.subscribe(user => {
            this.currentFlowUser.next(user);

            // 点击connect 选择了dapper/blocto，链接成功后会进入这个判断
            if ([NFTPlatform.DAPPER, NFTPlatform.BLOCTO].includes(this.selectedWallet$.getValue())) {
                // 可以连接dapper,并在这里替换address
                this.setSessionData(this.selectedWallet$.getValue(), this.currentFlowUser.getValue()?.addr);
            }
        });

        // Near
        this.setupNearConnection();

        // Wallet Connect
        this.walletConnectProvider = new WalletConnectProvider({
            infuraId: '005233ef0d61464a98a17a1230d7c59a',
        });

        // Particle
        // this.particleWalletConnect = new ParticleConnect({
        //     projectId: 'cd74c209-9209-4144-9cd9-fc60843a3059',
        //     clientKey: 'c30nvisPmpPAwJUilhZMs3wMR5CDpp9aCG04r0fL',
        //     appId: 'e5203214-0120-46a0-8662-64fac732c923',
        //     chains: [
        //         {
        //             id: 1,
        //             name: 'Ethereum',
        //         },
        //     ],
        //     particleWalletEntry: {
        //         displayWalletEntry: true,
        //         supportChains: [Ethereum],
        //         customStyle: {},
        //     },
        //     wallets: evmWallets({ qrcode: false }),
        // });
    }

    setWalletByCurrentValue() {
        this.setSessionData(this.selectedWallet$.getValue(), this.selectedWalletAddr$.getValue());
    }

    async setupNearConnection() {
        const { keyStores, WalletConnection } = nearAPI;
        const keyStore = new keyStores.BrowserLocalStorageKeyStore();

        const { connect } = nearAPI;

        const config = {
            networkId: environment.near.networkId,
            keyStore,
            nodeUrl: environment.near.nodeUrl,
            walletUrl: environment.near.walletUrl,
            helperUrl: environment.near.helperUrl,
            explorerUrl: environment.near.explorerUrl,
            headers: null,
        };

        // connect to NEAR
        const near = await connect(config);

        // create wallet connection
        this.nearWallet = new WalletConnection(near, 'W3W');

        if (this.selectedWallet$.getValue() === NFTPlatform.NEAR) {
            this.setSessionData(this.selectedWallet$.getValue(), this.nearWallet.getAccountId());
        } else {
            this.nearWallet.signOut();
        }
    }

    setSessionData(type?: NFTPlatform, addr?: string) {
        if (!addr) {
            window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.WALLET_TYPE);
            window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.WALLET_ADDR);
            window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.ENCRYPTED_WALLET_ADDR);
        } else {
            window.sessionStorage.setItem(SESSION_STORAGE_KEY_ENUM.WALLET_TYPE, type);
            window.sessionStorage.setItem(SESSION_STORAGE_KEY_ENUM.WALLET_ADDR, addr);
            window.sessionStorage.setItem(SESSION_STORAGE_KEY_ENUM.ENCRYPTED_WALLET_ADDR, encrypt(addr));
        }

        // 因为其他地方subscribe的是type，所以必须保证先设置addr，再设置type。
        // 否则type变化了，此时addr未变，会导致addr 与 type不匹配。
        this.selectedWalletAddr$.next(addr);
        this.selectedWallet$.next(type);
    }

    // near / dapper / blocto 不会调用
    appendCombineWalletSessionData(type: NFTPlatform, addr: string) {
        const currentList = this.combinedWalletList$.getValue() || [];
        this.combinedWalletList$.next(currentList.concat({ walletType: type, walletAddr: addr, encryptedWalletAddr: encrypt(addr) }));
        window.sessionStorage.setItem('combinedWalletList', JSON.stringify(this.combinedWalletList$.getValue() || {}));
    }

    connectWallet(
        modalConfig: { type: 'general' | 'customer'; combineWallet?: boolean } = { type: 'general' },
        additionalParams: object = {},
        okCallback?: () => any
    ) {
        if (this.screenService.isMobile()) {
            this.modalService.create({
                nzContent: ConnectNftModalComponent,
                nzFooter: null,
                nzWrapClassName: 'no-bg no-padding',
                nzClosable: false,
                nzCentered: true,
                nzWidth: '100vw',
                nzStyle: {
                    verticalAlign: 'bottom',
                    maxWidth: '100vw',
                    padding: 0,
                    margin: 0,
                },
                nzComponentParams: {
                    modalConfig,
                    ...additionalParams,
                },
                nzOnOk: () => {
                    okCallback && okCallback();
                    return true;
                },
            });
        } else {
            this.modalService.create({
                nzContent: ConnectNftModalComponent,
                nzFooter: null,
                nzClosable: false,
                nzCentered: true,
                nzZIndex: 10,
                nzWidth: '800px',
                nzWrapClassName: 'no-bg no-padding',
                nzComponentParams: {
                    modalConfig,
                    ...additionalParams,
                },
                nzOnOk: () => {
                    okCallback && okCallback();
                    return true;
                },
            });
        }
    }

    disconnectWallet() {
        if ([NFTPlatform.DAPPER, NFTPlatform.BLOCTO].includes(this.selectedWallet$.getValue())) {
            fcl.unauthenticate();
        }

        // Phantom
        if (this.selectedWallet$.getValue() === NFTPlatform.PHANTOM) {
            (window as any).solana?.disconnect();
        }

        // Near
        if (this.selectedWallet$.getValue() === NFTPlatform.NEAR) {
            this.nearWallet?.signOut();
        }

        // Wallet Connect
        if (this.selectedWallet$.getValue() === NFTPlatform.WALLET_CONNECT) {
            this.walletConnectProvider.disconnect();
        }

        // Particle
        if (this.selectedWallet$.getValue() === NFTPlatform.PARTICLE) {
            this.particleWalletConnect.disconnect();
        }

        this.selectedWalletAddr$.next(null);
        window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.WALLET_ADDR);
        this.selectedWallet$.next(null);
        window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.WALLET_TYPE);
        this.combinedWalletList$.next(null);
        window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.COMBINE_WALLET_LIST);

        this.userInfo$.next(null);
    }
}
