<section class="performance-card">
    <ng-container *ngTemplateOutlet="circleTpl"></ng-container>

    <ng-template #circleTpl>
        <div class="circle-box relative">
            <img class="outer" src="assets/imgs/admin/circle/1.png" />
            <img class="middle" src="assets/imgs/admin/circle/2.png" />
            <img class="middle" src="assets/imgs/admin/circle/3.png" />
            <img class="middle" src="assets/imgs/admin/circle/4.png" />
            <img class="inner" src="assets/imgs/admin/circle/1.png" />

            <div class="text-info relative">
                <div class="title" [innerHTML]="cardInfo.name" nz-tooltip [nzTooltipTitle]="cardInfo.name_tooltip"></div>
                <div class="count colorful-text">{{cardInfo.count | number:'1.0-0'}}</div>
                <div *ngIf="cardInfo.type" class="desc">{{cardInfo.type | uppercase}}</div>
                <span *ngIf="cardInfo.increased_count > 0" class="increased-count" nz-tooltip [nzTooltipTitle]="cardInfo.tooltip">
                    {{cardInfo.increased_count}}
                    <i nz-icon nzType="arrow-up" nzTheme="outline"></i>
                </span>
                <span
                    *ngIf="cardInfo.additional_info"
                    class="additional-info"
                    nz-tooltip
                    [nzTooltipTitle]="cardInfo.additional_info_tooltip"
                >
                    {{cardInfo.additional_info}}
                </span>
            </div>
        </div>
    </ng-template>
</section>
