import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NidavellirService } from '@modules/main/nidavellir.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-edit-brand-name-modal',
    templateUrl: './edit-brand-name-modal.component.html',
    styleUrls: ['./edit-brand-name-modal.component.less'],
})
export class EditBrandNameModalComponent implements OnInit, OnDestroy {
    @Input() name: string;

    submitting = false;

    brandName = new FormControl('');
    projectName: string;

    isAlreadyExists = false;
    isUpdateProjectKey = false;
    validatingProjectName = false;

    locationOrigin: string;

    private unsubscribe: Subject<void> = new Subject();

    constructor(public modalRef: NzModalRef, private nidavellirService: NidavellirService) {}

    ngOnInit() {
        this.locationOrigin = location.origin;
        this.brandName.patchValue(this.name);
        this.projectName = this.name.replace(/[^0-9a-zA-Z]/g, '')?.toLowerCase();

        this.brandName.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(text => {
            this.validatingProjectName = true;
            this.projectName = text.replace(/[^0-9a-zA-Z]/g, '')?.toLowerCase();
            this.nidavellirService
                .validateProjectName({ name: text })
                .then(res => (this.isAlreadyExists = false))
                .catch(() => (this.isAlreadyExists = true))
                .finally(() => (this.validatingProjectName = false));
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
