/* eslint-disable max-len */
import { GeneralAdminModalComponent } from '@admin/components/general-admin-modal/general-admin-modal.component';
import { RequestLifoServiceService } from '@admin/service/request-lifo-service.service';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandProjectStatus } from '@modules/admin/service/admin-data.service';
import { NFTMerchProduct } from '@modules/main/advanced-customization/advanced-customization-state.service';
import { NidavellirService } from '@modules/main/nidavellir.service';
import { CommonService } from '@services/common.service';
import { UtilService } from '@services/util.service';
import { SubscriptionPlansService } from '@src/app/modules/admin/individual-pages/subscription-plans/subscription-plans.service';
import { AdminCustomEventEnum, FirebaseEventsService } from '@src/app/services/firebase/firebase-event.service';
import { FirebaseFileUploadService } from '@src/app/services/firebase/firebase-file-upload.service';
import { ShopProfileService } from '@src/app/services/shop-profile.service';
import { SESSION_STORAGE_KEY_ENUM } from '@src/app/shared/types/general.type';
import { getObjectURL } from '@utils/draw';
import { cloneDeep } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { SubscriptionPlanEnum } from '../../types/profile.typing';
import { PLANS, Plan } from '../../types/subscription.type';

export enum ImportProjectType {
    OPENSEA = 'opensea',
    ELEMENT = 'element',
    MAGIC_EDEN = 'magiceden',
    COINMARKETCAP = 'coinmarketcap',
    MANUAL = 'manual',
}

export enum SocialMediaEnum {
    TWITTER = 'twitter',
    YOUTUBE = 'youtube',
    FACEBOOK = 'facebook',
    SNAPCHAT = 'snapchat',
    INSTAGRAM = 'instagram',
    PINTEREST = 'pinterest',
    SPOTIFY = 'spotify',
    TIKTOK = 'tiktok',
    LINKEDIN = 'linkedin',
    TUMBLR = 'tumblr',
    DISCORD = 'discord',
    PATREON = 'patreon',
    MEDIUM = 'medium',
    APPLE_PODCASTS = 'applePodcasts',
    TWITCH = 'twitch',
    WEBSITE = 'website',
    TELEGRAM = 'telegram',
}

export const Platforms = [
    { label: 'Twitter', value: SocialMediaEnum.TWITTER, select: false },
    { label: 'Youtube', value: SocialMediaEnum.YOUTUBE, select: false },
    { label: 'Facebook', value: SocialMediaEnum.FACEBOOK, select: false },
    { label: 'Snapchat', value: SocialMediaEnum.SNAPCHAT, select: false },
    { label: 'Instagram', value: SocialMediaEnum.INSTAGRAM, select: false },
    { label: 'Pinterest', value: SocialMediaEnum.PINTEREST, select: false },
    { label: 'Spotify', value: SocialMediaEnum.SPOTIFY, select: false },
    { label: 'Tiktok', value: SocialMediaEnum.TIKTOK, select: false },
    { label: 'Linkedin', value: SocialMediaEnum.LINKEDIN, select: false },
    { label: 'Tumblr', value: SocialMediaEnum.TUMBLR, select: false },
    { label: 'Discord', value: SocialMediaEnum.DISCORD, select: true },
    { label: 'Patreon', value: SocialMediaEnum.PATREON, select: false },
    { label: 'Medium', value: SocialMediaEnum.MEDIUM, select: false },
    { label: 'Apple Podcasts', value: SocialMediaEnum.APPLE_PODCASTS, select: false },
    { label: 'Twitch', value: SocialMediaEnum.TWITCH, select: false },
    { label: 'Website', value: SocialMediaEnum.WEBSITE, select: false },
    { label: 'Telegram', value: SocialMediaEnum.TELEGRAM, select: false },
];

@Component({
    selector: 'app-create-project',
    templateUrl: './create-project.component.html',
    styleUrls: ['./create-project.component.less'],
    providers: [RequestLifoServiceService, SubscriptionPlansService],
})
export class CreateProjectComponent implements OnInit, OnDestroy {
    @Input() pageType: string;

    PLANS;
    BrandProjectStatus = BrandProjectStatus;
    ImportProjectType = ImportProjectType;
    SubscriptionPlanEnum = SubscriptionPlanEnum;

    projectType: 'normal' | 'gamify' = 'normal';
    prevCollectionType: 'erc_721' | 'erc_20' = 'erc_721';
    collectionType: 'erc_721' | 'erc_20' = 'erc_721';
    prevImportProject: ImportProjectType = ImportProjectType.COINMARKETCAP;
    importProject: ImportProjectType = ImportProjectType.OPENSEA;
    searchOpenseaUrl = new FormControl('');
    searchElementUrl = new FormControl('');
    searchMagicEdenUrl = new FormControl('');
    searchCoinMarketCapUrl = new FormControl('');
    isInvalidOpenseaUrl;
    isInvalidElementUrl;
    isInvalidMagicEdenUrl;
    isInvalidCoinMarketCapUrl;
    openseaData;
    elementData;
    magicEdenData;
    coinMarketCapData;
    projectFormParams = {
        name: null,
        link: null,
        description: null,
        chain: null,
        contract_name: null,
        contract_address: null,
        social_medias: [{ name: SocialMediaEnum.DISCORD, link: null }],
        resource: {
            brand_avatar: null,
            banner_bg: null,
        },
    };
    updateProject = false;
    termsOfService = true;

    platforms = Platforms;

    selectedNFTMerchIds: Set<number> = new Set();
    socialMediaList = [];
    logoFile;
    logoFileBgUrl;
    bannerFile;
    bannerFileBgUrl;

    productList: NFTMerchProduct[];

    contactUsVisible;
    createErrorMsg;
    isColdStart = false;

    projectName = new FormControl('');
    isAlreadyExists = false;
    validatingProjectName: boolean;

    hasContractList: boolean;

    prevIsSkip: boolean = false;
    isSkip: boolean = false;

    isExceedQuota = false;
    selectedPlan = PLANS[0];

    paymentPeriod: 'monthly' | 'annually' = 'monthly';

    private unsubscribe: Subject<void> = new Subject();

    get disabledCreateBtn() {
        if (this.isSkip) return this.pageType !== 'admin' && !this.projectFormParams.name;

        if (this.importProject === ImportProjectType.OPENSEA) {
            return !this.openseaData || !this.termsOfService;
        }

        if (this.importProject === ImportProjectType.MAGIC_EDEN) {
            return !this.magicEdenData || !this.termsOfService;
        }

        if (this.importProject === ImportProjectType.ELEMENT) {
            return !this.elementData || !this.termsOfService;
        }

        if (this.importProject === ImportProjectType.COINMARKETCAP) {
            return !this.coinMarketCapData || !this.termsOfService;
        }

        return (
            (this.pageType !== 'admin' && !this.projectFormParams.name) ||
            !this.projectFormParams.resource.brand_avatar ||
            !this.projectFormParams.contract_name ||
            !this.projectFormParams.contract_address ||
            this.logoFile ||
            !this.termsOfService ||
            (this.updateProject && (this.validatingProjectName || this.isAlreadyExists))
        );
    }

    get shopDetail() {
        return this.profileService.shopDetail;
    }

    get isFree() {
        return this.shopDetail?.nft_project && this.profileService.isFree;
    }

    constructor(
        public vcr: ViewContainerRef,
        public profileService: ShopProfileService,
        private loadingService: CommonService,
        private utilService: UtilService,
        private firebaseUploadService: FirebaseFileUploadService,
        private message: CommonService,
        private nidavellirService: NidavellirService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private firebaseEventService: FirebaseEventsService,
        private modalService: NzModalService,
        private screenService: CommonService,
        private requestLifoService: RequestLifoServiceService,
        private subscriptionPlanService: SubscriptionPlansService,
        private location: Location
    ) {}

    ngOnInit(): void {
        this.isColdStart = !!this.activatedRoute.snapshot.queryParamMap.get('cold_start');
        this.PLANS =
            this.pageType === 'admin'
                ? PLANS.filter(item => item.id !== SubscriptionPlanEnum.ENTERPRISE)
                : PLANS.filter(item => [SubscriptionPlanEnum.FREE, SubscriptionPlanEnum.ENTERPRISE].includes(item.id));
        this.initPage();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    initPage() {
        if (this.pageType !== 'admin') {
            this.getNFTProducts();
        }

        this.projectName.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(text => {
            this.projectFormParams.name = text;
            this.validatingProjectName = true;
            this.nidavellirService
                .validateProjectName({ name: text })
                .then(res => (this.isAlreadyExists = false))
                .catch(() => (this.isAlreadyExists = true))
                .finally(() => (this.validatingProjectName = false));
        });

        if (this.pageType === 'admin') {
            this.profileService.shopDetailSubject
                .pipe(
                    filter(data => !!data),
                    takeUntil(this.unsubscribe)
                )
                .subscribe(shopDetail => {
                    this.hasContractList = shopDetail?.nft_project?.contract_list?.some(ele => ele.contract_address && ele.contract_name);
                    if (shopDetail?.nft_project?.project_type) {
                        this.projectType = shopDetail.nft_project.project_type === 'normal' ? 'normal' : 'gamify';
                    }
                });
        }

        const marketplaceArr = [
            {
                marketplace: 'opensea',
                formControl: this.searchOpenseaUrl,
                dataKey: 'openseaData',
                flag: 'isInvalidOpenseaUrl',
                urlReg: /^https:\/\/(www.)?opensea.io\/collection\//,
            },
            {
                marketplace: 'element',
                formControl: this.searchElementUrl,
                dataKey: 'elementData',
                flag: 'isInvalidElementUrl',
                urlReg: /^https:\/\/(www.)?element.market\/collections\//,
            },
            {
                marketplace: 'magiceden',
                formControl: this.searchMagicEdenUrl,
                dataKey: 'magicEdenData',
                flag: 'isInvalidMagicEdenUrl',
                urlReg: /^https:\/\/(www.)?magiceden.io\/marketplace\//,
            },
            {
                marketplace: 'coinmarketcap',
                formControl: this.searchCoinMarketCapUrl,
                dataKey: 'coinMarketCapData',
                flag: 'isInvalidCoinMarketCapUrl',
                urlReg: /^https:\/\/(www.)?coinmarketcap.com\/currencies\//,
            },
        ];

        for (const item of marketplaceArr) {
            item.formControl.valueChanges
                .pipe(
                    takeUntil(this.unsubscribe),
                    filter(text => {
                        if (!item.urlReg.test(text)) {
                            this[item.dataKey] = null;
                            this[item.flag] = true;
                        }
                        return item.urlReg.test(text);
                    }),
                    debounceTime(500)
                )
                .subscribe(text => {
                    const baseUrl = text.split('?')[0];
                    const slug = baseUrl.replace(item.urlReg, '').replace(/\/$/, '');
                    this.nidavellirService
                        .isValidProjectUrl(item.marketplace, slug)
                        .then((res: any) => {
                            this[item.dataKey] = res;
                            this[item.flag] = false;
                        })
                        .catch(() => {
                            this[item.dataKey] = null;
                            this[item.flag] = true;
                        });
                });
        }

        this.selectedPlan = PLANS.find(item => item.id === this.profileService.projectPlan?.sub_plan) || PLANS[0];
    }

    changeProjectType(type) {
        if (this.projectType === type) return;
        if (this.selectedPlan.id === SubscriptionPlanEnum.FREE && type === 'gamify') return;
        [this.isSkip, this.prevIsSkip] = [this.prevIsSkip, this.isSkip];
        const temp = this.prevCollectionType;
        this.prevCollectionType = this.collectionType;
        this.changeCollection(temp);
        this.projectType = type;
    }

    changeCollection(type) {
        if (this.collectionType === type) return;

        [this.importProject, this.prevImportProject] = [this.prevImportProject, this.importProject];
        this.collectionType = type;
    }

    getNFTProducts() {
        this.loadingService.showLoading();
        this.nidavellirService
            .getNFTMerchTemplates()
            .then(res => {
                this.productList = res;
                // this.productList.forEach(ele => {
                //     ele.checked = true;
                //     this.selectedNFTMerchIds.add(ele.id);
                // });
            })
            .catch(() => this.message.error())
            .finally(() => this.loadingService.hideLoading());
    }

    selectPlatformChange(e, media) {
        media.name = e;
        this.platforms.forEach(plat => {
            plat.select = !!this.projectFormParams.social_medias.find(item => plat.value === item.name);
        });
    }

    addSocialMedia() {
        this.projectFormParams.social_medias.push({ name: null, link: null });
    }

    removeSocialMedia(i) {
        this.platforms.forEach(plat => {
            if (plat.value === this.projectFormParams.social_medias[i].name) {
                plat.select = false;
            }
        });
        this.projectFormParams.social_medias.splice(i, 1);
    }

    chooseNFTMerch(value: boolean, data: NFTMerchProduct) {
        if (value) {
            this.selectedNFTMerchIds.add(data.id);
        } else {
            this.selectedNFTMerchIds.delete(data.id);
        }
    }

    getCreateParams() {
        const openseaData = cloneDeep(this.openseaData);
        const magicEdenData = cloneDeep(this.magicEdenData);
        const elementData = cloneDeep(this.elementData);
        const coinMarketCapData = cloneDeep(this.coinMarketCapData);
        const projectFormParams = cloneDeep(this.projectFormParams);
        let params;

        if (this.importProject === ImportProjectType.OPENSEA) {
            params = {
                ...openseaData,
                platform: ImportProjectType.OPENSEA,
            };
        } else if (this.importProject === ImportProjectType.MAGIC_EDEN) {
            params = {
                ...magicEdenData,
                platform: ImportProjectType.MAGIC_EDEN,
            };
        } else if (this.importProject === ImportProjectType.ELEMENT) {
            params = {
                ...elementData,
                platform: ImportProjectType.ELEMENT,
            };
        } else if (this.importProject === ImportProjectType.COINMARKETCAP) {
            params = {
                ...coinMarketCapData,
                platform: ImportProjectType.COINMARKETCAP,
            };
        } else {
            params = {
                ...projectFormParams,
                platform: ImportProjectType.MANUAL,
            };
        }
        params.social_medias = params.social_medias.filter(ele => ele.link);
        if (this.pageType === 'admin' && this.shopDetail?.nft_project) {
            params.update_project = this.updateProject;
        }
        if (this.pageType !== 'admin' || !this.shopDetail?.nft_project) {
            params.project_type = this.projectType;
        }
        return params;
    }

    createProject() {
        let params;
        if (!this.isSkip) {
            params = this.getCreateParams();
        } else {
            params = {
                project_type: this.projectType,
                name: this.projectFormParams.name,
                resource: {
                    brand_avatar: null,
                    banner_bg: null,
                },
            };
        }
        params.merch_options = Array.from(this.selectedNFTMerchIds);
        params.sub_plan = this.selectedPlan.id;

        this.loadingService.showLoading();
        this.nidavellirService
            .createNFTProjectDetail(params)
            .then((res: any) => {
                if (res.status === 'active') {
                    this.message.success('Your brand page is created successfully!', { nzDuration: 5000 });
                }
                if (res.status === 'pending') {
                    this.message.success('Your page is under review, we will notify you when it is ready.', { nzDuration: 5000 });
                }

                this.router.navigate([res.project_key]);
            })
            .catch(err => {
                if (err.body?.code === 409) {
                    this.createErrorMsg = err.body.message;
                } else {
                    this.createErrorMsg = null;
                }
                this.contactUsVisible = true;
            })
            .finally(() => this.loadingService.hideLoading());
    }

    createShopProject() {
        if (this.isSkip) {
            this.skipCreate();
            return;
        }

        this.firebaseEventService.logEvent({
            name: AdminCustomEventEnum.IMPORT_COLLECTION_CUSTOM_EVENT,
            properties: {
                type: this.shopDetail.nft_project ? 'continue' : 'first_continue',
            },
        });

        this.isExceedQuota = false;

        const params = this.getCreateParams();

        this.loadingService.showLoading();
        this.nidavellirService
            .addNFTShopProject(params)

            // 不要使用 router.navigate
            // 不然 shop-detail.guard 不会拉取新的 shopDetail 信息
            // 除非 navigate 前手动设置 shopDetail 为 null
            .then(async (res: any) => {
                if (
                    ![BrandProjectStatus.PENDING, BrandProjectStatus.ACTIVE].includes(this.shopDetail?.nft_project?.status) &&
                    !this.shopDetail?.nft_project?.contract_list?.length
                ) {
                    sessionStorage.setItem(SESSION_STORAGE_KEY_ENUM.IS_FIRST_IMPORT_PROJECT, JSON.stringify(true));
                }
                if (res.is_exceed_quota) {
                    this.loadingService.hideLoading();
                    this.isExceedQuota = true;
                    this.showUpgrade(res);
                } else {
                    const { checkout_url } = await this.updatePlan();
                    if (checkout_url) {
                        this.location.replaceState('/admin/home');
                        window.location.href = checkout_url;
                    } else {
                        window.location.href = '/admin/home';
                    }
                }
            })
            .catch(err => {
                this.loadingService.hideLoading();
                if (err.body?.code === 426) {
                    this.showUpgradePremiumModal(err.body.message);
                } else {
                    if (err.body?.code === 409) {
                        this.createErrorMsg = err.body.message;
                    } else {
                        this.createErrorMsg = null;
                    }
                    this.contactUsVisible = true;
                }
            });
    }

    async skipCreate() {
        this.firebaseEventService.logEvent({
            name: AdminCustomEventEnum.IMPORT_COLLECTION_CUSTOM_EVENT,
            properties: { type: 'skip_now' },
        });
        this.loadingService.showLoading();
        this.nidavellirService
            .skipNFTShopProject({
                project_type: this.projectType,
            })
            .then(async () => {
                const { checkout_url } = await this.updatePlan();

                if (checkout_url) {
                    this.location.replaceState('/admin/home');
                    window.location.href = checkout_url;
                } else {
                    window.location.href = '/admin/home';
                }
            })
            .catch(() => {
                this.message.error();
                this.loadingService.hideLoading();
            });
    }

    showUpgrade(res: any) {
        this.modalService.create({
            nzContent: GeneralAdminModalComponent,
            nzFooter: null,
            nzWrapClassName: 'no-bg no-padding',
            nzClosable: false,
            nzCentered: true,
            nzWidth: this.screenService.isMobile() ? '100vw' : '520px',
            nzStyle: this.screenService.isMobile()
                ? {
                      verticalAlign: 'bottom',
                      maxWidth: '100vw',
                      padding: 0,
                      margin: 0,
                  }
                : {},
            nzComponentParams: {
                confirmText: 'UPGRADE',
                cancelText: 'MAYBE LATER',
                iconType: 'warning',
                description: `<div class="text-align-center">
                    <div class="fw-600 fs-16 mt-20">Oops, Wallet Analysis Quota Reached.</div>
                    <div style="margin: 5px 0 35px; line-height: 24px">Your collection size approaches or exceeds the available
                    quota of <span style="color: rgba(255, 61, 0, 0.9)">${res.quota_wallet} wallets</span>.
                    Upgrade your plan for enhanced community intelligence analysis.</div>
                </div>`,
            },
            nzOnOk: () => {
                this.router.navigateByUrl('/admin/subscription');
            },
            nzOnCancel: () => {
                window.location.href = '/admin/home';
            },
        });
    }

    showUpgradePremiumModal(errMessage) {
        this.modalService.create({
            nzContent: GeneralAdminModalComponent,
            nzFooter: null,
            nzWrapClassName: 'no-bg no-padding',
            nzClosable: false,
            nzCentered: true,
            nzWidth: this.screenService.isMobile() ? '100vw' : '480px',
            nzStyle: this.screenService.isMobile()
                ? {
                      verticalAlign: 'bottom',
                      maxWidth: '100vw',
                      padding: 0,
                      margin: 0,
                  }
                : {},
            nzComponentParams: {
                title: 'Premium Account Required',
                confirmText: 'APPLY',
                cancelText: 'CANCEL',
                description: errMessage,
            },
            nzOnOk: comp => {
                this.requestLifoService.showRequestServiceModal('Change pricing plan');
            },
        });
    }

    removeImg(key) {
        this.projectFormParams.resource[key] = null;
        if (key === 'banner_bg') {
            this.bannerFileBgUrl = null;
        }
        if (key === 'brand_avatar') {
            this.logoFileBgUrl = null;
        }
    }

    beforeUpload(file: File, flag: 'logo' | 'banner') {
        this.utilService
            .checkIfImagePixelValid(file, { width: 4472, height: 4472, size: 20 * 1024 })
            .then(() => {
                switch (flag) {
                    case 'logo':
                        this.logoFile = file;
                        this.logoFileBgUrl = getObjectURL(file);
                        break;
                    case 'banner':
                        this.bannerFile = file;
                        this.bannerFileBgUrl = getObjectURL(file);
                        break;
                }
                this.startUpload(file, flag);
            })
            .catch(() => {
                this.message.error('Please upload an image less than 4472 by 4472 pixels');
            });
    }

    startUpload(file: File, flag: 'logo' | 'banner') {
        const { uploadTask } = this.firebaseUploadService.startUpload(file, '/public/images/nft_brand');

        return uploadTask
            .then(downloadUrl => {
                switch (flag) {
                    case 'logo':
                        this.projectFormParams.resource.brand_avatar = downloadUrl;
                        this.logoFile = null;
                        break;
                    case 'banner':
                        this.projectFormParams.resource.banner_bg = downloadUrl;
                        this.bannerFile = null;
                        break;
                }
            })
            .catch(() => {
                switch (flag) {
                    case 'logo':
                        this.logoFile = null;
                        this.logoFileBgUrl = null;
                        break;
                    case 'banner':
                        this.bannerFile = null;
                        this.bannerFileBgUrl = null;
                        break;
                }
                this.message.error('Upload image failed, please try again later');
            });
    }

    logoBeforeUpload = file => {
        this.beforeUpload(file, 'logo');
        return false;
    };

    bannerBeforeUpload = file => {
        this.beforeUpload(file, 'banner');
        return false;
    };

    logBackEvent() {
        // 导入collection，如果提示超出配额，需要重新拉取一下shopDetail数据
        if (this.isExceedQuota) {
            this.profileService.getShopDetail().then(shopProfile => this.profileService.shopDetailSubject.next(shopProfile));
        }

        this.firebaseEventService.logEvent({
            name: AdminCustomEventEnum.IMPORT_COLLECTION_CUSTOM_EVENT,
            properties: { type: 'back' },
        });
    }

    selectPlan(plan: Plan) {
        this.selectedPlan = plan;

        if (this.selectedPlan.id === SubscriptionPlanEnum.FREE) {
            this.changeProjectType('normal');
        }
    }

    periodSwitchChange(status: boolean) {
        this.paymentPeriod = status ? 'annually' : 'monthly';
    }

    updatePlan() {
        if (this.selectedPlan.id === SubscriptionPlanEnum.FREE || this.selectedPlan.id === this.profileService.projectPlan?.sub_plan) {
            return Promise.resolve({ checkout_url: null });
        }

        return this.subscriptionPlanService
            .subscribePlan({
                sub_plan: this.selectedPlan.id,
                billing_period: this.paymentPeriod,
                success_url: `${window.location.protocol}//${window.location.host}/admin/subscription-success?redirect=/admin/home&show_success_tooltip=1`,
                cancel_url: `${window.location.protocol}//${window.location.host}/admin/home`,
            })
            .catch(() => ({ checkout_url: null }));
    }

    goSubscriptionPage() {
        this.router.navigateByUrl('/admin/subscription?redirect=/admin/brand-quick-start');
    }
}
