import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as tz from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(tz);
dayjs.extend(utc);
dayjs.extend(duration);

export const TIMEZONE_LIST = [
    {
        name: 'Hawaii Standard Time',
        value: 'HST',
    },
    {
        name: 'Atlantic Time',
        value: 'America/Anguilla',
    },
    {
        name: 'Pacific Time - Los Angeles',
        value: 'America/Los_Angeles',
    },
    {
        name: 'Mountain Time',
        value: 'Canada/Mountain',
    },
    {
        name: 'Central Time',
        value: 'US/Central',
    },
    {
        name: 'Eastern Time - New York',
        value: 'America/New_York',
    },
    {
        name: 'Greenwich Mean Time',
        value: 'GMT',
    },
    {
        name: 'Central European Standard Time',
        value: 'CET',
    },
    {
        name: 'Eastern European Standard Time',
        value: 'EET',
    },
    {
        name: 'Moscow Standard Time',
        value: 'Europe/Moscow',
    },
    {
        name: 'India Standard Time',
        value: 'Asia/Kolkata',
    },
    {
        name: 'China Standard Time - Shanghai',
        value: 'Asia/Shanghai',
    },
    {
        name: 'Japan Standard Time',
        value: 'Asia/Tokyo',
    },
    {
        name: 'Australian Eastern Standard Time',
        value: 'Australia/Brisbane',
    },
    {
        name: 'New Zealand Standard Time',
        value: 'NZ',
    },
];

@Injectable({
    providedIn: 'root',
})
export class DateTimeService {
    timezoneMap = new Map<string, string>();

    constructor() {
        try {
            this.getTimeZoneList().forEach(timezoneItem => this.timezoneMap.set(timezoneItem.value, timezoneItem.name));
        } catch (err) {
            console.warn(err);
            console.log('Error in DateTimeService constructor');
        }
    }

    replaceCurrentTzWithPST(date: string | number, format: string = null): string {
        return date ? dayjs.tz(dayjs(date).format('YYYY-MM-DD HH:mm:ss'), 'America/Los_Angeles').format(format) : '';
    }

    replaceCurrentTzWithTimezone(date: string | number, timezone: string, format: string = null): string {
        return date ? dayjs.tz(dayjs(date).format('YYYY-MM-DD HH:mm:ss'), timezone).format(format) : '';
    }

    formatDateWithTimezone(date: string | number, format = 'YYYY-MM-DD HH:mm:ss', timezone = 'America/Los_Angeles') {
        return date ? dayjs(date).tz(timezone).format(format) : '';
    }

    parseDateWithTimeZone(date: string, timezone = 'America/Los_Angeles', format = 'YYYY-MM-DD HH:mm:ss') {
        return date ? dayjs(dayjs(date).tz(timezone).format(format)).toString() : '';
    }

    formatToW3WStandardDate(date: string | Date) {
        return date ? dayjs(date).format('HH:mm MMM DD, YYYY') : '';
    }

    getTimeZoneList(): { name: string; value: string }[] {
        const data = [];
        TIMEZONE_LIST.forEach(({ name, value }) => {
            const offsetMinutes = dayjs.tz(dayjs(), value).utcOffset();
            const offsetFormatted = dayjs('2020-01-01 00:00:00').add(Math.abs(offsetMinutes), 'minutes');
            const timezoneItem = {
                name: `(GMT${offsetMinutes < 0 ? '-' : '+'}${offsetFormatted.format('HH:mm')}) ${name}`,
                value,
            };
            data.push(timezoneItem);
        });
        return data;
    }
}
