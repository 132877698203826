import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateTimeService } from '@src/app/services/date-time.service';
import * as dayjs from 'dayjs';
import { CampaignStatus, ProjectCampaign } from '../../admin/campaign/campaign.model';
import { walletImgMap } from '../../main/advanced-customization/campaign-detail/campaign-detail.service';
import { NidavellirService } from '../../main/nidavellir.service';
import { WalletStatusService } from '../../main/wallet-status.service';
import { RequiredCampaignDataService } from './required-campaign-data.service';

@Component({
    selector: 'app-required-campaign-data',
    templateUrl: './required-campaign-data.component.html',
    styleUrls: ['./required-campaign-data.component.less'],
    providers: [RequiredCampaignDataService, NidavellirService],
})
export class RequiredCampaignDataComponent implements OnInit {
    get campaignId() {
        return this.requiredCampaignDataService.campaignId;
    }

    constructor(
        private requiredCampaignDataService: RequiredCampaignDataService,
        private activatedRoute: ActivatedRoute,
        private nidavellirService: NidavellirService,
        private dateTimeService: DateTimeService
    ) {}

    ngOnInit(): void {
        // Initial Variable
        this.requiredCampaignDataService.campaignId = Number(this.activatedRoute.snapshot.paramMap.get('campaign_id'));
        this.requiredCampaignDataService.referrer = this.activatedRoute.snapshot.queryParamMap.get('referrer');

        // Set Data
        this.getCampaignDetail();
        this.requiredCampaignDataService.setWalletChain();
    }

    private getCampaignDetail() {
        this.nidavellirService
            .getNftCampaignDetail(this.campaignId)
            .then(res => this.handlerAfterGetCampaignDetail(res))
            .catch(err => {
                // if (err.status === 400) {
                //     this.messageService.error(err.body.message, { nzDuration: 5000 });
                //     setTimeout(
                //         () =>
                //             this.router.navigate(['/', 'main', this.acStateService.projectKey], {
                //                 queryParams: { back: this.activatedRoute.snapshot.queryParamMap.get('back') },
                //             }),
                //         300
                //     );
                // } else {
                //     this.messageService.error();
                // }
            });
    }

    private handlerAfterGetCampaignDetail(campaignDetail: ProjectCampaign) {
        let status: CampaignStatus;
        const start = this.dateTimeService.formatToW3WStandardDate(campaignDetail.start_date);
        const end = this.dateTimeService.formatToW3WStandardDate(campaignDetail.end_date);

        if (dayjs(campaignDetail.start_date).isAfter(dayjs())) {
            status = CampaignStatus.UPCOMING;
        } else if (dayjs(campaignDetail.end_date).isBefore(dayjs())) {
            status = CampaignStatus.ENDED;
        } else {
            status = CampaignStatus.ACTIVE;
        }
        const campaignInfo = {
            ...campaignDetail,
            status,
            handledDuration: `${start} - ${end}`,
        };
        this.requiredCampaignDataService.campaignInfo = campaignInfo;
        this.requiredCampaignDataService.campaignInfo$.next(campaignInfo);
    }
}

@Component({
    selector: 'app-public-campaign-detail-pre-disconnect',
    template: `
        <div
            *ngIf="walletStatusService.walletType"
            style="box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15); font-family: 'Kanit', sans-serif"
            [ngStyle]="{ 'background-color': disconnectMobileVisible ? '#181c20' : '#353d46' }"
        >
            <header *ngIf="disconnectMobileVisible" class="d-flex justify-content-end" style="padding: 20px 20px 0">
                <div
                    class="d-flex align-items-center justify-content-center fs-18"
                    style="width: 44px; height: 44px; border: 2px solid #72bbfd; color: #72bbfd"
                    (click)="closeModal()"
                >
                    <i nz-icon nzType="close"></i>
                </div>
            </header>

            <div class="d-flex justify-content-center align-items-center p-20" style="border-bottom: 1px solid #000">
                <img width="28" height="28" class="object-fit-contain" [src]="walletImgMap.get(walletStatusService.walletType)" />
                <span class="pl-10" [ngStyle]="{ 'font-size': disconnectMobileVisible ? '18px' : '14px' }">
                    {{ walletStatusService.walletAddress | omit : 10 : 10 }}
                </span>
            </div>
            <div
                style="padding: 20px 30px; white-space: nowrap; color: #72bbfd; display: flex; justify-content: center; align-items: center"
                class="active-mask cursor-pointer"
                (click)="preDisconnect()"
            >
                <i class="mr-20 vertical-align-middle" style="font-size: 22px" nz-icon nzType="poweroff" nzTheme="outline"></i>
                <span class="fs-16 fw-600">Disconnect wallet</span>
            </div>
        </div>
    `,
    styles: [],
})
export class PublicCampaignDetailPreDisconnectComponent {
    @Input() disconnectMobileVisible: boolean;
    @Output() disconnectMobileVisibleChange = new EventEmitter<boolean>();

    @Output() disconnect = new EventEmitter();

    walletImgMap = walletImgMap;

    constructor(public walletStatusService: WalletStatusService) {}

    closeModal() {
        this.disconnectMobileVisibleChange.emit(false);
    }

    preDisconnect() {
        this.disconnect.emit();
    }
}
