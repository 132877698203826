<div class="select-nft-collection" [ngClass]="[themeMode]">
    <app-general-modal-header *ngIf="themeMode === 'modal'" [title]="'Select NFT Collection'" [isBrandModal]="true" (cancel)="cancel.emit()"> </app-general-modal-header>

    <!-- <p class="mt-2.5">BNB Chain is not Supported.</p> -->

    <section *ngIf="tempSelectedNftCollections?.length" class="selected-box mb-2.5">
        <span class="pr-10" style="color: #fff">Selected: </span>

        <nz-tag *ngFor="let item of tempSelectedNftCollections" [nzMode]="'closeable'" (nzOnClose)="removeCollection(item)"> {{item.name | nzEllipsis:30:'...'}} </nz-tag>
    </section>

    <section class="my-collections">
        <div class="section-title mb-2.5">My Collections</div>
        <div class="section-content">
            <button *ngIf="tempMyCollectionList.length" style="padding-left: 0; margin-bottom: 10px" nz-button nzType="link" (click)="selectAllMyCollection()">Select All</button>
            <p *ngIf="!tempMyCollectionList?.length" class="mt-2.5">You don't have any collection yet.</p>
            <div *ngIf="tempMyCollectionList?.length" class="collection-list">
                <div *ngFor="let collection of tempMyCollectionList" class="collection-item">
                    <label
                        nz-checkbox
                        [nzValue]="collection.id"
                        [nzChecked]="tempSelectedNftCollectionIds.has(collection.id)"
                        (nzCheckedChange)="checkStatusChange(collection,$event)"
                        [nzDisabled]="isDisabledCollection(collection)"
                        nz-tooltip
                        nzTooltipPlacement="bottom"
                        [nzTooltipTitle]="(isDisabledCollection(collection)) ? 'Available for ERC-721 & ERC-1155 communities' : null"
                    >
                        <img *ngIf="collection.image" src="{{collection.image}}" class="mr-2.5 ml-2.5" style="width: 20px; height: 20px; border-radius: 50%; object-fit: cover" />
                        <span class="overflow-ellipsis">{{collection.name | nzEllipsis:30:'...'}}</span>
                    </label>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="section-title">NFT Collections</div>
        <div class="section-content search-box">
            <nz-input-group [nzPrefix]="prefixIconSearch">
                <input #searchInput type="text" nz-input placeholder="Search NFT Collection" class="width-100p" [(ngModel)]="searchVal" />
            </nz-input-group>
            <ng-template #prefixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>
        </div>
    </section>

    <section class="popular-collections">
        <div class="title">Popular Collections</div>

        <div class="collection-list" appLoading [loading]="fetchingCollectionList">
            <cdk-virtual-scroll-viewport itemSize="40" orientation="vertical" style="width: 100%; height: 200px">
                <div *cdkVirtualFor="let collection of popularCollectionList" class="collection-item">
                    <div>
                        <label
                            nz-checkbox
                            [nzValue]="collection.id"
                            [nzChecked]="tempSelectedNftCollectionIds.has(collection.id)"
                            (nzCheckedChange)="checkStatusChange(collection,$event)"
                        >
                        </label>
                        <img
                            *ngIf="collection.image"
                            [src]="collection.image"
                            class="mr-2.5 ml-2.5"
                            style="width: 20px; height: 20px; border-radius: 50%; object-fit: cover; margin-top: -4px"
                        />

                        <span class="overflow-ellipsis" [class.marketplace-link]="!!collection.marketplaceLink" (click)="openMarketplaceLink(collection.marketplaceLink)">
                            {{collection.name | nzEllipsis:(screenService.isMobile() ? 25 : 30):'...'}}
                        </span>

                        <span *ngIf="collection.isVerified" class="verified">
                            <img *ngIf="collection.platform === 'opensea'" src="/assets/imgs/admin/opensea-logo.png" />
                            <img *ngIf="collection.platform === 'magiceden'" src="/assets/imgs/admin/magiceden.png" />
                            <img *ngIf="collection.platform === 'element'" src="/assets/imgs/admin/element.png" />
                            <span class="colorful-text">Verified</span>
                        </span>
                    </div>
                </div>

                <p *ngIf="!popularCollectionList?.length && !fetchingCollectionList" class="mt-2.5 mb-2.5">No collection contains "{{searchVal}}"</p>
            </cdk-virtual-scroll-viewport>
        </div>
    </section>

    <footer *ngIf="themeMode === 'modal'">
        <button class="cancel" nz-button nzGhost nzShape="round" (click)="cancel.emit()">Cancel</button>
        <button nz-button nzShape="round" nzType="primary" (click)="confirmClicked()">Confirm</button>
    </footer>
</div>
