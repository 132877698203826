import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Platforms, SocialMediaEnum } from '../../create-project/create-project.component';

@Component({
    selector: 'app-add-social-media-modal',
    templateUrl: './add-social-media-modal.component.html',
    styleUrls: ['./add-social-media-modal.component.less'],
})
export class AddSocialMediaModalComponent implements OnInit {
    @Input() socialMedias: { name: string; link: string }[];

    submitting = false;

    brandSocialMedias: { name: string; link: string }[];
    platforms = Platforms;

    constructor(public modalRef: NzModalRef) {}

    ngOnInit() {
        this.brandSocialMedias = this.socialMedias?.length ? cloneDeep(this.socialMedias) : [{ name: SocialMediaEnum.DISCORD, link: null }];
        this.brandSocialMedias.forEach(ele => this.selectPlatformChange(ele.name, ele));
    }

    selectPlatformChange(e, media) {
        media.name = e;
        this.platforms.forEach(plat => {
            plat.select = !!this.brandSocialMedias.find(item => plat.value === item.name);
        });
    }

    addSocialMedia() {
        this.brandSocialMedias.push({ name: null, link: null });
    }

    removeSocialMedia(i) {
        this.platforms.forEach(plat => {
            if (plat.value === this.brandSocialMedias[i].name) {
                plat.select = false;
            }
        });
        this.brandSocialMedias.splice(i, 1);
    }
}
