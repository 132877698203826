<div class="lifo-toolbar">
    <button nz-button nzType="text" [class.active]="selectedToolbars?.has(ToolBarEnum.BOLD)" (click)="selectToolBarItem(ToolBarEnum.BOLD)">
        <i nz-icon nzType="bold" nzSize="small" nzTheme="outline"></i>
    </button>
    <button
        nz-button
        nzType="text"
        [class.active]="selectedToolbars?.has(ToolBarEnum.ITALIC)"
        (click)="selectToolBarItem(ToolBarEnum.ITALIC)"
    >
        <i nz-icon nzType="italic" nzSize="small" nzTheme="outline"></i>
    </button>
    <button
        nz-button
        nzType="text"
        [class.active]="selectedToolbars?.has(ToolBarEnum.TEXT_DECORATION) && textDecoration === 'underline'"
        (click)="selectToolBarItem(ToolBarEnum.TEXT_DECORATION,'underline')"
    >
        <i nz-icon nzType="underline" nzSize="small" nzTheme="outline"></i>
    </button>
    <button
        nz-button
        nzType="text"
        [class.active]="selectedToolbars?.has(ToolBarEnum.TEXT_DECORATION) && textDecoration === 'line-through'"
        (click)="selectToolBarItem(ToolBarEnum.TEXT_DECORATION,'line-through')"
    >
        <i nz-icon nzType="strikethrough" nzSize="small" nzTheme="outline"></i>
    </button>
    <button
        nz-button
        nzType="text"
        [class.active]="selectedToolbars?.has(ToolBarEnum.TEXT_ALIGN) && textAlign === 'left'"
        (click)="selectToolBarItem(ToolBarEnum.TEXT_ALIGN,'left')"
    >
        <i nz-icon nzType="align-left" nzSize="small" nzTheme="outline"></i>
    </button>
    <button
        nz-button
        nzType="text"
        [class.active]="selectedToolbars?.has(ToolBarEnum.TEXT_ALIGN) && textAlign === 'center'"
        (click)="selectToolBarItem(ToolBarEnum.TEXT_ALIGN,'center')"
    >
        <i nz-icon nzType="align-center" nzSize="small" nzTheme="outline"></i>
    </button>
    <button
        nz-button
        nzType="text"
        [class.active]="selectedToolbars?.has(ToolBarEnum.TEXT_ALIGN) && textAlign === 'right'"
        (click)="selectToolBarItem(ToolBarEnum.TEXT_ALIGN,'right')"
    >
        <i nz-icon nzType="align-right" nzSize="small" nzTheme="outline"></i>
    </button>
    <button nz-button nzType="text">
        <span
            style="
                display: inline-block;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                vertical-align: text-bottom;
                border: 1px solid #eee;
            "
            [style.background]="color || '#000'"
        ></span>
        <input
            class="color-picker"
            [colorPicker]="color || '#000'"
            cpPosition="auto"
            (colorPickerChange)="selectToolBarItem(ToolBarEnum.TEXT_COLOR,$event)"
        />
    </button>
</div>
