import { environment } from '@src/environments/environment';
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

export enum CustomEventEnum {
    CONNECT_WALLET = 'connect_wallet',
    UPDATE_ADDRESS = 'update_address',
    BEGIN_CHECKOUT = 'begin_checkout',
    ADD_TO_CART = 'add_to_cart',

    VIEW_CAMPAIGN_DETAIL = 'view_campaign_detail',
    JOIN_CAMPAIGN = 'join_campaign',
    START_CONNECT_SOCIAL_MEDIA = 'start_connect_social_media',
    SOCIAL_MEDIA_CONNECTED = 'social_media_connected',
    SHOW_CONNECT_WALLET_MODAL = 'show_connect_wallet_modal',
    HELIO_PAY_CHECKOUT = 'helio_pay_checkout',
    SHARE_CAMPAIGN = 'share_campaign',

    VIEW_DAPP_IN_LIST = 'view_dapp_in_list',
    CLICK_DAPP_CARD = 'click_dapp_card',
    VIEW_DAPP_DETAIL = 'view_dapp_detail',
    CLICK_DAPP_URL = 'click_dapp_url',
    DOWNLOAD_DAPP = 'download_dapp',
    LAUNCH_DAPP = 'download_dapp',
}

export enum AdminCustomEventEnum {
    SING_UP = 'sign_up',
    LOGIN = 'login',
    START_SEND_EMAIL = 'start_send_email',
    SEND_EMAIL = 'send_email',
    START_SEND_AIRDROP = 'start_send_airdrop',
    START_CREATE_CAMPAIGN = 'start_create_campaign',
    HEADER_NAV_CLICK = 'header_nav_click',
    CREATE_CAMPAIGN = 'create_campaign',
    EDIT_CAMPAIGN = 'edit_campaign',
    START_IMPORT_COLLECTION = 'start_import_collection',
    IMPORT_COLLECTION_CUSTOM_EVENT = 'import_collection_custom_event',
    HOME_PAGE_FEATURE_CLICK = 'home_page_feature_click',
    BATCH_REMOVE_CUSTOM_TAG = 'batch_remove_custom_tag',
    BATCH_EDIT_CUSTOM_TAG = 'batch_edit_custom_tag',
    SINGLE_EDIT_CUSTOM_TAG = 'single_edit_custom_tag',
    SINGLE_REMOVE_CUSTOM_TAG = 'single_remove_custom_tag',
    ADVANCED_FILTER = 'advanced_filter',
    START_SAVE_FILTER_AS_TAG = 'start_save_filter_as_tag',
    CONFIRM_SAVE_FILTER_AS_TAG = 'confirm_save_filter_as_tag',
    SEND_AIRDROP_UPLOAD_NFT_IMG = 'send_airdrop_upload_nft_img',
    CONFIRM_SEND_AIRDROP = 'confirm_send_airdrop',
    START_IMPORT_ADDRESS = 'start_import_address',
    IMPORT_ADDRESS_ADD_CSV = 'import_address_add_csv',
    IMPORT_ADDRESS_ADD_NEW_ADDRESS_CLICKED = 'import_address_add_new_address_clicked',
    IMPORT_ADDRESS_ADD_CUSTOM_TAG = 'import_address_add_custom_tag',
    SAVE_IMPORT_ADDRESS = 'save_import_address',
    VIEW_PROJECT_BY_EMAIL_TPL = 'view_project_by_email_tpl',
    EXPORT_ADDRESS = 'export_address',
    START_CONNECT_TO_DISCORD = 'start_connect_to_discord',
    ADD_NEW_SERVER = 'add_new_server',
    START_ADD_ROLES = 'start_add_roles',
    ROLE_CREATE_NEW_CLICK = 'role_create_new_click',
    ROLE_ADD_ELIGIBLE_CLICK = 'role_add_eligible_click',
    CONFIRM_ADD_ROLES = 'confirm_add_roles',
    SEARCH_WALLET_ADDRESS = 'search_wallet_address',
    CONNECT_DISCORD_SUCCESS = 'connect_discord_success',
    START_ADVANCED_FILTER = 'start_advanced_filter',
}

export interface CustomFirebaseEvent {
    name: string;
    properties?: any;
}

@Injectable({
    providedIn: 'root',
})
export class FirebaseEventsService {
    constructor(private analytics: AngularFireAnalytics) {
        this.analytics.setAnalyticsCollectionEnabled(environment.production);
    }

    logEvent(event: CustomFirebaseEvent | string) {
        if (typeof event === 'string') {
            this.analytics.logEvent(event);
        } else {
            this.analytics.logEvent(event.name, event.properties);
        }
        // eslint-disable-next-line no-console
        console.debug('event_sent:', event);
    }

    setupFirebaseEvent(params: { uid?: any }) {
        if (params.uid) {
            this.analytics.setUserId(params.uid);
        }
    }

    addShopIdForDefaultEvent(shopId: any) {
        this.analytics.updateConfig({ shop_id: shopId });
    }
}
