<section *ngIf="config" class="report-section" [class.light]="config.theme === 'light'">
    <div>
        <div class="section-title">
            <span>
                <img *ngIf="config.sectionNameImgLink" [src]="config.sectionNameImgLink" />
                <span>{{config.sectionName}}</span>
            </span>
        </div>

        <div *ngIf="config.sectionDescription" class="section-desc" [innerHTML]="config.sectionDescription | safeHtml"></div>
    </div>
    <main class="content">
        <div class="chart">
            <section class="chart-ele" appEcharts [initialOption]="initialOption" [optionToBeUpdate]="config.chartConfig"></section>

            <div *ngIf="config.donutChartCenterInfo" class="additional-info">
                <div class="name">{{config.donutChartCenterInfo.name}}</div>
                <div class="count">
                    <img *ngIf="config.donutChartCenterInfo.img" [src]="config.donutChartCenterInfo.img" />
                    <ng-container *ngIf="isNumber(config.donutChartCenterInfo.count); else strCount">
                        {{config.donutChartCenterInfo.count | number:'1.0-0'}}
                    </ng-container>
                    <ng-template #strCount>{{config.donutChartCenterInfo.count}}</ng-template>
                </div>
            </div>
        </div>

        <div class="legend-info">
            <div *ngFor="let legend of config.legendConfig;let i = index;" class="legend-item">
                <div class="line1">
                    <div class="dot" [style.backgroundColor]="LIFO_CHART_COLORS[i]"></div>
                    <span class="count">{{legend.count|number:'1.0-0'}}</span>
                    <div class="divider"></div>
                    <div class="percent">{{legend.percent | percent:'1.1-1'}}</div>
                </div>
                <div class="line2">{{legend.name}}</div>
            </div>
        </div>
    </main>
</section>
