<div class="landscape-card" [style.backgroundColor]="cardInfo.color">
    <div class="inner-card-item">
        <span>{{cardInfo.name | uppercase}}</span>
        <span class="count">
            <ng-container *ngIf="isNumber(cardInfo.count);else emptyCount">
                {{cardInfo.count|number:'1.0-0'}}<ng-container *ngIf="cardInfo.countType === 'percent'">%</ng-container>
            </ng-container>
        </span>
        <span>{{cardInfo.desc | uppercase}}</span>
    </div>
</div>

<ng-template #emptyCount>-</ng-template>
