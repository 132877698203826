import { Injectable } from '@angular/core';
import { ProjectCampaign, SocialMedia } from '@modules/admin/campaign/campaign.model';
import { SocialMediaConnectService } from '@services/socialmedia-connect.service';
import { RequestService } from '@src/app/services/request.service';
import { encrypt } from '@src/app/shared/utils/pem';
import { BehaviorSubject, Subject } from 'rxjs';
import { CreateCampaign } from './../../main/advanced-customization/advanced-customization.model';

export enum W3W_CAMPAIGN_CUSTOM_EVENT_ENUM {
    CAMPAIGN_JOIN_SUCCESS = 'w3wCampaignJoinSuccess',
    CAMPAIGN_JOIN_FAILED = 'w3wCampaignJoinFailed',
    CAMPAIGN_CONNECT_WALLET = 'w3wConnectWallet',
    CAMPAIGN_DISCONNECT_WALLET = 'w3wDisconnectWallet',
    CAMPAIGN_PAGE_SIZE_CHANGED = 'w3wPageSizeChanged',
}
@Injectable({
    providedIn: 'root',
})
export class CampaignChallengeService {
    campaignId: number;
    campaignInfo: ProjectCampaign;
    campaignInfo$ = new BehaviorSubject<ProjectCampaign>(null);
    walletAddress$ = new BehaviorSubject<string>(null);

    subscriptions = {
        pageLeave: new Subject(),
    };

    // 当前 Address 的一些额外信息
    userInfo$ = new BehaviorSubject<{
        emailList: string[];
        referralLink: string;
        points: number;
        invitationCode: string;
        campaignEntryList: { campaignId: number }[];
        walletContactList: {
            contact: any;
            contactId: any;
            contactType: any;
        }[];
    }>(null);

    get walletAddress() {
        return this.walletAddress$.value;
    }

    get discordUserInfo() {
        return this.discordUserInfo$.getValue();
    }

    get twitterUserInfo() {
        return this.twitterUserInfo$.getValue();
    }

    get telegramUserInfo() {
        return this.telegramUserInfo$.getValue();
    }

    get discordUserInfo$() {
        return this.socialMediaConnectService.discordUserInfo$;
    }

    get twitterUserInfo$() {
        return this.socialMediaConnectService.twitterUserInfo$;
    }

    get telegramUserInfo$() {
        return this.socialMediaConnectService.telegramUserInfo$;
    }

    constructor(private socialMediaConnectService: SocialMediaConnectService, private requestService: RequestService) {}

    updateUserInfo(data: {
        walletAddress: string;
        email?: string;
        walletName?: string;
        discord?: string;
        contactList?: {
            contact: string;
            contactId: string;
            contactType: string;
        }[];
    }) {
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'POST',
            url: `/external/nft_account/${data.walletAddress}`,
            api: 'admin-api',
            data,
        });
    }

    getNftCampaignDetail() {
        return this.requestService.sendUnauthorizedRequest<ProjectCampaign>({
            method: 'GET',
            url: `/customer/campaign?id=${this.campaignId}`,
            api: 'admin-api',
        });
    }

    checkCampaignChallengeStatus(data: { campaignId: any; walletAddress: string; challengeType?: string }) {
        return this.requestService.sendUnauthorizedRequest<{
            checkDetails: {
                atAccounts: number;
                originalChallenge: {
                    challengeType: SocialMedia;
                    checkType: 'follow' | 'retweet' | 'join' | null;
                    challengeInput: string;
                    id: number;
                    atAccounts: number;
                    hashtags: string[];
                };
                result: boolean;
                message: string;
                status: null | 401 | 429 | number;
                hashtagChecks: { [key: string]: boolean };
                verifyRoleChecks: { [key: number]: boolean };
                serverJoin: boolean;
            }[];
            success: boolean;
        }>({
            method: 'POST',
            url: '/external/campaign/campaign_challenges/check',
            api: 'data-api',
            data,
        });
    }

    entryNftCampaign(data: CreateCampaign) {
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'POST',
            url: '/customer/campaign/entry',
            api: 'admin-api',
            data: {
                ...data,
                encrypt_wallet_address: encrypt(data.wallet_address),
            },
        });
    }

    subscribeWalletAddressUpdate(data: { chain: string; address: string }) {
        // 09/14 Switch to new API
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'GET',
            url: `/external/wallet_data?address=${data.address}`,
            api: 'admin-api',
        });
    }

    getUserInfo(address: string) {
        return this.requestService.sendUnauthorizedRequest({
            method: 'GET',
            url: `/external/nft_account/${address}`,
            api: 'admin-api',
        });
    }
}
