import { Component, Input, OnInit, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { EchartsOption } from '@src/app/shared/directives/echarts.directive';
import { LIFO_CHART_COLORS } from '@src/app/shared/types/general.type';
import { isNumber } from 'lodash';
import { AbstractLifoChartComponent, LifoChartConfig } from './abstract-lifo-chart.component';

@Component({
    selector: 'app-general-lifo-chart',
    templateUrl: './general-lifo-chart.component.html',
    styleUrls: ['./general-lifo-chart.component.less'],
})
export class GeneralLifoChartComponent extends AbstractLifoChartComponent implements OnChanges {
    @Input() lifoChartConfig: LifoChartConfig = {
        chartType: 'echarts',
        sectionName: 'General Lifo Chart',
        sectionDescription: 'This is a general lifo chart.',
        theme: 'dark',
    };
    @Input() MAX_VERTICAL_LEGENDS_COUNT = 3;
    @Input() fetchingData = false;
    @Input() selectedTopRightItemValue: any;
    @Input() noEchartsChartData = false;

    @Output() selectedTopRightItemValueChange = new EventEmitter<any>();

    LIFO_CHART_COLORS = LIFO_CHART_COLORS;

    isNumber = isNumber;

    initialOptions: EchartsOption = {
        legend: { show: false },
        backgroundColor: 'transparent',
        color: LIFO_CHART_COLORS,
    };

    maxCount = 0;

    get hasOuterLegend(): boolean {
        return !!this.lifoChartConfig.legendConfig;
    }

    get chartSize(): string {
        return this.lifoChartConfig?.chartHeight || '160px';
    }

    constructor() {
        super();
    }

    ngOnChanges(e: SimpleChanges) {
        if (e.lifoChartConfig && e.lifoChartConfig.currentValue && e.lifoChartConfig.currentValue.customListChartConfig) {
            this.maxCount = Math.max(...this.lifoChartConfig.customListChartConfig.map(item => item.count));
        }

        if (this.lifoChartConfig?.colors) {
            this.LIFO_CHART_COLORS = this.lifoChartConfig.colors;
            this.initialOptions.color = this.lifoChartConfig.colors;
        }
    }

    getBackgroundColor(): string {
        let color: string;
        if (this.lifoChartConfig.chartType === 'textBarOneLine') {
            color = '#766FFA';
        }

        if (this.lifoChartConfig.chartType === 'textBarTwoLine') {
            color = '#1271F1';
        }

        if (this.lifoChartConfig.customListChartConfig[0]?.color) {
            color = this.lifoChartConfig.customListChartConfig[0]?.color;
        }

        return color;
    }

    onTopRightItemChange(selectedRightItemValue: any) {
        this.selectedTopRightItemValueChange.emit(selectedRightItemValue);
    }
}
