import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'omit',
})
export class OmitPipe implements PipeTransform {
    transform(value: any, startNum: number, endNum: number, character?: string): string {
        const handledVal = value ? String(value) : '';

        if (handledVal.length <= startNum + endNum) {
            return value;
        }

        return `${handledVal.slice(0, startNum)}${character || '...'}${handledVal.slice(handledVal.length - endNum)}`;
    }
}
