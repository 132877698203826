import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ShopProfileService } from '@services/shop-profile.service';
import { AdminCustomEventEnum, FirebaseEventsService } from '@src/app/services/firebase/firebase-event.service';
import { SocialMediaConnectService } from '@src/app/services/socialmedia-connect.service';
import { SESSION_STORAGE_KEY_ENUM } from '@src/app/shared/types/general.type';
import firebase from 'firebase/compat/app';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-sign-in-modal',
    templateUrl: './sign-in-modal.component.html',
    styleUrls: ['./sign-in-modal.component.less'],
})
export class SignInModalComponent implements OnInit {
    validateForm!: FormGroup;
    checked = true;
    submitting = false;
    constructor(
        public modalRef: NzModalRef,
        private fb: FormBuilder,
        private auth: AngularFireAuth,
        private message: NzMessageService,
        private profileService: ShopProfileService,
        private firebaseEventService: FirebaseEventsService,
        private socialMediaConnectService: SocialMediaConnectService,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            email: [null, [Validators.required]],
            password: [null, [Validators.required]],
            remember: [false, [Validators.required]],
        });
    }

    goSignUp() {
        this.modalRef.triggerCancel();
        this.profileService.showSignUpModal();
    }

    goForgotPassword() {
        this.modalRef.triggerCancel();
        this.profileService.showResetPasswordModal();
    }

    submitForm(): void {
        if (this.submitting) {
            return;
        }
        if (!this.validateForm.valid) {
            throw new Error('Form is not valid');
        }
        this.submitting = true;
        const signInInformation = this.validateForm.getRawValue();
        this.auth.signInWithEmailAndPassword(signInInformation.email, signInInformation.password).then(
            async data => {
                this.firebaseEventService.logEvent(AdminCustomEventEnum.LOGIN);
                await this.auth.setPersistence(
                    signInInformation.remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION
                );
                await this.modalRef.triggerCancel();

                window.location.href = this.activatedRoute.snapshot.queryParamMap.has('show_subscription')
                    ? '/admin/subscription'
                    : '/admin/home';

                // 清空 B端 sessionStorage 存储的discord信息
                window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.SESSION_ROLE_DISCORD_INFO_KEY);

                // 清空 C 端 localStorage 存储的discord信息
                this.socialMediaConnectService.clearSocialMediaInfo();
            },
            err => this.errorHandler(err)
        );
    }

    private errorHandler(error) {
        this.submitting = false;

        if (error.code === 'auth/wrong-password') {
            return this.message.create('error', 'Sorry, the email or password is incorrect.');
        }

        if (error.code === 'auth/user-not-found') {
            return this.message.create('error', 'Incorrect email/password combination, or email doesn’t exit');
        }

        return this.message.create('error', error.message);
    }
}
