export enum WalletEnum {
    DAPPER = 'dapper',
    BLOCTO = 'blocto',
    WALLET_CONNECT = 'wallet_connect',
    META_MASK = 'meta_mask',
    COINBASE = 'coinbase',
    PHANTOM = 'phantom',
    NEAR = 'near',
    UNSTOPPABLE_DOMAINS = 'unstoppable_domains',
    TRUST_WALLET = 'trust_wallet',
    PARTICLE = 'particle',
}

export enum ChainEnum {
    ETHEREUM = 'eth',
    SOLANA = 'solana',
    // GK HIDE BNB/BSC
    // BNB = 'bnb',
    NEAR = 'near',
    POLYGON = 'polygon',
    MUMBAI_TEST = 'mumbai-testnet',
}

export interface ChainInfo {
    name: string;
    wallets: WalletEnum[] | any;
    infoUsedConnectWallet?: {
        chainId: string;
        rpcUrls: string[];
        chainName: string;
        nativeCurrency?: {
            symbol: string;
            decimals: number;
        };
    };
}

export const WalletsSupportSwitchChain = [WalletEnum.META_MASK, WalletEnum.COINBASE, WalletEnum.TRUST_WALLET];

export const ChainInfoMap: Map<ChainEnum, ChainInfo> = new Map([
    [
        ChainEnum.ETHEREUM,
        {
            name: 'Ethereum',
            wallets: [
                WalletEnum.WALLET_CONNECT,
                WalletEnum.META_MASK,
                WalletEnum.COINBASE,
                WalletEnum.UNSTOPPABLE_DOMAINS,
                WalletEnum.TRUST_WALLET,
                WalletEnum.PARTICLE,
            ],
            infoUsedConnectWallet: {
                chainId: '0x1',
                rpcUrls: ['https://mainnet.infura.io/v3/'],
                chainName: 'Ethereum Mainnet',
                nativeCurrency: {
                    symbol: 'ETH',
                    decimals: 18,
                },
            },
        },
    ],
    [
        ChainEnum.SOLANA,
        {
            name: 'Solana',
            wallets: [WalletEnum.PHANTOM],
        },
    ],
    // GK HIDE BNB/BSC
    // [
    //     ChainEnum.BNB,
    //     {
    //         name: 'BNB Chain',
    //         wallets: [WalletEnum.WALLET_CONNECT, WalletEnum.META_MASK, WalletEnum.COINBASE, WalletEnum.TRUST_WALLET],
    //         infoUsedConnectWallet: {
    //             chainId: '0x38',
    //             rpcUrls: ['https://bsc-dataseed.binance.org'],
    //             chainName: 'BNB Chain',
    //             nativeCurrency: {
    //                 symbol: 'BNB',
    //                 decimals: 18,
    //             },
    //         },
    //     },
    // ],
    [
        ChainEnum.POLYGON,
        {
            name: 'Polygon',
            wallets: [WalletEnum.WALLET_CONNECT, WalletEnum.META_MASK, WalletEnum.COINBASE, WalletEnum.TRUST_WALLET],
            infoUsedConnectWallet: {
                chainId: '0x89',
                rpcUrls: ['https://polygon-rpc.com'],
                chainName: 'Polygon',
                nativeCurrency: {
                    symbol: 'MATIC',
                    decimals: 18,
                },
            },
        },
    ],
    [
        ChainEnum.NEAR,
        {
            name: 'Near',
            wallets: [WalletEnum.NEAR],
        },
    ],
    [
        ChainEnum.MUMBAI_TEST,
        {
            name: 'Mumbai Testnet',
            wallets: [WalletEnum.WALLET_CONNECT, WalletEnum.META_MASK, WalletEnum.COINBASE, WalletEnum.TRUST_WALLET],
            infoUsedConnectWallet: {
                chainId: '0x13881',
                rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
                chainName: 'Mumbai Testnet',
                nativeCurrency: {
                    symbol: 'MATIC',
                    decimals: 18,
                },
            },
        },
    ],
]);
