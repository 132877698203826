import { GeneralAdminModalComponent } from '@admin/components/general-admin-modal/general-admin-modal.component';
import { Injectable } from '@angular/core';
import { ResetPasswordModalComponent } from '@modules/main/auth/reset-password-modal/reset-password-modal.component';
import { SignInModalComponent } from '@modules/main/auth/sign-in-modal/sign-in-modal.component';
import { SignUpModalComponent } from '@modules/main/auth/sign-up-modal/sign-up-modal.component';
import { NidavellirService } from '@modules/main/nidavellir.service';
import { CommonService } from '@services/common.service';
import { RequestService } from '@src/app/services/request.service';
import * as dayjs from 'dayjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';
import { AuthTokenService } from '../core/authentication/auth-token.service';
import { ShopProfile, SubscriptionPlanEnum } from '../shared/types/profile.typing';

@Injectable({
    providedIn: 'root',
})
export class ShopProfileService {
    shopDetailSubject = new BehaviorSubject<ShopProfile>(null);

    userSeatLimitReachedErrorInfo: { owner_email: string; quota_seat: number };

    get isGamifyProject() {
        return ['gamify', 'gamify_plus'].includes(this.shopDetail?.nft_project?.project_type);
    }

    get isGamifyPlusProject() {
        return ['gamify_plus'].includes(this.shopDetail?.nft_project?.project_type);
    }

    get shopDetail() {
        return this.shopDetailSubject.getValue();
    }

    get isFreeTrial() {
        return this.shopDetail?.subscription_plan === SubscriptionPlanEnum.FREE;
    }

    get isAnimoca() {
        return this.shopDetail?.nft_project.animoca_demo;
    }

    get projectPlan() {
        return this.shopDetail?.project_plan;
    }

    get isFree() {
        return this.shopDetail.project_plan.sub_plan === SubscriptionPlanEnum.FREE;
    }

    get isEnterprise() {
        return this.shopDetail.project_plan.sub_plan === SubscriptionPlanEnum.ENTERPRISE;
    }

    get quotaWallet() {
        return this.shopDetail.project_plan.quota_wallet;
    }

    get isInFreeTrial() {
        return dayjs().isBefore(this.projectPlan?.trial_end_date);
    }

    constructor(
        private requestService: RequestService,
        private modalService: NzModalService,
        private screenService: CommonService,
        private messageService: CommonService,
        private nidavellirService: NidavellirService,
        private authTokenService: AuthTokenService
    ) {}

    getShopDetail() {
        return this.requestService.sendRequest<ShopProfile>({
            method: 'GET',
            url: '/brand/project/shop_detail',
            api: 'admin-api',
        });
    }

    switchProjectType() {
        this.modalService.create({
            nzContent: GeneralAdminModalComponent,
            nzFooter: null,
            nzWrapClassName: 'no-bg no-padding',
            nzClosable: false,
            nzCentered: true,
            nzWidth: this.screenService.isMobile() ? '100vw' : '560px',
            nzStyle: this.screenService.isMobile()
                ? {
                      verticalAlign: 'bottom',
                      maxWidth: '100vw',
                      padding: 0,
                      margin: 0,
                  }
                : {},
            nzComponentParams: {
                confirmText: 'SWITCH',
                cancelText: 'CANCEL',
                title: `Are you sure switch to the dashboard type: ${this.isGamifyProject ? 'dApp' : 'GameFi'} ?`,
            },
            nzOnOk: comp => {
                comp.submitting = true;
                return this.nidavellirService
                    .updateNFTShopProject({
                        id: this.shopDetail.nft_project.id,
                        project_type: this.isGamifyProject ? 'normal' : 'gamify',
                    })
                    .then(() => {
                        location.reload();
                    })
                    .catch(() => {
                        this.messageService.error();
                        return false;
                    })
                    .finally(() => (comp.submitting = false));
            },
        });
    }

    showSignInModal(directToAdminWhenLogged = false) {
        if (directToAdminWhenLogged && this.authTokenService.isLoggedIn) {
            window.location.href = '/admin/home';
            return;
        }

        if (this.screenService.isMobile()) {
            this.modalService.create({
                nzContent: SignInModalComponent,
                nzFooter: null,
                nzWrapClassName: 'no-bg no-padding',
                nzClosable: false,
                nzCentered: true,
                nzWidth: '100vw',
                nzStyle: {
                    verticalAlign: 'bottom',
                    maxWidth: '100vw',
                    padding: 0,
                    margin: 0,
                },
            });
        } else {
            this.modalService.create({
                nzContent: SignInModalComponent,
                nzFooter: null,
                nzClosable: false,
                nzCentered: true,
                nzZIndex: 10,
                nzWrapClassName: 'no-bg no-padding',
                nzWidth: '1000px',
            });
        }
    }

    showSignUpModal(directToAdminWhenLogged = false, signUpParams = null) {
        if (directToAdminWhenLogged && this.authTokenService.isLoggedIn) {
            window.location.href = '/admin/home';
            return;
        }
        if (this.screenService.isMobile()) {
            this.modalService.create({
                nzContent: SignUpModalComponent,
                nzFooter: null,
                nzWrapClassName: 'no-bg no-padding',
                nzClosable: false,
                nzCentered: true,
                nzWidth: '100vw',
                nzStyle: {
                    verticalAlign: 'bottom',
                    maxWidth: '100vw',
                    padding: 0,
                    margin: 0,
                },
                nzComponentParams: {
                    signUpParams,
                },
            });
        } else {
            this.modalService.create({
                nzContent: SignUpModalComponent,
                nzFooter: null,
                nzClosable: false,
                nzCentered: true,
                nzZIndex: 10,
                nzWrapClassName: 'no-bg no-padding',
                nzWidth: '1100px',
                nzStyle: {
                    maxWidth: '90vw',
                },
                nzComponentParams: {
                    signUpParams,
                },
            });
        }
    }

    showResetPasswordModal() {
        if (this.screenService.isMobile()) {
            this.modalService.create({
                nzContent: ResetPasswordModalComponent,
                nzFooter: null,
                nzWrapClassName: 'no-bg no-padding',
                nzClosable: false,
                nzCentered: true,
                nzWidth: '100vw',
                nzStyle: {
                    verticalAlign: 'bottom',
                    maxWidth: '100vw',
                    padding: 0,
                    margin: 0,
                },
            });
        } else {
            this.modalService.create({
                nzContent: ResetPasswordModalComponent,
                nzFooter: null,
                nzClosable: false,
                nzCentered: true,
                nzZIndex: 10,
                nzWrapClassName: 'no-bg no-padding',
                nzWidth: '660px',
            });
        }
    }

    checkInvitationCode(data: { invitation_code: string }): Promise<any> {
        return this.requestService.sendUnauthorizedRequest({
            method: 'POST',
            url: '/shared/validate_invitation_code',
            data,
            api: 'shop-api',
        });
    }

    sendInvitationCode<T extends { invitation_code: string }>(data: T): Promise<any> {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/nft_shop/create_new_account',
            data,
            api: 'shop-api',
        });
    }

    receiveInvited(data: { currentEmail: string; invitationCode: string; inviteState?: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/referral/receive_invited',
            data,
            api: 'data-api',
        });
    }

    getWeightOfSubscriptionPlan(plan: SubscriptionPlanEnum) {
        if (plan === SubscriptionPlanEnum.FREE) {
            return 1;
        }

        if (plan === SubscriptionPlanEnum.ADVANCED) {
            return 2;
        }

        if (plan === SubscriptionPlanEnum.PREMIUM) {
            return 3;
        }

        return 4;
    }

    getSubscriptionPlanByWeight(weight: number) {
        if (weight === 1) {
            return SubscriptionPlanEnum.FREE;
        }

        if (weight === 2) {
            return SubscriptionPlanEnum.ADVANCED;
        }

        if (weight === 3) {
            return SubscriptionPlanEnum.PREMIUM;
        }

        return null;
    }

    getSubscriptionName(plan: SubscriptionPlanEnum) {
        if (plan === SubscriptionPlanEnum.FREE) {
            return 'Free';
        }

        if (plan === SubscriptionPlanEnum.ADVANCED) {
            return 'Advanced';
        }

        if (plan === SubscriptionPlanEnum.PREMIUM) {
            return 'Premium';
        }

        if (plan === SubscriptionPlanEnum.ENTERPRISE) {
            return 'Enterprise';
        }

        return null;
    }
}
