<section class="report-section">
    <div>
        <div class="section-title">
            <span>Gas Fee Distribution</span>
            <span>
                <span style="font-size: 12px; font-weight: normal">Total Txns</span>
                {{total | number:'1.0-0'}}</span
            >
        </div>

        <div class="section-description">
            There are over {{lastPercent | percent:'1.0-0'}} (a top BNB Socialfi project is less than 0.1%) mints with over $0.3 gas fee.
        </div>
    </div>

    <main class="content">
        <section class="chart" appEcharts [initialOption]="initialOption" [optionToBeUpdate]="optionToBeUpdate"></section>
    </main>
</section>
