import { Injectable } from '@angular/core';
import { RequestService } from '@src/app/services/request.service';
import { environment } from '@src/environments/environment';
import { ShopifyCountry } from './advanced-customization/advanced-customization-state.service';

import { HttpClient } from '@angular/common/http';
import { ProjectCampaign, SocialMedia } from '@modules/admin/campaign/campaign.model';
import { CreateCampaign, NFTMerchProduct } from '@modules/main/advanced-customization/advanced-customization-state.service';
import { SESSION_STORAGE_KEY_ENUM } from '@src/app/shared/types/general.type';
import { ChainEnum } from '@src/app/shared/types/wallet-chain.model';
import { CreateNFTShopProject, NFTTokenDetail, NFTView, NFTViewByProject, UpdateNFTShopProject } from '@type/nft.type';
import { encrypt } from '@utils/pem';

export * from './advanced-customization/nidavellir.type';

export const sellingTagMap = {
    high_sell_through: {
        title: 'High Sell-Through',
        color: 'rgba(210, 159, 59, 1)',
        bgColor: 'rgba(210, 159, 59, 0.1)',
    },
    new: {
        title: 'New',
        color: 'rgba(34, 188, 185, 1)',
        bgColor: 'rgba(34, 188, 185, 0.1)',
    },
    popular: {
        title: 'Popular',
        color: 'rgba(243, 116, 94, 1)',
        bgColor: 'rgba(243, 116, 94, 0.1)',
    },
    high_profit: {
        title: 'High Profit',
        color: 'rgba(87, 51, 87, 1)',
        bgColor: 'rgba(87, 51, 87, 0.1)',
    },
};

export const productAttrMap = {
    eco_friendly: {
        title: 'Eco-friendly',
        iconName: 'eco-friendly',
    },
    customizable: {
        title: 'Customizable',
        iconName: 'customizable',
    },
    customizable_packaging: {
        title: 'Customizable Packaging',
        iconName: 'customizable-packaging',
    },
    not_on_amazon: {
        title: 'Not on Amazon',
        iconName: 'not-amazon',
    },
    social_good: {
        title: 'Social Good',
        iconName: 'social-good',
    },
    handmade: {
        title: 'Handmade',
        iconName: 'handmade',
    },
    designer_product: {
        title: 'Designer Product',
        iconName: 'designer-product',
    },
    small_batch: {
        title: 'Small Batch',
        iconName: 'small-batch',
    },
};

export interface FaqListItem {
    category: string;
    question: string;
    response: string;
    show_size_chart: boolean;
    mpc_points_redeem;
}

export interface CheckoutData {
    option_id: any;
    points_used: number;
    wallet_address: string;
    contract_address: string;
    token_id: any;
    series_number: any;
    owner: string;
    product_options?: {
        id: number;
    }[];
    image_package: { type: 'sticker' | 'qr_info' | 'basic_info'; src?: string; b64?: string }[];
    invitation_code: any;
    contract_name: string;
    frame_id: any;
    project_id?: any;
    quantity: number;
    address_detail:
        | {
              email: string;
              phone_number: string;
              first_name: string;
              last_name: string;
              address_line_1: string;
              address_line_2: string;
              city: string;
              state: string;
              country: string;
              zip: string;
          }
        | {};
}

@Injectable({
    providedIn: 'root',
})
export class NidavellirService {
    constructor(private requestService: RequestService, private http: HttpClient) {}

    getVoucherData() {
        return this.requestService.sendUnauthorizedRequest<any[]>({
            method: 'GET',
            url: '/external/mpc_points_redeem',
            api: 'data-shop',
        });
    }

    getNFTMerchTemplates() {
        return this.requestService.sendUnauthorizedRequest<NFTMerchProduct[]>({
            method: 'GET',
            url: '/external/nft_merch_templates',
            api: 'data-shop',
        });
    }

    getNFTImages(address: string) {
        return this.requestService.sendUnauthorizedRequest<NFTView[]>({
            method: 'GET',
            url: `/customer/wallet_nft?address=${address}`,
            api: 'admin-api',
        });
    }

    getNFTImagesByProject(address: string, chain?: ChainEnum) {
        return this.requestService.sendUnauthorizedRequest<NFTViewByProject[]>({
            method: 'GET',
            url: `/customer/wallet_nft?address=${address}&by_project=true${chain ? `&chain=${chain}` : ''}`,
            api: 'admin-api',
        });
    }

    generateQrCode(data: { contract_address: string; token_id: any; wallet_address: string; fill_color?: number[] }) {
        return this.requestService.sendUnauthorizedRequest$<{ path: string; src: string }>({
            method: 'POST',
            url: '/images/gen_qrcode',
            api: 'data-shop',
            data,
        });
    }

    generateImageByHtml(data: { text: string; width: number; height: number }) {
        return this.requestService.sendUnauthorizedRequest$<{ path: string; src: string }>({
            method: 'POST',
            url: '/images/html2image',
            api: 'data-shop',
            data,
        });
    }

    getCountryList() {
        return this.requestService.sendUnauthorizedRequest<ShopifyCountry[]>({
            method: 'GET',
            url: '/external/shipping_address/country',
            api: 'data-shop',
        });
    }

    nftCheckout(data: CheckoutData) {
        const encrypt_wallet_address = environment.production
            ? window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.ENCRYPTED_WALLET_ADDR) || encrypt('')
            : encrypt(data.wallet_address);
        return this.requestService.sendUnauthorizedRequest<{ id?: string; shopify_order_id?: string; coupon_code?: string }>({
            method: 'POST',
            url: '/external/nft_merch_checkout',
            api: 'data-shop',
            data: {
                ...data,
                encrypt_wallet_address,
            },
        });
    }

    getNFTTokenDetail(contract_address: string, token_id: string, owner_address: string) {
        return this.requestService.sendUnauthorizedRequest<NFTTokenDetail>({
            method: 'GET',
            url: `/customer/wallet_nft_detail/${contract_address}/${token_id}/${owner_address}`,
            api: 'admin-api',
        });
    }

    getFaqAll(type: 'w3w' | 'w3w_brand' | 'w3w_home') {
        return this.requestService.sendUnauthorizedRequest<FaqListItem[]>({
            method: 'GET',
            url: `/shared/faq?type=${type}`,
            api: 'data-shop',
        });
    }

    getNFTProjectDetail(name: string) {
        return this.requestService.sendUnauthorizedRequest<any>(
            {
                method: 'GET',
                url: `/customer/project?key=${name}`,
                api: 'admin-api',
            },
            3
        );
    }

    createNFTProjectDetail(data: CreateNFTShopProject) {
        return this.requestService.sendUnauthorizedRequest({
            method: 'POST',
            url: '/customer/project',
            api: 'admin-api',
            data,
        });
    }

    addNFTShopProject(data: CreateNFTShopProject) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/brand/project/add',
            api: 'admin-api',
            data,
        });
    }

    skipNFTShopProject(data = {}) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/brand/project/skip',
            api: 'admin-api',
            data,
        });
    }

    updateNFTShopProject(data: UpdateNFTShopProject) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/brand/project/update',
            api: 'admin-api',
            data,
        });
    }

    deleteCollection(id: number) {
        return this.requestService.sendRequest({
            method: 'DELETE',
            url: `/brand/project/collection?id=${id}`,
            api: 'admin-api',
        });
    }

    validateProjectName(data: { name: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/nft_shop/validate_project_name',
            api: 'shop-api',
            data,
        });
    }

    createCohostProject(data: CreateNFTShopProject) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/brand/project/create_cohost',
            api: 'admin-api',
            data,
        });
    }

    updateCohostProject(data: CreateNFTShopProject) {
        return this.requestService.sendRequest({
            method: 'PUT',
            url: '/brand/project/update_cohost',
            api: 'admin-api',
            data,
        });
    }

    getUserInfo(address: string) {
        return this.requestService.sendUnauthorizedRequest({
            method: 'GET',
            url: `/external/nft_account/${address}`,
            api: 'admin-api',
        });
    }

    updateUserInfo(data: {
        walletAddress: string;
        email?: string;
        walletName?: string;
        discord?: string;
        contactList?: {
            contact: string;
            contactId: string;
            contactType: string;
        }[];
    }) {
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'POST',
            url: `/external/nft_account/${data.walletAddress}`,
            api: 'admin-api',
            data,
        });
    }

    isValidProjectUrl(marketplace: string, slug: string) {
        return this.requestService.sendUnauthorizedRequest({
            method: 'GET',
            url: `/shared/nft_collection?marketplace=${marketplace}&slug=${slug}`,
            api: 'admin-api',
        });
    }

    getMpcPointsRedeem(walletAddress: string) {
        return this.requestService.sendUnauthorizedRequest<any[]>({
            method: 'GET',
            url: `/external/mpc_points_redeem?address=${walletAddress}`,
            api: 'data-shop',
        });
    }

    redeemPoints(data: { token_id: string; contract_name: string; contract_address: string; wallet_address: string }) {
        const encrypt_wallet_address = environment.production
            ? window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.ENCRYPTED_WALLET_ADDR) || encrypt('')
            : encrypt(data.wallet_address);
        return this.requestService.sendUnauthorizedRequest<any[]>({
            method: 'POST',
            url: '/external/mpc_points_redeem',
            api: 'data-shop',
            data: {
                ...data,
                encrypt_wallet_address,
            },
        });
    }

    getAllNftProjectList() {
        return this.requestService.sendUnauthorizedRequest<any[]>({
            method: 'GET',
            url: '/external/all_nft_project',
            api: 'data-shop',
        });
    }

    getNftCampaignDetail(id) {
        return this.requestService.sendUnauthorizedRequest<ProjectCampaign>({
            method: 'GET',
            url: `/customer/campaign?id=${id}`,
            api: 'admin-api',
        });
    }

    validateNftCampaign(data: { campaign_id: number; wallet_address: string; wallet_type: string }) {
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'POST',
            url: '/customer/campaign/validate',
            api: 'admin-api',
            data,
        });
    }

    validateAddressMultiple(data: {
        campaign_id: number;
        wallet_list: { wallet_type: string; wallet_address: string; encrypt_wallet_address: string }[];
    }) {
        return this.requestService.sendUnauthorizedRequest<{
            is_eligible: boolean;
            total_weight: number;
            wallet_list: {
                wallet_type: string;
                wallet_address: string;
                encrypt_wallet_address: string;
                weight: number;
                is_joined: boolean;
                is_eligible: boolean;
            }[];
        }>({
            method: 'POST',
            url: '/customer/campaign/validate_multiple',
            api: 'admin-api',
            data,
        });
    }

    entryNftCampaign(data: CreateCampaign) {
        const encrypt_wallet_address = environment.production
            ? window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.ENCRYPTED_WALLET_ADDR) || encrypt('')
            : encrypt(data.wallet_address);
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'POST',
            url: '/customer/campaign/entry',
            api: 'admin-api',
            data: {
                ...data,
                encrypt_wallet_address,
            },
        });
    }

    subscribeWalletAddressUpdate(data: { chain: string; walletList?: { walletAddr: string; [key: string]: any }[] }) {
        // 09/14 Switch to new API
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'GET',
            url: `/external/wallet_data?address=${data.walletList.map(item => item.walletAddr).join(',')}`,
            api: 'admin-api',
        });
    }

    checkCampaignChallengeStatus(data: { campaignId: any; walletAddress: string; projectId?: any; challengeType?: string }) {
        return this.requestService.sendUnauthorizedRequest<{
            checkDetails: {
                atAccounts: number;
                originalChallenge: {
                    challengeType: SocialMedia;
                    checkType: 'follow' | 'retweet' | 'join' | null;
                    challengeInput: string;
                    id: number;
                    atAccounts: number;
                    hashtags: string[];
                    roles?: any[];
                };
                result: boolean;
                message: string;
                status: null | 401 | 429 | number;
                hashtagChecks: { [key: string]: boolean };
                verifyRoleChecks: { [key: number]: boolean };
                serverJoin: boolean;
            }[];
            success: boolean;
        }>({
            method: 'POST',
            url: '/external/campaign/campaign_challenges/check',
            api: 'data-api',
            data,
        });
    }

    getCampaignByPagination(data: { page: number; size: number; status?: string } = { page: 1, size: 20 }) {
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'GET',
            url: `/external/all_nft_campaign?page=${data.page}&size=${data.size}${data.status ? `&status=${data.status}` : ''}`,
            api: 'data-shop',
        });
    }

    getProjectDetailById(id: any) {
        return this.requestService.sendUnauthorizedRequest<any>(
            {
                method: 'GET',
                url: `/customer/project?id=${id}`,
                api: 'admin-api',
            },
            3
        );
    }

    createReferralAndClaim<T>(data: {
        campaignId: string;
        referrerAddress: string;
        signatureMsg: string;
        msg: string;
        mintToAddress: string;
        count: number;
    }) {
        return this.requestService.sendRequest<T>({
            method: 'POST',
            url: '/referral/referralCreateAndMint',
            api: 'referral-api',
            data,
        });
    }

    getRecommendationCampaign() {
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'GET',
            url: '/customer/recommendation/campaign',
            api: 'admin-api',
        });
    }

    getRecommendationMerch() {
        return this.requestService.sendUnauthorizedRequest<any>({
            method: 'GET',
            url: '/customer/recommendation/merch',
            api: 'admin-api',
        });
    }
}
