import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-bullet-point2',
    templateUrl: './bullet-point2.component.html',
    styleUrls: ['./bullet-point2.component.less'],
})
export class BulletPoint2Component {
    @Input() config: {
        theme: 'light' | 'dark';
        sectionName: string;
    };
    @Input() bulletPointList: {
        imgUrl: string;
        name: string;
        count: number;
        percent: number;
        color: string;
    }[] = [];

    constructor() {}
}
