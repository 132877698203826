import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NFTPlatform } from '@components/connect-nft-modal/connect-nft-modal.component';
import { NidavellirService } from '@modules/main/nidavellir.service';
import { UtilService } from '@services/util.service';
import { NFTView, NFTViewByProject } from '@type/nft.type';
import * as _ from 'lodash';
import { flatten } from 'lodash';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ChainEnum, WalletsSupportSwitchChain } from './../../../shared/types/wallet-chain.model';
import { WalletStatusService } from './../wallet-status.service';
import { ImageInfo } from './merch-product-detail.service';
import { AdvancedCustomizedTemplate, FramePattern, PreviewBackground } from './nidavellir.type';

import { DOCUMENT, Location } from '@angular/common';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { Web3Provider } from '@ethersproject/providers';
import { getChainByChainId } from '@src/app/shared/utils/wallet-chain.util';
import { NFTMerchProduct, ProjectDetail, projectTheme } from './advanced-customization.model';
export { CreateCampaign, NFTMerchProduct, ProjectDetail, ShopifyCountry, projectTheme } from './advanced-customization.model';

type Product_ID = number;
type Variant_Name = string;

const SESSION_INVITE_COUPON_KEY = 'inviteCoupon';

@Injectable()
export class AdvancedCustomizationStateService {
    // 项目配置信息
    selectedProjectTheme = projectTheme.general;

    // 左侧操作区渲染图片的数据
    logoList: any[];
    logoMap: Map<any, ImageInfo>;

    // 通用的产品列表（非当前项目）
    defaultNFTProducts$ = new BehaviorSubject<NFTMerchProduct[]>(null);
    get defaultNFTProducts() {
        return this.defaultNFTProducts$.value;
    }

    // 实际使用的产品列表
    NFTProducts$ = new BehaviorSubject<NFTMerchProduct[]>(null);
    get NFTProducts() {
        return this.NFTProducts$.value;
    }

    // Variant 级别的预览图片 Map
    previewImagesByVariantAndProduct: Map<Product_ID, Map<Variant_Name, PreviewBackground[]>> = new Map();

    // 选中的 ProductId
    selectedProductId$ = new BehaviorSubject<number>(null);
    // 选中的 Product
    get selectedProduct() {
        return this.NFTProducts?.find(item => item.id === this.selectedProductId$.value);
    }
    // 选中的 Template
    selectedTemplate$ = new BehaviorSubject<AdvancedCustomizedTemplate>(null);

    // 选中的 FramePattern
    selectedFramePattern$ = new BehaviorSubject<FramePattern>(null);

    // 选中的 View (e.g. 'front', 'back')
    selectedViewID: number; // 当前选中的viewID，例如（Front : 0, Back : 1）
    get selectedView() {
        return this.selectedTemplate$.value?.preview_images.find(item => item.id === this.selectedViewID);
    }

    // 选中的 Brand Collection
    selectedProject$ = new BehaviorSubject<NFTViewByProject>(null);

    // 当前 Address 所拥有的所有 NFT
    nftImagesByProject$ = new BehaviorSubject<NFTViewByProject[]>(null);
    currentCollectionChain: ChainEnum = ChainEnum.ETHEREUM;

    // 项目信息（只在 Brand 页面才有）
    projectInfo$ = new BehaviorSubject<ProjectDetail>(null);
    get projectInfo() {
        return this.projectInfo$.value;
    }

    // 从 CH 端来的，暂时不用
    viewMode: 'preview' | 'edit' = 'preview';
    selectAreaPosition = {
        x: 0,
        y: 0,
    };
    operateBoxZIndex = 0;

    pSelectedImage: any;
    get selectedImage() {
        return this.pSelectedImage;
    }
    set selectedImage(img) {
        this.pSelectedImage = img;

        const index = this.displayLogoList.findIndex(item => item === img);
        this.operateBoxZIndex = index + 1;
    }

    // 当前产品下所有 template 的 option 都是一样的，所以此处等于每个产品的 option map
    // { optionNameArr: string[]; [key: string]: string }
    templateOptionMap: { [key: number]: { [key: string]: string | string[] } };

    dynamicImageGroup: {
        nftImage$: BehaviorSubject<NFTView>;
        qrCodeImage: { url: string }; // selectedProductId
        basicInfoImage: { url: string };
        htmlTemplate: { url: string }; // selectedProductId
    } = {
        nftImage$: new BehaviorSubject<NFTView>(null),
        qrCodeImage: null,
        basicInfoImage: null,
        htmlTemplate: null,
    };

    // 异步图片生成状态
    imageGeneratingStatus = {
        qrCodeImage: false,
        basicInfoImage: false,
        htmlTemplate: false,
    };

    // 移动端缩放参数
    mobileAdaptParams = {
        ratio: 1,
        offsetHeight: 0,
    };

    // 一些临时 UI 状态
    descriptionExpandStatus = false;
    sizeGuidanceExpandStatus = false;

    // 暂时不用。全局的订阅弹窗
    subscriptionModalTimeoutId: any;

    // 打算使用的 Points
    usedPoints = 0;

    currentPreviewImages$ = new Subject<PreviewBackground[]>();

    // 二维码生成时的颜色
    qrCodeColor = '#72bbfd';

    // 当前选中的 Template 是 Template 还是单纯的产品图
    selectedTemplateType: 'template' | 'productImage' = 'template';
    // 选中的产品图，只有在 selectedTemplateType === 'productImage'时才存在
    selectedProductImage: { src: string; path: string };

    // 是否显示 template 列表模块
    templateListVisible = true;

    // Banner 是否过期
    bannerExpired = false;

    // 选中的 NFT 分辨率是否过低
    resolutionOfSelectedNftTooLow = false;

    // 产品数量
    quantity = 1;

    ownerName: string;

    // campaign detail 页面加入campaign成功后，通知父组件更新数据
    isJoinedCampaign$ = new BehaviorSubject(false);

    // Project Key from Router
    projectKey: string;

    supportedWallets = [
        NFTPlatform.BLOCTO,
        NFTPlatform.COINBASE,
        NFTPlatform.DAPPER,
        NFTPlatform.META_MASK,
        NFTPlatform.PHANTOM,
        NFTPlatform.WALLET_CONNECT,
        NFTPlatform.NEAR,
        NFTPlatform.UNSTOPPABLE_DOMAINS,
    ];

    requiredChainWalletMap: { [key: string]: NFTPlatform[] } = {
        eth_ud: [NFTPlatform.UNSTOPPABLE_DOMAINS],
        near: [NFTPlatform.NEAR],
    };

    private requests: { [key: string]: Subscription } = {
        basicInfoRequest$: null,
        qrCodeRequest$: null,
        htmlTemplateRequest$: null,
    };

    get isMemberOnlyProduct() {
        return this.selectedProduct?.sales_type === 'member_only';
    }

    get isAirdropOnlyProduct() {
        return this.selectedProduct?.sales_type === 'airdrop_only';
    }

    get isCurrentProductFreeForCurrentToken() {
        return this.dynamicImageGroup.nftImage$.value?.free_options?.includes(this.selectedProduct?.id);
    }

    get isCurrentNftNotEligibleToPurchaseCurrentProduct() {
        return (
            this.selectedProduct?.sales_type === 'airdrop_only' &&
            !this.dynamicImageGroup.nftImage$.value?.eligible_options?.includes(this.selectedProduct.id)
        );
    }

    get displayLogoList() {
        return this.logoList.filter(item => this.logoMap?.get(item)?.viewId === this.selectedViewID && !this.logoMap?.get(item).uploading);
    }

    get isBrandPage() {
        return !!this.projectInfo;
    }

    get singleProductReferralDiscount() {
        if (window.sessionStorage.getItem(SESSION_INVITE_COUPON_KEY)) {
            return Number((this.selectedProduct?.price * 0.2).toFixed(2)) || 0;
        }

        return 0;
    }

    get singleProductProjectDiscount() {
        if (this.projectInfo?.discount_rule) {
            if (this.projectInfo.discount_rule.value_type === 'percentage') {
                const realDiscount = this.projectInfo.discount_rule.value / 100;
                return Number((this.selectedProduct?.price * realDiscount).toFixed(2)) || 0;
            }

            if (this.projectInfo.discount_rule.value_type === 'fixed_amount') {
                return this.projectInfo.discount_rule.value;
            }

            return 0;
        }
        return 0;
    }

    get singleProductOverAllDiscount() {
        return Math.max(this.singleProductReferralDiscount, this.singleProductProjectDiscount);
    }

    get discountPrice() {
        let nowAmount = this.selectedProduct?.price * this.quantity;
        if (this.singleProductOverAllDiscount > 0) {
            nowAmount = Number((this.selectedProduct.price - this.singleProductOverAllDiscount).toFixed(2)) * this.quantity;
        }

        if (this.usedPoints) {
            return Number((nowAmount - this.usedPoints / 100).toFixed(2)) || 0;
        }

        return nowAmount || 0;
    }

    get isProjectNotPending() {
        return this.projectInfo?.status !== 'pending';
    }

    get isHasMerchTemplates() {
        return !!this.projectInfo?.merch_templates?.length;
    }

    get isHasCampaignList() {
        return !!this.projectInfo?.campaign_list?.filter(ele => ele.is_public)?.length;
    }

    get connectedWalletNotSupport() {
        return (
            this.projectInfo?.required_chain &&
            !this.requiredChainWalletMap[this.projectInfo.required_chain]?.includes(this.walletStatusService.walletType)
        );
    }

    constructor(
        private nidavellirService: NidavellirService,
        private utilService: UtilService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private walletStatusService: WalletStatusService,
        private location: Location,
        @Inject(DOCUMENT) private document: Document
    ) {
        const iconLinkEle: HTMLLinkElement = this.document.head.querySelector('link[rel=icon]');
        iconLinkEle.href = this.selectedProjectTheme.browserLogo || 'favicon.ico';

        this.initServiceData();

        // this.setWalletChain().then(chainId => {
        //     this.walletStatusService.selectedWalletAddr$.subscribe(() => {
        //         const walletAddress = this.walletStatusService.selectedWalletAddr$.getValue();

        //         if (walletAddress) {
        //             this.getNFTImages(
        //                 this.walletStatusService.walletAddress,
        //                 getChainByChainId(chainId) || this.selectedProject$.value?.chain || null
        //             );
        //         } else {
        //             // 连接另一个wallet时，可能会出现上一个wallet缓存，所以清空
        //             this.nftImagesByProject$.next(null);
        //             this.selectedProject$.next(null);
        //             this.dynamicImageGroup.nftImage$.next(null);
        //         }
        //     });
        // });

        this.walletStatusService.selectedWalletAddr$.subscribe(async () => {
            const walletAddress = this.walletStatusService.selectedWalletAddr$.getValue();

            const chainId = await this.setWalletChain();

            if (walletAddress) {
                this.getNFTImages(
                    this.walletStatusService.walletAddress,
                    getChainByChainId(chainId) || this.selectedProject$.value?.chain || null
                );
            } else {
                // 连接另一个wallet时，可能会出现上一个wallet缓存，所以清空
                this.nftImagesByProject$.next(null);
                this.selectedProject$.next(null);
                this.dynamicImageGroup.nftImage$.next(null);
            }
        });
    }

    async setWalletChain() {
        const connectedWalletIsSupported = WalletsSupportSwitchChain.includes(this.walletStatusService.walletType);

        if (connectedWalletIsSupported && this.walletStatusService.walletAddress && this.walletStatusService.walletType) {
            // Set walletChain
            let provider: any = window.ethereum;
            if (this.walletStatusService.walletType === NFTPlatform.META_MASK) {
                // @ts-ignore
                provider = window?.ethereum?.providers?.find(p => p.isMetaMask) || window.ethereum;
                try {
                    this.walletStatusService.selectedWalletChainId = await provider?.request({ method: 'eth_chainId' });
                } catch (err) {
                    console.warn(err);
                }
            }

            if (this.walletStatusService.walletType === NFTPlatform.TRUST_WALLET) {
                try {
                    this.walletStatusService.selectedWalletChainId = await provider?.request({ method: 'eth_chainId' });
                } catch (err) {
                    console.warn(err);
                }
            }

            if (this.walletStatusService.walletType === NFTPlatform.COINBASE) {
                const coinbaseWallet = new CoinbaseWalletSDK({
                    appName: 'Lifo INC',
                    darkMode: false,
                });

                const providerCoinbase = coinbaseWallet.makeWeb3Provider(
                    'https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a',
                    1
                );
                provider = new Web3Provider(providerCoinbase);
                try {
                    this.walletStatusService.selectedWalletChainId = await provider?.send('eth_chainId', []);
                } catch (err) {
                    console.warn(err);
                }
            }
        }

        return this.walletStatusService.selectedWalletChainId;
    }

    updateUsedPointsToMaxAvailable() {
        const singleProductPriceExceptDiscount = this.selectedProduct?.price - this.singleProductOverAllDiscount || 0;
        const currentProductsAmountToPoints = singleProductPriceExceptDiscount * 100 * this.quantity;
        this.usedPoints = Number(Math.min(currentProductsAmountToPoints, this.walletStatusService.userInfo?.points || 0).toFixed(2));
    }

    getNFTProducts() {
        return this.nidavellirService.getNFTMerchTemplates();
    }

    handleNFTProducts(data: NFTMerchProduct[]) {
        if (!data?.length) {
            return;
        }

        this.NFTProducts$.next(data);

        const routerProductId = this.activatedRoute.snapshot.queryParams.product_id;

        if (this.selectedProductId$.value !== Number(routerProductId)) {
            this.quantity = 1;
        }

        this.selectedProductId$.next(Number(routerProductId));

        if (!data.find(item => item.id === this.selectedProductId$.value)) {
            this.selectedProductId$.next(data[0].id);
        }

        flatten(this.NFTProducts.map(product => product.templates)).forEach(templateItem => {
            templateItem.options.forEach(option => {
                const middleIndex = Math.floor((option.values.length - 1) / 2);
                if (!this.templateOptionMap[templateItem.id]) {
                    this.templateOptionMap[templateItem.id] = {
                        [option.name]: option.values[middleIndex],
                    };
                } else {
                    this.templateOptionMap[templateItem.id][option.name] = option.values[middleIndex];
                }
            });

            this.templateOptionMap[templateItem.id].optionNameArr = templateItem.options.map(item => item.name);
        });

        // handle previewImagesByVariantAndProduct;
        this.NFTProducts.forEach(product => {
            let currentProductMap = new Map();

            if (this.previewImagesByVariantAndProduct.get(product.id)) {
                currentProductMap = this.previewImagesByVariantAndProduct.get(product.id);
            }

            this.previewImagesByVariantAndProduct.set(product.id, currentProductMap);

            product.variants?.forEach(variant => {
                currentProductMap.set(variant.option, variant.product_image_list);
            });
        });

        if (this.selectedProduct.templates.length > 1 || this.selectedProduct.templates.some(item => item.product_images?.length > 0)) {
            this.templateListVisible = true;
        } else {
            this.templateListVisible = false;
        }

        this.applyTemplate(this.selectedProduct.templates[0]);
        this.updateUsedPointsToMaxAvailable();

        if (routerProductId) {
            setTimeout(() => {
                // Scroll To Selected Product
                const selectedProductElement = this.document.getElementById(`product-${this.selectedProductId$.value}`);
                if (selectedProductElement) {
                    selectedProductElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }
            }, 300);
        }
    }

    getNFTProjectDetail(name: string): Promise<any> {
        return this.nidavellirService.getNFTProjectDetail(name);
    }

    getNFTImages(addr: string, chain: ChainEnum) {
        this.nidavellirService
            .getNFTImagesByProject(addr, chain)
            .then(data => this.nftImagesByProject$.next(data || []))
            .catch(() => this.nftImagesByProject$.next([]));
    }

    getQrCode(info: any) {
        if (this.requests.qrCodeRequest$ && !this.requests.qrCodeRequest$.closed) {
            this.requests.qrCodeRequest$.unsubscribe();
        }

        this.requests.qrCodeRequest$ = this.nidavellirService.generateQrCode(info).subscribe(
            data => (this.dynamicImageGroup.qrCodeImage = { url: data.src }),
            () => {},
            () => (this.imageGeneratingStatus.qrCodeImage = false)
        );
    }

    getBasicInfoImage(info: { text: string; width: number; height: number }) {
        if (this.requests.basicInfoRequest$ && !this.requests.basicInfoRequest$.closed) {
            this.requests.basicInfoRequest$.unsubscribe();
        }

        this.requests.basicInfoRequest$ = this.nidavellirService.generateImageByHtml(info).subscribe(
            data => (this.dynamicImageGroup.basicInfoImage = { url: data.src }),
            () => {},
            () => (this.imageGeneratingStatus.basicInfoImage = false)
        );
    }

    generateHtmlTemplateImage() {
        if (!this.dynamicImageGroup?.nftImage$.value || !this.selectedTemplate$.value) {
            return;
        }
        this.dynamicImageGroup.htmlTemplate = null;

        const htmlTemplateItem = this.selectedFramePattern$.value?.data?.find(item => item.html);
        if (htmlTemplateItem) {
            this.imageGeneratingStatus.htmlTemplate = true;

            let handledHtml = htmlTemplateItem.html || '';

            if (htmlTemplateItem.placeholders) {
                htmlTemplateItem.placeholders.forEach(item => {
                    const realValue = _.get(this.dynamicImageGroup?.nftImage$.value, item.key_in_nft);
                    handledHtml = handledHtml
                        .replaceAll(item.placeholder_name, realValue)
                        .replaceAll('$$.owner', this.ownerName || this.walletStatusService.walletAddress);
                });
            }

            // htmlTemplateRequest$
            if (this.requests.htmlTemplateRequest$ && !this.requests.htmlTemplateRequest$.closed) {
                this.requests.htmlTemplateRequest$.unsubscribe();
            }

            this.requests.htmlTemplateRequest$ = this.nidavellirService
                .generateImageByHtml({
                    text: handledHtml,
                    width: htmlTemplateItem.size.htmlWidth,
                    height: htmlTemplateItem.size.htmlHeight,
                })
                .subscribe(
                    data => (this.dynamicImageGroup.htmlTemplate = { url: data.src }),
                    () => {},
                    () => (this.imageGeneratingStatus.htmlTemplate = false)
                );
        }
    }

    changeProduct(product: NFTMerchProduct) {
        if (product.id === this.selectedProductId$.value) {
            return;
        }

        /**
         * 区分二维码颜色是修改过的，还是从之前产品继承的默认色
         *
         * 1.现在产品没有有默认色，要切换的产品有默认色 -> 重新生成
         * 2.现在产品没有默认色，要切换的产品没有默认色 -> 不必重新生成
         * 3.现在产品有默认色，要切换的产品有默认色 -> 重新生成
         * 4.现在产品有默认色，要切换的产品没有默认色 -> 重新生成
         */
        let needUpdateQrcode = false;
        const currentProduct = this.selectedProduct;
        const currentQrLogoTemplate = currentProduct?.templates[0].frame_pattern
            ?.find(item => item === this.selectedFramePattern$.value)
            ?.data?.find(item => item.type === 'qr_info');
        const nextProduct = this.NFTProducts.find(item => item.id === product.id);
        const nextQrLogoTemplate = nextProduct?.templates[0]?.frame_pattern[0]?.data?.find(item => item.type === 'qr_info');

        // 1,3
        if (nextQrLogoTemplate && nextQrLogoTemplate.default_color) {
            needUpdateQrcode = true;
        } else if (currentQrLogoTemplate && currentQrLogoTemplate.default_color) {
            needUpdateQrcode = true;
        }

        this.selectedProductId$.next(product.id);
        this.applyTemplate(this.selectedProduct.templates[0]);
        this.selectedViewID = 0;
        this.updateUsedPointsToMaxAvailable();
        this.quantity = 1;

        if (needUpdateQrcode && this.dynamicImageGroup.nftImage$.value) {
            this.generateQrCode();
        }

        if (this.selectedProduct.templates.length > 1 || this.selectedProduct.templates.some(item => item.product_images?.length > 0)) {
            this.templateListVisible = true;
        } else {
            this.templateListVisible = false;
        }

        const queryParams = {
            ...(this.activatedRoute.snapshot.queryParams || {}),
            product_id: product.id,
        };
        const queryParamsStr = _.uniq(Object.keys(queryParams))
            .map(key => `${key}=${queryParams[key]}`)
            .join('&');

        this.location.replaceState(window.location.pathname, queryParamsStr);
    }

    applyTemplate(template: AdvancedCustomizedTemplate) {
        this.selectedTemplateType = 'template';
        this.selectedProductImage = null;

        const currentQrLogoTemplate = this.selectedTemplate$.value?.frame_pattern
            ?.find(item => item === this.selectedFramePattern$.value)
            ?.data?.find(item => item.type === 'qr_info');
        const nextQrLogoTemplate = template?.frame_pattern[0]?.data?.find(item => item.type === 'qr_info');
        if (nextQrLogoTemplate?.default_color) {
            this.qrCodeColor = nextQrLogoTemplate.default_color;
        } else if (currentQrLogoTemplate?.default_color) {
            this.qrCodeColor = '#72bbfd';
        }

        this.selectedTemplate$.next(template);
        this.selectedFramePattern$.next(template.frame_pattern ? template.frame_pattern[0] : null);
        this.selectedImage = null;
        this.generateHtmlTemplateImage();
        this.generateCurrentPreviewImages();
    }

    changeFramePattern(data: FramePattern) {
        this.selectedFramePattern$.next(data);
        // 不同的 framePattern 会有不同的html_template，所以需要重新生成
        this.generateHtmlTemplateImage();
    }

    generateCurrentPreviewImages() {
        this.selectedViewID = 0;
        const firstTemplateOfSelectedProduct = this.selectedProduct.templates[0];
        if (firstTemplateOfSelectedProduct.id === this.selectedTemplate$.value.id) {
            const currentOptionInfo = this.templateOptionMap[this.selectedTemplate$.value.id];
            const variantName = (currentOptionInfo.optionNameArr as string[]).map(optionName => currentOptionInfo[optionName]).join('/');
            const matchedVariantPreviewImages = this.previewImagesByVariantAndProduct.get(this.selectedProduct.id)?.get(variantName);

            this.currentPreviewImages$.next(matchedVariantPreviewImages || this.selectedTemplate$.value?.preview_images);
        } else {
            this.currentPreviewImages$.next(this.selectedTemplate$.value?.preview_images);
        }
    }

    selectImage(file: any) {
        this.selectedImage = file;
        this.selectAreaPosition = this.logoMap.get(file).dragPosition;
    }

    generateQrCode() {
        this.imageGeneratingStatus.qrCodeImage = true;
        const rgbColor = this.utilService.hexToRgb(this.qrCodeColor);
        return this.getQrCode({
            wallet_address: this.walletStatusService.walletAddress,
            token_id: this.dynamicImageGroup.nftImage$.value.token_id,
            contract_address: this.dynamicImageGroup.nftImage$.value.contract_address,
            fill_color: [rgbColor.r, rgbColor.g, rgbColor.b, 255],
        });
    }

    changeProductImage(template: AdvancedCustomizedTemplate, productImage: { src: string; path: string }) {
        if (
            this.selectedTemplate$.value === template &&
            this.selectedTemplateType === 'productImage' &&
            productImage.src === this.selectedProductImage?.src
        ) {
            return;
        }

        this.applyTemplate(template);
        this.selectedViewID = 0;

        this.selectedTemplateType = 'productImage';
        this.selectedProductImage = productImage;
    }

    isSameProject(projectA: NFTViewByProject, projectB: NFTViewByProject) {
        return (
            projectA &&
            projectB &&
            projectA.contract_address &&
            projectB.contract_address &&
            projectA.contract_name &&
            projectB.contract_name &&
            projectA.contract_address.toLowerCase() === projectB.contract_address.toLowerCase() &&
            projectA.contract_name.toLowerCase() === projectB.contract_name.toLowerCase()
        );
    }

    // 验证所选中的 nft 是否符合1200 * 1200标准
    checkSelectedNftResolution() {
        this.utilService
            .checkIfImagePixelGreater(this.dynamicImageGroup.nftImage$.value?.thumbnail?.url, { width: 1200, height: 1200 })
            .then(() => (this.resolutionOfSelectedNftTooLow = false))
            .catch(() => (this.resolutionOfSelectedNftTooLow = true));
    }

    initServiceData() {
        this.logoList = [];
        this.logoMap = new Map();
        this.selectedViewID = 0;
        this.templateOptionMap = {};
        this.projectInfo$.next(null);
        this.nftImagesByProject$.next(null);
        this.NFTProducts$.next(null);
    }
}
