import { AdminService } from '@admin/service/admin.service';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NidavellirService } from '@modules/main/nidavellir.service';
import { CommonService } from '@services/common.service';
import { ShopProfileService } from '@src/app/services/shop-profile.service';
import { toLower } from 'lodash';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { VerificationContentComponent } from '../verification-content/verification-content.component';

@Component({
    selector: 'app-verification-project',
    templateUrl: './verification-project.component.html',
    styleUrls: ['./verification-project.component.less'],
})
export class VerificationProjectComponent implements OnInit, OnDestroy {
    @Input() isFirstImportProject: boolean;
    @Input() contractInfo;
    @Input() name: string;
    @ViewChild('verifyContentCmp') verifyContentCmp: VerificationContentComponent;

    submitting = false;

    brandName = new FormControl('');
    projectName: string;

    isAlreadyExists = false;
    isUpdateProjectKey = true;
    validatingProjectName = false;

    locationOrigin: string;

    private unsubscribe: Subject<void> = new Subject();

    get twitterUserInfo() {
        return this.verifyContentCmp?.twitterUserInfo$.getValue();
    }

    get content() {
        return this.verifyContentCmp?.verificationMaterials ? this.verifyContentCmp?.content : '';
    }

    get disabledSubmitBtn() {
        return (
            (this.contractInfo.official_twitter
                ? toLower(this.contractInfo.official_twitter) !== toLower(this.twitterUserInfo?.name)
                : !this.twitterUserInfo?.name) ||
            !this.brandName.value ||
            this.validatingProjectName ||
            this.isAlreadyExists
        );
    }

    constructor(
        public modalRef: NzModalRef,
        private nidavellirService: NidavellirService,
        private adminService: AdminService,
        private messageService: CommonService,
        private profileService: ShopProfileService,
        private loadingService: CommonService
    ) {}

    ngOnInit() {
        this.locationOrigin = location.origin;
        const defaultBrandName = `Project ${this.profileService.shopDetail.id}`;
        if (this.name !== defaultBrandName) {
            this.brandName.patchValue(this.name);
            this.projectName = this.name?.replace(/[^0-9a-zA-Z]/g, '')?.toLowerCase();
        }

        this.brandName.valueChanges.pipe(debounceTime(500), takeUntil(this.unsubscribe)).subscribe(text => {
            if (this.brandName.value === this.name || !text) {
                this.isAlreadyExists = false;
            } else {
                this.validatingProjectName = true;
                this.projectName = text.replace(/[^0-9a-zA-Z]/g, '')?.toLowerCase();
                this.nidavellirService
                    .validateProjectName({ name: text })
                    .then(res => (this.isAlreadyExists = false))
                    .catch(() => (this.isAlreadyExists = true))
                    .finally(() => (this.validatingProjectName = false));
            }
        });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    goVerifyProject() {
        this.submitting = true;
        this.adminService
            .updateProjectVerificationInfo({
                verified_twitter: this.twitterUserInfo.name,
                content: this.content,
                contract_name: this.contractInfo.contract_name,
                contract_address: this.contractInfo.contract_address,
                name: this.brandName.value,
                update_project_key: this.brandName.value !== this.name && !!this.isUpdateProjectKey,
            })
            .then(() => {
                this.messageService.success(`Your ownership to ${this.contractInfo.contract_name} has been successfully verified.`);
                this.modalRef.triggerOk();
                this.loadingService.showLoading();
                this.profileService.getShopDetail().then(shopProfile => this.profileService.shopDetailSubject.next(shopProfile));
            })
            .catch(() => this.messageService.error(`Failed to verify your ownership to ${this.contractInfo.contract_name}`))
            .finally(() => (this.submitting = false));
    }
}
