import { AfterViewInit, Component, OnInit } from '@angular/core';
import { EchartsOption } from '@shared/directives/echarts.directive';
import { LIFO_CHART_COLORS } from '@shared/types/general.type';

@Component({
    selector: 'app-distribution-of-gas-fee',
    templateUrl: './distribution-of-gas-fee.component.html',
    styleUrls: ['./distribution-of-gas-fee.component.less'],
})
export class DistributionOfGasFeeComponent implements AfterViewInit {
    total: number;
    lastPercent: number;

    initialOption: EchartsOption = {
        title: {
            show: false,
        },
        color: LIFO_CHART_COLORS,
        backgroundColor: 'transparent',
        legend: {
            show: false,
        },
        grid: {
            top: '20',
            left: '10',
            right: '10',
            bottom: '10',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: ['0-$0.1', '$0.1-$0.2', '$0.2-$0.3', '$0.3+'],
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            type: 'value',
            splitLine: {
                lineStyle: {
                    color: '#596175',
                    type: 'dashed',
                    width: 0.5,
                },
            },
        },
        tooltip: {
            show: true,
        },
    };

    optionToBeUpdate: EchartsOption;

    constructor() {}

    ngAfterViewInit(): void {
        this.setupUpdatedOption();
    }

    setupUpdatedOption() {
        this.optionToBeUpdate = {
            series: [
                {
                    data: [1997476, 494073, 2203, 664724],
                    type: 'bar',
                    color: '#1271F1',
                    barWidth: 30,
                    barMinHeight: 5,
                    itemStyle: {
                        borderRadius: [5, 5, 0, 0],
                    },
                },
            ],
        };
        this.total = this.optionToBeUpdate.series[0].data.reduce((acc, cur) => acc + cur, 0);
        this.lastPercent = this.optionToBeUpdate.series[0].data[3] / this.total || 0;
    }
}
