<section class="select-csv-list">
    <app-general-modal-header [isBrandModal]="true" [title]="'Select List'" (cancel)="modalRef.triggerCancel()"></app-general-modal-header>

    <span *ngIf="fileList?.length" class="select-all cursor-pointer" (click)="selectAll()"> {{fileList?.length === selectedFileSet.size ? 'Unselect' : 'Select'}} All </span>

    <div class="csv-file-list" appLoading [loading]="fetchingFileList">
        <div *ngFor="let fileItem of fileList" class="file-item">
            <label nz-checkbox [nzValue]="fileItem.id" [nzChecked]="selectedFileSet.has(fileItem)" (nzCheckedChange)="checkStatusChange(fileItem,$event)">
                <span nz-tooltip nzTooltipTitle="{{fileItem.fileName}}"> {{fileItem.fileName | nzEllipsis:(screenService.isMobile() ? 15: 30):'...'}} </span>
            </label>

            <span class="time-operation">
                <span class="upload-time">Uploaded {{fileItem.createdAt | date:'MM/dd/yyyy'}}</span>

                <i *ngIf="!removingFileSet.has(fileItem)" nz-icon nzType="delete" class="remove active-mask cursor-pointer" (click)="removeFile(fileItem)"></i>
                <i *ngIf="removingFileSet.has(fileItem)" nz-icon nzType="loading" class="remove active-mask cursor-pointer"></i>
            </span>
        </div>

        <p *ngIf="!fileList?.length && !fetchingFileList" class="text-align-center mt-2.5">Oops, there’s nothing here.</p>
    </div>

    <footer class="d-flex justify-content-center align-items-center">
        <button nz-button nzType="primary" (click)="modalRef.triggerOk()" [disabled]="!selectedFileSet.size">Confirm</button>
    </footer>
</section>
