import { Component, Input, Output, EventEmitter } from '@angular/core';

export enum ToolBarEnum {
    BOLD = 'font-weight',
    ITALIC = 'font-style',
    TEXT_COLOR = 'color',
    TEXT_ALIGN = 'text-align',
    TEXT_DECORATION = 'text-decoration',
}

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.less'],
})
export class ToolbarComponent {
    @Input() color: string;
    @Input() textAlign: 'left' | 'center' | 'right' | string = 'left';
    @Input() textDecoration: 'line-through' | 'underline';
    @Input() selectedToolbars: Set<ToolBarEnum> = new Set([ToolBarEnum.TEXT_ALIGN]);
    @Output() menuClicked = new EventEmitter<{ styles: { [key: string]: any }; selectedToolBars: Set<ToolBarEnum> }>();

    toolbarStyleMap = new Map([
        [ToolBarEnum.BOLD, { 'font-weight': 'bold' }],
        [ToolBarEnum.ITALIC, { 'font-style': 'italic' }],
        [ToolBarEnum.TEXT_DECORATION, { 'text-decoration': '' }],
        [ToolBarEnum.TEXT_COLOR, { color: '' }],
        [ToolBarEnum.TEXT_ALIGN, { 'text-align': '' }],
    ]);

    ToolBarEnum = ToolBarEnum;

    toolbarsWithChildren = new Set([ToolBarEnum.TEXT_DECORATION, ToolBarEnum.TEXT_COLOR, ToolBarEnum.TEXT_ALIGN]);
    toolbarsWithNoChildren = new Set([ToolBarEnum.BOLD, ToolBarEnum.ITALIC]);

    constructor() {}

    selectToolBarItem(toolBarItem: ToolBarEnum, data?: string) {
        this.toolbarStyleMap.get(ToolBarEnum.TEXT_DECORATION)['text-decoration'] = this.textDecoration;
        this.toolbarStyleMap.get(ToolBarEnum.TEXT_COLOR).color = this.color;
        this.toolbarStyleMap.get(ToolBarEnum.TEXT_ALIGN)['text-align'] = this.textAlign;

        // 选中另一种模式，添加到selectedTollBars
        if (
            (toolBarItem === ToolBarEnum.TEXT_ALIGN && data !== this.textAlign) ||
            (toolBarItem === ToolBarEnum.TEXT_COLOR && data !== this.color) ||
            (toolBarItem === ToolBarEnum.TEXT_DECORATION && data !== this.textDecoration)
        ) {
            this.selectedToolbars?.add(toolBarItem);
        }

        // 如果这个按钮是激活状态
        if (this.selectedToolbars?.has(toolBarItem)) {
            // 没有子选项的选项，取消选中
            if (
                (toolBarItem === ToolBarEnum.TEXT_ALIGN && data === this.textAlign) ||
                (toolBarItem === ToolBarEnum.TEXT_COLOR && data === this.color) ||
                (toolBarItem === ToolBarEnum.TEXT_DECORATION && data === this.textDecoration)
            ) {
                this.selectedToolbars?.delete(toolBarItem);

                if (toolBarItem === ToolBarEnum.TEXT_ALIGN) {
                    this.textAlign = null;
                }

                if (toolBarItem === ToolBarEnum.TEXT_COLOR) {
                    this.color = null;
                }

                if (toolBarItem === ToolBarEnum.TEXT_DECORATION) {
                    this.textDecoration = null;
                }
            }

            // 有子选项的选项，取消选中
            if (this.toolbarsWithNoChildren.has(toolBarItem)) {
                this.selectedToolbars?.delete(toolBarItem);
            }
        } else {
            this.selectedToolbars?.add(toolBarItem);
        }

        let resStyleObj: any = {};

        Array.from(this.selectedToolbars).forEach(toolbar => {
            const styleOfItem = this.toolbarStyleMap.get(toolbar);
            if (toolBarItem === ToolBarEnum.TEXT_COLOR) {
                this.color = data;
                styleOfItem.color = this.color;
            }

            if (toolBarItem === ToolBarEnum.TEXT_ALIGN) {
                this.textAlign = data as any;
                styleOfItem['text-align'] = this.textAlign;
            }

            if (toolBarItem === ToolBarEnum.TEXT_DECORATION) {
                this.textDecoration = data as any;
                styleOfItem['text-decoration'] = this.textDecoration;
            }

            resStyleObj = { ...resStyleObj, [toolbar]: styleOfItem[toolbar] };
        });

        this.menuClicked.emit({ styles: resStyleObj, selectedToolBars: this.selectedToolbars });
    }
}
