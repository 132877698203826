<div class="select-nft-project">
    <app-general-modal-header [title]="'Select Co-host'" [isBrandModal]="true" (cancel)="modalRef.triggerCancel()"> </app-general-modal-header>

    <p class="mt-2.5">You can only select one collection.</p>

    <div class="form-item" *ngIf="!isManualInput">
        <div class="form-label">
            Select From Existing Collections *
            <button nz-button nzType="link" class="manual-input-btn" (click)="isManualInput = true">Manual Input</button>
        </div>
        <div class="form-control relative">
            <img [src]="selectedProject?.resource.brand_avatar" [hidden]="!selectedProject?.resource.brand_avatar" class="select-img" />
            <nz-select
                nzShowSearch
                nzAllowClear
                [ngModel]="selectedProjectId"
                [nzShowArrow]="!activeProjectList?.length"
                [nzLoading]="!activeProjectList?.length"
                [nzClearIcon]="clearIcon"
                [ngClass]="{'pl-40': selectedProject?.resource.brand_avatar}"
                [nzDropdownClassName]="'select-item-no-padding'"
                [nzDropdownStyle]="{
                    background: '#1F1F23',
                    border: '1px solid rgba(255, 255, 255, 0.3)',
                    borderRadius: '10px'
                }"
                (ngModelChange)="changeProject($event)"
            >
                <nz-option *ngFor="let item of activeProjectList" nzCustomContent [nzLabel]="item.name" [nzValue]="item.id">
                    <div class="overflow-ellipsis project-option">
                        <img [src]="item.resource.brand_avatar" />
                        {{item.name}}
                    </div>
                </nz-option>
            </nz-select>
            <ng-template #clearIcon>
                <span nz-icon nzType="close-circle" nzTheme="outline"></span>
            </ng-template>
        </div>
    </div>

    <ng-container *ngIf="isManualInput">
        <div class="form-item">
            <div class="form-label">Collection Logo *</div>
            <div class="form-control project-logo">
                <div class="upload-logo">
                    <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="logoBeforeUpload" [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'" [nzDisabled]="isDisabled">
                        <div class="content" *ngIf="!(projectFormParams.brandAvatar || logoFile)">
                            <i nz-icon nzType="svg:upload-pic" style="font-size: 36px"></i>
                        </div>
                        <div class="logo-image" *ngIf="logoFileBgUrl" [ngStyle]="{'background-image': 'url(' + logoFileBgUrl + ')' }">
                            <div class="progress-box" *ngIf="logoFile">
                                <i nz-icon [nzType]="'loading'"></i>
                            </div>
                        </div>
                    </nz-upload>
                </div>
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">Collection Name *</div>
            <div class="form-control">
                <input nz-input placeholder="Collection name" [(ngModel)]="projectFormParams.name" [disabled]="isDisabled" />
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">Opensea or Magic Eden Collection URL</div>
            <div class="form-control">
                <input nz-input placeholder="Opensea or Magic Eden Collection URL" [(ngModel)]="projectFormParams.link" [disabled]="isDisabled" />
            </div>
        </div>
    </ng-container>

    <footer>
        <button class="cancel" nz-button nzGhost nzShape="round" (click)="modalRef.triggerCancel()">Cancel</button>
        <button nz-button nzShape="round" nzType="primary" [disabled]="disableConfirmBtn" (click)="modalRef.triggerOk()">Confirm</button>
    </footer>
</div>
