import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaqListItem, NidavellirService } from '@modules/main/nidavellir.service';
import { CommonService } from '@services/common.service';
import { camelCase, groupBy, map } from 'lodash';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.less'],
})
export class FaqComponent implements OnInit {
    @Input() isBrandPage: boolean = false;

    faqList: { category: string; id: string; list: FaqListItem[] }[];

    constructor(
        private loadingService: CommonService,
        private nidavellirService: NidavellirService,
        private messageService: CommonService,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit(): void {
        this.loadingService.showLoading();
        this.nidavellirService
            .getFaqAll(this.isBrandPage ? 'w3w_brand' : 'w3w')
            .then(res => {
                const faqGroup = groupBy(res, 'category');
                this.faqList = map(faqGroup, (value, key) => ({
                    id: camelCase(key),
                    category: key,
                    list: value,
                }));

                const position = this.activatedRoute.snapshot.queryParamMap.get('position');
                if (position) {
                    this.scrollInToView(position);
                }
            })
            .catch(() => this.messageService.error())
            .finally(() => this.loadingService.hideLoading());
    }

    scrollInToView(position) {
        setTimeout(() => {
            const positionEle = this.document.getElementById(position);
            if (position && positionEle) {
                this.document.documentElement.scrollTo({ left: 0, top: positionEle.offsetTop - 80, behavior: 'smooth' });
            }
        });
    }

    visitW3wWhitepaper() {
        window.open('https://w3w.gitbook.io/w3w.ai-whitepaper/', '_blank');
    }
}
