import { CampaignService } from '@admin/campaign/campaign.service';
import { ContractItem } from '@admin/service/admin-data.service';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { ChainEnum } from '@src/app/shared/types/wallet-chain.model';
import { cloneDeep } from 'lodash';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export interface TempCollection {
    id: number;
    name: string;
    image: string;
    threshold?: number;
    originalId?: number;
    isVerified?: boolean;
    platform?: 'opensea' | 'magiceden' | 'element';
    marketplaceLink?: string;
    enable_community?: boolean;
    contractName?: string;
    totalVolume?: number;
    type?: string;
    highValueUserCount?: number;
    recentActiveWalletOnEth?: number;
    recentActiveWalletOnPolygon?: number;
    recentActiveWalletOnBnb?: number;
}

@Component({
    selector: 'app-select-nft-collection',
    templateUrl: './select-nft-collection.component.html',
    styleUrls: ['./select-nft-collection.component.less'],
})
export class SelectNftCollectionComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() themeMode: 'modal' | 'embed' = 'modal';
    @Input() needDisableNoCommunityMyCollection = false;
    @Input() onlyFullDataCollection = false;
    @Input() selectedNftCollections: TempCollection[];
    @Input() myCollections: ContractItem[];
    @Input() chain: ChainEnum = ChainEnum.ETHEREUM;

    @Output() confirm = new EventEmitter<TempCollection[]>();
    @Output() cancel = new EventEmitter<void>();

    @ViewChild('searchInput') searchInput: ElementRef;

    searchVal: string = '';
    popularCollectionList: TempCollection[];
    tempMyCollectionList: TempCollection[];
    tempSelectedNftCollections: TempCollection[];
    tempSelectedNftCollectionIds = new Set<number>();

    fetchingCollectionList = false;

    destroy$ = new Subject();

    constructor(private campaignService: CampaignService, public screenService: CommonService) {}

    ngOnInit(): void {
        this.tempMyCollectionList = this.myCollections.map(c => ({
            id: c.collection_id,
            name: c.contract_name,
            image: c.contract_image_url,
            enable_community: c.enable_community,
            contractName: '',
        }));
        this.tempSelectedNftCollectionIds = new Set(this.selectedNftCollections.map(item => item.id));
        this.tempSelectedNftCollections = cloneDeep(this.selectedNftCollections);
        this.getSearchResult();
    }

    ngAfterViewInit() {
        fromEvent(this.searchInput.nativeElement, 'input')
            .pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe(() => this.getSearchResult());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getSearchResult() {
        this.fetchingCollectionList = true;
        this.campaignService
            .searchNftPopularCollection({
                chain: this.chain,
                searchValue: this.searchVal,
                onlyFullDataCollection: this.onlyFullDataCollection,
            })
            .subscribe(
                data => {
                    this.popularCollectionList = data.map(c => ({
                        id: c.id,
                        name: c.contract_name,
                        image: c.image_url,
                        isVerified: c.is_verified,
                        platform: c.platform,
                        marketplaceLink: c.marketplace_link,
                        contractName: c.contract_name,
                    }));
                    this.fetchingCollectionList = false;
                },
                () => (this.fetchingCollectionList = false)
            );
    }

    removeCollection(collection: TempCollection) {
        this.tempSelectedNftCollectionIds.delete(collection.id);
        this.tempSelectedNftCollections = this.tempSelectedNftCollections.filter(c => c.id !== collection.id);
    }

    checkStatusChange(item: TempCollection, checked: boolean) {
        if (checked) {
            this.tempSelectedNftCollectionIds.add(item.id);
            this.tempSelectedNftCollections.push(item);
        } else {
            this.removeCollection(item);
        }
    }

    openMarketplaceLink(link: string) {
        if (link) {
            window.open(link, '_blank');
        }
    }

    selectAllMyCollection() {
        this.tempMyCollectionList.forEach(c => {
            this.removeCollection(c);

            if (!this.isDisabledCollection(c)) {
                this.checkStatusChange(c, true);
            }
        });
    }

    confirmClicked() {
        this.confirm.emit(this.tempSelectedNftCollections);
    }

    isDisabledCollection(collection: TempCollection) {
        return this.needDisableNoCommunityMyCollection && !collection.enable_community;
    }
}
