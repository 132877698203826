<section class="general-modal-box edit-description-box">
    <app-general-modal-header
        [title]="'Edit Description'"
        [isBrandModal]="true"
        (cancel)="modalRef.triggerCancel()"
    ></app-general-modal-header>

    <div class="content">
        <textarea nz-input placeholder="Add description" [(ngModel)]="brandDescription"></textarea>
    </div>

    <footer class="d-flex justify-content-center align-items-center footer-btns">
        <button nz-button nzShape="round" (click)="modalRef.triggerCancel()" class="cancel-btn">CANCEL</button>
        <button nz-button nzType="primary" nzShape="round" [nzLoading]="submitting" (click)="modalRef.triggerOk()">CONFIRM</button>
    </footer>
</section>
