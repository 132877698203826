import { Component, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import { Web3Provider } from '@ethersproject/providers';
import { SocialMedia } from '@modules/admin/campaign/campaign.model';
import { NidavellirService } from '@modules/main/nidavellir.service';
import { WalletStatusService } from '@modules/main/wallet-status.service';
import * as fcl from '@onflow/fcl';
import { Ethereum, ParticleConnect, evmWallets } from '@particle-network/connect';
import { CommonService } from '@services/common.service';
import { DiscordService } from '@services/discord.service';
import { CustomEventEnum, FirebaseEventsService } from '@src/app/services/firebase/firebase-event.service';
import { ShopProfileService } from '@src/app/services/shop-profile.service';
import { WalletEnum as NFTPlatform } from '@src/app/shared/types/wallet-chain.model';
import { environment } from '@src/environments/environment';
import UAuth from '@uauth/js';
import WalletConnect from '@walletconnect/web3-provider';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { SESSION_STORAGE_KEY_ENUM } from '../../types/general.type';
export { WalletEnum as NFTPlatform } from '@src/app/shared/types/wallet-chain.model';

interface ConnectWalletModalConfig {
    type: 'customer' | 'general';
    combineWallet?: boolean;
}

@Component({
    selector: 'app-connect-nft-modal',
    templateUrl: './connect-nft-modal.component.html',
    styleUrls: ['./connect-nft-modal.component.less'],
})
export class ConnectNftModalComponent {
    @Input() enabledNFTPlatforms: NFTPlatform[] = [
        NFTPlatform.BLOCTO,
        NFTPlatform.COINBASE,
        NFTPlatform.DAPPER,
        NFTPlatform.META_MASK,
        NFTPlatform.PHANTOM,
        NFTPlatform.WALLET_CONNECT,
        NFTPlatform.NEAR,
        NFTPlatform.UNSTOPPABLE_DOMAINS,
        NFTPlatform.TRUST_WALLET,
        NFTPlatform.PARTICLE,
    ];

    @Input() modalConfig: ConnectWalletModalConfig = {
        type: 'customer',
        combineWallet: false,
    };

    @Input() projectName: string;

    @Input() triggerType: 'header_account' | 'connect_wallet' | 'combine_wallet';

    NFTPlatform = NFTPlatform;

    web3Provider: Web3Provider;

    uAuth: UAuth;

    dapperEnv = {
        DAPPER_WALLET_DISCOVERY: 'https://accounts.meetdapper.com/fcl/authn-restricted',
        DAPPER_WALLET_DISCOVERY_METHOD: 'POP/RPC',
        FLOW_ACCESS_NODE: 'https://access-mainnet.onflow.org',
    };
    bloctoEnv = {
        BLOCTO_WALLET_DISCOVERY: 'https://flow-wallet.blocto.app/api/flow/authn',
        BLOCTO_WALLET_DISCOVERY_METHOD: 'HTTP/POST',
        FLOW_ACCESS_NODE: 'https://access-mainnet.onflow.org',
    };
    unstoppableDomainsEnv = {
        clientID: environment.production ? '267c9a01-55ff-42cd-90de-30e4594adb9f' : 'c148f055-16ae-4624-b729-5d6b9dcb83d0',
        redirectUri: `${window.location.protocol}//${window.location.host}`,
        scope: 'openid wallet email profile social',
    };

    constructor(
        public modalRef: NzModalRef,
        public discordService: DiscordService,
        private nidavellirService: NidavellirService,
        private firebaseEventService: FirebaseEventsService,
        private screenService: CommonService,
        private messageService: CommonService,
        private authService: AngularFireAuth,
        private profileService: ShopProfileService,
        public walletStatusService: WalletStatusService
    ) {}

    async connect(type: NFTPlatform) {
        if (this.triggerType) {
            this.firebaseEventService.logEvent({
                name: CustomEventEnum.SHOW_CONNECT_WALLET_MODAL,
                properties: { type },
            });
        }

        try {
            switch (type) {
                case NFTPlatform.DAPPER:
                    await this.connectDapper();
                    break;

                case NFTPlatform.WALLET_CONNECT:
                    await this.connectWalletConnect();
                    break;

                case NFTPlatform.COINBASE:
                    await this.connectCoinBase();
                    break;

                case NFTPlatform.META_MASK:
                    await this.connectMetaMask();
                    break;

                case NFTPlatform.PHANTOM:
                    await this.connectPhantom();
                    break;

                case NFTPlatform.BLOCTO:
                    await this.connectBlocto();
                    break;

                case NFTPlatform.NEAR:
                    await this.connectNearWallet();
                    break;

                case NFTPlatform.UNSTOPPABLE_DOMAINS:
                    await this.connectUnstoppableDomains();
                    break;

                case NFTPlatform.TRUST_WALLET:
                    await this.connectTrustWallet();
                    break;

                case NFTPlatform.PARTICLE:
                    await this.connectParticle();
                    break;
            }
        } catch (err) {
            if (err.code === -30002) {
                this.messageService.error(err.message || null);
            }
        }

        this.firebaseEventService.logEvent({
            name: CustomEventEnum.CONNECT_WALLET,
            properties: { wallet_type: type },
        });
    }

    async connectNearWallet() {
        sessionStorage.setItem(SESSION_STORAGE_KEY_ENUM.WALLET_TYPE, NFTPlatform.NEAR);
        sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.WALLET_ADDR);

        if (!this.walletStatusService.nearWallet) {
            await this.walletStatusService.setupNearConnection();
        }

        this.walletStatusService.nearWallet.requestSignIn();
    }

    async connectWalletConnect() {
        const provider = new WalletConnect({
            infuraId: '005233ef0d61464a98a17a1230d7c59a',
        });

        const accounts = await provider.enable();
        if (this.modalConfig.combineWallet) {
            this.walletStatusService.pendingCombinedWallet$.next({
                walletType: NFTPlatform.WALLET_CONNECT,
                walletAddr: accounts[0],
            });
        } else {
            this.walletStatusService.setSessionData(NFTPlatform.WALLET_CONNECT, accounts[0]);
            this.walletStatusService.selectedWalletChainId = await provider.send('eth_chainId', []);
        }
        this.modalRef.triggerOk();
    }

    connectDapper() {
        fcl.config({
            'discovery.wallet': this.dapperEnv.DAPPER_WALLET_DISCOVERY,
            'discovery.wallet.method': this.dapperEnv.DAPPER_WALLET_DISCOVERY_METHOD,
            'accessNode.api': this.dapperEnv.FLOW_ACCESS_NODE,
            'app.detail.title': 'W3W',
            'app.detail.icon': '',
            env: 'mainnet',
        });

        fcl.authenticate();
        this.walletStatusService.setSessionData(NFTPlatform.DAPPER, null);
        this.modalRef.triggerOk();
    }

    connectBlocto() {
        fcl.config({
            'discovery.wallet': this.bloctoEnv.BLOCTO_WALLET_DISCOVERY,
            'accessNode.api': this.bloctoEnv.FLOW_ACCESS_NODE,
            'discovery.wallet.method': this.bloctoEnv.BLOCTO_WALLET_DISCOVERY_METHOD,
            'challenge.handshake': 'https://flow-wallet-mainnet.blocto.app/authn',
            'app.detail.title': 'W3W',
            'app.detail.icon': '',
            env: 'mainnet',
        });

        fcl.authenticate();
        this.walletStatusService.setSessionData(NFTPlatform.BLOCTO, null);
        this.modalRef.triggerOk();
    }

    async connectCoinBase() {
        const coinbaseWallet = new CoinbaseWalletSDK({
            appName: 'Lifo INC',
            darkMode: false,
        });

        const providerCoinbase = coinbaseWallet.makeWeb3Provider('https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a', 1);
        this.web3Provider = new Web3Provider(providerCoinbase);
        const accounts = await this.web3Provider.send('eth_requestAccounts', []);
        if (this.modalConfig.combineWallet) {
            this.walletStatusService.pendingCombinedWallet$.next({
                walletType: NFTPlatform.COINBASE,
                walletAddr: accounts[0],
            });
        } else {
            this.walletStatusService.selectedWalletChainId = await this.web3Provider?.send('eth_chainId', []);
            this.walletStatusService.setSessionData(NFTPlatform.COINBASE, accounts[0]);
        }

        this.modalRef.triggerOk();
    }

    async connectMetaMask() {
        const globalWindow: any = window;

        const metamaskProvider =
            globalWindow?.ethereum?.providers?.find(p => p.isMetaMask) ||
            (globalWindow.ethereum?.isMetaMask ? globalWindow.ethereum : null);

        if (metamaskProvider) {
            this.web3Provider = new Web3Provider(metamaskProvider);
        } else {
            if (this.screenService.isMobile()) {
                // eslint-disable-next-line max-len
                globalWindow.location.href = `https://metamask.app.link/dapp/${globalWindow.location.host}/${globalWindow.location.pathname}`;
            } else {
                globalWindow.open('https://metamask.io/download/', '_blank');
            }

            return;
        }

        const accounts = await this.web3Provider.send('eth_requestAccounts', []);
        if (this.modalConfig.combineWallet) {
            this.walletStatusService.pendingCombinedWallet$.next({
                walletType: NFTPlatform.META_MASK,
                walletAddr: accounts[0],
            });
        } else {
            this.walletStatusService.selectedWalletChainId = await metamaskProvider?.request({ method: 'eth_chainId' });
            this.walletStatusService.setSessionData(NFTPlatform.META_MASK, accounts[0]);
        }

        this.modalRef.triggerOk();
    }

    async connectTrustWallet() {
        const isTrustWalletEnabled = Boolean((window.ethereum as any)?.isTrust) || Boolean((window.ethereum as any)?.isTrustWallet);
        const trustWalletProvider = window.ethereum as any;

        if (isTrustWalletEnabled) {
            this.web3Provider = new Web3Provider(trustWalletProvider);
        } else {
            if (this.screenService.isMobile()) {
                // eslint-disable-next-line max-len
                window.location.href = `https://link.trustwallet.com/open_url?url=${window.location.protocol}//${window.location.host}/${window.location.pathname}&coin_id=60`;
            } else {
                window.open('https://chrome.google.com/webstore/detail/trust-wallet/egjidjbpglichdcondbcbdnbeeppgdph', '_blank');
            }
            return;
        }

        const accounts = await this.web3Provider.send('eth_requestAccounts', []);
        if (this.modalConfig.combineWallet) {
            this.walletStatusService.pendingCombinedWallet$.next({
                walletType: NFTPlatform.TRUST_WALLET,
                walletAddr: accounts[0],
            });
        } else {
            this.walletStatusService.selectedWalletChainId = await trustWalletProvider?.request({ method: 'eth_chainId' });
            this.walletStatusService.setSessionData(NFTPlatform.TRUST_WALLET, accounts[0]);
        }

        this.modalRef.triggerOk();
    }

    async connectPhantom() {
        if ('solana' in window) {
            const phantomProvider = (window as any).solana;
            if (phantomProvider.isPhantom) {
                try {
                    const resp = await (window as any).solana.connect();
                    const pubKey = resp.publicKey.toString();
                    if (this.modalConfig.combineWallet) {
                        this.walletStatusService.pendingCombinedWallet$.next({
                            walletType: NFTPlatform.PHANTOM,
                            walletAddr: pubKey,
                        });
                    } else {
                        this.walletStatusService.setSessionData(NFTPlatform.PHANTOM, pubKey);
                    }

                    this.modalRef.triggerOk();
                } catch (err) {
                    this.messageService.error(err.message);
                }
            }
        } else {
            if (this.screenService.isMobile()) {
                const encodeUri = encodeURIComponent(location.href);
                window.location.href = `https://phantom.app/ul/browse/${encodeUri}?ref=${encodeUri}`;
            } else {
                window.open('https://phantom.app/', '_blank');
            }
        }
    }

    async connectUnstoppableDomains() {
        this.uAuth = new UAuth(this.unstoppableDomainsEnv);

        try {
            const authorization = await this.uAuth.loginWithPopup();
            if (this.modalConfig.combineWallet) {
                this.walletStatusService.pendingCombinedWallet$.next({
                    walletType: NFTPlatform.UNSTOPPABLE_DOMAINS,
                    walletAddr: authorization.idToken.wallet_address,
                });
            } else {
                this.walletStatusService.setSessionData(NFTPlatform.UNSTOPPABLE_DOMAINS, authorization.idToken.wallet_address);
            }

            const contactList = [];

            // Twitter
            if (authorization.idToken.twitter) {
                contactList.push({
                    contactId: null,
                    contact: authorization.idToken.twitter.location,
                    contactType: SocialMedia.TWITTER,
                });
            }

            // Discord
            if (authorization.idToken.discord) {
                contactList.push({
                    contactId: null,
                    contact: authorization.idToken.discord.location,
                    contactType: SocialMedia.DISCORD,
                });
            }

            // YouTube
            if (authorization.idToken.youtube) {
                contactList.push({
                    contactId: null,
                    contact: authorization.idToken.youtube.location,
                    contactType: SocialMedia.YOUTUBE,
                });
            }

            // Reddit
            if (authorization.idToken.reddit) {
                contactList.push({
                    contactId: null,
                    contact: authorization.idToken.reddit.location,
                    contactType: SocialMedia.REDDIT,
                });
            }

            // Telegram
            if (authorization.idToken.telegram) {
                contactList.push({
                    contactId: null,
                    contact: authorization.idToken.telegram.location,
                    contactType: SocialMedia.TELEGRAM,
                });
            }

            if (contactList.length > 0 || (authorization.idToken.email && authorization.idToken.email_verified)) {
                const data = {
                    email: authorization.idToken.email,
                    walletAddress: authorization.idToken.wallet_address,
                    walletName: NFTPlatform.UNSTOPPABLE_DOMAINS,
                    contactList,
                };

                if (!data.email) {
                    delete data.email;
                }

                this.nidavellirService.updateUserInfo(data).catch(() => console.warn('Update Contact List Failed'));
            }

            this.modalRef.triggerOk();
        } catch (error) {
            console.error(error);
        }
    }

    async connectParticle() {
        const connectKit = new ParticleConnect({
            projectId: 'cd74c209-9209-4144-9cd9-fc60843a3059',
            clientKey: 'c30nvisPmpPAwJUilhZMs3wMR5CDpp9aCG04r0fL',
            appId: 'e5203214-0120-46a0-8662-64fac732c923',
            chains: [
                {
                    id: 1,
                    name: 'Ethereum',
                },
            ],
            particleWalletEntry: {
                displayWalletEntry: true,
                supportChains: [Ethereum],
                customStyle: {},
            },
            wallets: evmWallets({ qrcode: false }),
        });

        const provider: any = await connectKit.connect();
        const web3Provider = new Web3Provider(provider as any);
        const addressList = await web3Provider.send('eth_requestAccounts', []);
        if (this.modalConfig?.combineWallet) {
            this.walletStatusService.pendingCombinedWallet$.next({
                walletType: NFTPlatform.PARTICLE,
                walletAddr: addressList[0],
            });
            this.modalRef.triggerOk();
        } else {
            this.walletStatusService.setSessionData(NFTPlatform.PARTICLE, addressList[0]);
            this.walletStatusService.selectedWalletChainId = await web3Provider.send('eth_chainId', []);
            this.modalRef.triggerOk();
        }

        // const particle = new ParticleNetwork({
        //     projectId: '55a7f62e-c9fa-48b3-88b3-65ad1e926ded',
        //     clientKey: 'cOT4uh5h5s0QbvlktmX5wmhY4aDs3O4pSCiI5QX5',
        //     appId: '178d0ba3-123f-4d44-979f-1331ca89258d',
        //     chainName: 'Ethereum',
        //     chainId: 1,
        //     wallet: {
        //         displayWalletEntry: true,
        //         defaultWalletEntryPosition: WalletEntryPosition.BR,
        //         // supportChains: [
        //         //   {
        //         //     id: 1,
        //         //     name: "Ethereum",
        //         //   },
        //         //   // {
        //         //   //   id: 101,
        //         //   //   name: "Solana",
        //         //   // },
        //         // ],
        //     },
        // });

        // particle.auth
        //     .login({
        //         preferredAuthType: 'email',
        //     })
        //     .then(val => alert(JSON.stringify(val)));
    }

    async brandLogin() {
        this.modalRef.triggerCancel();

        if (await this.authService.currentUser) {
            window.location.href = '/admin/home';
        } else {
            this.profileService.showSignInModal();
        }
    }
}
