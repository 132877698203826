import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { NgxStripeModule } from 'ngx-stripe';
import { SwiperModule } from 'swiper/angular';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        DragDropModule,
        ColorPickerModule,
        NgxStripeModule,
        NgxPlaidLinkModule,
        LazyLoadImageModule,
        SwiperModule,
        EditorModule,
    ],
    exports: [DragDropModule, ColorPickerModule, NgxStripeModule, NgxPlaidLinkModule, LazyLoadImageModule, SwiperModule, EditorModule],
})
export class OtherLibsModule {}
