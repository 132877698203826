import { NFTPlatform } from '@components/connect-nft-modal/connect-nft-modal.component';
import { SocialMedia } from '@services/socialmedia-connect.service';
import { ChainEnum } from '@src/app/shared/types/wallet-chain.model';
import { MocaToken } from '../../public/required-campaign-data/required-campaign-data.service';
import { NetworkEnum } from './campaign.service';
export { SocialMedia } from '@services/socialmedia-connect.service';

export enum CampaignChallengeCheckType {
    FOLLOW = 'follow',
    JOIN = 'join', // Discord join server
    RETWEET = 'retweet',
    REPLY_TWEET = 'reply_tweet',
    POST_TWEET = 'post_tweet',
    QUOTE_TWEET = 'quote_tweet',
}

export enum CampaignRequirementType {
    COLLECTION = 'collection',
    TOKEN = 'token',
    FILE = 'file',
    GROUP = 'group',
    ROLE = 'role',
    FILTER_TAG = 'filter_tag',
    CUSTOM = 'custom',
}

export enum CampaignChallengeType {
    TWITTER_FOLLOW = 'twitterFollow',
    TWITTER_RETWEET = 'twitterRetweet',
    TWITTER_REPLY = 'twitterReply',
    TWITTER_QUOTE = 'twitterQuote',
    TWITTER_POST = 'twitterPost',
    DISCORD_FOLLOW = 'discordFollow',
    DISCORD_FOLLOW_VERIFY = 'discordFollowVerify', // discordFollowVerify is a subset of discordFollow
    TELEGRAM_FOLLOW = 'telegramFollow',
    CUSTOM_FIELD = 'customField',
}

export interface CampaignChallenge {
    id?: any;
    campaign_id?: any;
    challenge_type: SocialMedia | 'custom_input';
    check_type: CampaignChallengeCheckType;
    challenge_input: string;
    at_accounts?: number;
    hashtags?: string[];
    discord_server_guild_id?: string;
}
export interface DataUsedToCreateCampaign {
    type: CampaignTypeEnum;
    name: string;
    description: string;
    start_date: string;
    end_date: string;
    images: MediaFile[];
    sales_type: EligibleTypeEnum;
    required_social_media: { type: SocialMedia; is_required: boolean }[];
    campaign_challenges: CampaignChallenge[];
    require_retweet: boolean;
    require_wallet: boolean;
    supported_wallet: NFTPlatform[];
    is_public: boolean;
    chain: ChainEnum;
    timezone: string;
    raffle_type: RaffleTypeEnum;
    raffle_weighted_collections?: { collection_id: number; weight: number }[];
    campaign_requirements?: (
        | { collection_id: number; type: CampaignRequirementType.COLLECTION }
        | { collection_id: number; type: CampaignRequirementType.TOKEN }
        | { attachment_id: number; type: CampaignRequirementType.FILE }
        | { group_id: number; type: CampaignRequirementType.GROUP }
        | { role_id: number; type: CampaignRequirementType.ROLE }
        | { filter_tag_id: number; type: CampaignRequirementType.FILTER_TAG }
        | {
              custom_field_config: {
                  display_value: string;
                  message: string;
                  name: string;
                  value: string;
              };
              type: CampaignRequirementType.CUSTOM;
          }
    )[];
}

export interface HandledProjectCampaign extends ProjectCampaign {
    sign_up_count: number;
    status: CampaignStatus;
    hidden?: boolean;
}

export enum CampaignStatus {
    ACTIVE = 'ACTIVE',
    ENDED = 'ENDED',
    UPCOMING = 'UPCOMING',
}
export interface ProjectCampaign extends Omit<DataUsedToCreateCampaign, 'campaign_requirements'> {
    id: number;
    is_winner_picked: boolean;
    total_winner_count: number;
    project_id: number;
    project_key: string;
    referral_campaign_id: number;
    collection_detail: {
        contract_image_url: string;
        contract_name: string;
    };
    campaign_challenges: {
        challenge_type: SocialMedia | 'custom_input';
        check_type: CampaignChallengeCheckType;
        challenge_input: string;
        id: number;
        display?: string;
        at_accounts?: number;
        hashtags?: string[];
        roles?: string[];
        role_list?: {
            id: number;
            color: number;
            name: string;
        }[];
    }[];
    raffle_weighted_collections: { collection_name: string; collection_id: any; weight: number }[];
    campaign_requirements?: (
        | {
              id: number;
              collection_id: number;
              type: CampaignRequirementType.COLLECTION;
              contract_name?: string;
              collection_name?: string;
              collection_image_url?: string;
              threshold: number;
          }
        | {
              id: number;
              collection_id: number;
              type: 'token';
              collection_name?: string;
              collection_image_url?: string;
              threshold: number;
              contract_name?: string;
          }
        | { id: number; attachment_id: number; type: CampaignRequirementType.FILE; file_name?: string; upload_path?: string }
        | { id: number; group_id: number; type: CampaignRequirementType.GROUP }
        | { id: number; role_id: number; type: CampaignRequirementType.ROLE }
        | { id: number; filter_tag_id: number; type: CampaignRequirementType.FILTER_TAG }
        | {
              id: number;
              custom_field_config: {
                  display_value: string;
                  message: string;
                  name: string;
                  value: string;
              };
              type: CampaignRequirementType.CUSTOM;
          }
    )[];
    cohost_campaign: boolean;
    is_host: boolean;
    is_cohost: boolean;
    project_name: string;
    brand_avatar: string;
    status: string;
    banner_bg: string;
    cohost_project_id: number;
    cohost_project_key: string;
    cohost_brand_avatar: string;
    cohost_status: string;
    cohost_project_name: string;
    moca_only_campaign: boolean;
    referral_token_image?: string;
}

export class MediaFile {
    src: string;
    path: string;

    constructor(path: string, src: string) {
        this.src = src;
        this.path = path;
    }
}

export class CSVFile extends MediaFile {
    name: string;

    constructor(name: string, path: string, src: string, attachmentId: number) {
        super(path, src);
        this.name = name;
    }
}

export interface PerformanceCardData {
    name: string;
    name_tooltip?: string;
    count: number;
    increased_count: number;
    type: string;
    tooltip: string;
    additional_info?: string;
    additional_info_tooltip?: string;
}

export interface PerformanceOverview {
    campaign_signup_count: { [key: number]: number };
    discord_count: number;
    discord_count_7_day: number;
    email_count: number;
    email_count_7_day: number;
    sign_up_count: number;
    sign_up_count_7_day: number;
    sign_up_time_series: {
        name: string;
        date_map: { [key: string]: number };
        values: {
            date: string;
            value: number;
        }[];
    }[];
    sign_up_time_series_by_month: {
        name: string;
        date_map: { [key: string]: number };
        values: {
            date: string;
            value: number;
        }[];
    }[];
    sign_up_time_legends: string[];
    sign_up_time_legends_by_month: string[];
    telegram_count: number;
    telegram_count_7_day: number;
    twitter_count: number;
    twitter_count_7_day: number;
}

export interface CampaignEntry {
    campaign_id: number;
    campaign_name: string;
    id: number;
    entry_id: number;
    wallet_address: string;
    wallet_id: number;
    wallet_type: string;
    campaign_type: CampaignTypeEnum;
    is_winner: boolean;
    created_at: string;
    account_balance: {
        balance: number;
        eth_balance_updated_at: string;
        bnb_balance_updated_at: string;
        polygon_balance_updated_at: string;
        chain: string;
        bnb_balance: number;
        eth_balance: number;
        polygon_balance: number;
    };
    contacts: { contact: string; contactType: SocialMedia; source: string }[];
    holder_score: number;
    collections: { name: string; imageUrl: string; nftCount: number; tags: any[]; chain: string }[];
    my_collections: { name: string; imageUrl: string; nftCount: number; tags: any[]; chain: string; holding_status: string }[];
    tags: string[];
    holdings_updated_at: string;
    chain: NetworkEnum;
    holding_status: string;
    weight: number;
    email: string;
    filter_tags: { name: string; filter_tag_id: number }[];
    groups: any;
    bnb_balance: number;
    eth_balance: number;
    polygon_balance: number;
    custom_input?: { value: string; challenge_id: number }[];
    winning_moca_list?: MocaToken[];
    is_lock: boolean;
}

export interface FrontEndCampaignEntry extends CampaignEntry {
    handled_created_at?: string;
    handled_balance_updated_at?: {
        eth: string;
        // GK HIDE BNB/BSC
        // bnb: string;
        polygon: string;
    };
    handled_wallet_address_updated_at?: string;
    expand?: boolean;
    combined_wallet_count?: number;
    parent?: FrontEndCampaignEntry;
    isMultiWallet?: boolean;
}

export interface ScrollData {
    offsetLeft: number;
    isStart: boolean;
    isEnd: boolean;
}

export interface AdditionalSignUpUserInfo {
    walletAddress: {
        chain: string;
        address: string;
        profileImg: string;
        username: string;
        updatedAt: number;
    };
    collections: {
        chain: string;
        name: string;
        slug: string;
        imageUrl: string;
        description: string;
        discordUrl: string;
        externalUrl: string;
        nftCount: number;
        tags: string[];
    }[];
    socialInfo: {
        contact: string;
        contactType: SocialMedia;
    }[];
    tags: string[];
}

export enum JoinedFromEnum {
    CAMPAIGN = 'campaign',
    COLLECTION = 'collection',
    MANUAL_INPUT = 'manual_input',
}

export interface CommunityTableItem {
    account_balance: any;
    contacts: any[];
    holding_status: any;
    id: number;
    wallet_id: number;
    wallet_address: string;
    participate_campaigns: {
        campaign_id: number;
        campaign_name: string;
        is_selected: boolean;
        created_at?: string;
        campaign_type?: CampaignTypeEnum;
    }[];
    holder_score: number;
    collections: {
        name: string;
        imageUrl: string;
        nftCount: number;
        floor_price: number;
        tags: any[];
        chain: NetworkEnum;
        last_transfer_in_date: string;
        month_transaction_in: number;
        last_transfer_out_date: string;
        month_transaction_out: number;
        collection_tags?: string[];
    }[];
    my_collections: {
        name: string;
        imageUrl: string;
        nftCount: number;
        floor_price: number;
        tags: any[];
        chain: NetworkEnum;
        last_transfer_in_date: string;
        month_transaction_in: number;
        last_transfer_out_date: string;
        month_transaction_out: number;
        holding_status: string;
    }[];
    tags: string[];
    holdings_updated_at: string;
    chain: NetworkEnum;
    groups: { name: string; group_id: number }[];
    filter_tags: { name: string; filter_tag_id: number }[];
    email: string;
    is_new_wallet: boolean;
    total_transfer_in: number;
    total_transfer_out: number;
    total_net_earnings: number;
    collection_spendings: {
        name: string;
        imageUrl: string;
        transfer_in: number;
        transfer_out: number;
        net_earnings: number;
    }[];
    eth_balance: number;
    bnb_balance: number;
    polygon_balance: number;
    eth_first_transaction_date: string;
    eth_last_transaction_date: string;
    bnb_first_transaction_date: string;
    bnb_last_transaction_date: string;
    polygon_first_transaction_date: string;
    polygon_last_transaction_date: string;
    eth_total_transaction: number;
    eth_30_day_transaction: number;
    bnb_total_transaction: number;
    bnb_30_day_transaction: number;
    polygon_total_transaction: number;
    polygon_30_day_transaction: number;
    total_buying_power: number;
    collections_spending_net: number;
    my_tokens: {
        chain: string;
        count: number;
        imageUrl: string;
        last_transfer_in_date: string;
        last_transfer_out_date: string;
        month_transaction_in: number;
        month_transaction_out: number;
        name: string;
    }[];
    joined_at: string;
    joined_from: JoinedFromEnum;
}

export interface CommunityDetailScore {
    activity_user_label: string;
    avg_erc20_hold_time: number;
    avg_erc721_collection_hold_time: number;
    avg_erc721_token_hold_time: number;
    created_at: string;
    credit_label: string;
    credit_score: number;
    cur_hold_erc20_type_cnt: number;
    cur_hold_erc721_collection_cnt: number;
    cur_hold_erc721_token_cnt: number;
    displayed_credit_score: number;
    erc20_token_arbitrage_probability: number;
    erc20_token_arbitrage_score: number;
    erc721_token_arbitrage_probability: number;
    erc721_token_arbitrage_score: number;
    eth_balance_amount: number;
    eth_balance_value: number;
    ether_wealth_label: any;
    his_hold_erc20_type_cnt: number;
    his_hold_erc721_collection_cnt: number;
    his_hold_erc721_token_cnt: number;
    id: number;
    last_1m_tx_cnt: number;
    last_1w_tx_cnt: number;
    last_1yr_tx_cnt: number;
    nft_in_tx: number;
    nft_out_tx: number;
    nft_total_tx: number;
    nft_tx_label: string;
    token_collector_score: number;
    total_tx_cnt: number;
    updated_at: string;
    wallet_address: string;
    wallet_id: number;
    web3_player_score: number;
}

export interface CoinItem {
    amount: number;
    contractAddress: string;
    name: string;
    nativeValue: number;
    symbol: string;
    usdValue: number;
    logo_url: string;
    contract_name: string;
}

export interface CollectibleItem {
    floorPrice: number;
    inTs: string;
    name: string;
    openseaSlug: string;
    quantity: number;
    imageUrl: string;
}

export interface CommunityDetailScoreV2 {
    coins: CoinItem[];
    collectibles: CollectibleItem[];
    ens_name: string;
    id: number;
    labels: string[];
    lifetime_average_hold: number;
    lifetime_longest_hold: number;
    lifetime_oldest_transfer_in: string;
    portfolio_average_hold: number;
    portfolio_coin_value: number;
    portfolio_collectible_count: number;
    portfolio_collectible_value: number;
    portfolio_longest_hold: number;
    portfolio_newest_transfer_in: string;
    portfolio_oldest_transfer_in: string;
    portfolio_total_value: number;
    score_hands: string;
    score_whaleness: number;
    transfer_count_burn: number;
    transfer_count_flip: number;
    transfer_count_mint: number;
    transfer_count_profitable_flip: number;
    transfer_count_purchase: number;
    transfer_count_sale: number;
    transfer_count_transfer_in: number;
    transfer_count_transfer_out: number;
    wallet_address: string;
    wallet_id: number;
}

export interface CommunityDetailHoldings {
    imageUrl: string;
    name: string;
    nftCount: number;
}

export interface EmailSenderInfo {
    fromEmail: string;
    id?: number;
    projectId?: number;
    senderId?: number;
    senderName: string;
    verified?: boolean;
}

export interface FrontEndPickWinnerParams {
    howToPick: PickWinnerMethod;
    totalWinnerSpots?: number;
    maxWinsPerWallet?: number;
    stakeAtWeek?: number;
    whitelistName?: string;
    snapshotDate?: string;
}

export enum CampaignTypeEnum {
    FREE_MINT = 'free_mint',
    WHITELIST = 'whitelist',
    AIRDROP = 'airdrop',
    GIVEAWAY = 'giveaway',
    REFER_TO_EARN = 'refer_to_earn',
}

export enum EligibleTypeEnum {
    PUBLIC = 'generic',
    CUSTOM = 'custom',
}

export enum RaffleTypeEnum {
    DEFAULT = 'default',
    WEIGHT_BY_ENTRY = 'weight_by_entry',
}

export const CampaignTypeMap = new Map([
    [CampaignTypeEnum.AIRDROP, 'AirDrop'],
    [CampaignTypeEnum.FREE_MINT, 'Free Mint'],
    [CampaignTypeEnum.WHITELIST, 'Whitelist'],
    [CampaignTypeEnum.GIVEAWAY, 'Giveaway'],
    [CampaignTypeEnum.REFER_TO_EARN, 'Refer-to-earn'],
]);

export const EligibleTypeMap = new Map([
    [EligibleTypeEnum.PUBLIC, 'Available to all'],
    [EligibleTypeEnum.CUSTOM, 'Custom Requirements'],
]);

export enum PickWinnerMethod {
    RANDOM = 'random',
    FILTER = 'filter',
    RANDOM_VIA_MOCAVERSE = 'random_via_mocaverse',
    FCFS = 'fcfs',
    MANUAL = 'manual',
}

export const PickWinnerMethodMap = new Map([
    [PickWinnerMethod.RANDOM, 'Random Selection / All Campaign Users'],
    [PickWinnerMethod.FILTER, 'Random Selection / Pick from Filter'],
    [PickWinnerMethod.RANDOM_VIA_MOCAVERSE, 'Random Selection / All Campaign Users'],
    [PickWinnerMethod.FCFS, 'FCFS'],
    [PickWinnerMethod.MANUAL, 'Manual Selection'],
]);

export enum SetUpSenderMethod {
    W3W = 'w3w_domain',
    CUSTOM = 'custom_domain',
}

const GameTags = [
    'Action',
    'Adventure',
    'Puzzle',
    'Role-Playing',
    'Simulation',
    'Strategy',
    'Turn-Based',
    'Sports',
    'MMO',
    'Sandbox',
    'Scientific Studies',
    'Card',
    'Casual',
    'Digital Collectible',
    'Idle',
    'Others',
    'Metaverse',
    'Gambling',
    'Flighting',
    'MOBA',
    'RPG',
    'SLG',
    'Arcade',
    'Tower Defance',
    'Racing',
    'Sports',
    'Mining',
    'Breeding',
    'P2E',
    'Open-World',
    'PVP',
    'Betale',
    'Bulding',
    'Combat Roleplay',
    'Move-to-Earn',
    'Play-to-Earn',
    'Learning',
    'Shooter',
    'Collectable',
    'Virtual-World',
];

const GameTagColors = ['#72BBFD', '#1271F1', '#45F4FF', '#8680FF', '#9E72FD'];

export const getGameTagColorMap = () => {
    const gameTagColorMap = new Map();
    GameTags.forEach((tag, index) => {
        gameTagColorMap.set(tag, GameTagColors[index % GameTagColors.length]);
    });
    return gameTagColorMap;
};
