import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialMediaEnum } from '@components/create-project/create-project.component';
import { BrandProjectStatus } from '@modules/admin/service/admin-data.service';
import { ProjectDetail } from '@modules/main/advanced-customization/advanced-customization-state.service';
import { NidavellirService } from '@modules/main/nidavellir.service';
import { CommonService } from '@services/common.service';
import { UtilService } from '@services/util.service';
import { AdminRoleEnum, AuthTokenService } from '@src/app/core/authentication/auth-token.service';
import { FirebaseFileUploadService } from '@src/app/services/firebase/firebase-file-upload.service';
import { ShopProfileService } from '@src/app/services/shop-profile.service';
import { UpdateNFTShopProject } from '@type/nft.type';
import * as dayjs from 'dayjs';
import * as duration from 'dayjs/plugin/duration';
import * as sameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subject } from 'rxjs';
import { AddSocialMediaModalComponent } from './add-social-media-modal/add-social-media-modal.component';
import { EditBrandNameModalComponent } from './edit-brand-name-modal/edit-brand-name-modal.component';
import { EditDescriptionModalComponent } from './edit-description-modal/edit-description-modal.component';
// eslint-disable-next-line max-len
import { VerificationProjectComponent } from '@admin/components/verification-submit-modal/verification-project/verification-project.component';
import { AdminCustomEventEnum, FirebaseEventsService } from '@src/app/services/firebase/firebase-event.service';

dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(sameOrBefore);

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.less'],
})
export class BannerComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() projectInfo: Partial<ProjectDetail>;
    @Input() isAdminPage: boolean = false;
    @Input() isShowBack: boolean = false;
    @Input() backLink: string = '';
    @Input() isShowDescription: boolean = true;

    BrandProjectStatus = BrandProjectStatus;
    AdminRoleEnum = AdminRoleEnum;
    mediaLabelMap = {
        [SocialMediaEnum.TWITTER]: 'Twitter',
        [SocialMediaEnum.YOUTUBE]: 'Youtube',
        [SocialMediaEnum.FACEBOOK]: 'Facebook',
        [SocialMediaEnum.SNAPCHAT]: 'Snapchat',
        [SocialMediaEnum.INSTAGRAM]: 'Instagram',
        [SocialMediaEnum.PINTEREST]: 'Pinterest',
        [SocialMediaEnum.SPOTIFY]: 'Spotify',
        [SocialMediaEnum.TIKTOK]: 'Tiktok',
        [SocialMediaEnum.LINKEDIN]: 'Linkedin',
        [SocialMediaEnum.TUMBLR]: 'Tumblr',
        [SocialMediaEnum.DISCORD]: 'Discord',
        [SocialMediaEnum.PATREON]: 'Patreon',
        [SocialMediaEnum.MEDIUM]: 'Medium',
        [SocialMediaEnum.APPLE_PODCASTS]: 'Apple Podcasts',
        [SocialMediaEnum.TWITCH]: 'Twitch',
        [SocialMediaEnum.WEBSITE]: 'Website',
        [SocialMediaEnum.TELEGRAM]: 'Telegram',
    };

    expanded = false;

    isOverflow = false;

    subscription$ = new Subject();

    intervalId: any;

    timeArray: {
        name: string;
        numbers: string[];
    }[];

    bannerExpired;

    get isMobile() {
        return this.screenService.isMobile();
    }

    get isAdminRole() {
        return this.authTokenService.role === AdminRoleEnum.ADMIN;
    }

    get isShowVerifyBtn() {
        return (
            this.isAdminPage &&
            (this.projectInfo.status === BrandProjectStatus.UNVERIFIED || this.projectInfo.status === BrandProjectStatus.DRAFT) &&
            !this.projectInfo.contract_list?.filter(item => item.contract_address && !['ERC20', 'BEP20'].includes(item.nft_type)).length
        );
    }

    get backUrl() {
        if (this.activatedRoute.snapshot.queryParamMap.get('back') === 'all-campaign') {
            return ['/', 'main', 'campaigns'];
        }

        if (this.backLink) {
            return [this.backLink];
        }

        return ['/', 'main', 'brand', 'all-brands'];
    }

    constructor(
        public shopProfileService: ShopProfileService,
        private ele: ElementRef,
        private screenService: CommonService,
        private utilService: UtilService,
        private message: CommonService,
        private firebaseUploadService: FirebaseFileUploadService,
        private modalService: NzModalService,
        private nidavellirService: NidavellirService,
        private loadingService: CommonService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authTokenService: AuthTokenService,
        private firebaseEventService: FirebaseEventsService
    ) {}

    ngOnChanges(e: SimpleChanges) {
        if (e.projectInfo?.currentValue) {
            setTimeout(() => {
                this.judgeOverflow();
            });

            if (this.projectInfo.banner?.end_time) {
                this.initInterval();
            }
        }
    }

    ngAfterViewInit() {
        this.judgeOverflow();
    }

    ngOnDestroy() {
        this.subscription$.next();
        this.subscription$.complete();
        clearInterval(this.intervalId);
    }

    @HostListener('window:resize')
    judgeOverflow() {
        const ele: HTMLDivElement = this.ele.nativeElement.querySelector('.brand-description > div');
        if (!ele) {
            return;
        }

        this.isOverflow = ele.clientWidth < ele.scrollWidth || ele.clientHeight < ele.scrollHeight;
        this.cdr.detectChanges();
    }

    initInterval() {
        this.intervalHandle();
        this.intervalId = setInterval(() => this.intervalHandle(), 1000);
    }

    intervalHandle() {
        if (dayjs(this.projectInfo?.banner.end_time).isSameOrBefore(dayjs())) {
            this.bannerExpired = true;
            clearInterval(this.intervalId);
        }
        this.timeArray = this.generateTimeArr(this.projectInfo?.banner.end_time);
    }

    back() {
        if (this.activatedRoute.snapshot.queryParamMap.get('back') === 'all-campaign') {
            this.router.navigateByUrl('/main/campaigns');
        } else {
            this.router.navigateByUrl(this.backLink || '/main/brand/all-brands');
        }
    }

    viewStore() {
        if (this.isAdminPage) {
            this.firebaseEventService.logEvent({
                name: AdminCustomEventEnum.HOME_PAGE_FEATURE_CLICK,
                properties: { type: 'view_brand_page' },
            });
        }
        window.open(`/main/${this.projectInfo?.project_key}`, '_blank');
    }

    generateTimeArr(date) {
        if (dayjs(date).isBefore(dayjs())) {
            return [
                {
                    name: 'Days',
                    numbers: ['0', '0'],
                },
                {
                    name: 'Hours',
                    numbers: ['0', '0'],
                },
                {
                    name: 'Mins',
                    numbers: ['0', '0'],
                },
                {
                    name: 'Secs',
                    numbers: ['0', '0'],
                },
            ];
        }

        const diffTime = dayjs.duration(dayjs(date).diff(dayjs()), 'millisecond');

        const day = String(Math.floor(diffTime.asDays())).padStart(2, '0');
        const hours = String(diffTime.hours()).padStart(2, '0');
        const minutes = String(diffTime.minutes()).padStart(2, '0');
        const seconds = String(diffTime.seconds()).padStart(2, '0');

        return [
            {
                name: 'Days',
                numbers: day.split(''),
            },
            {
                name: 'Hours',
                numbers: hours.split(''),
            },
            {
                name: 'Mins',
                numbers: minutes.split(''),
            },
            {
                name: 'Secs',
                numbers: seconds.split(''),
            },
        ];
    }

    updateNFTShopProject(data: UpdateNFTShopProject) {
        return this.nidavellirService
            .updateNFTShopProject(data)
            .then(res => {
                this.loadingService.showLoading();
                this.shopProfileService.getShopDetail().then(shopProfile => this.shopProfileService.shopDetailSubject.next(shopProfile));
            })
            .catch(err => {
                this.message.error();
                return false;
            });
    }

    getModalStyle() {
        return {
            width: this.isMobile ? '100vw' : '660px',
            style: this.isMobile ? { verticalAlign: 'bottom', maxWidth: '100vw', padding: 0, margin: 0 } : { maxWidth: '660px' },
        };
    }

    showEditBrandNameModal() {
        const modalStyle = this.getModalStyle();

        this.modalService.create({
            nzContent: EditBrandNameModalComponent,
            nzFooter: null,
            nzWidth: modalStyle.width,
            nzMaskClosable: false,
            nzCentered: true,
            nzWrapClassName: 'no-bg no-padding',
            nzClosable: false,
            nzStyle: modalStyle.style,
            nzComponentParams: {
                name: this.projectInfo.name,
            },
            nzOnOk: async comp => {
                comp.submitting = true;
                const params: { name: string; update_project_key?: boolean } = { name: comp.brandName.value };
                if (comp.isUpdateProjectKey) {
                    params.update_project_key = true;
                }
                const status = await this.updateNFTShopProject(params);
                comp.submitting = false;
                return status;
            },
        });
    }

    showEditDescription(e) {
        const modalStyle = this.getModalStyle();

        this.modalService.create({
            nzContent: EditDescriptionModalComponent,
            nzFooter: null,
            nzWidth: modalStyle.width,
            nzMaskClosable: false,
            nzCentered: true,
            nzWrapClassName: 'no-bg no-padding',
            nzClosable: false,
            nzStyle: modalStyle.style,
            nzComponentParams: {
                description: this.projectInfo.description,
            },
            nzOnOk: async comp => {
                comp.submitting = true;
                const status = await this.updateNFTShopProject({ description: comp.brandDescription });
                comp.submitting = false;
                return status;
            },
        });

        e.stopPropagation();
    }

    showAddSocialMediaModal() {
        const modalStyle = this.getModalStyle();

        this.modalService.create({
            nzContent: AddSocialMediaModalComponent,
            nzFooter: null,
            nzWidth: modalStyle.width,
            nzMaskClosable: false,
            nzCentered: true,
            nzWrapClassName: 'no-bg no-padding',
            nzClosable: false,
            nzStyle: modalStyle.style,
            nzComponentParams: {
                socialMedias: this.projectInfo.social_medias,
            },
            nzOnOk: async comp => {
                if (comp.brandSocialMedias.find(item => !item.link?.startsWith('http'))) {
                    this.message.error('The link must start with http', { nzDuration: 5000 });
                    return false;
                }
                comp.submitting = true;
                const socialMedias = comp.brandSocialMedias?.filter(ele => ele.name && ele.link);
                const status = await this.updateNFTShopProject({ social_medias: socialMedias });
                comp.submitting = false;
                return status;
            },
        });
    }

    verifyProject() {
        this.modalService.create({
            nzContent: VerificationProjectComponent,
            nzFooter: null,
            nzWrapClassName: 'no-bg no-padding',
            nzClosable: false,
            nzMaskClosable: false,
            nzCentered: this.screenService.isMobile() ? true : false,
            nzWidth: '100vw',
            nzStyle: this.screenService.isMobile()
                ? {
                      verticalAlign: 'bottom',
                      maxWidth: '100vw',
                      padding: 0,
                      margin: 0,
                  }
                : {
                      maxWidth: '766px',
                  },
            nzComponentParams: {
                contractInfo: this.projectInfo,
                name: this.projectInfo.name,
            },
        });
    }

    beforeUpload(file: File, flag: 'logo' | 'banner') {
        this.utilService
            .checkIfImagePixelValid(file, { width: 4472, height: 4472, size: 20 * 1024 })
            .then(() => this.startUpload(file, flag))
            .catch(() => this.message.error('Please upload an image less than 4472 by 4472 pixels'));
    }

    startUpload(file: File, flag: 'logo' | 'banner') {
        this.loadingService.showLoading();

        const { uploadTask } = this.firebaseUploadService.startUpload(file, '/public/images/nft_brand');

        return uploadTask
            .then(downloadUrl => {
                switch (flag) {
                    case 'logo':
                        this.updateNFTShopProject({
                            resource: {
                                ...(this.projectInfo.resource || {}),
                                brand_avatar: downloadUrl,
                            },
                        });
                        break;
                    case 'banner':
                        this.updateNFTShopProject({
                            resource: {
                                ...(this.projectInfo.resource || {}),
                                banner_bg: downloadUrl,
                            },
                        });
                        break;
                }
            })
            .catch(() => {
                this.loadingService.hideLoading();
                this.message.error('Upload image failed, please try again later');
            });
    }

    bannerBeforeUpload = file => {
        this.beforeUpload(file, 'banner');
        return false;
    };

    logoBeforeUpload = file => {
        this.beforeUpload(file, 'logo');
        return false;
    };
}
