import { SubscriptionPlanEnum } from './profile.typing';

export interface Plan {
    id: SubscriptionPlanEnum;
    name: string;
    price: number;
    annuallyPrice: number;
    description: string;
    numWalletsManagement: number; // Wallets Management
    analysisDashboardType: { name: string; isSupport: boolean }[]; // Analysis Dashboard Type: ['dApp Dashboard', 'GameFi Dashboard']
    // eslint-disable-next-line max-len
    analysisCollectionType: { name: string; isSupport: boolean }[]; // Analysis Collection Type: ['ERC/BEP-721', 'ERC/BEP-1155', 'ERC/BEP-20']
    hasAdvancedReport: boolean; // Advanced Report
    numCommunityEmails: number; // Community Emails
    numSimilarCommunitiesAnalysis: number; // Similar Communities Analysis
    numUserSeats: number; // # of User Seats
    canUpgrade: boolean; // Can Upgrade by customer
}

export const PLANS: Plan[] = [
    {
        id: SubscriptionPlanEnum.FREE,
        name: 'Free',
        price: 0,
        annuallyPrice: 0,
        description: 'Good for Starter',
        numWalletsManagement: 1000,
        analysisDashboardType: [
            { name: 'dApp Dashboard', isSupport: true },
            { name: 'GameFi Dashboard', isSupport: false },
        ],
        analysisCollectionType: [
            { name: 'ERC-721', isSupport: true },
            { name: 'ERC-1155', isSupport: true },
            { name: 'ERC-20', isSupport: false },
        ],
        hasAdvancedReport: false,
        numCommunityEmails: 0,
        numSimilarCommunitiesAnalysis: 3,
        numUserSeats: 1,
        canUpgrade: true,
    },
    {
        id: SubscriptionPlanEnum.ADVANCED,
        name: 'Advanced',
        price: 99,
        annuallyPrice: 950,
        description: 'Best for Growing Community & Gamefi',
        numWalletsManagement: 5000,
        analysisDashboardType: [
            { name: 'dApp Dashboard', isSupport: true },
            { name: 'GameFi Dashboard', isSupport: true },
        ],
        analysisCollectionType: [
            { name: 'ERC-721', isSupport: true },
            { name: 'ERC-1155', isSupport: true },
            { name: 'ERC-20', isSupport: true },
        ],
        hasAdvancedReport: true,
        numCommunityEmails: 100,
        numSimilarCommunitiesAnalysis: 10,
        numUserSeats: 2,
        canUpgrade: true,
    },
    {
        id: SubscriptionPlanEnum.PREMIUM,
        name: 'Premium',
        price: 249,
        annuallyPrice: 2390,
        description: 'Best for Large Community',
        numWalletsManagement: 20000,
        analysisDashboardType: [
            { name: 'dApp Dashboard', isSupport: true },
            { name: 'GameFi Dashboard', isSupport: true },
        ],
        analysisCollectionType: [
            { name: 'ERC-721', isSupport: true },
            { name: 'ERC-1155', isSupport: true },
            { name: 'ERC-20', isSupport: true },
        ],
        hasAdvancedReport: true,
        numCommunityEmails: 500,
        numSimilarCommunitiesAnalysis: 20,
        numUserSeats: 5,
        canUpgrade: false,
    },
    {
        id: SubscriptionPlanEnum.ENTERPRISE,
        name: 'Enterprise',
        price: 0,
        annuallyPrice: 0,
        description: '',
        numWalletsManagement: 200000,
        analysisDashboardType: [
            { name: 'dApp Dashboard', isSupport: true },
            { name: 'GameFi Dashboard', isSupport: true },
        ],
        analysisCollectionType: [
            { name: 'ERC-721', isSupport: true },
            { name: 'ERC-1155', isSupport: true },
            { name: 'ERC-20', isSupport: true },
        ],
        hasAdvancedReport: true,
        numCommunityEmails: 100,
        numSimilarCommunitiesAnalysis: 5,
        numUserSeats: 5,
        canUpgrade: false,
    },
];

export const PLAN_INFO_MAP = {
    [SubscriptionPlanEnum.FREE]: PLANS.find(item => item.id === SubscriptionPlanEnum.FREE),
    [SubscriptionPlanEnum.ADVANCED]: PLANS.find(item => item.id === SubscriptionPlanEnum.ADVANCED),
    [SubscriptionPlanEnum.PREMIUM]: PLANS.find(item => item.id === SubscriptionPlanEnum.PREMIUM),
    [SubscriptionPlanEnum.ENTERPRISE]: PLANS.find(item => item.id === SubscriptionPlanEnum.ENTERPRISE),
};
