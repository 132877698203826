import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberAbbreviation',
})
export class NumberAbbreviationPipe implements PipeTransform {
    transform(num: number): string {
        if (num >= 1000000) {
            return `${(num / 1000000).toFixed(1)}M`;
        }
        if (num >= 1000) {
            return `${(num / 1000).toFixed(1)}K`;
        }

        return num.toFixed(2);
    }
}
