import { Injectable } from '@angular/core';
import {
    AddressSalesItem,
    BrandProjectDetail,
    PopularNFTItem,
    ProductSalesItem,
    QRCodeScannedItem,
} from '@modules/admin/service/admin-data.service';
import { NFTMerchProduct } from '@modules/main/advanced-customization/advanced-customization-state.service';
import { RequestService } from '@src/app/services/request.service';

export interface BrandNftMerchProduct extends NFTMerchProduct {
    estimate_earnings: number;
}

export interface AccountBalance {
    account_balance: number;
    id: number;
    influencer_id: string;
    paypal_account: string;
    pending_balance: number;
    total_earning: number;
    reserved_balance: number;
    lifo_credit: number;
}

@Injectable()
export class AdminService {
    constructor(private requestService: RequestService) {}

    getNFTMerchList() {
        return this.requestService.sendRequest$<BrandNftMerchProduct[]>({
            method: 'GET',
            url: '/influencer/nft_shop/product',
            api: 'data-shop',
        });
    }

    updateProjectProductInfo(data: Pick<BrandProjectDetail, 'merch_options' | 'resource'>) {
        return this.requestService.sendRequest<BrandProjectDetail>({
            method: 'PUT',
            url: '/influencer/nft_shop/product',
            api: 'data-shop',
            data,
        });
    }

    updateProjectVerificationInfo(data: {
        verified_twitter?: string;
        content: string;
        contract_name: string;
        contract_address: string;
        name?: string;
        update_project_key?: boolean;
    }) {
        return this.requestService.sendRequest<BrandProjectDetail>({
            method: 'POST',
            url: '/brand/project/verification', // /influencer/nft_shop/project_verification
            api: 'admin-api',
            data,
        });
    }

    getProductSales(data: { start: string; end: string }) {
        return this.requestService.sendRequest<{ total: number; list: ProductSalesItem[] }>({
            method: 'GET',
            url: `/influencer/nft_brand/product_sales?start=${data.start}&end=${data.end}`,
            api: 'data-api',
        });
    }

    getAddressSales(data: { start: string; end: string }) {
        return this.requestService.sendRequest<{ total: number; list: AddressSalesItem[] }>({
            method: 'GET',
            url: `/influencer/nft_brand/address_sales?start=${data.start}&end=${data.end}`,
            api: 'data-api',
        });
    }

    getAccountBalance() {
        return this.requestService.sendRequest<AccountBalance>({
            method: 'GET',
            url: '/influencer/account_balance',
            api: 'data-api',
        });
    }

    getQRCodeScanned(data: { start: string; end: string; contract_address: string }) {
        return this.requestService.sendRequest<{ contract: string; data: QRCodeScannedItem[] }>({
            method: 'GET',
            url: `/statistics/count/${data.contract_address}?start=${data.start}&end=${data.end}`,
            api: 'data-landing',
        });
    }

    getPopularNFT(data: { start: string; end: string }) {
        return this.requestService.sendRequest<PopularNFTItem[]>({
            method: 'GET',
            url: `/influencer/nft_brand/popular_nft?start=${data.start}&end=${data.end}`,
            api: 'data-api',
        });
    }

    cashOut(data: { amount: number; payment_method: any }) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/influencer/cash_out',
            api: 'data-api',
            data,
        });
    }

    getSalesInfo(params: { start: number; end: number }): any {
        return this.requestService.sendRequest({
            method: 'GET',
            url: `/influencer/shop_management/sales?since=${params.start}&end=${params.end}`,
            api: 'data-shop',
        });
    }

    getAdminHomeOverViewStats() {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: '/influencer/nft_shop/home',
            api: 'data-shop',
        });
    }

    getPerkList() {
        return this.requestService.sendRequest<any[]>({
            method: 'GET',
            url: '/influencer/nft_perk',
            api: 'data-shop',
        });
    }

    applyPerk(data: { perk_id: number; detail?: string }) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/influencer/nft_perk/apply',
            api: 'data-shop',
            data,
        });
    }

    saveAdFilterTag(data: { name: string; advanced_filters: any }) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/influencer/nft_filter_tag',
            api: 'data-shop',
            data,
        });
    }

    getFilterTagList() {
        return this.requestService.sendRequest<{ id: number; advanced_filters: any; name: string }[]>({
            method: 'GET',
            url: '/influencer/nft_filter_tag',
            api: 'data-shop',
        });
    }

    getDiscordServerDaily(guildId: number) {
        return this.requestService.sendRequest<
            {
                deleted: boolean;
                guildId: number;
                id: number;
                memberCount: number;
                presenceCount: number;
                presentDate: string;
                roleCount: number;
            }[]
        >({
            method: 'GET',
            url: `/influencer/nft_brand/discord_server_daily/${guildId}`,
            api: 'data-api',
        });
    }

    getEmailQuota(walletIds: number[]) {
        return this.requestService.sendRequest<{
            quotaInitial: number;
            quotaRemain: number;
            quotaRequired: number;
            walletsHasEmail: { id: number; walletAddress: string }[];
        }>({
            method: 'POST',
            url: '/influencer/community/send_email_preview',
            api: 'data-api',
            data: { walletIds },
        });
    }

    sendCustomEmail(data: { body: string; htmlData: any; sendTime: string; subject: string; walletIds: number[] }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/influencer/community/send_email',
            api: 'data-api',
            data,
        });
    }

    getQuickFilterList() {
        return this.requestService.sendRequest<
            {
                advanced_filters: any;
                gamify_only: boolean;
                id: number;
                name: string;
            }[]
        >({
            method: 'GET',
            url: '/influencer/web3/community/quick_filter',
            api: 'data-shop',
        });
    }

    getCustomEmailSendHistory(params: { pageNo: number; pageSize: number }) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: `/influencer/community/email_history?pageNo=${params.pageNo}&pageSize=${params.pageSize}`,
            api: 'data-api',
        });
    }

    getWeb3Games() {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: '/influencer/popular_web3_game',
            api: 'data-shop',
        });
    }

    getTrendingCollection() {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: '/brand/trending_collection',
            api: 'admin-api',
        });
    }
}
