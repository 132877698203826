import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-general-modal-header',
    templateUrl: './general-modal-header.component.html',
    styleUrls: ['./general-modal-header.component.less'],
})
export class GeneralModalHeaderComponent {
    @Input() title: string;
    @Input() isBrandModal = false;
    @Output() cancel = new EventEmitter();

    constructor() {}
}
