import { BrandProjectDetail } from '@modules/admin/service/admin-data.service';

export enum AvailablePaymentMethods {
    PAYPAL = 'paypal',
    BANK_ACCOUNT = 'bank_account',
}

export enum PaymentMethod {
    SHOPIFY = 'shopify',
    HELIO = 'helio',
}

export enum SubscriptionPlanEnum {
    FREE = 'starter',
    ADVANCED = 'advanced',
    PREMIUM = 'premium',
    ENTERPRISE = 'enterprise',
}

export interface ShopProfile {
    id: string;
    name: string;
    email: string;
    phone: string;
    shop_owner: string;
    domain: string;
    myshopify_domain: string;
    shop_detail: any;
    theme_settings: any;
    setup_complete: boolean;
    ship_to: any;
    theme_id: any;
    service_fee_rate: number;
    user_id: string;
    available_credit_rate: number;
    shop_type: 'Shared' | 'Shopify' | string;
    nft_project: BrandProjectDetail;
    created_at: string;
    payment_methods: PaymentMethod[];
    subscription_plan: string;
    project_plan: ProjectPlan;
}

export interface ProjectPlan {
    id: number;
    project_id: number;
    sub_plan: SubscriptionPlanEnum;
    sub_plan_start_date: any;
    sub_plan_end_date: any;
    next_sub_plan: any;
    prev_sub_plan: any;
    stripe_checkout_session_id: any;
    stripe_customer_id: any;
    stripe_subscription_id: any;
    last_billing_date: any;
    next_billing_date: any;
    billing_status: any;
    billing_period: 'monthly' | 'annually';
    billing_card: string;
    trial_start_date: any;
    trial_end_date: any;
    quota_wallet: number;
    quota_email: number;
    quota_seat: number;
    quota_campaign: number;
    quota_similar_community: number;
    support_gamefi: boolean;
    support_advanced_report: boolean;
    created_by: string;
    updated_by: string;
    created_at: string;
    updated_at: string;
    community_total: number;
}
