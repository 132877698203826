import { Directive, HostListener, Input, Output, EventEmitter, ElementRef, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appContentEditableModel]',
})
export class ContentEditableModelDirective implements OnChanges, AfterViewInit {
    @Input() contentEditableModel: string;
    @Output() contentEditableModelChange = new EventEmitter();

    private lastViewModel: string;

    constructor(private elRef: ElementRef) {}

    /** This should probably be debounced. */
    @HostListener('keyup')
    onKeyup() {
        const value = this.elRef.nativeElement.innerHTML;

        this.lastViewModel = value;
        this.contentEditableModelChange.emit(value);
    }

    ngAfterViewInit() {
        this.elRef.nativeElement.contentEditable = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.contentEditableModel && changes.contentEditableModel.currentValue !== this.lastViewModel) {
            this.lastViewModel = this.contentEditableModel;
            this.refreshView();
        }
    }

    private refreshView() {
        this.elRef.nativeElement.innerHTML = this.contentEditableModel;
    }
}
