import { NFTPlatform } from '@components/connect-nft-modal/connect-nft-modal.component';
import { ProjectCampaign } from '@modules/admin/campaign/campaign.model';
import { PaymentMethod } from '@src/app/shared/types/profile.typing';
import { CountryCode } from 'libphonenumber-js';
import { BrandProjectStatus } from '../../admin/service/admin-data.service';
import { AdvancedCustomizedTemplate, PreviewBackground } from './nidavellir.type';

export const RequiredChainWalletMap = {
    near: {
        wallets: [NFTPlatform.NEAR],
    },
};

export interface ProjectDetail {
    id: number;
    contract_address: string;
    contract_image_url: string;
    contract_name: string;
    description: string;
    key: string;
    link: string;
    merch_templates: NFTMerchProduct[];
    name: string;
    resource: { [key: string]: string };
    social_medias: { name: string; link: string }[];
    status?: string;
    banner?: { text?: string; link_text?: string; link_route?: string; end_time?: string };
    contract_list: {
        contract_address: string;
        contract_image_url: string;
        contract_name: string;
        [key: string]: any;
        collection_id: any;
        status: BrandProjectStatus;
    }[];
    is_official: boolean;
    project_key: string;
    discount_rule: {
        value: number;
        title: string;
        value_type: 'percentage' | 'fixed_amount';
    };
    campaign_list: ProjectCampaign[];
    update_project?: boolean;
    enable_cart?: boolean;
    required_chain?: string; // 目前是给Near / Unstoppable Domains 这种项目用，必须关联对应的钱包。
    show_all_project?: boolean;
    payment_methods?: PaymentMethod[];
    project_type: string;
    has_campaign?: boolean;
    has_merch?: boolean;
}

export interface ShopifyCountry {
    id: number;
    code: CountryCode;
    name: string;
    provinces: {
        id: number;
        code: string;
        name: string;
    }[];
}

export interface NFTMerchProduct {
    id: number;
    name: string;
    price: number;
    compare_at_price: number;
    image_url?: string;
    checked?: boolean;
    templates: AdvancedCustomizedTemplate[];
    shopify_variant_ids: number[];
    variants: { shopify_variant_ids: any[]; option: string; product_image_list: PreviewBackground[] }[];
    is_nft_merch: boolean;
    additional_shipping_cost: number;
    sales_type: 'generic' | 'member_only' | 'airdrop_only';
    listed: boolean;
    allow_multiple: boolean;
}

export interface CreateCampaign {
    wallet_address: string;
    wallet_list: string[];
    email: string;
    recaptcha_result: string;
    pass_recaptcha?: boolean;
    custom_input?: { challenge_id: number; value: string }[];
    campaign_id?: number;
    wallet_type?: string;
    discord_id?: string;
    twitter_id?: string;
    telegram_id?: string;
    retweet_link?: string;
}

export const projectTheme = {
    general: {
        name: 'Hemeraf',
        browserLogo: 'favicon.ico',
        headerLogo: '/assets/imgs/logo/hemera-light.png',
        footerLogo: '/assets/imgs/logo/hemera-light.png',
        buyLink: null,
        loadingIcon: '/assets/imgs/general-spinner.gif',
        socialMedias: [
            {
                name: 'discord',
                link: 'https://discord.gg/ZqYx5dXsAh',
            },
            {
                name: 'twitter',
                link: 'https://twitter.com/HemeraProtocol',
            },
            {
                name: 'linkedIn',
                link: 'https://www.linkedin.com/company/thehemera/',
            },
            // {
            //     name: 'instagram',
            //     link: 'https://www.instagram.com/web3_wear',
            // },
        ],
    },

    panda: {
        name: 'Meta Panda Club',
        browserLogo: '/assets/imgs/metapanda-portrait.png',
        headerLogo: '/assets/imgs/metapanda-logo.png',
        footerLogo: '/assets/imgs/logo/hemera-light.png',
        buyLink: 'https://metapandaclub.com/',
        loadingIcon: '/assets/imgs/loading-icon.gif',
        socialMedias: [
            {
                name: 'discord',
                link: 'https://discord.com/invite/fbtPABTa8w',
            },
            {
                name: 'twitter',
                link: 'https://twitter.com/metapandaclub',
            },
            {
                name: 'instagram',
                link: 'https://www.instagram.com/metapandaclub',
            },
        ],
    },
};
