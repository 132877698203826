import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FirebaseFileUploadService } from '@src/app/services/firebase/firebase-file-upload.service';

@Component({
    selector: 'app-lifo-richtext-editor',
    templateUrl: './lifo-richtext-editor.component.html',
    styleUrls: ['./lifo-richtext-editor.component.less'],
})
export class LifoRichtextEditorComponent implements OnChanges {
    @Input() initialData: string;
    @Input() initialConfig: {
        content_css?: string;
        content_style?: string;
        skin?: string;
    };
    @Input() isDarkMode = false;

    currentData: string;

    imageUploader = blobInfo => {
        const { uploadTask } = this.firebaseUploadService.startUpload(blobInfo.blob(), '/public/images/nft_brand/campaign');
        return uploadTask;
    };

    config = {
        height: 300,
        menubar: false,
        plugins: 'autolink lists link image charmap preview ' + 'visualblocks fullscreen ' + 'insertdatetime code' + 'lists ',
        toolbar:
            'undo redo | bold italic forecolor backcolor | \
       alignleft aligncenter alignright alignjustify | \
       outdent indent bullist numlist table image preview',
        content_css: 'dark',
        content_style: 'body.editor-body{background-color:#000}',
        body_class: 'editor-body',
        skin: 'oxide-dark',
        images_upload_handler: this.imageUploader,
    };

    constructor(private firebaseUploadService: FirebaseFileUploadService) {
        this.currentData = this.initialData;
    }

    ngOnChanges(e: SimpleChanges) {
        if (e.initialData?.currentValue) {
            this.currentData = e.initialData.currentValue;
        }

        if (e.initialConfig?.currentValue) {
            this.config.content_css = e.initialConfig.currentValue.content_css || 'dark';
            this.config.skin = e.initialConfig.currentValue.skin || 'oxide-dark';
            this.config.content_style = e.initialConfig.currentValue.content_style || 'body.editor-body{background-color:#000}';
        }
    }
}
