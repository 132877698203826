/* eslint-disable max-len */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { DeviceDetectorService } from 'ngx-device-detector';

import {
    checkIfImagePixelGreater,
    checkIfImagePixelValid,
    downloadURI,
    getImageWidthHeight,
    hexToRgb,
    replaceNewLineSignal,
} from '../shared/utils/common';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    pageInfoJson: any;

    constructor(
        private $title: Title,
        private $meta: Meta,
        private screenService: CommonService,
        private deviceDetectorService: DeviceDetectorService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    checkIfImagePixelValid(file: File, limitations: { width: number; height: number; size: number }) {
        return checkIfImagePixelValid(file, limitations);
    }

    checkIfImagePixelGreater(file, limitations: { width: number; height: number }) {
        return checkIfImagePixelGreater(file, limitations);
    }

    replaceNewLineSignal(data: string) {
        return replaceNewLineSignal(data);
    }

    async downloadImage(imageSrc: string, fileName: string) {
        const image = await fetch(imageSrc);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = this.document.createElement('a');
        link.href = imageURL;
        link.download = fileName;

        this.document.body.appendChild(link);
        link.click();
        this.document.body.removeChild(link);
    }

    hexToRgb(hex: string): { r: number; g: number; b: number } {
        return hexToRgb(hex);
    }

    downloadURI(uri: string | Blob, name = 'download') {
        downloadURI(uri, name);
    }

    popupWindow(url: string, title = 'Lifo', needFocus = true, w = 800, h = 600) {
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth
            ? window.innerWidth
            : this.document.documentElement.clientWidth
            ? this.document.documentElement.clientWidth
            : screen.width;
        const height = window.innerHeight
            ? window.innerHeight
            : this.document.documentElement.clientHeight
            ? this.document.documentElement.clientHeight
            : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;

        const newWindow = window.open(
            url,
            title,
            `scrollbars=yes,width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`
        );
        if (needFocus) {
            newWindow.focus();
        }
    }

    openLinkWithAHref(url: string) {
        const a = this.document.createElement('a');
        a.href = url;
        a.target = '_blank';
        this.document.body.appendChild(a);
        a.click();
        this.document.body.removeChild(a);
    }

    seoSetup(data: { title: string; description?: string; keywords?: string; ogImage?: string }) {
        this.$title.setTitle(data.title);
        // Set og:title
        this.$meta.updateTag({ property: 'og:title', content: data.title });

        if (data.description) {
            this.$meta.updateTag({ name: 'description', content: data.description });
            // Set og:description
            this.$meta.updateTag({ property: 'og:description', content: data.description });
        }

        if (data.keywords) {
            this.$meta.updateTag({ name: 'keywords', content: data.keywords });
        }

        if (data.ogImage) {
            this.$meta.updateTag({ property: 'og:image', content: data.ogImage });
        }
    }

    // 检查浏览器是否支持color picker
    getColorPickerSupporterOfCurrentBrowser(): boolean {
        const prvOfStartSupportVersion = [12, 2, 0];
        const nowBrowserVersion: number[] = this.deviceDetectorService.browser_version.split('.').map(item => Number(item));

        let isNotSupport = false;

        for (let i = 0; i < prvOfStartSupportVersion.length; i++) {
            if (nowBrowserVersion[i] === prvOfStartSupportVersion[i]) {
                continue;
            }

            if (nowBrowserVersion[i] > prvOfStartSupportVersion[i]) {
                break;
            }

            if (nowBrowserVersion[i] < prvOfStartSupportVersion[i]) {
                isNotSupport = true;
            }
        }

        if (
            this.screenService.isMobile() &&
            this.deviceDetectorService.browser === 'Safari' &&
            this.deviceDetectorService.os === 'iOS' &&
            isNotSupport
        ) {
            return true;
        }

        return false;
    }

    getImageWidthHeight(file: File | { url: string }): Promise<{ width: number; height: number }> {
        return getImageWidthHeight(file);
    }

    getPageInfoByPath(path: string) {
        if (this.pageInfoJson) {
            return Promise.resolve(this.pageInfoJson[path]);
        }
        return fetch('/assets/json/seo.json')
            .then(res => res.json())
            .then(data => {
                this.pageInfoJson = data;
                return data[path];
            });
    }

    setPageInfo(resetIfNoData = true) {
        const path = new URL(window?.location.href).pathname;
        this.getPageInfoByPath(path).then(data => {
            if (data) {
                this.seoSetup(data);
            } else {
                if (resetIfNoData) {
                    // Set to Default Page Info
                    this.seoSetup({
                        title: 'Hemera - Decentralized Programmable Indexing Network',
                        description:
                            'Hemera Protocol is a decentralized account-centric programmable indexing network that is designed to serve as a public goods data infrastructure unlocking the full potential of data networks in web3.',
                        keywords:
                            'Hemera, Hemera Protocol, W3W, W3W.ai, SocialScan, Blockchain Explorer, Web3 Airdrop Auditing, Community Quality Auditing, Anti-Sybil, Bot Detection, Web3 CRM, Web3 Intelligence, Data Interoperability, AI, ML, Public Goods, Web3 Social, On-Chain Portfolio, Programmable Indexing, Data Network, Modular Blockchain Network, Web3 Data Network, Data Fragmentation, Account-Centric Indexing, Blockchain Infrastructure, Data Infrastructure, Data Democratization, AI Democratization, GameFi CRM, NFT CRM, SocialFi, DeFi, Inscription, Social Graph, ZK Coprocessor',
                    });
                }
            }
        });
    }
}
