<section class="campaign-challenge" [class.cebg]="true">
    <form nz-form [formGroup]="campaignForm" nzLayout="vertical">
        <ng-template #formErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
                <span class="input-tip">Required for participation</span>
            </ng-container>
            <ng-container *ngIf="control.hasError('email')">
                <span class="input-tip">Please enter a valid email</span>
            </ng-container>
        </ng-template>

        <ng-template #emptyErrorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
                <span class="input-tip">Required for participation</span>
            </ng-container>
        </ng-template>

        <nz-form-item *ngIf="socialMediaArr.includes(SocialMedia.DISCORD)">
            <nz-form-control [nzErrorTip]="emptyErrorTpl" class="social-media-input">
                <div class="content">
                    <span class="social-media-name">
                        <i nz-icon nzType="antd:discord" nzTheme="outline" style="color: #5865f2"></i>
                        Discord
                        <ng-container *ngIf="!requiredSocialMediaArr.includes(SocialMedia.DISCORD)"> (Optional) </ng-container>
                    </span>

                    <div *ngIf="campaignChallengeService.walletAddress$" class="btn-box">
                        <button
                            nz-button
                            class="discord"
                            [class.duplicate]="duplicateSocialMedias.has(SocialMedia.DISCORD)"
                            style="color: #fff"
                            (click)="connectSocialMedia(SocialMedia.DISCORD)"
                        >
                            <i class="icon" nz-icon nzType="antd:discord"></i>
                            <span class="overflow-ellipsis">{{discordUserInfo ? discordUserInfo.name : 'Connect'}}</span>
                        </button>

                        <ng-container *ngIf="discordUserInfo">
                            <i
                                *ngIf="!duplicateSocialMedias.has(SocialMedia.DISCORD)"
                                class="remove-socialmedia"
                                nz-icon
                                nzType="close-circle"
                                (click)="removeSocialMediaInfo(SocialMedia.DISCORD)"
                            ></i>
                            <span
                                *ngIf="duplicateSocialMedias.has(SocialMedia.DISCORD)"
                                class="input-tip re-connect"
                                (click)="connectSocialMedia(SocialMedia.DISCORD)"
                            >
                                Re-Connect
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="child-list">
                    <div *ngFor="let challenge of discordChallengeList" class="child-item">
                        <div>
                            <i
                                nz-icon
                                nzType="antd:{{!campaignInfo || checkingCampaignTask || incompleteCampaignTask.has(challenge.id) ? 'unchecked' : 'checked'}}"
                                nzTheme="outline"
                            ></i>
                            Join <a (click)="joinDiscordOrTelegram(challenge)">{{challenge.display}}</a> Discord
                        </div>

                        <div *ngIf="challenge.role_list?.length > 0" class="discord-roles mt-10">
                            <i
                                nz-icon
                                nzType="antd:{{!campaignInfo || checkingCampaignTask || incompleteDiscordRoleTask.has(challenge.id) ? 'unchecked' : 'checked'}}"
                                nzTheme="outline"
                            ></i>
                            <div class="role-box">
                                <span>Must have roles: </span>
                                <span *ngFor="let role of challenge.role_list" class="role-item">
                                    <span
                                        *ngIf="role.color"
                                        style="
                                            display: inline-block;
                                            width: 16px;
                                            height: 16px;
                                            border-radius: 50%;
                                            margin-right: 4px;
                                            vertical-align: middle;
                                            border: 1px solid #596175;
                                        "
                                        [style.backgroundColor]="getHexColor(role.color)"
                                    ></span>

                                    <span>{{role.name}}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="duplicateSocialMedias.has(SocialMedia.DISCORD)" class="mt-10 mb-2.5">
                    <span class="input-tip">This social media is already used by another wallet</span>
                </div>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="socialMediaArr.includes(SocialMedia.TELEGRAM)">
            <nz-form-control [nzErrorTip]="emptyErrorTpl" class="social-media-input">
                <div class="content">
                    <span class="social-media-name">
                        <i nz-icon nzType="antd:telegram" nzTheme="outline" style="color: #40b3e0"></i>
                        Telegram
                        <ng-container *ngIf="!requiredSocialMediaArr.includes(SocialMedia.TELEGRAM)"> (Optional) </ng-container>
                    </span>
                    <div *ngIf="campaignChallengeService.walletAddress " class="btn-box">
                        <button
                            nz-button
                            class="telegram"
                            style="color: #fff"
                            [class.duplicate]="duplicateSocialMedias.has(SocialMedia.TELEGRAM)"
                            (click)="connectSocialMedia(SocialMedia.TELEGRAM)"
                        >
                            <i class="icon" nz-icon nzType="antd:telegram"></i>
                            <span class="overflow-ellipsis"> {{telegramUserInfo ? telegramUserInfo.username : 'Connect'}} </span>
                        </button>

                        <ng-container *ngIf="telegramUserInfo ">
                            <i
                                *ngIf="!duplicateSocialMedias.has(SocialMedia.TELEGRAM)"
                                class="remove-socialmedia"
                                nz-icon
                                nzType="close-circle"
                                (click)="removeSocialMediaInfo(SocialMedia.TELEGRAM)"
                            ></i>
                            <span
                                *ngIf="duplicateSocialMedias.has(SocialMedia.TELEGRAM)"
                                class="input-tip re-connect"
                                (click)="connectSocialMedia(SocialMedia.TELEGRAM)"
                            >
                                Re-Connect
                            </span>
                        </ng-container>
                    </div>
                </div>

                <div class="child-list">
                    <div *ngFor="let challenge of telegramChallengeList" class="child-item">
                        <div *ngIf="challenge.check_type === 'follow'">
                            <i
                                nz-icon
                                nzType="antd:{{!campaignInfo || checkingCampaignTask || incompleteCampaignTask.has(challenge.id) ? 'unchecked' : 'checked'}}"
                                nzTheme="outline"
                            ></i>
                            Join <a (click)="joinDiscordOrTelegram(challenge)">@{{challenge.display}}</a> on Telegram
                        </div>
                    </div>
                </div>

                <div *ngIf="duplicateSocialMedias.has(SocialMedia.TELEGRAM)" class="mt-10 mb-2.5">
                    <span class="input-tip">This social media is already used by another wallet</span>
                </div>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item *ngIf="socialMediaArr.includes(SocialMedia.TWITTER)">
            <nz-form-control [nzErrorTip]="emptyErrorTpl" class="social-media-input">
                <div class="content">
                    <span class="social-media-name">
                        <i nz-icon nzType="antd:twitter" nzTheme="outline" style="color: #55acee"></i>
                        Twitter
                        <ng-container *ngIf="!requiredSocialMediaArr.includes(SocialMedia.TWITTER)"> (Optional) </ng-container>
                    </span>

                    <div *ngIf="campaignChallengeService.walletAddress " class="btn-box">
                        <button
                            nz-button
                            class="twitter"
                            style="color: #fff"
                            [class.duplicate]="duplicateSocialMedias.has(SocialMedia.TWITTER)"
                            (click)="connectSocialMedia(SocialMedia.TWITTER)"
                        >
                            <i class="icon" nz-icon nzType="antd:twitter"></i>
                            <span class="overflow-ellipsis"> {{twitterUserInfo ? twitterUserInfo.name : 'Connect'}}</span>
                        </button>

                        <ng-container *ngIf="twitterUserInfo">
                            <i
                                *ngIf="!duplicateSocialMedias.has(SocialMedia.TWITTER)"
                                class="remove-socialmedia"
                                nz-icon
                                nzType="close-circle"
                                (click)="removeSocialMediaInfo(SocialMedia.TWITTER)"
                            ></i>
                            <span
                                *ngIf="duplicateSocialMedias.has(SocialMedia.TWITTER)"
                                class="input-tip re-connect"
                                (click)="connectSocialMedia(SocialMedia.TWITTER)"
                            >
                                Re-Connect
                            </span>
                        </ng-container>
                    </div>
                </div>

                <div class="child-list">
                    <div *ngFor="let challenge of twitterChallengeList" class="child-item">
                        <div *ngIf="challenge.check_type === 'follow'">
                            <i
                                nz-icon
                                nzType="antd:{{!campaignInfo || checkingCampaignTask || incompleteCampaignTask.has(challenge.id) ? 'unchecked' : 'checked'}}"
                                nzTheme="outline"
                            ></i>
                            Follow <a (click)="followTwitter(challenge)">@{{challenge.challenge_input}}</a> on Twitter
                        </div>

                        <div *ngIf="challenge.check_type === 'retweet'">
                            <i
                                nz-icon
                                nzType="antd:{{!campaignInfo || checkingCampaignTask || incompleteCampaignTask.has(challenge.id) ? 'unchecked' : 'checked'}}"
                                nzTheme="outline"
                            ></i>
                            <a [class.empty-a]="!challenge.challenge_input" (click)="retweetTwitter(challenge)">Retweet</a>
                            <span> on Twitter</span>
                            <span *ngIf="!challenge.challenge_input" class="empty-tweet">
                                <span nz-icon nzType="info-circle"></span>
                                URL to be added
                            </span>
                        </div>

                        <div *ngIf="challenge.check_type === 'quote_tweet' || challenge.check_type === 'reply_tweet'">
                            <i
                                nz-icon
                                nzType="antd:{{!campaignInfo || checkingCampaignTask || incompleteCampaignTask.has(challenge.id) ? 'unchecked' : 'checked'}}"
                                nzTheme="outline"
                            ></i>
                            <a [class.empty-a]="!challenge.challenge_input" (click)="quoteOrReplyTweet(challenge)">
                                {{challenge.check_type === 'quote_tweet' ? 'Quote' : 'Reply'}} a tweet
                            </a>
                            <span *ngIf="challenge.at_accounts || challenge.hashtags?.length;else onTwitter;">
                                and complete the following</span
                            >
                            <span *ngIf="!challenge.challenge_input" class="empty-tweet">
                                <span nz-icon nzType="info-circle"></span>
                                URL to be added
                            </span>
                            <ng-template #onTwitter>
                                <span> on Twitter </span>
                            </ng-template>
                        </div>
                        <div
                            *ngIf="challenge.check_type === 'quote_tweet' || challenge.check_type === 'reply_tweet'"
                            class="quote-tweet-sub-child"
                        >
                            <div *ngIf="challenge.at_accounts" class="pl-30 mt-10">
                                <i
                                    nz-icon
                                    nzType="antd:{{!campaignInfo || checkingCampaignTask || incompleteAtAccountsTask.has(challenge.id) ? 'unchecked' : 'checked'}}"
                                    nzTheme="outline"
                                ></i>
                                Tag {{challenge.at_accounts}} Twitter Accounts
                            </div>

                            <div *ngIf="challenge.hashtags?.length" class="pl-30 mt-10">
                                <i
                                    nz-icon
                                    nzType="antd:{{!campaignInfo || checkingCampaignTask || incompleteHashtagTask.has(challenge.id) ? 'unchecked' : 'checked'}}"
                                    nzTheme="outline"
                                ></i>
                                {{challenge.check_type === 'quote_tweet' ? 'Quote' : 'Reply'}} a tweet with
                                <ng-container *ngFor="let hashtag of challenge.hashtags;let i = index">
                                    <span style="color: #badaff">#{{hashtag}}</span>
                                    <span *ngIf="i !== challenge.hashtags.length - 1">, </span>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="duplicateSocialMedias.has(SocialMedia.TWITTER)" class="mt-10 mb-2.5">
                    <span class="input-tip">This social media is already used by another wallet</span>
                </div>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>
                <span nz-tooltip [nzTooltipTitle]="'Your email will not be shared with the project owner'">
                    Your Contact Email For Campaign Update *
                </span>
            </nz-form-label>
            <nz-form-control [nzErrorTip]="formErrorTpl">
                <input
                    nz-input
                    class="email-input"
                    type="text"
                    placeholder="Email"
                    formControlName="email"
                    style="padding-left: 10px"
                    (input)="duplicateSocialMedias.delete(SocialMedia.EMAIL)"
                />
            </nz-form-control>
            <div *ngIf="duplicateSocialMedias.has(SocialMedia.EMAIL)" class="mt-10 mb-2.5">
                <span class="input-tip">This email is already used by another wallet</span>
            </div>
            <div *ngIf="!campaignForm?.get('email')?.value" class="mt-10 mb-2.5">
                <span class="input-tip">Please enter your email address </span>
            </div>
        </nz-form-item>

        <div id="robot"></div>

        <div class="mt-20">
            <button
                *ngIf="incompleteCampaignTask.size === 0 || !campaignChallengeService.walletAddress; else recheckBtn"
                nz-button
                nzType="primary"
                class="submit-btn"
                [nzLoading]="submitting"
                [disabled]="disableRegisterBtn"
                (click)="joinCampaign()"
            >
                REGISTER
            </button>

            <ng-template #recheckBtn>
                <button
                    nz-button
                    nzType="primary"
                    class="submit-btn"
                    [nzLoading]="checkingCampaignTask"
                    [disabled]="disableReCheckBtn"
                    (click)="checkingCampaignTask = true;checkCampaignChallenge()"
                >
                    RE-CHECK QUALIFICATION
                </button>
            </ng-template>
        </div>

        <div *ngIf="errorMessage" class="mt-20 error-message">{{errorMessage}}</div>
    </form>
</section>
