import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SelectCsvListComponent } from '@components/admin/select-csv-list/select-csv-list.component';
import { SelectNftCollectionComponent } from '@components/admin/select-nft-collection/select-nft-collection.component';
import { SelectNftProjectComponent } from '@components/admin/select-nft-project/select-nft-project.component';
import { AddSocialMediaModalComponent } from '@components/banner/add-social-media-modal/add-social-media-modal.component';
import { BannerComponent } from '@components/banner/banner.component';
import { EditBrandNameModalComponent } from '@components/banner/edit-brand-name-modal/edit-brand-name-modal.component';
import { EditDescriptionModalComponent } from '@components/banner/edit-description-modal/edit-description-modal.component';
import { ConnectNftModalComponent } from '@components/connect-nft-modal/connect-nft-modal.component';
import { CreateProjectComponent } from '@components/create-project/create-project.component';
import { FaqComponent } from '@components/faq/faq.component';
import { GeneralDialogComponent } from '@components/general-dialog/general-dialog.component';
import { ShippingDetailComponent } from '@components/shipping-detail/shipping-detail.component';
import { ToolbarComponent } from '@components/toolbar/toolbar.component';
import { SelectNftCollectionModalComponent } from './admin/select-nft-collection-modal/select-nft-collection-modal.component';
import { SelectNftTokenComponent } from './admin/select-nft-token/select-nft-token.component';
import { GeneralLifoChartComponent } from './charts/general-lifo-chart/general-lifo-chart.component';
import { GeneralLineSeriesChartComponent } from './charts/general-line-series-chart/general-line-series-chart.component';
import { GeneralPieChartComponent } from './charts/general-pie-chart/general-pie-chart.component';
import { GeneralModalHeaderComponent } from './general-modal-header/general-modal-header.component';
import { LandscapeChartCardComponent } from './landscape-chart-card/landscape-chart-card.component';
import { LifoRichtextEditorComponent } from './lifo-richtext-editor/lifo-richtext-editor.component';
import { LoadingComponent } from './loading/loading.component';
import { PerformanceCardComponent } from './performance-card/performance-card.component';
import { BulletPoint1Component } from './report/bullet-point1/bullet-point1.component';
import { BulletPoint2Component } from './report/bullet-point2/bullet-point2.component';
import { CommunityLandscapeComponent } from './report/community-landscape/community-landscape.component';
import { DistributionOfGasFeeComponent } from './report/distribution-of-gas-fee/distribution-of-gas-fee.component';
import { HolderAnalysicsComponent } from './report/holder-analysics/holder-analysics.component';
import { MintsSummaryComponent } from './report/mints-summary/mints-summary.component';
import { Top10OverlapProjectComponent } from './report/top10-overlap-project/top10-overlap-project.component';

import { AntdModulesModule } from '../antd-modules.module';
import { DirectivePipeModule } from '../directive-pipe.module';
import { OtherLibsModule } from '../other-libs.module';

@NgModule({
    declarations: [
        GeneralDialogComponent,
        ShippingDetailComponent,
        ConnectNftModalComponent,
        CreateProjectComponent,
        SelectNftCollectionComponent,
        SelectCsvListComponent,
        SelectNftProjectComponent,
        LoadingComponent,
        PerformanceCardComponent,
        BannerComponent,
        GeneralModalHeaderComponent,
        EditBrandNameModalComponent,
        EditDescriptionModalComponent,
        AddSocialMediaModalComponent,
        FaqComponent,
        ToolbarComponent,
        LifoRichtextEditorComponent,
        SelectNftCollectionModalComponent,
        SelectNftTokenComponent,
        CommunityLandscapeComponent,
        MintsSummaryComponent,
        DistributionOfGasFeeComponent,
        GeneralPieChartComponent,
        HolderAnalysicsComponent,
        Top10OverlapProjectComponent,
        BulletPoint1Component,
        BulletPoint2Component,
        GeneralLineSeriesChartComponent,
        GeneralLifoChartComponent,
        LandscapeChartCardComponent,
    ],
    imports: [
        CommonModule,
        AntdModulesModule,
        OtherLibsModule,
        DirectivePipeModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        ScrollingModule,
    ],
    exports: [
        GeneralDialogComponent,
        ShippingDetailComponent,
        ConnectNftModalComponent,
        CreateProjectComponent,
        SelectNftCollectionComponent,
        SelectCsvListComponent,
        SelectNftProjectComponent,
        LoadingComponent,
        PerformanceCardComponent,
        BannerComponent,
        GeneralModalHeaderComponent,
        EditBrandNameModalComponent,
        EditDescriptionModalComponent,
        AddSocialMediaModalComponent,
        FaqComponent,
        ToolbarComponent,
        LifoRichtextEditorComponent,
        SelectNftCollectionModalComponent,
        SelectNftTokenComponent,
        CommunityLandscapeComponent,
        MintsSummaryComponent,
        DistributionOfGasFeeComponent,
        GeneralPieChartComponent,
        HolderAnalysicsComponent,
        Top10OverlapProjectComponent,
        BulletPoint1Component,
        BulletPoint2Component,
        GeneralLineSeriesChartComponent,
        GeneralLifoChartComponent,
        LandscapeChartCardComponent,

        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        ScrollingModule,
    ],
})
export class ComponentsModule {}
