/* eslint-disable max-len */
import { saveAs } from 'file-saver';
import { getObjectURL } from './draw';

export function checkIfImagePixelValid(file: File, limitations: { width: number; height: number; size: number }) {
    return new Promise<void>((resolve, reject) => {
        const pURL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = () => {
            const valid = img.width <= limitations.width && img.height <= limitations.height && file.size <= limitations.size * 1024;
            if (valid) {
                resolve();
            } else {
                reject();
            }
        };
        img.src = pURL.createObjectURL(file);
    });
}

export function checkIfImagePixelGreater(file, limitations: { width: number; height: number }) {
    return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const valid = img.width >= limitations.width && img.height >= limitations.height;
            if (valid) {
                resolve();
            } else {
                reject();
            }
        };
        img.src = getObjectURL(file);
    });
}

export function replaceNewLineSignal(data: string) {
    return data.replace(new RegExp('\r?\n', 'g'), '<br />') || '';
}

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

export function downloadURI(uri: string | Blob, name = 'download') {
    saveAs(uri, name);
}

export function getImageWidthHeight(file: File | { url: string }): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
        const pURL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = () => {
            resolve({ width: img.width, height: img.height });
        };
        img.onerror = reject;

        if (file instanceof File) {
            img.src = pURL.createObjectURL(file);
        } else {
            img.src = file.url;
        }
    });
}
