<!-- /**
     * 1. result_out -> Wallet Not Connect && Campaign Winner Picked
     * 2. you_are_winner -> Wallet Connect && Campaign Winner Picked && Campaign Joined && Is Campaign Winner
     * 3. you_are_not_winner -> Wallet Connected && Campaign Winner Picked && Campaign Joined && Not Campaign Winner
     * 4. success_joined -> Wallet Connected && Campaign Winner Not Picked && Campaign Joined
     * 5. registration_closed -> Campaign Ended && Not Other Status
     * 6. normal -> Others
     */ -->

<ng-container *ngIf="campaignInfo">
    <!-- Loading Data -->
    <div
        *ngIf="walletStatusService.isLoggedIn && fetchingUserInfo; else resultOut"
        class="result section-bg"
        style="height: 200px"
        appLoading
        [loading]="true"
    ></div>

    <!-- Result Out -->
    <ng-template #resultOut>
        <div *ngIf="!walletStatusService.isLoggedIn && campaignInfo.is_winner_picked;else yourAreWinner" class="result section-bg p-20">
            <img src="/assets/imgs/mocaverse/moca-common.png" style="width: 150px" />
            <div class="result-title">Result is Out!</div>
            <div class="result-subtitle">Connect Wallet for raffle result.</div>
            <button nz-button nzType="primary" class="cta not-connected mb-2.5" (click)="connect()">Connect Wallet</button>
            <div class="result-desc">
                Join
                <a class="primary" href="https://discord.gg/MocaverseNFT" target="_blank">
                    <i nz-icon nzType="antd:discord"></i>
                    Discord
                </a>
                to stay up to date
            </div>
        </div>
    </ng-template>

    <!-- You are winner -->
    <ng-template #yourAreWinner>
        <div
            *ngIf="walletStatusService.isLoggedIn && campaignInfo.is_winner_picked && isCampaignWinner;else yourAreNotWinner"
            class="winner result section-bg p-20"
        >
            <img src="/assets/imgs/mocaverse/moca-congratulations.png" style="width: 150px" />
            <div class="result-title">Congratulations!</div>

            <div class="result-desc">
                <ng-container *ngIf="!isNoMocaCampaign">
                    You have won
                    <span class="fs-700"
                        ><img src="/assets/imgs/mocaverse/moca-ticket.png" width="20" /> x{{wonTokens?.length || '-'}}
                    </span>
                    in this campaign.
                    <div class="won-mocas">
                        <p>Won Mocas</p>
                        <div appLoading [loading]="fetchingWonTokens" class="moca-list">
                            <div *ngFor="let token of wonTokens" class="token-item">
                                <div class="img-item">
                                    <img [src]="token.image" [alt]="token.name" />
                                    <div class="token-info">
                                        <div class="serial-num">{{token.name}}</div>
                                        <div class="name overflow-ellipsis">{{token.tribe}}</div>
                                    </div>
                                </div>
                                <div class="xp">{{token.xp_point|number}} XP</div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="isNoMocaCampaign" class="mb-20">You have won in this campaign</div>

                Join
                <a class="primary" href="https://discord.gg/MocaverseNFT" target="_blank">
                    <i nz-icon nzType="antd:discord"></i>
                    Discord
                </a>
                to stay up to date
            </div>
        </div>
    </ng-template>

    <!-- You are not winner -->
    <ng-template #yourAreNotWinner>
        <div
            *ngIf="walletStatusService.isLoggedIn && campaignInfo.is_winner_picked && hotWalletJoined && !isCampaignWinner;else joinSuccess"
            class="result section-bg p-20"
        >
            <img src="/assets/imgs/mocaverse/moca-oops.png" style="width: 150px" />
            <div class="result-title">Oops...</div>

            <div class="result-desc">
                It’s not your lucky day today, better luck next time!<br />
                Join
                <a class="primary" href="https://discord.gg/MocaverseNFT" target="_blank">
                    <i nz-icon nzType="antd:discord"></i>
                    Discord
                </a>
                to stay up to date
            </div>
        </div>
    </ng-template>

    <!-- Join Success -->
    <ng-template #joinSuccess>
        <div
            *ngIf="walletStatusService.isLoggedIn && !campaignInfo.is_winner_picked && hotWalletJoined; else registrationClosed"
            class="result section-bg p-20"
        >
            <img src="/assets/imgs/mocaverse/moca-success.png" style="width: 150px" />
            <div class="result-title">Campaign Joined Successfully!</div>
        </div>
    </ng-template>

    <!-- Registration Closed -->
    <ng-template #registrationClosed>
        <div *ngIf="campaignInfo.status === CampaignStatus.ENDED;else  normal" class="result section-bg p-20">
            <img src="/assets/imgs/mocaverse/moca-common.png" style="width: 150px" />
            <div class="result-title">Registration Has Closed</div>
            <div class="result-desc">
                Join
                <a class="primary" href="https://discord.gg/MocaverseNFT" target="_blank">
                    <i nz-icon nzType="antd:discord"></i>
                    Discord
                </a>
                to stay up to date for raffle result.
            </div>
        </div>
    </ng-template>

    <!-- Normal -->
    <ng-template #normal>
        <form nz-form [formGroup]="campaignForm" nzLayout="vertical" class="campaign-form">
            <!-- <div class="form-title desktop-show">Complete Steps to Register</div> -->

            <!-- Connect Wallet -->
            <div *ngIf="walletStatusService.isLoggedIn" class="step-item step-connect-wallet section-bg">
                <div class="step-title">
                    <div class="title-box">
                        <span class="name" [style.opacity]="formButtonHidden? '0.8' : '1'">Connect Wallet</span>
                        <ng-container *ngIf="walletStatusService.isLoggedIn && !walletEligibleProcessing">
                            <span
                                *ngIf="walletAssetsEligible && !walletEligibleProcessing"
                                class="desktop-show"
                                nz-icon
                                nzType="check-circle"
                                nzTheme="fill"
                                style="color: #00cd3c"
                            ></span>
                            <span
                                *ngIf="!walletAssetsEligible && !walletEligibleProcessing"
                                class="desktop-show"
                                nz-icon
                                nzType="close-circle"
                                nzTheme="fill"
                                style="color: #ff0000"
                            ></span>
                        </ng-container>
                    </div>

                    <button
                        *ngIf="!walletStatusService.isLoggedIn"
                        nz-button
                        nzType="primary"
                        class="cta not-connected"
                        (click)="connect()"
                    >
                        Connect Wallet
                    </button>

                    <button
                        *ngIf="walletStatusService.isLoggedIn"
                        class="wallet-address-button cta"
                        [class.error]="!walletEligibleProcessing && joinedCampaignWallets.has(walletAddress)"
                        nz-button
                        nzSize="large"
                        style="font-size: 14px; font-weight: normal"
                        [disabled]="walletEligibleProcessing"
                        (click)="disconnect()"
                    >
                        <img width="32" height="32" class="object-fit-contain" [src]="walletImgMap.get(walletStatusService.walletType)" />
                        <div class="addr-info" [class.error]="joinedCampaignWallets.has(walletAddress)">
                            <ng-container *ngIf="!walletEligibleProcessing">
                                <div
                                    *ngIf="!joinedCampaignWallets.has(walletAddress) && walletEligibleStatus[walletAddress]"
                                    class="eligible"
                                >
                                    <span class="text">Eligible</span>
                                </div>

                                <div
                                    *ngIf="joinedCampaignWallets.has(walletAddress) || !walletEligibleStatus[walletAddress]"
                                    class="eligible not-eligible"
                                >
                                    <span
                                        *ngIf="joinedCampaignWallets.has(walletAddress) && walletEligibleStatus[walletAddress]"
                                        class="text"
                                    >
                                        Already Joined
                                    </span>
                                    <span
                                        *ngIf="!joinedCampaignWallets.has(walletAddress) && !walletEligibleStatus[walletAddress]"
                                        class="text"
                                    >
                                        Not Eligible
                                    </span>
                                </div>
                            </ng-container>

                            <div *ngIf="walletEligibleProcessing" class="eligible verifying">
                                <span nz-icon nzType="ic:loader" [nzSpin]="true"></span>
                                <span>Verifying...</span>
                            </div>

                            <span class="address">{{walletAddress | omit:5:4 }}</span>
                        </div>
                        <span nz-icon class="fs-28" nzType="ic:exit-line"></span>
                    </button>
                </div>
                <div *ngIf="!isNoMocaCampaign">
                    <!-- No connect message -->
                    <p *ngIf="!walletStatusService.isLoggedIn" class="no-connect-msg mt-2.5">
                        Connect with hot wallet. Use <a href="https://delegate.cash">delegate.cash</a> to delegate your cold wallet to hot
                        wallet in order to register with your cold wallet Mocas
                    </p>

                    <!-- Cold Wallet List -->
                    <ng-container *ngIf="(requiredCampaignDataService.combinedWalletList$ | async)?.length">
                        <p class="cold-wallet-title">
                            Cold Wallets Detected ({{(requiredCampaignDataService.combinedWalletList$ | async)?.length}})
                        </p>
                        <div class="wallet-list">
                            <button
                                *ngFor="let combinedWallet of requiredCampaignDataService.combinedWalletList$ | async "
                                class="wallet-address-button cta"
                                nz-button
                                nzSize="large"
                                style="font-size: 14px; font-weight: normal"
                                [class.joined]="joinedCampaignWallets.has(combinedWallet.walletAddr)"
                            >
                                <label
                                    [nzDisabled]="joinedCampaignWallets.has(combinedWallet.walletAddr)"
                                    nz-checkbox
                                    [nzChecked]="!unselectedCombinedWallets.has(combinedWallet.walletAddr)"
                                    (nzCheckedChange)="selectCombineWallet(combinedWallet.walletAddr,$event)"
                                ></label>
                                <img
                                    width="32"
                                    height="32"
                                    class="object-fit-contain"
                                    [src]="walletImgMap.get(combinedWallet.walletType)"
                                />
                                <div class="addr-info" [class.error]="joinedCampaignWallets.has(combinedWallet.walletAddr)">
                                    <ng-container *ngIf="!walletEligibleProcessing">
                                        <div *ngIf="!joinedCampaignWallets.has(combinedWallet.walletAddr)" class="eligible">
                                            <span class="text">Eligible</span>
                                        </div>

                                        <div *ngIf="joinedCampaignWallets.has(combinedWallet.walletAddr)" class="eligible not-eligible">
                                            <span class="text">Already Joined</span>
                                        </div>
                                    </ng-container>

                                    <div *ngIf="walletEligibleProcessing" class="eligible verifying">
                                        <span nz-icon nzType="ic:loader" [nzSpin]="true"></span>
                                        <span>Verifying...</span>
                                    </div>

                                    <span class="address">{{combinedWallet.walletAddr | omit:4:4 }}</span>
                                </div>
                            </button>
                        </div>
                    </ng-container>

                    <div
                        *ngIf="!walletAssetsEligible && !walletEligibleProcessing && walletStatusService.isLoggedIn && hasUnJoinedCampaignWallet"
                        class="wallet-assets-ineligible"
                    >
                        <span nz-icon nzType="info-circle" nzTheme="outline" class="mr-2.5"></span>
                        <span *ngIf="campaignRequirementsType === 'onlyCollection'">
                            The wallet you selected does not meet the minimum holding requirement. Try to connect more wallets to see if you
                            are eligible to participate.
                        </span>
                        <span *ngIf="campaignRequirementsType === 'onlySpecificAddress'">
                            The wallet you selected is not in our special list. Connect more wallets to see if you are eligible to
                            participate.
                        </span>
                        <span *ngIf="campaignRequirementsType === 'all'">
                            The wallet you selected is not in our special list and dose not meet the minimum holding requirement. Connect
                            more wallets to see if you are eligible to participate.
                        </span>
                    </div>

                    <!-- Token List -->
                    <div *ngIf="walletStatusService.isLoggedIn && shownTokens?.length" class="token-list block-bg">
                        <div *ngFor="let token of shownTokens" class="token-item">
                            <div class="img-item">
                                <img [src]="token.image" [alt]="token.name" />
                                <div class="token-info">
                                    <div class="serial-num">{{token.name}}</div>
                                    <div class="name overflow-ellipsis">{{token.tribe}}</div>
                                </div>
                            </div>
                            <div class="xp">{{token.xp_point|number}} XP</div>
                        </div>
                    </div>

                    <!-- No Moca -->
                    <div
                        *ngIf="!shownTokens?.length && !fetchingMocaXPInfo && walletStatusService.isLoggedIn"
                        class="no-moca text-align-center block-bg"
                        style="line-height: 1.2"
                    >
                        <img class="desktop-show" src="/assets/imgs/mocaverse/no-moca-found.png" width="70" />
                        <img class="mobile-show" src="/assets/imgs/mocaverse/no-moca-mobile.png" width="70" />

                        <div class="fs-18 fw-700 mt-2.5">No Moca Found in Wallet</div>
                        <div class="fs-16 fw-500 mt-2.5 mb-4">
                            Get a Moca to register in upcoming<br />
                            experiences
                        </div>

                        <button nz-button nzType="primary" class="cta not-connected" style="margin: 0 auto" (click)="purchaseMoca()">
                            <span nz-icon nzType="antd:opensea" class="text-2xl" style="min-height: 24px"></span>
                            Get Moca
                        </button>
                    </div>

                    <!-- Total entries you have -->
                    <div *ngIf="walletStatusService.isLoggedIn" class="entry-count-box">
                        <div>
                            <img src="/assets/imgs/entry.png" style="width: 20px; margin-right: 10px" />
                            Total entries you have : {{(shownTokens?.length || 0) | number:'1.0-0'}}
                        </div>
                    </div>
                </div>
            </div>

            <section class="section-2 section-bg">
                <!-- Step 1 Social Media Requirements -->
                <div
                    *ngIf="campaignInfo.required_social_media?.length"
                    class="step-item step-social-media"
                    [class.connected]="walletStatusService.isLoggedIn"
                >
                    <div class="step-title" [style.opacity]="formButtonHidden ? '0.8' : '1'">
                        <div class="title-box">
                            <span class="num">1</span>
                            <span class="name">Connect Social Accounts and Complete Tasks</span>
                        </div>
                    </div>

                    <div class="block-bg">
                        <ng-template #emptyErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                <span class="input-tip">Required for participation</span>
                            </ng-container>
                        </ng-template>

                        <!-- Discord Connect -->
                        <ng-template #DiscordConnectBtn>
                            <div
                                *ngIf="walletStatusService.walletAddress && campaignInfo?.status !== CampaignStatus.UPCOMING"
                                class="btn-box"
                            >
                                <button
                                    *ngIf="discordUserInfo"
                                    nz-button
                                    class="discord cta"
                                    [class.duplicate]="duplicateSocialMedias.has(SocialMedia.DISCORD)"
                                    [disabled]="!hasUnJoinedCampaignWallet"
                                    (click)="connectSocialMedia(SocialMedia.DISCORD)"
                                >
                                    <i class="icon" style="color: #5865f2" nz-icon nzType="antd:discord"></i>
                                    <span class="overflow-ellipsis">{{discordUserInfo.name | nzEllipsis:15:'..'}}</span>
                                    <span nz-icon class="fs-28" nzType="ic:exit-line"></span>
                                </button>

                                <button
                                    *ngIf="!discordUserInfo"
                                    nz-button
                                    class="discord cta not-connected"
                                    (click)="connectSocialMedia(SocialMedia.DISCORD)"
                                >
                                    Connect
                                </button>
                            </div>
                        </ng-template>

                        <!-- Twitter Connect -->
                        <ng-template #TwitterConnectBtn>
                            <div
                                *ngIf="walletStatusService.walletAddress && campaignInfo?.status !== CampaignStatus.UPCOMING"
                                class="btn-box"
                            >
                                <button
                                    *ngIf="twitterUserInfo"
                                    nz-button
                                    class="twitter cta"
                                    [class.duplicate]="duplicateSocialMedias.has(SocialMedia.TWITTER)"
                                    [disabled]="!hasUnJoinedCampaignWallet"
                                    (click)="connectSocialMedia(SocialMedia.TWITTER)"
                                >
                                    <i class="icon" nz-icon nzType="antd:twitter" style="color: #55acee"></i>
                                    <span class="overflow-ellipsis"> {{twitterUserInfo.name | nzEllipsis:15:'..'}}</span>
                                    <span nz-icon class="fs-28" nzType="ic:exit-line"></span>
                                </button>

                                <button
                                    *ngIf="!twitterUserInfo"
                                    nz-button
                                    class="twitter cta not-connected"
                                    (click)="connectSocialMedia(SocialMedia.TWITTER)"
                                >
                                    Connect
                                </button>
                            </div>
                        </ng-template>

                        <!-- Telegram Connect -->
                        <ng-template #TelegramConnectBtn>
                            <div
                                *ngIf="walletStatusService.walletAddress && campaignInfo?.status !== CampaignStatus.UPCOMING"
                                class="btn-box"
                            >
                                <button
                                    *ngIf="telegramUserInfo"
                                    nz-button
                                    class="telegram cta"
                                    [class.duplicate]="duplicateSocialMedias.has(SocialMedia.TELEGRAM)"
                                    [disabled]="!hasUnJoinedCampaignWallet"
                                    (click)="connectSocialMedia(SocialMedia.TELEGRAM)"
                                >
                                    <i class="icon" nz-icon nzType="antd:telegram" style="color: #40b3e0"></i>
                                    <span class="overflow-ellipsis"> {{telegramUserInfo.username | nzEllipsis:15:'..'}} </span>
                                    <span nz-icon class="fs-28" nzType="ic:exit-line"></span>
                                </button>

                                <button
                                    *ngIf="!telegramUserInfo"
                                    nz-button
                                    class="telegram cta not-connected"
                                    (click)="connectSocialMedia(SocialMedia.TELEGRAM)"
                                >
                                    Connect
                                </button>
                            </div>
                        </ng-template>

                        <nz-form-item *ngIf="socialMediaArr.includes(SocialMedia.DISCORD)">
                            <nz-form-control [nzErrorTip]="emptyErrorTpl" class="social-media-input">
                                <div class="content">
                                    <span class="social-media-name">
                                        <i nz-icon nzType="antd:discord" nzTheme="outline" style="color: #5865f2"></i>
                                        Discord
                                        <ng-container *ngIf="!requiredSocialMediaArr.includes(SocialMedia.DISCORD)">
                                            (Optional)
                                        </ng-container>
                                    </span>

                                    <div class="desktop-show">
                                        <ng-container *ngTemplateOutlet="DiscordConnectBtn"></ng-container>
                                    </div>
                                </div>
                                <div class="child-list">
                                    <div *ngFor="let challenge of discordChallengeList" class="child-item">
                                        <div class="child-item-name">
                                            <i
                                                nz-icon
                                                [nzType]="getCheckIconType(challenge.id,'campaignTask')"
                                                [class.checked]="campaignInfo && !checkingCampaignTask && !incompleteCampaignTask.has(challenge.id)"
                                                [style.color]="getCheckIconType(challenge.id,'campaignTask') === ErrorIconName ? '#d72828' : ''"
                                                nzTheme="outline"
                                            ></i>
                                            <span>
                                                Join <a (click)="joinDiscordOrTelegram(challenge)">{{challenge.display}}</a> Discord
                                            </span>
                                        </div>

                                        <div *ngIf="challenge.role_list?.length > 0" class="discord-roles mt-2.5">
                                            <i
                                                nz-icon
                                                [nzType]="getCheckIconType(challenge.id,'roleTask')"
                                                [class.checked]="campaignInfo && !checkingCampaignTask && !incompleteDiscordRoleTask.has(challenge.id)"
                                                [style.color]="getCheckIconType(challenge.id,'roleTask') === ErrorIconName ? '#d72828' : ''"
                                                nzTheme="outline"
                                            ></i>
                                            <div class="role-box">
                                                <span>Must have roles: </span>
                                                <span *ngFor="let role of challenge.role_list" class="role-item">
                                                    <span
                                                        *ngIf="role.color"
                                                        style="
                                                            display: inline-block;
                                                            width: 16px;
                                                            height: 16px;
                                                            border-radius: 50%;
                                                            margin-right: 4px;
                                                            vertical-align: middle;
                                                            border: 1px solid #596175;
                                                        "
                                                        [style.backgroundColor]="getHexColor(role.color)"
                                                    ></span>

                                                    <span>{{role.name}}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mobile-show">
                                    <ng-container *ngTemplateOutlet="DiscordConnectBtn"></ng-container>
                                </div>

                                <div *ngIf="duplicateSocialMedias.has(SocialMedia.DISCORD)" class="mt-2.5 mb-2.5">
                                    <span class="input-tip">This social media is already used by another wallet</span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item *ngIf="socialMediaArr.includes(SocialMedia.TWITTER)">
                            <nz-form-control [nzErrorTip]="emptyErrorTpl" class="social-media-input">
                                <div class="content">
                                    <span class="social-media-name">
                                        <i nz-icon nzType="antd:twitter" nzTheme="outline" style="color: #55acee"></i>
                                        Twitter
                                        <ng-container *ngIf="!requiredSocialMediaArr.includes(SocialMedia.TWITTER)">
                                            (Optional)
                                        </ng-container>
                                    </span>

                                    <div class="desktop-show">
                                        <ng-container *ngTemplateOutlet="TwitterConnectBtn"></ng-container>
                                    </div>
                                </div>

                                <div class="child-list">
                                    <div *ngFor="let challenge of twitterChallengeList" class="child-item">
                                        <div *ngIf="challenge.check_type === 'follow'" class="child-item-name">
                                            <i
                                                nz-icon
                                                [nzType]="getCheckIconType(challenge.id,'campaignTask')"
                                                [style.color]="getCheckIconType(challenge.id,'campaignTask') === ErrorIconName ? '#d72828' : ''"
                                                nzTheme="outline"
                                                [class.checked]="campaignInfo && !checkingCampaignTask && !incompleteCampaignTask.has(challenge.id)"
                                            ></i>
                                            <span>
                                                Follow <a (click)="followTwitter(challenge)">@{{challenge.challenge_input}}</a> on Twitter
                                            </span>
                                        </div>

                                        <div *ngIf="challenge.check_type === 'retweet'" class="child-item-name">
                                            <i
                                                nz-icon
                                                [nzType]="getCheckIconType(challenge.id,'campaignTask')"
                                                [style.color]="getCheckIconType(challenge.id,'campaignTask') === ErrorIconName ? '#d72828' : ''"
                                                nzTheme="outline"
                                                [class.checked]="campaignInfo && !checkingCampaignTask && !incompleteCampaignTask.has(challenge.id)"
                                            ></i>
                                            <span>
                                                <a [class.empty-a]="!challenge.challenge_input" (click)="retweetTwitter(challenge)"
                                                    >Retweet</a
                                                >
                                                <span> on Twitter</span>
                                                <span *ngIf="!challenge.challenge_input" class="empty-tweet">
                                                    <span nz-icon nzType="info-circle"></span>
                                                    URL to be added
                                                </span>
                                            </span>
                                        </div>

                                        <div
                                            *ngIf="challenge.check_type === 'quote_tweet' || challenge.check_type === 'reply_tweet' || challenge.check_type === 'post_tweet'"
                                            class="child-item-name"
                                        >
                                            <i
                                                nz-icon
                                                [nzType]="getCheckIconType(challenge.id,'campaignTask')"
                                                [style.color]="getCheckIconType(challenge.id,'campaignTask') === ErrorIconName ? '#d72828' : ''"
                                                nzTheme="outline"
                                                [class.checked]="campaignInfo && !checkingCampaignTask && !incompleteCampaignTask.has(challenge.id)"
                                            ></i>
                                            <span>
                                                <a
                                                    *ngIf="challenge.check_type === 'quote_tweet' || challenge.check_type === 'reply_tweet'"
                                                    [class.empty-a]="!challenge.challenge_input"
                                                    (click)="quoteOrReplyTweet(challenge)"
                                                >
                                                    {{challenge.check_type === 'quote_tweet' ? 'Quote' : 'Reply'}} a tweet
                                                </a>
                                                <span *ngIf="challenge.check_type === 'post_tweet'">
                                                    Post a new tweet
                                                    <span
                                                        nz-icon
                                                        nzType="info-circle"
                                                        style="margin-left: 4px"
                                                        nz-tooltip
                                                        nzTooltipTitle="We will automatically check your latest 10 tweets for a qualifying tweet."
                                                    ></span>
                                                </span>
                                                <span
                                                    *ngIf="(challenge.at_accounts || challenge.hashtags?.length) && challenge.check_type !== 'post_tweet';else onTwitter;"
                                                >
                                                    and complete the following
                                                </span>
                                                <span
                                                    *ngIf="!challenge.challenge_input && challenge.check_type !== 'post_tweet'"
                                                    class="empty-tweet"
                                                >
                                                    <span nz-icon nzType="info-circle"></span>
                                                    URL to be added
                                                </span>
                                                <ng-template #onTwitter>
                                                    <span *ngIf="challenge.check_type !== 'post_tweet'"> on Twitter </span>
                                                </ng-template>
                                            </span>
                                        </div>
                                        <div
                                            *ngIf="challenge.check_type === 'quote_tweet' || challenge.check_type === 'reply_tweet' || challenge.check_type === 'post_tweet'"
                                            class="quote-tweet-sub-child"
                                        >
                                            <div *ngIf="challenge.at_accounts" class="pl-[30px] mt-2.5">
                                                <i
                                                    nz-icon
                                                    [nzType]="getCheckIconType(challenge.id,'atAccountsTask')"
                                                    [style.color]="getCheckIconType(challenge.id,'atAccountsTask') === ErrorIconName ? '#d72828' : ''"
                                                    [class.checked]="campaignInfo && !checkingCampaignTask && !incompleteAtAccountsTask.has(challenge.id)"
                                                    nzTheme="outline"
                                                ></i>
                                                Tag {{challenge.at_accounts}} Twitter accounts
                                            </div>

                                            <div *ngIf="challenge.hashtags?.length" class="hashtag-box pl-[30px] mt-2.5">
                                                <span>
                                                    <i
                                                        nz-icon
                                                        [nzType]="getCheckIconType(challenge.id,'hashtagTask')"
                                                        [style.color]="getCheckIconType(challenge.id,'hashtagTask') === ErrorIconName ? '#d72828' : ''"
                                                        [class.checked]="campaignInfo && !checkingCampaignTask && !incompleteHashtagTask.has(challenge.id)"
                                                        nzTheme="outline"
                                                    ></i>
                                                    <ng-container *ngIf="challenge.check_type === 'quote_tweet'">
                                                        Quote a tweet with
                                                    </ng-container>
                                                    <ng-container *ngIf="challenge.check_type === 'reply_tweet'">
                                                        Reply a tweet with
                                                    </ng-container>
                                                    <ng-container *ngIf="challenge.check_type === 'post_tweet'">
                                                        Post a new tweet with
                                                    </ng-container>
                                                </span>

                                                <span *ngFor="let hashtag of challenge.hashtags;let i = index" class="twitter-hashtag">
                                                    #{{hashtag}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mobile-show">
                                    <ng-container *ngTemplateOutlet="TwitterConnectBtn"></ng-container>
                                </div>

                                <div *ngIf="duplicateSocialMedias.has(SocialMedia.TWITTER)" class="mt-2.5 mb-2.5">
                                    <span class="input-tip">This social media is already used by another wallet</span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>

                        <nz-form-item *ngIf="socialMediaArr.includes(SocialMedia.TELEGRAM)">
                            <nz-form-control [nzErrorTip]="emptyErrorTpl" class="social-media-input">
                                <div class="content">
                                    <span class="social-media-name">
                                        <i nz-icon nzType="antd:telegram" nzTheme="outline" style="color: #40b3e0"></i>
                                        Telegram
                                        <ng-container *ngIf="!requiredSocialMediaArr.includes(SocialMedia.TELEGRAM)">
                                            (Optional)
                                        </ng-container>
                                    </span>

                                    <div class="desktop-show">
                                        <ng-container *ngTemplateOutlet="TelegramConnectBtn"></ng-container>
                                    </div>
                                </div>

                                <div class="child-list">
                                    <div *ngFor="let challenge of telegramChallengeList" class="child-item">
                                        <div *ngIf="challenge.check_type === 'follow'" class="child-item-name">
                                            <i
                                                nz-icon
                                                [nzType]="getCheckIconType(challenge.id,'campaignTask')"
                                                [class.checked]="campaignInfo && !checkingCampaignTask && !incompleteCampaignTask.has(challenge.id)"
                                                [style.color]="getCheckIconType(challenge.id,'campaignTask') === ErrorIconName ? '#d72828' : ''"
                                                nzTheme="outline"
                                            ></i>
                                            <span>
                                                Join <a (click)="joinDiscordOrTelegram(challenge)">@{{challenge.display}}</a> on Telegram
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="mobile-show">
                                    <ng-container *ngTemplateOutlet="TelegramConnectBtn"></ng-container>
                                </div>

                                <div *ngIf="duplicateSocialMedias.has(SocialMedia.TELEGRAM)" class="mt-2.5 mb-2.5">
                                    <span class="input-tip">This social media is already used by another wallet</span>
                                </div>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div>
                    <div class="step-item step-3">
                        <div class="step-title" [style.opacity]="formButtonHidden ? '0.8' : '1'">
                            <div class="title-box">
                                <span class="num">{{hasSocialMedia ? 2 : 1}}</span>
                                <span class="name">Enter Email to Get Campaign Update</span>
                            </div>
                        </div>

                        <nz-form-item [hidden]="formButtonHidden">
                            <nz-form-control [nzErrorTip]="emailErrorTpl">
                                <input
                                    nz-input
                                    class="email-input"
                                    type="text"
                                    placeholder="Enter email address"
                                    formControlName="email"
                                    style="padding-left: 15px"
                                    (input)="duplicateSocialMedias.delete(SocialMedia.EMAIL)"
                                />
                                <ng-template #emailErrorTpl let-control>
                                    <ng-container *ngIf="control.hasError('required')">
                                        <span class="input-tip">Required for participation</span>
                                    </ng-container>
                                    <ng-container *ngIf="control.hasError('email')">
                                        <span class="input-tip">Please enter a valid email</span>
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <!-- Custom Fields -->
                    <div *ngFor="let item of (customFields$ | async);let i = index" class="step-item step-3">
                        <div class="step-title" [style.opacity]="formButtonHidden ? '0.8' : '1'">
                            <div class="title-box">
                                <span class="num">{{i + (hasSocialMedia ? 3 : 2)}}</span>
                                <span class="name">{{item.name}}</span>
                            </div>
                        </div>
                        <nz-form-item [hidden]="formButtonHidden">
                            <nz-form-control [nzErrorTip]="customErrorTip">
                                <input
                                    nz-input
                                    class="email-input"
                                    type="text"
                                    placeholder="Enter answer"
                                    style="padding-left: 15px"
                                    formControlName="custom_input_{{item.challenge_id}}"
                                />

                                <ng-template #customErrorTip let-control>
                                    <ng-container *ngIf="control.hasError('required')">
                                        <span class="input-tip">Required for participation</span>
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div class="robot-check" [hidden]="formButtonHidden">
                    <div id="robot"></div>

                    <div class="check-btn-box">
                        <button
                            *ngIf="(incompleteCampaignTask.size === 0 && incompleteAtAccountsTask.size === 0 && incompleteHashtagTask.size === 0 && incompleteDiscordRoleTask.size === 0) || !walletStatusService.walletAddress || !hasUnJoinedCampaignWallet; else recheckBtn"
                            nz-button
                            nzType="primary"
                            class="submit-btn cta not-connected"
                            [disabled]="disableRegisterBtn"
                            [nzLoading]="submittingJoinForm"
                            (click)="joinCampaign()"
                        >
                            {{(!hasUnJoinedCampaignWallet && walletStatusService.isLoggedIn) ? 'Joined' : 'Register' }}
                        </button>

                        <ng-template #recheckBtn>
                            <button
                                nz-button
                                nz-tooltip
                                nzType="primary"
                                class="submit-btn cta not-connected"
                                [nzTooltipTitle]="hasEmptyChallenge ? 'The campaign set up is not complete, waiting for the brand to add social media links' : ''"
                                [class.disabled]="hasEmptyChallenge"
                                [nzLoading]="checkingCampaignTask"
                                (click)="checkCampaignChallenge()"
                            >
                                Check Qualification
                            </button>
                        </ng-template>
                    </div>
                </div>

                <div *ngIf="errors?.length" class="" mt-2.5>
                    <p *ngFor="let msg of errors" style="color: #d72828">{{msg}}</p>
                </div>
            </section>
        </form>
    </ng-template>
</ng-container>
