<div class="report-section">
    <div class="section-title">
        <span>{{projectData.name}}</span>
    </div>

    <div class="content">
        <div *ngFor="let item of projectData?.data" class="list-item">
            <span class="index">{{item.rank + 1}}</span>
            <div class="project-info">
                <img errorImage="assets/imgs/break-img.png" appImgLoadSmooth [lazyLoad]="item.imageUrl" />
                <span>{{item.value}}</span>
            </div>
            <span class="overlap">{{item.count | number:'1.0-0'}}</span>
        </div>
    </div>
</div>
