import { Injectable } from '@angular/core';
import { NzMessageDataOptions, NzMessageService } from 'ng-zorro-antd/message';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';

export enum MessageEnum {
    ERROR = 'Oops! Something went wrong. Please try again.',
    LOADING = 'Loading',
}

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    loadingStatus$ = new BehaviorSubject(true);
    private pMobile: boolean;

    constructor(private message: NzMessageService, private deviceDetectorService: DeviceDetectorService) {
        // this.pMobile = window?.innerWidth < 768;
        // window?.addEventListener('resize', () => (this.pMobile = window.innerWidth < 768));
    }

    // Loading
    showLoading() {
        this.loadingStatus$.next(true);
    }

    hideLoading() {
        this.loadingStatus$.next(false);
    }

    // Message
    success(content: string = '', options?: NzMessageDataOptions) {
        this.message.success(content, options);
    }

    error(content?: string, options?: NzMessageDataOptions) {
        const handledOptions = {
            ...{ nzDuration: 15000 },
            ...(options || {}),
        };
        this.message.error(content ?? MessageEnum.ERROR, handledOptions);
    }

    warning(content: string = '', options?: NzMessageDataOptions) {
        this.message.warning(content, options);
    }

    info(content: string = '', options?: NzMessageDataOptions) {
        this.message.info(content, options);
    }

    loading(content: string = '', options?: NzMessageDataOptions) {
        this.message.loading(content ?? MessageEnum.LOADING, options);
    }

    errorMessageByResponse(error: any) {
        if (error.status === 400) {
            this.error(error.body.message, { nzDuration: 5000 });
        } else {
            this.error(error.message, { nzDuration: 5000 });
        }
    }

    // Screen
    isMobile() {
        // return this.pMobile;
        return this.deviceDetectorService.isMobile();
    }

    isDesktop() {
        // return !this.pMobile;
        return !this.deviceDetectorService.isMobile();
    }
}
