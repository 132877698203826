import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopProfileService } from '@services/shop-profile.service';
import { AdminCustomEventEnum, FirebaseEventsService } from '@src/app/services/firebase/firebase-event.service';
import { SocialMediaConnectService } from '@src/app/services/socialmedia-connect.service';
import { SESSION_STORAGE_KEY_ENUM } from '@src/app/shared/types/general.type';
import firebase from 'firebase/compat/app';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, merge } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sign-up-modal',
    templateUrl: './sign-up-modal.component.html',
    styleUrls: ['./sign-up-modal.component.less'],
})
export class SignUpModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() signUpParams: {
        utm_source?: string;
        utm_medium?: string;
        utm_campaign?: string;
    };

    validateForm!: FormGroup;
    submitting = false;
    isCodeChecked = false;
    shopifyToken: string;

    private unsubscribe: Subject<void> = new Subject();

    get invitationCode() {
        return !this.validateForm.valid || (!!this.validateForm.controls.invitation_code.value && !this.isCodeChecked);
    }

    constructor(
        public modalRef: NzModalRef,
        private auth: AngularFireAuth,
        private fb: FormBuilder,
        private message: NzMessageService,
        private profileService: ShopProfileService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private firebaseEventService: FirebaseEventsService,
        private socialMediaConnectService: SocialMediaConnectService
    ) {}

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            email: [null, [Validators.required]],
            password: [null, [Validators.required, Validators.minLength(8)]],
            confirm_password: [null, [Validators.required]],
            terms_of_use: [false, [Validators.requiredTrue]],
            invitation_code: [null],
        });

        merge(this.validateForm.controls.password.valueChanges, this.validateForm.controls.confirm_password.valueChanges)
            .pipe(takeUntil(this.unsubscribe), debounceTime(300))
            .subscribe(() => {
                this.validateForm.get('confirm_password').markAsTouched();
                this.validateForm.get('confirm_password').setErrors(this.checkPasswords(this.validateForm));
            });

        this.validateForm.controls.invitation_code.valueChanges.pipe(takeUntil(this.unsubscribe), debounceTime(500)).subscribe(async () => {
            this.isCodeChecked = false;
            if (this.validateForm.controls.invitation_code.errors) {
                Object.keys(this.validateForm.controls.invitation_code.errors)
                    .filter(errorCode => errorCode !== 'invalidCode')
                    .forEach(errorCode => {
                        this.validateForm.controls.invitation_code.setErrors({
                            [errorCode]: this.validateForm.controls.invitation_code.errors[errorCode],
                        });
                    });
            }
            if (this.validateForm.controls.invitation_code.value) {
                try {
                    await this.profileService.checkInvitationCode({ invitation_code: this.validateForm.controls.invitation_code.value });
                    this.isCodeChecked = true;
                } catch (error) {
                    if (this.validateForm.controls.invitation_code.value) {
                        this.validateForm.controls.invitation_code.setErrors({ invalidCode: error.body.error });
                    }
                }
            }
        });

        this.shopifyToken = this.activatedRoute.snapshot.queryParamMap.get('shopifyToken');
    }

    ngAfterViewInit() {
        const invitationCode = this.activatedRoute.snapshot.queryParamMap.get('invitation_code');
        if (invitationCode) {
            this.validateForm.get('invitation_code').patchValue(invitationCode);
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    checkPasswords(group: FormGroup) {
        const pass = group.get('password').value;
        const confirmPass = group.get('confirm_password').value;
        return !!pass && !!confirmPass && pass === confirmPass ? null : { notSame: true };
    }

    submitForm(): void {
        if (this.submitting) {
            return;
        }
        if (this.invitationCode) {
            throw new Error('Form is not valid');
        }
        this.submitting = true;
        const signInInformation = this.validateForm.getRawValue();
        this.auth
            .createUserWithEmailAndPassword(signInInformation.email, signInInformation.password)
            .then(() => {
                const codeParams: any = {
                    invitation_code: this.validateForm.controls.invitation_code.value,
                };
                const utm_source = this.activatedRoute.snapshot.queryParamMap.get('utm_source') || this.signUpParams?.utm_source;
                const utm_medium = this.activatedRoute.snapshot.queryParamMap.get('utm_medium') || this.signUpParams?.utm_medium;
                const utm_campaign = this.activatedRoute.snapshot.queryParamMap.get('utm_campaign') || this.signUpParams?.utm_campaign;

                if (this.shopifyToken) {
                    codeParams.shopify_token = this.shopifyToken;
                }

                if (utm_source) {
                    codeParams.utm_source = utm_source;
                }

                if (utm_medium) {
                    codeParams.utm_medium = utm_medium;
                }

                if (utm_campaign) {
                    codeParams.utm_campaign = utm_campaign;
                }

                return this.profileService.sendInvitationCode(codeParams);
            })
            .then(async res => {
                if (res.error) {
                    this.message.error(res.error, { nzDuration: 3000 });
                    this.auth.signOut();
                    return false;
                }

                const invitation_code = this.activatedRoute.snapshot.queryParamMap.get('t');

                if (invitation_code) {
                    const invitedParams = {
                        currentEmail: signInInformation.email,
                        invitationCode: invitation_code,
                    };
                    return this.profileService.receiveInvited(invitedParams);
                }
                this.firebaseEventService.logEvent(AdminCustomEventEnum.SING_UP);
                await firebase.auth().currentUser.getIdToken(true);
                this.firebaseEventService.logEvent({
                    name: AdminCustomEventEnum.START_IMPORT_COLLECTION,
                    properties: {
                        from: 'sign_up',
                    },
                });
                const routerLink = this.activatedRoute.snapshot.queryParamMap.get('redirect') || '/admin/home';

                this.router.navigateByUrl(routerLink).finally(() => this.modalRef.triggerCancel());

                // 清空 B端 sessionStorage 存储的discord信息
                window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.SESSION_ROLE_DISCORD_INFO_KEY);

                // 清空 C 端 localStorage 存储的discord信息
                this.socialMediaConnectService.clearSocialMediaInfo();

                return Promise.resolve(true);
            })
            .catch(error => {
                this.submitting = false;
                this.auth.signOut();
                this.message.create('error', error.message || error?.body.message);
            });
    }

    goSignIn() {
        this.modalRef.triggerCancel();
        this.profileService.showSignInModal();
    }
}
