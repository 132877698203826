import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BackButtonDirective } from './directives/back-button.directive';
import { ContentEditableModelDirective } from './directives/content-editable-model.directive';
import { EchartsDirective } from './directives/echarts.directive';
import { ImgLoadSmoothDirective } from './directives/img-load-smooth.directive';
import { LoadingDirective } from './directives/loading.directive';
import { NumberAbbreviationPipe } from './pipes/abbreviate-number.pipe';
import { EmailAsteriskPipe } from './pipes/email-asterisk.pipe';
import { OmitPipe } from './pipes/omit.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';

@NgModule({
    declarations: [
        ImgLoadSmoothDirective,
        LoadingDirective,
        ContentEditableModelDirective,
        EchartsDirective,
        BackButtonDirective,
        SafeUrlPipe,
        SafeHtmlPipe,
        OmitPipe,
        EmailAsteriskPipe,
        NumberAbbreviationPipe,
    ],
    imports: [CommonModule],
    exports: [
        ImgLoadSmoothDirective,
        LoadingDirective,
        ContentEditableModelDirective,
        EchartsDirective,
        BackButtonDirective,
        SafeUrlPipe,
        SafeHtmlPipe,
        OmitPipe,
        EmailAsteriskPipe,
        NumberAbbreviationPipe,
    ],
})
export class DirectivePipeModule {}
