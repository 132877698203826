import { Injectable } from '@angular/core';
import { RequestService } from '@src/app/services/request.service';
import { SubscriptionPlanEnum } from '@src/app/shared/types/profile.typing';

@Injectable()
export class SubscriptionPlansService {
    constructor(private requestService: RequestService) {}

    subscribePlan(data: {
        sub_plan: SubscriptionPlanEnum;
        billing_period: 'monthly' | 'annually';
        success_url: string;
        cancel_url: string;
    }) {
        return this.requestService.sendRequest<{ checkout_url: string; [key: string]: any }>({
            method: 'POST',
            url: '/brand/project/subscription',
            api: 'admin-api',
            data,
        });
    }

    validateSubscription() {
        return this.requestService.sendRequest({
            method: 'GET',
            url: '/brand/project/subscription/validate',
            api: 'admin-api',
        });
    }
}
