<section *ngIf="lifoChartConfig" class="lifo-chart" [class.light]="lifoChartConfig.theme === 'light'">
    <!-- Title / Description / Title Image -->
    <div class="width-100p">
        <div *ngIf="lifoChartConfig.sectionName || lifoChartConfig.sectionNameHTML" class="section-title">
            <!-- Section Name as Text -->
            <span *ngIf="lifoChartConfig.sectionNameHTML">
                <span [innerHTML]="lifoChartConfig.sectionNameHTML | safeHtml"></span>
                <i
                    *ngIf="lifoChartConfig.sectionNameTooltip"
                    nz-tooltip
                    nz-icon
                    nzType="info-circle"
                    [nzTooltipTitle]="lifoChartConfig.sectionNameTooltip"
                    style="vertical-align: middle; margin-left: 4px; font-size: 12px; margin-top: -4px"
                ></i>
            </span>

            <!-- SectionName as html -->
            <span *ngIf="!lifoChartConfig.sectionNameHTML">
                <img
                    *ngIf="lifoChartConfig.sectionNameImgLink"
                    class="section-name-prefix-img"
                    [src]="lifoChartConfig.sectionNameImgLink"
                />
                <span [ngStyle]="lifoChartConfig.sectionNameStyle">{{ lifoChartConfig.sectionName }}</span>
                <i
                    *ngIf="lifoChartConfig.sectionNameTooltip"
                    nz-tooltip
                    nz-icon
                    nzType="info-circle"
                    [nzTooltipTitle]="lifoChartConfig.sectionNameTooltip"
                    style="vertical-align: middle; margin-left: 4px; font-size: 12px; margin-top: -4px"
                ></i>
            </span>

            <span *ngIf="lifoChartConfig.topRightInfo" class="top-right-info">
                <!-- Type: Count -->
                <ng-container *ngIf="lifoChartConfig.topRightInfo.type === 'count'">
                    <span style="font-size: 12px; font-weight: normal">{{lifoChartConfig.topRightInfo.text}}</span>
                    <ng-container *ngIf="isNumber(lifoChartConfig.topRightInfo.count); else showRightInfoCountText">
                        {{lifoChartConfig.topRightInfo.count | number:'1.0-0'}}
                    </ng-container>
                    <ng-template #showRightInfoCountText>{{lifoChartConfig.topRightInfo.count}}</ng-template>
                </ng-container>

                <!-- Type: Increased Count -->
                <ng-container *ngIf="lifoChartConfig.topRightInfo.type === 'increasedCount'">
                    <ng-container *ngIf="isNumber(lifoChartConfig.topRightInfo.count); else showRightInfoCountText">
                        {{lifoChartConfig.topRightInfo.count | number:'1.0-0'}}
                    </ng-container>
                    <ng-template #showRightInfoCountText>{{lifoChartConfig.topRightInfo.count}}</ng-template>
                    <span class="divider"></span>
                    <div class="increased-count" [ngClass]="lifoChartConfig.topRightInfo.increasedType">
                        {{lifoChartConfig.topRightInfo.increasedType === 'positive' ? '↑' : '↓'}}
                        {{lifoChartConfig.topRightInfo.increasedString}}
                    </div>
                </ng-container>

                <!-- Type: Select -->
                <ng-container *ngIf="lifoChartConfig.topRightInfo.type === 'select'">
                    <ng-template #arrowDown>
                        <i nz-icon nzType="antd:colorful-caret-down" style="font-size: 8px"></i>
                    </ng-template>
                    <nz-select
                        class="top-right-select"
                        [nzPlaceHolder]="lifoChartConfig.topRightInfo.placeholder || 'Select'"
                        [nzSuffixIcon]="arrowDown"
                        [(ngModel)]="selectedTopRightItemValue"
                        (ngModelChange)="onTopRightItemChange($event)"
                    >
                        <nz-option
                            *ngFor="let option of lifoChartConfig.topRightInfo.options"
                            [nzLabel]="option.label"
                            [nzValue]="option.value"
                        ></nz-option>
                    </nz-select>
                </ng-container>
            </span>
        </div>

        <div *ngIf="lifoChartConfig.sectionDescription" class="section-desc" [ngStyle]="lifoChartConfig.sectionDescriptionStyle">
            {{lifoChartConfig.sectionDescription}}
        </div>
    </div>

    <nz-skeleton *ngIf="fetchingData"></nz-skeleton>
    <ng-container *ngIf="!fetchingData">
        <ng-template #noData><div style="margin: 50px auto; width: 100%; text-align: center">No Data</div></ng-template>

        <!-- Echarts Chart -->
        <main *ngIf="lifoChartConfig.chartType === 'echarts'" class="echarts-content" [class.chart-with-legend]="hasOuterLegend">
            <div class="chart" [style.height]="chartSize" [style.width]="hasOuterLegend ? '35%' : '100%'">
                <section
                    class="chart-ele"
                    appEcharts
                    [initialOption]="initialOptions"
                    [optionToBeUpdate]="lifoChartConfig.chartConfig"
                ></section>

                <div *ngIf="lifoChartConfig.donutChartCenterInfo" class="additional-info">
                    <div class="name">{{lifoChartConfig.donutChartCenterInfo.name}}</div>
                    <div class="count">
                        <img *ngIf="lifoChartConfig.donutChartCenterInfo.img" [src]="lifoChartConfig.donutChartCenterInfo.img" />
                        <ng-container *ngIf="isNumber(lifoChartConfig.donutChartCenterInfo.count); else strCount">
                            {{lifoChartConfig.donutChartCenterInfo.count | number:'1.0-0'}}
                        </ng-container>
                        <ng-template #strCount>{{lifoChartConfig.donutChartCenterInfo.count}}</ng-template>
                    </div>
                </div>
            </div>

            <div
                *ngIf="hasOuterLegend"
                class="legend-info"
                [class.vertical-legends]="lifoChartConfig.legendConfig.length <= MAX_VERTICAL_LEGENDS_COUNT"
                [style.width]="hasOuterLegend ? '65%' : '100%'"
            >
                <div *ngFor="let legend of lifoChartConfig.legendConfig;let i = index;" class="legend-item">
                    <div class="line1">
                        <div class="dot" [style.backgroundColor]="LIFO_CHART_COLORS[i]"></div>
                        <span class="count">{{legend.count|number:'1.0-0'}}</span>
                        <div class="divider"></div>
                        <div class="percent">{{legend.percent | percent:'1.1-1'}}</div>
                    </div>
                    <div class="line2">{{legend.name}}</div>
                </div>
            </div>

            <div class="no-echarts-data" *ngIf="!fetchingData && noEchartsChartData">
                <ng-container *ngTemplateOutlet="noData"></ng-container>
            </div>
        </main>

        <!-- Custom Chart 1 Line -->
        <main *ngIf="lifoChartConfig.chartType === 'textBarOneLine'" class="custom-content" [style.height]="chartSize">
            <ng-container *ngIf="!fetchingData && !lifoChartConfig.customListChartConfig?.length">
                <ng-container *ngTemplateOutlet="noData"></ng-container>
            </ng-container>
            <div class="list-item one-line">
                <div class="list-names">
                    <div
                        *ngFor="let item of lifoChartConfig.customListChartConfig"
                        class="list-name"
                        nz-tooltip
                        [nzTooltipTitle]="item.tooltip || (item.name?.length >= 20 ? item.name : '')"
                    >
                        {{item.name | nzEllipsis : 20 : '...'}}
                        <i *ngIf="item.tooltip" nz-icon nzType="info-circle" style="margin-left: 2px"></i>
                    </div>
                </div>
                <div class="list-bars">
                    <div *ngFor="let item of lifoChartConfig.customListChartConfig" class="list-bar">
                        <div
                            class="bar-item"
                            [style.width]="(lifoChartConfig.makeMaxCountItemAsRealPercent ? item.percent : (item.count / maxCount) ) | percent:'1.2-2'"
                            [style.backgroundColor]="getBackgroundColor()"
                        >
                            <span>{{item.count}}</span>
                            <span class="divider"></span>
                            <span>{{item.percent | percent:'1.0-0'}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <!-- Custom Chart 2 Line -->
        <main *ngIf="lifoChartConfig.chartType === 'textBarTwoLine'" class="custom-content" [style.height]="chartSize">
            <ng-container *ngIf="!fetchingData && !lifoChartConfig.customListChartConfig?.length">
                <ng-container *ngTemplateOutlet="noData"></ng-container>
            </ng-container>
            <div *ngFor="let item of lifoChartConfig.customListChartConfig" class="list-item two-line">
                <div class="list-name overflow-ellipsis">
                    <img *ngIf="item.img" [src]="item.img" />
                    {{item.name}}
                    <i
                        *ngIf="item.tooltip"
                        nz-icon
                        nzType="info-circle"
                        nz-tooltip
                        [nzTooltipTitle]="item.tooltip"
                        style="margin-left: 2px"
                    ></i>
                </div>
                <div class="list-bar">
                    <div
                        class="bar-item"
                        [style.width]="(lifoChartConfig.makeMaxCountItemAsRealPercent ? item.percent : (item.count / maxCount)) | percent:'1.2-2'"
                        [style.backgroundColor]="getBackgroundColor()"
                    >
                        <span>{{item.count}}</span>
                        <span class="divider"></span>
                        <span>{{item.percent | percent:'1.0-0'}}</span>
                    </div>
                </div>
            </div>
        </main>
    </ng-container>
</section>
