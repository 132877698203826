<section class="verification-content">
    <ng-container *ngIf="contractInfo.official_twitter">
        <div class="section-title">Verify by Official Twitter *</div>
        <div class="twitter-account">
            <div class="d-flex align-items-center justify-content-between">
                <div class="overflow-ellipsis twitter-name">
                    <i class="twitter-icon" nz-icon nzType="antd:twitter"></i>
                    @{{contractInfo.official_twitter}}
                </div>
                <button nz-button nzType="primary" nzShape="round" class="verify-btn" (click)="connectTwitter()">
                    <div class="d-flex align-items-center">
                        <span *ngIf="!twitterUserInfo">Verify</span>
                        <span *ngIf="twitterUserInfo" class="overflow-ellipsis">@{{twitterUserInfo.name}}</span>
                        <i
                            *ngIf="twitterUserInfo && twitterUserInfo.name !== contractInfo.official_twitter"
                            nz-icon
                            nzType="close-circle"
                            nzTheme="outline"
                            (click)="disconnectTwitter($event)"
                        ></i>
                    </div>
                </button>
            </div>
            <div *ngIf="twitterUserInfo && contractInfo.official_twitter !== twitterUserInfo.name" class="error-tip">
                Error: Please Connect Your Official Project Twitter Account To Be Verified.
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!contractInfo.official_twitter">
        <div class="section-title">Verify by Twitter *</div>
        <div>
            <button
                *ngIf="!twitterUserInfo"
                nz-button
                nzType="primary"
                nzShape="round"
                (click)="connectTwitter()"
                style="background: #55acee; color: #fff; margin-bottom: 10px; width: 200px"
            >
                <i nz-icon nzType="antd:twitter"></i>
                Connect
            </button>
            <div *ngIf="twitterUserInfo" class="twitter-account">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="overflow-ellipsis twitter-name">
                        <i class="twitter-icon" nz-icon nzType="antd:twitter"></i>
                        @{{twitterUserInfo.name}}
                    </div>
                    <button nz-button nzType="primary" nzShape="round" class="verify-btn" (click)="changeTwitterAccount()">
                        <span class="overflow-ellipsis">Change Account</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="section-title">
            Additional Verification Materials
            <nz-switch [(ngModel)]="verificationMaterials" style="margin-left: 10px"></nz-switch>
        </div>
        <textarea
            *ngIf="verificationMaterials"
            nz-input
            placeholder="Share links to any materials to help us verify your ownership of this project (i.e screenshot of your 'My Collection' page from OpenSea). Please make sure the links are accessible to us."
            [(ngModel)]="content"
        ></textarea>
    </ng-container>
</section>
