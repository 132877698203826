import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SocialMedia } from '@modules/admin/campaign/campaign.model';
import { CustomEventEnum, FirebaseEventsService } from '@src/app/services/firebase/firebase-event.service';
import { SocialMediaConnectService } from '@src/app/services/socialmedia-connect.service';
import { WalletEnum } from '@src/app/shared/types/wallet-chain.model';
import { environment } from '@src/environments/environment';
import * as _ from 'lodash';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { CampaignChallengeService, W3W_CAMPAIGN_CUSTOM_EVENT_ENUM } from './campaign-challenge.service';
@Component({
    selector: 'app-campaign-challenge',
    templateUrl: './campaign-challenge.component.html',
    styleUrls: ['./campaign-challenge.component.less'],
})
export class CampaignChallengeComponent implements OnInit, AfterViewInit {
    public SocialMedia = SocialMedia;

    checkingCampaignTask = false;
    submitting = false;

    campaignForm: FormGroup;

    socialMediaArr: SocialMedia[] = [];

    requiredSocialMediaArr: SocialMedia[] = [];

    incompleteCampaignTask = new Set<number>();
    incompleteAtAccountsTask = new Set<number>();
    incompleteHashtagTask = new Set<number>();
    incompleteDiscordRoleTask = new Set<number>();

    campaignChallengeSubscribe$ = new Subject();

    duplicateSocialMedias = new Set<SocialMedia>();

    errorMessage: string;

    get userInfo$() {
        return this.campaignChallengeService.userInfo$;
    }

    get userInfo() {
        return this.userInfo$.getValue();
    }

    get campaignJoined() {
        return !!this.userInfo?.campaignEntryList?.find(item => item.campaignId === this.campaignId);
    }

    get subscriptions() {
        return this.campaignChallengeService.subscriptions;
    }

    get discordUserInfo() {
        return this.discordUserInfo$.getValue();
    }

    get twitterUserInfo() {
        return this.twitterUserInfo$.getValue();
    }

    get telegramUserInfo() {
        return this.telegramUserInfo$.getValue();
    }

    get discordUserInfo$() {
        return this.campaignChallengeService.discordUserInfo$;
    }

    get twitterUserInfo$() {
        return this.campaignChallengeService.twitterUserInfo$;
    }

    get telegramUserInfo$() {
        return this.campaignChallengeService.telegramUserInfo$;
    }
    get campaignId() {
        return this.campaignChallengeService.campaignId;
    }

    get discordChallengeList() {
        return this.campaignInfo?.campaign_challenges?.filter(item => item.challenge_type === SocialMedia.DISCORD);
    }
    get telegramChallengeList() {
        return this.campaignInfo?.campaign_challenges?.filter(item => item.challenge_type === SocialMedia.TELEGRAM);
    }
    get twitterChallengeList() {
        return this.campaignInfo?.campaign_challenges?.filter(item => item.challenge_type === SocialMedia.TWITTER);
    }

    get campaignInfo() {
        return this.campaignChallengeService.campaignInfo;
    }

    get disableRegisterBtn() {
        return !this.campaignChallengeService.walletAddress || this.campaignForm.invalid;
    }

    get disableReCheckBtn() {
        if (this.socialMediaArr.length === 0) {
            return false;
        } else {
            if (
                this.socialMediaArr.includes(SocialMedia.TWITTER) &&
                this.requiredSocialMediaArr.includes(SocialMedia.TWITTER) &&
                !this.twitterUserInfo
            ) {
                return true;
            }

            if (
                this.socialMediaArr.includes(SocialMedia.DISCORD) &&
                this.requiredSocialMediaArr.includes(SocialMedia.DISCORD) &&
                !this.discordUserInfo
            ) {
                return true;
            }

            if (
                this.socialMediaArr.includes(SocialMedia.TELEGRAM) &&
                this.requiredSocialMediaArr.includes(SocialMedia.TELEGRAM) &&
                !this.telegramUserInfo
            ) {
                return true;
            }

            return false;
        }
    }

    constructor(
        public campaignChallengeService: CampaignChallengeService,
        private firebaseEventService: FirebaseEventsService,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private socialMediaConnectService: SocialMediaConnectService
    ) {}

    ngOnInit() {
        this.campaignChallengeService.campaignId = Number(this.activatedRoute.snapshot.paramMap.get('campaignId'));

        this.initCampaignForm();
        this.setupCampaignChallenges();
        this.setupSocialMediaInfoUpdateListener();
        this.setupUserInfoUpdateListener();
        this.getCampaignInfo();
        this.setupCampaignChallengesUpdateListener();
        this.setupMessageListener();

        combineLatest([this.campaignChallengeService.walletAddress$, this.campaignChallengeService.campaignInfo$])
            .pipe(
                distinctUntilChanged((a, b) => _.isEqual(a[0], b[0])),
                filter(([, campaignInfo]) => !!campaignInfo),
                takeUntil(this.subscriptions.pageLeave)
            )
            .subscribe(([walletAddr, campaignInfo]) => {
                if (walletAddr) {
                    this.campaignChallengeService.getUserInfo(this.campaignChallengeService.walletAddress).then((userInfo: any) => {
                        if (!userInfo.walletAddress) {
                            this.campaignChallengeService
                                .updateUserInfo({ walletAddress: this.campaignChallengeService.walletAddress })
                                .then(data => this.campaignChallengeService.userInfo$.next(data))
                                .catch(() => this.campaignChallengeService.userInfo$.next(null));
                        } else {
                            this.campaignChallengeService.userInfo$.next(userInfo);
                        }
                    });
                } else {
                    // 清除 SocialMedia 信息
                    this.twitterUserInfo$.next(null);
                    this.discordUserInfo$.next(null);
                    this.telegramUserInfo$.next(null);
                    this.campaignForm?.get('discord_id').setValue('');
                    this.campaignForm?.get('twitter_id').setValue('');
                    this.campaignForm?.get('telegram_id').setValue('');

                    // Challenge 信息
                    campaignInfo.campaign_challenges?.forEach(item => {
                        this.incompleteCampaignTask.add(item.id);
                        this.incompleteAtAccountsTask.add(item.id);
                        this.incompleteHashtagTask.add(item.id);
                        this.incompleteDiscordRoleTask.add(item.id);
                    });
                }
            });
    }

    ngAfterViewInit() {
        this.setGreCaptcha();
    }

    public async joinCampaign() {
        if (!this.campaignForm.get('recaptcha_result').value) {
            this.setErrorMessage('Failed to pass recaptcha');
            return;
        }
        if (!this.campaignForm.get('email').value) {
            this.setErrorMessage('Please input your email');
            return;
        }

        try {
            this.submitting = true;
            const campaignChallengeStatus: any = await this.checkCampaignChallenge();
            if (!campaignChallengeStatus.success) {
                this.submitting = false;
                this.setErrorMessage('Please complete all required tasks');
                return;
            }
            await this.realJoinCampaign(this.campaignForm);
            this.submitting = false;
        } catch (err) {
            this.setErrorMessage('Oops, something went wrong, please try again later');
            this.submitting = false;
        }
    }

    public async connectSocialMedia(type: SocialMedia) {
        this.duplicateSocialMedias.delete(type);
        this.firebaseEventService.logEvent({
            name: CustomEventEnum.START_CONNECT_SOCIAL_MEDIA,
            properties: {
                type,
                wallet_address: this.campaignChallengeService.walletAddress || null,
                campaignId: this.campaignId,
            },
        });

        if (type === SocialMedia.DISCORD || type === SocialMedia.TWITTER) {
            this.socialMediaConnectService.connectDiscordOrTwitter(type, {
                walletAddress: this.campaignChallengeService.walletAddress,
                // TODO: 应该为外部网站的链接
                backPageUrl: window.location.href,
            });
        }

        if (type === SocialMedia.TELEGRAM) {
            this.socialMediaConnectService.connectTelegram();
        }
    }

    public removeSocialMediaInfo(type: SocialMedia) {
        switch (type) {
            case SocialMedia.DISCORD:
                this.campaignChallengeService.discordUserInfo$.next(null);
                localStorage.removeItem('discordUserInfo');
                break;
            case SocialMedia.TWITTER:
                this.campaignChallengeService.twitterUserInfo$.next(null);
                localStorage.removeItem('twitterUserInfo');
                break;
            case SocialMedia.TELEGRAM:
                this.campaignChallengeService.telegramUserInfo$.next(null);
                localStorage.removeItem('telegramUserInfo');
                break;
        }
    }

    public followTwitter(challenge) {
        window.open(this.getTwitterFollowLink(challenge), '_blank');
    }

    public retweetTwitter(challenge) {
        window.open(this.getTwitterRetweetLink(challenge), '_blank');
    }

    public joinDiscordOrTelegram(challenge) {
        window.open(challenge.challenge_input, '_blank');
    }

    public checkCampaignChallenge() {
        if (this.campaignInfo?.campaign_challenges.length && this.campaignChallengeService.walletAddress) {
            return this.campaignChallengeService
                .checkCampaignChallengeStatus({
                    campaignId: this.campaignInfo.id,
                    walletAddress: this.campaignChallengeService.walletAddress,
                })
                .then(res => {
                    this.incompleteCampaignTask.clear();
                    this.incompleteAtAccountsTask.clear();
                    this.incompleteHashtagTask.clear();
                    this.incompleteDiscordRoleTask.clear();

                    if (!res.success) {
                        const errors: string[] = [];
                        res.checkDetails
                            .filter(item => !item.result)
                            .forEach(failedItem => {
                                if (!failedItem.serverJoin) {
                                    this.incompleteCampaignTask.add(failedItem.originalChallenge.id);
                                }

                                if (
                                    failedItem.originalChallenge.atAccounts &&
                                    failedItem.atAccounts !== failedItem.originalChallenge.atAccounts
                                ) {
                                    this.incompleteAtAccountsTask.add(failedItem.originalChallenge.id);
                                }
                                if (Object.values(failedItem.hashtagChecks).some(item => !item)) {
                                    this.incompleteHashtagTask.add(failedItem.originalChallenge.id);
                                }
                                if (Object.values(failedItem.verifyRoleChecks).some(item => !item)) {
                                    this.incompleteDiscordRoleTask.add(failedItem.originalChallenge.id);
                                }
                                // 非正常(任务未完成)错误
                                switch (failedItem.status) {
                                    case 401:
                                        errors.push(
                                            // eslint-disable-next-line max-len
                                            `Sorry, ${failedItem.originalChallenge.challengeType} connection expired. Please reconnect again.`
                                        );
                                        break;
                                    case 429:
                                        errors.push('Sorry, Refreshing is too frequent. Please try again after 15 minutes.');
                                        break;
                                }
                            });

                        // uniq(errors).forEach(error => {this.setErrorMessage});
                        if (errors.length) {
                            this.setErrorMessage(errors[0]);
                        }
                    }

                    return res;
                })
                .catch(err => this.setErrorMessage('Oops! Something went wrong. Please try again later'))
                .finally(() => (this.checkingCampaignTask = false));
        }

        return { success: true };
    }

    getCampaignInfo() {
        this.campaignChallengeService.getNftCampaignDetail().then(res => {
            this.campaignChallengeService.campaignInfo = res;
            this.campaignChallengeService.campaignInfo$.next(res);
        });
    }

    getTwitterFollowLink(challenge) {
        const screenName = challenge.challenge_input;
        const originalReferer = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
        return `https://twitter.com/intent/follow?screen_name=${screenName}&original_referer=${originalReferer}`;
    }

    getTwitterRetweetLink(challenge) {
        const retweetId = challenge.challenge_input?.split('?')[0].split('/').pop();
        const originalReferer = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
        return `https://twitter.com/intent/retweet?tweet_id=${retweetId}&original_referer=${originalReferer}`;
    }

    getHexColor(color: number) {
        return `#${color?.toString(16)}`;
    }

    quoteOrReplyTweet(challenge) {
        if (!challenge.challenge_input) return;
        window.open(challenge.challenge_input, '_blank');
    }

    private setupUserInfoUpdateListener() {
        this.campaignChallengeService.userInfo$
            .pipe(
                filter(data => !!data),
                takeUntil(this.subscriptions.pageLeave)
            )
            .subscribe(data => {
                if (data.walletContactList) {
                    const discordInfo: any = _.last(
                        data.walletContactList.filter(item => item.contactType === SocialMedia.DISCORD && item.contactId)
                    );
                    const twitterInfo: any = _.last(
                        data.walletContactList.filter(item => item.contactType === SocialMedia.TWITTER && item.contactId)
                    );
                    const telegramInfo: any = _.last(
                        data.walletContactList.filter(item => item.contactType === SocialMedia.TELEGRAM && item.contactId)
                    );
                    const emailInfo: any = _.last(data.walletContactList.filter(item => item.contactType === SocialMedia.EMAIL));

                    if (discordInfo) {
                        this.discordUserInfo$.next({
                            name: discordInfo.contact,
                            principalName: null,
                        });
                    }

                    if (twitterInfo) {
                        this.twitterUserInfo$.next({
                            name: twitterInfo.contact,
                            principalName: null,
                        });
                    }

                    if (telegramInfo) {
                        this.telegramUserInfo$.next({
                            username: telegramInfo.contact,
                            id: null,
                        });
                    }

                    if (emailInfo) {
                        this.campaignForm.get('email').patchValue(emailInfo.contact);
                        if (this.campaignJoined) {
                            this.campaignForm.get('email').disable();
                        }
                    }
                }
            });
    }

    private setupCampaignChallengesUpdateListener() {
        this.campaignChallengeSubscribe$
            .pipe(debounceTime(300), takeUntil(this.subscriptions.pageLeave))
            .subscribe(() => this.checkCampaignChallenge());

        combineLatest([
            this.campaignChallengeService.walletAddress$,
            this.discordUserInfo$,
            this.twitterUserInfo$,
            this.telegramUserInfo$,
            this.campaignChallengeService.campaignInfo$,
        ])
            .pipe(takeUntil(this.subscriptions.pageLeave))
            .subscribe(([walletAddress, discordUserInfo, twitterUserInfo, telegramUserInfo, campaignInfo]) => {
                if (
                    walletAddress &&
                    !twitterUserInfo?.principalName &&
                    !telegramUserInfo?.id &&
                    !discordUserInfo?.principalName &&
                    campaignInfo &&
                    (discordUserInfo || twitterUserInfo || telegramUserInfo)
                ) {
                    this.campaignChallengeSubscribe$.next();
                }
            });
    }

    private setupCampaignChallenges() {
        this.campaignChallengeService.campaignInfo$
            .pipe(
                filter(data => !!data),
                takeUntil(this.subscriptions.pageLeave)
            )
            .subscribe(campaignInfo => {
                campaignInfo.required_social_media.forEach(ele => {
                    this.socialMediaArr.push(ele.type);
                    if (ele.is_required) {
                        this.requiredSocialMediaArr.push(ele.type);
                        this.campaignForm.get(`${ele.type}_id`).setValidators(Validators.required);
                    }
                });
                campaignInfo.campaign_challenges?.forEach(item => {
                    this.incompleteCampaignTask.add(item.id);
                    this.incompleteAtAccountsTask.add(item.id);
                    this.incompleteHashtagTask.add(item.id);
                    this.incompleteDiscordRoleTask.add(item.id);
                });
            });
    }

    private setupSocialMediaInfoUpdateListener() {
        combineLatest([this.campaignChallengeService.walletAddress$, this.discordUserInfo$, this.twitterUserInfo$, this.telegramUserInfo$])
            .pipe(takeUntil(this.subscriptions.pageLeave))
            .subscribe(async ([walletAddress, discordUserInfo, twitterUserInfo, telegramUserInfo]) => {
                const contactList = [];
                if (discordUserInfo?.principalName) {
                    contactList.push({
                        contactId: discordUserInfo.principalName,
                        contact: discordUserInfo.name,
                        contactType: SocialMedia.DISCORD,
                    });
                }
                if (twitterUserInfo?.principalName) {
                    contactList.push({
                        contactId: twitterUserInfo.principalName,
                        contact: twitterUserInfo.name,
                        contactType: SocialMedia.TWITTER,
                    });
                }
                if (telegramUserInfo?.id) {
                    contactList.push({
                        contactId: telegramUserInfo.id,
                        contact: telegramUserInfo.username,
                        contactType: SocialMedia.TELEGRAM,
                    });
                }

                if (contactList.length > 0 && walletAddress) {
                    this.campaignChallengeService
                        .updateUserInfo({
                            walletAddress,
                            contactList,
                        })
                        .then(res => {
                            this.campaignForm.get('discord_id').patchValue(discordUserInfo?.name || '');
                            this.campaignForm.get('twitter_id').patchValue(twitterUserInfo?.name || '');
                            this.campaignForm.get('telegram_id').patchValue(telegramUserInfo?.username || '');

                            // Remove social media info from local storage
                            localStorage.removeItem('twitterUserInfo');
                            localStorage.removeItem('discordUserInfo');
                            localStorage.removeItem('telegramUserInfo');
                            this.campaignChallengeService.userInfo$.next(res);
                        });
                }

                if (discordUserInfo || twitterUserInfo || telegramUserInfo) {
                    this.campaignForm?.get('discord_id').setValue(discordUserInfo?.name || '');
                    this.campaignForm?.get('twitter_id').setValue(twitterUserInfo?.name || '');
                    this.campaignForm?.get('telegram_id').setValue(telegramUserInfo?.username || '');
                }
            });
    }

    private initCampaignForm() {
        this.campaignForm = this.fb.group({
            recaptcha_result: [null, Validators.required],
            campaign_id: Number(this.campaignChallengeService.campaignId),
            wallet_type: [WalletEnum.META_MASK],
            wallet_address: [null],
            discord_id: [''],
            twitter_id: [''],
            telegram_id: [''],
            email: [{ value: '', disabled: this.campaignJoined }, [Validators.required, Validators.email]],
        });
    }

    private setErrorMessage(message: string) {
        this.errorMessage = message;
        // setTimeout(() => {
        //     this.errorMessage = null;
        // }, 5000);
    }

    private realJoinCampaign(campaignForm: FormGroup) {
        campaignForm.get('wallet_address').setValue(this.campaignChallengeService.walletAddress);

        return this.campaignChallengeService
            .subscribeWalletAddressUpdate({ chain: 'ETH', address: this.campaignChallengeService.walletAddress })
            .then(() => this.campaignChallengeService.entryNftCampaign(campaignForm.getRawValue()))
            .then(() => {
                console.info('Thanks for your participation!');
                this.postMessageToParentWindow(W3W_CAMPAIGN_CUSTOM_EVENT_ENUM.CAMPAIGN_JOIN_SUCCESS);
            })
            .catch(err => {
                if (err.status === 400 && err.body?.details) {
                    err.body?.details.forEach((item: { contact_type: SocialMedia; contact: string; is_duplicate: boolean }) => {
                        this.duplicateSocialMedias.add(item.contact_type);
                    });
                } else {
                    this.setErrorMessage(err.status === 400 ? err.body?.message : 'Oops! Something went wrong. Please try again.');
                    this.postMessageToParentWindow(W3W_CAMPAIGN_CUSTOM_EVENT_ENUM.CAMPAIGN_JOIN_FAILED);
                }
            });
    }

    private setGreCaptcha() {
        this.campaignChallengeService.campaignInfo$
            .pipe(
                filter(data => !!data),
                takeUntil(this.subscriptions.pageLeave)
            )
            .subscribe(() => {
                try {
                    window['grecaptcha'].render('robot', {
                        sitekey: environment.grecaptcha.sitekey,
                        theme: 'dark',
                        callback: token => this.campaignForm.get('recaptcha_result').setValue(token), // 验证成功回调
                        'expired-callback': () => this.campaignForm.get('recaptcha_result').setValue(null), // 验证过期回调
                        'error-callback': () => {
                            this.setErrorMessage('Oops! Fail to load the recaptcha. Please try again.');
                            this.campaignForm.get('recaptcha_result').setValue(null);
                        }, // 验证错误回调
                    });
                } catch (e) {
                    this.setErrorMessage('Oops! Fail to load the recaptcha. Please try again.');
                    this.campaignForm.get('recaptcha_result').removeValidators(Validators.required);
                    this.campaignForm.get('recaptcha_result').updateValueAndValidity();
                }
            });
    }

    private postMessageToParentWindow(type: W3W_CAMPAIGN_CUSTOM_EVENT_ENUM) {
        try {
            window.parent.postMessage(
                {
                    type,
                    data: {
                        campaignId: this.campaignChallengeService.campaignId,
                        walletAddress: this.campaignChallengeService.walletAddress,
                    },
                },
                '*'
            );
        } catch (err) {
            console.error('No parent window');
        }
    }

    private setupMessageListener() {
        window.addEventListener('message', event => {
            const messageData: {
                type: 'w3wWalletAddressUpdate';
                data: {
                    walletAddress: string;
                };
            } = event.data;

            if (messageData.type === 'w3wWalletAddressUpdate') {
                this.campaignChallengeService.walletAddress$.next(messageData.data.walletAddress || null);
            }
        });
    }
}
