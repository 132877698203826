import { ViewportScroller } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { CommonService } from '@services/common.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent {
    constructor(public loadingService: CommonService, private viewportScroller: ViewportScroller, private router: Router) {
        // 全局设置页面前进，后退时的恢复原先位置的动画
        this.router.events.pipe(filter(event => event instanceof Scroll)).subscribe(route => {
            const typedRoute: Scroll = route as Scroll;

            if (typedRoute.position) {
                // Back
                setTimeout(() => {
                    this.viewportScroller.scrollToPosition(typedRoute.position);
                }, 10);
            } else {
                // Forward
                setTimeout(() => {
                    this.viewportScroller.scrollToPosition([0, 0]);
                }, 10);
            }
        });
    }
}

@Component({
    selector: 'app-old-discord',
    template: '',
    styleUrls: [],
})
export class OldDiscordAuthComponent {
    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        this.router.navigate(['main', 'discord', this.activatedRoute.snapshot.paramMap.get('project')], { queryParamsHandling: 'merge' });
    }
}
