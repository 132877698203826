import { Component, Input } from '@angular/core';
import { ContractItem } from '@src/app/modules/admin/service/admin-data.service';
import { ChainEnum } from '@src/app/shared/types/wallet-chain.model';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { TempCollection } from '../select-nft-collection/select-nft-collection.component';

@Component({
    selector: 'app-select-nft-collection-modal',
    templateUrl: './select-nft-collection-modal.component.html',
    styleUrls: ['./select-nft-collection-modal.component.less'],
})
export class SelectNftCollectionModalComponent {
    @Input() selectedNftCollections: TempCollection[];
    @Input() myCollections: ContractItem[];
    @Input() chain: ChainEnum = ChainEnum.ETHEREUM;
    @Input() onlyFullDataCollection = false;
    @Input() needDisableNoCommunityMyCollection = false;

    tempSelectedNftCollections: TempCollection[];

    constructor(public modalRef: NzModalRef) {}

    confirm(collections: TempCollection[]) {
        this.tempSelectedNftCollections = collections;
        this.modalRef.triggerOk();
    }
}
