import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-edit-description-modal',
    templateUrl: './edit-description-modal.component.html',
    styleUrls: ['./edit-description-modal.component.less'],
})
export class EditDescriptionModalComponent implements OnInit {
    @Input() description: string;

    submitting = false;

    brandDescription: string;

    constructor(public modalRef: NzModalRef) {}

    ngOnInit() {
        this.brandDescription = this.description;
    }
}
