import { Component, Input, OnInit } from '@angular/core';
import { ProjectDetail } from '@main/advanced-customization/advanced-customization-state.service';
import { CommonService } from '@services/common.service';
import { ShopProfileService } from '@services/shop-profile.service';
import { UtilService } from '@services/util.service';
import { FirebaseFileUploadService } from '@src/app/services/firebase/firebase-file-upload.service';
import { getObjectURL } from '@utils/draw';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-select-nft-project',
    templateUrl: './select-nft-project.component.html',
    styleUrls: ['./select-nft-project.component.less'],
})
export class SelectNftProjectComponent implements OnInit {
    @Input() projectInfo: any;
    @Input() isManualInputProject = false;
    @Input() projectList: ProjectDetail[];

    activeProjectList: ProjectDetail[];
    selectedProjectId: number;
    selectedProject: ProjectDetail;
    isManualInput = false;
    isDisabled = false;

    logoFile;

    logoFileBgUrl;
    projectFormParams: {
        id?: number;
        name: string;
        link: string;
        brandAvatar: string;
    } = {
        name: null,
        link: null,
        brandAvatar: null,
    };

    get disableConfirmBtn() {
        if (!this.isManualInput) {
            return !this.selectedProjectId;
        } else {
            return !this.projectFormParams.name || !this.projectFormParams.brandAvatar;
        }
    }

    constructor(
        private utilService: UtilService,
        private firebaseUploadService: FirebaseFileUploadService,
        private message: CommonService,
        public modalRef: NzModalRef,
        public profileService: ShopProfileService,
        public screenService: CommonService
    ) {}

    ngOnInit(): void {
        this.activeProjectList = this.projectList.filter(
            ele => ele.status === 'active' && this.profileService.shopDetail.nft_project.id !== ele.id
        );
        if (this.isManualInputProject) {
            this.isManualInput = true;
            this.projectFormParams = {
                ...this.projectInfo,
            };
            this.logoFileBgUrl = this.projectFormParams.brandAvatar;
            this.isDisabled = !!this.projectList.find(ele => ele.id === this.projectFormParams.id);
        } else if (this.projectInfo?.id) {
            this.changeProject(this.projectInfo.id);
        }
    }

    changeProject(projectId: number) {
        this.selectedProjectId = projectId;
        this.selectedProject = this.activeProjectList.find(item => item.id === this.selectedProjectId);
    }

    removeImg() {
        this.projectFormParams.brandAvatar = null;
        this.logoFileBgUrl = null;
    }

    logoBeforeUpload = file => {
        this.utilService
            .checkIfImagePixelValid(file, { width: 4472, height: 4472, size: 20 * 1024 })
            .then(() => {
                this.logoFile = file;
                this.logoFileBgUrl = getObjectURL(file);
                this.startUpload(file);
            })
            .catch(() => {
                this.message.error('Please upload an image less than 4472 by 4472 pixels');
            });
        return false;
    };

    startUpload(file: File) {
        const { uploadTask } = this.firebaseUploadService.startUpload(file, '/public/images/nft_brand');

        return uploadTask
            .then(downloadUrl => {
                this.projectFormParams.brandAvatar = downloadUrl;
                this.logoFile = null;
            })
            .catch(() => {
                this.logoFile = null;
                this.logoFileBgUrl = null;
                this.message.error('Upload image failed, please try again later');
            });
    }
}
