<section class="general-modal-box add-social-media-box">
    <app-general-modal-header
        [title]="'Add Social Media'"
        [isBrandModal]="true"
        (cancel)="modalRef.triggerCancel()"
    ></app-general-modal-header>

    <div class="social-media-content">
        <nz-input-group nzCompact *ngFor="let media of brandSocialMedias; let i = index" class="form-input-group">
            <nz-select
                class="select-box"
                [ngModel]="media.name"
                nzSuffixIcon="caret-down"
                style="width: 130px"
                [nzDropdownMatchSelectWidth]="false"
                (ngModelChange)="selectPlatformChange($event, media)"
            >
                <nz-option
                    *ngFor="let plat of platforms"
                    [nzLabel]="plat.label"
                    [nzValue]="plat.value"
                    [nzDisabled]="plat.select"
                    nzCustomContent
                >
                    <i
                        nz-icon
                        [nzType]="'antd:' + plat.value"
                        style="margin-right: 10px; font-size: 20px; vertical-align: middle; color: #fff"
                    ></i>
                    <span style="text-transform: capitalize">{{plat.label}}</span>
                </nz-option>
            </nz-select>
            <input type="text" nz-input [placeholder]="(media.name || 'null') + ' link '" [(ngModel)]="media.link" />
            <i nz-icon nzType="close-circle" class="remove" nzTheme="outline" (click)="removeSocialMedia(i)"></i>
        </nz-input-group>
        <button nz-button nzType="link" class="add-social-media-btn" (click)="addSocialMedia()">+ Add social media</button>
    </div>

    <footer class="d-flex justify-content-center align-items-center footer-btns">
        <button nz-button nzShape="round" (click)="modalRef.triggerCancel()" class="cancel-btn">CANCEL</button>
        <button nz-button nzType="primary" nzShape="round" [nzLoading]="submitting" (click)="modalRef.triggerOk()">CONFIRM</button>
    </footer>
</section>
