import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { ComponentFactoryResolver, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { enUS } from 'date-fns/locale';

import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { nzConfigFactory } from '@shared/components/global-antd-config/global-antd-config.component';
import { NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NZ_DATE_LOCALE, NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { ReuseService } from './services/route-reuse.service';

import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent, OldDiscordAuthComponent } from './app.component';
import { CampaignChallengeComponent } from './modules/public/campaign-challenge/campaign-challenge.component';
import { GeneralCloseWindowComponent } from './modules/public/general-close-window/general-close-window.component';
import { PublicCampaignFormComponent } from './modules/public/required-campaign-data/public-campaign-form/public-campaign-form.component';
import {
    PublicCampaignDetailPreDisconnectComponent,
    RequiredCampaignDataComponent,
} from './modules/public/required-campaign-data/required-campaign-data.component';

registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent,
        OldDiscordAuthComponent,
        CampaignChallengeComponent,
        GeneralCloseWindowComponent,
        RequiredCampaignDataComponent,
        PublicCampaignFormComponent,
        PublicCampaignDetailPreDisconnectComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        AngularFireAnalyticsModule,
        AngularFireModule.initializeApp(environment.firebase),
        NgxStripeModule.forRoot(environment.stripe.token),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAnalytics(() => getAnalytics()),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        provideStorage(() => getStorage()),
    ],
    providers: [
        { provide: NZ_I18N, useValue: en_US },
        {
            provide: NZ_DATE_LOCALE,
            useValue: enUS,
        },
        ScreenTrackingService,
        UserTrackingService,
        { provide: RouteReuseStrategy, useClass: ReuseService },
        { provide: NZ_CONFIG, useFactory: nzConfigFactory, deps: [Injector, ComponentFactoryResolver] },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
