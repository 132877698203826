<section class="service-request-box general-modal-box">
    <app-general-modal-header
        [title]="'Advanced Service Request'"
        [isBrandModal]="true"
        (cancel)="modalRef.triggerCancel()"
    ></app-general-modal-header>

    <div class="content">
        <h3>What are you looking for?</h3>
        <nz-select nzSize="large" nzPlaceHolder="Select topic *" [(ngModel)]="selectedTopic">
            <nz-option *ngFor="let topic of topicList" [nzLabel]="topic" [nzValue]="topic"></nz-option>
        </nz-select>

        <textarea nz-input placeholder="Other note (optional)" [(ngModel)]="notes"></textarea>
    </div>

    <footer class="d-flex justify-content-center align-items-center">
        <button
            nz-button
            nzType="primary"
            nzShape="round"
            [disabled]="!selectedTopic"
            [nzLoading]="submitting"
            (click)="modalRef.triggerOk()"
        >
            SUBMIT
        </button>
    </footer>
</section>
