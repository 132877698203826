import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { catchError, retryWhen, scan, take, tap, delay, switchMap, timeout } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '@src/environments/environment';

interface IRequestOptions {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    api?: string;
    data?: any;
    params?: HttpParams;
}

export interface ResponseError {
    status: HttpErrorResponse['status'];
    body: {
        code: number;
        message: string;
    };
}

export function retryWithDelay<T>(delayTime: number, count = 1): MonoTypeOperatorFunction<T> {
    return input =>
        input.pipe(
            retryWhen(errors =>
                errors.pipe(
                    scan((acc, error) => ({ count: acc.count + 1, error }), {
                        count: 0,
                        error: undefined as any,
                    }),
                    tap(current => {
                        if (current.count > count) {
                            throw current.error;
                        }
                    }),
                    delay(delayTime)
                )
            )
        );
}

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(private http: HttpClient, private auth: AngularFireAuth) {}

    getHost(apiString: string = '') {
        switch (apiString) {
            case 'api-shop':
                return 'https://campaign-shop.lifo.ai';
            case 'data-api':
                return environment.dataApiUrl;
            case 'data-landing':
                return environment.landingApiUrl;
            case 'data-shop':
            case 'discover':
            case 'shop-api':
                return environment.shopApiService;
            case 'referral-api':
                return environment.referralApiUrl;
            case 'admin-api':
                return environment.adminApiUrl;
            default:
                return environment.campaignService;
        }
    }

    sendRequest$<T>(requestOptions: IRequestOptions, retryTime = 0): Observable<T> {
        const host = this.getHost(requestOptions.api);
        return this.auth.idToken.pipe(
            switchMap(token => {
                const httpOptions = {
                    headers: new HttpHeaders({
                        Authorization: token ?? '',
                    }),
                };

                const url = `${host ?? environment.campaignService}${requestOptions.url}`;

                switch (requestOptions.method) {
                    case 'GET':
                        return this.http.get<T>(url, httpOptions);
                    case 'DELETE':
                        return this.http.delete<T>(url, httpOptions);
                    case 'POST':
                        return this.http.post<T>(url, requestOptions.data, httpOptions);
                    case 'PUT':
                        return this.http.put<T>(url, requestOptions.data, httpOptions);
                }
            }),
            retryWithDelay(1000, retryTime),
            catchError(error => this.handleError(error))
        );
    }

    sendUnauthorizedRequest$<T>(requestOptions: IRequestOptions, retryTime = 0): Observable<T> {
        const host = this.getHost(requestOptions.api);

        const url = `${host ?? environment.campaignService}${requestOptions.url}`;

        return this.http
            .request<T>(requestOptions.method, url, {
                body: requestOptions.data ? requestOptions.data : null,
                params: requestOptions.params ?? {},
            })
            .pipe(
                retryWithDelay(1000, retryTime),
                catchError(error => this.handleError(error))
            );
    }

    sendRequest<T>(requestOptions: IRequestOptions, retryTime = 0): Promise<T> {
        return this.sendRequest$<T>(requestOptions, retryTime).pipe(take(1)).toPromise();
    }

    sendUnauthorizedRequest<T>(requestOptions: IRequestOptions, retryTime = 0): Promise<T> {
        return this.sendUnauthorizedRequest$<T>(requestOptions, retryTime).pipe(take(1)).toPromise();
    }

    private handleError(error: HttpErrorResponse) {
        return throwError({
            status: error.status,
            body:
                error.status < 500
                    ? error.error
                    : {
                          code: error.error?.code,
                          message: 'Oops! Something went wrong. Please try again.',
                      },
        });
    }
}
