import { JSEncrypt } from 'jsencrypt';

const PUB_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqUXC/J/V4BueF3te7D5o
lJTdyMkc1/UtPNLEZUyrB4MEDoiLuhfoLSVm8liCQAo+j5tFDEzo3SELdUqn3w1B
Y56iDRITvX1mbo5F3KRlW9wnxAvUgEzA5LkJ1Zptc+CiKHvnXy3zN0QgthIfH3L+
yXrK4/1YdH526SkdFiDX3lRIknnvaEMAjQ5hqJVNjwIKaXbfoQgwvBEwMHIOqgkb
0NU3TonbTCqHu88ypq0npiUG11Na39MU8KA4VG5TfZQZk5DYrfcHVm8dWRlV7a3Z
2xsjfT2T+NP0Mo9u3KtqHKOphObaMkos5VGwNh8MWeU+VW8C5zwngg7oIZFSxPKF
bwIDAQAB
-----END PUBLIC KEY-----
`;

export function encrypt(data: string) {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(PUB_KEY);
    const res = jsEncrypt.encrypt(data);
    return btoa(res as string);
}
