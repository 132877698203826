import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-service-request-modal',
    templateUrl: './service-request-modal.component.html',
    styleUrls: ['./service-request-modal.component.less'],
})
export class ServiceRequestModalComponent implements OnInit {
    @Input() defaultSelect: string;

    submitting = false;

    selectedTopic: string;

    notes: string;

    topicList = [
        'Advanced report',
        'Advanced product customization',
        'Change pricing plan',
        'Token-based campaigns',
        'Affiliate marketing',
        'Other',
    ];

    constructor(public modalRef: NzModalRef) {}

    ngOnInit() {
        this.selectedTopic = this.defaultSelect;
    }
}
