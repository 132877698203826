import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { RequestService } from '@src/app/services/request.service';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { LOCAL_STORAGE_KEY_ENUM } from '../shared/types/general.type';
import { CommonService } from './common.service';
import { UtilService } from './util.service';

export enum SocialMedia {
    DISCORD = 'discord',
    TWITTER = 'twitter',
    TELEGRAM = 'telegram',
    YOUTUBE = 'youtube',
    REDDIT = 'reddit',
    EMAIL = 'email',
}

export interface AuthUserInfoByCode {
    principalName: string;
    name: string;
    originalResponse: {
        user: {
            id: string;
            username: string;
            avatar: string;
            avatar_decoration: any;
            discriminator: string;
            public_flags: number;
        };
    };
    additionalParameters: { [key: string]: any };
}

@Injectable({
    providedIn: 'root',
})
export class SocialMediaConnectService {
    socialMediaWindow$: any;

    discordUserInfo$ = new BehaviorSubject<{ name: string; principalName: string }>(null);
    twitterUserInfo$ = new BehaviorSubject<{ name: string; principalName: string }>(null);
    telegramUserInfo$ = new BehaviorSubject<{
        auth_date?: number;
        first_name?: string;
        last_name?: string;
        username: string;
        id: number;
        hash?: string;
    }>(null);

    // 1. 用于GA 记录social media connect 的相关信息。有需要记录GA Event的页面，比如campaign detail页面，可以subscribe这个observable
    // 2. 社媒关联后的一些回调，可以订阅这个observable
    socialMediaConnected$ = new BehaviorSubject<{ type: SocialMedia; name: string; principalName: string }>(null);

    constructor(private requestService: RequestService, private utilService: UtilService, private commonService: CommonService) {
        this.handleExistingSocialMediaInfo();
    }

    /**
     *
     * @param type {Optional}
     * @desc 如果不传 type，就清除所有的 social media info
     */
    clearSocialMediaInfo(type?: SocialMedia) {
        this.clearStorageSocialMediaInfo(type);

        switch (type) {
            case SocialMedia.DISCORD:
                this.discordUserInfo$.next(null);
                break;
            case SocialMedia.TWITTER:
                this.twitterUserInfo$.next(null);
                break;
            case SocialMedia.TELEGRAM:
                this.telegramUserInfo$.next(null);
                break;
            default:
                this.clearSocialMediaInfo(SocialMedia.DISCORD);
                this.clearSocialMediaInfo(SocialMedia.TWITTER);
                this.clearSocialMediaInfo(SocialMedia.TELEGRAM);
        }
    }

    /**
     *
     * @description 清除local storage中的social media info
     */
    clearStorageSocialMediaInfo(type?: SocialMedia) {
        switch (type) {
            case SocialMedia.DISCORD:
                window.localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.DISCORD_USER_INFO);
                break;
            case SocialMedia.TWITTER:
                window.localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.TWITTER_USER_INFO);
                break;
            case SocialMedia.TELEGRAM:
                window.localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.TELEGRAM_USER_INFO);
                break;
            default:
                this.clearStorageSocialMediaInfo(SocialMedia.DISCORD);
                this.clearStorageSocialMediaInfo(SocialMedia.TWITTER);
                this.clearStorageSocialMediaInfo(SocialMedia.TELEGRAM);
        }
    }

    handleExistingSocialMediaInfo() {
        // get social media info from local storage
        const discordUserInfo = window.localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.DISCORD_USER_INFO);
        const twitterUserInfo = window.localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.TWITTER_USER_INFO);
        const telegramUserInfo = window.localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.TELEGRAM_USER_INFO);

        if (discordUserInfo) {
            this.discordUserInfo$.next(JSON.parse(discordUserInfo));
        }

        if (twitterUserInfo) {
            this.twitterUserInfo$.next(JSON.parse(twitterUserInfo));
        }

        if (telegramUserInfo) {
            this.telegramUserInfo$.next(JSON.parse(telegramUserInfo));
        }
    }

    /**
     * @param callBack  只用于B端通过web端授权
     */
    async connectDiscordOrTwitter(
        type: SocialMedia.DISCORD | SocialMedia.TWITTER,
        params: {
            backPageUrl: string;
            projectId?: number;
            walletAddress?: string; // is optional for admin page
            redirectUrl?: string;
        }
    ) {
        window.localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.SOCIAL_MEDIA_TYPE, type);

        const baseHost = `${location.protocol}//${location.host}`;
        const currentUrl = `public/completed-window/${type}`;
        const redirectUriParam = `redirect_uri=${baseHost}/${params?.redirectUrl || currentUrl}`;
        const walletAddressParam = `${params.walletAddress ? `&walletAddress=${params.walletAddress}` : ''}`;
        const backPageUrlParam = `&backPageUrl=${encodeURIComponent(params.backPageUrl)}`;
        const projectIdParam = `${params.projectId ? `&project_id=${params.projectId}` : ''}`;
        // eslint-disable-next-line max-len
        const authUrl = `${environment.dataApiUrl}/oauth2/oauth2/authorization/${type}?${redirectUriParam}${walletAddressParam}${backPageUrlParam}${projectIdParam}`;

        this.utilService.popupWindow(authUrl, type);
        this.socialMediaWindow$?.unsubscribe();

        this.socialMediaWindow$ = fromEvent<StorageEvent>(window, 'storage')
            .pipe(
                filter(e => e.key === LOCAL_STORAGE_KEY_ENUM.SOCIAL_MEDIA_AUTH && !!e.newValue),
                take(1)
            )
            .subscribe(e => {
                const data: { socialMedia: SocialMedia; queryParams: Params } = JSON.parse(e.newValue);
                this.getSocialMediaInfo(type, data.queryParams);
                window.localStorage.removeItem(LOCAL_STORAGE_KEY_ENUM.SOCIAL_MEDIA_AUTH);
            });
    }

    connectTelegram() {
        if (this.commonService.isMobile()) {
            return this.commonService.error('Telegram authentication is not supported on mobile devices. Please use a desktop browser.');
        }

        try {
            // @ts-ignore
            window.Telegram.Login.auth({ bot_id: environment.production ? 5570182086 : 5416032694, request_access: 'write' }, data => {
                if (!data) {
                    // authorization failed
                    return;
                }

                // Here you would want to validate data like described there
                // https://core.telegram.org/widgets/login#checking-authorization
                if (!data.username) {
                    data.username = `${data.first_name} ${data.last_name}`;
                }

                window.localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.SOCIAL_MEDIA_TYPE, SocialMedia.TELEGRAM);
                window.localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.TELEGRAM_USER_INFO, JSON.stringify(data));

                this.telegramUserInfo$.next(data);
                this.socialMediaConnected$.next({
                    type: SocialMedia.TELEGRAM,
                    name: `${data.first_name} ${data.last_name}`,
                    principalName: data.username,
                });
            });
        } catch (err) {
            // TODO:
            // this.messageService.error('Telegram is not available now. Please try again later.');
        }
    }

    getSocialMediaInfo(type: SocialMedia.DISCORD | SocialMedia.TWITTER, queryParams: any): any {
        return this.getUserInfoByParams(type, queryParams).then((res: { name: string; principalName: string; [key: string]: any }) => {
            if (type === SocialMedia.TWITTER) {
                this.twitterUserInfo$.next(res);
                window.localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.TWITTER_USER_INFO, JSON.stringify(res));
            }
            if (type === SocialMedia.DISCORD) {
                this.discordUserInfo$.next(res);
                window.localStorage.setItem(LOCAL_STORAGE_KEY_ENUM.DISCORD_USER_INFO, JSON.stringify(res));
            }

            this.socialMediaConnected$.next({ type, name: res.name, principalName: res.principalName });

            return res;
        });
    }

    getUserInfoByParams(type: any, queryParams: Params) {
        const queryParamsStr = Object.keys(queryParams)
            .map(key => `${key}=${queryParams[key]}`)
            .join('&');

        return this.requestService.sendRequest<AuthUserInfoByCode>({
            api: 'data-api',
            url: `/oauth2/login/oauth2/code/${type}?${queryParamsStr}`,
            method: 'GET',
        });
    }

    getTwitterUserInfo(queryParams: Params) {
        return this.requestService.sendRequest({
            api: 'data-api',
            url: '/oauth2/twitter/oauth1.0a/access_token',
            method: 'POST',
            data: queryParams,
        });
    }
}
