import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { Web3Provider } from '@ethersproject/providers';
import { RequestService } from '@src/app/services/request.service';
import { SocialMediaConnectService } from '@src/app/services/socialmedia-connect.service';
import { NFTPlatform } from '@src/app/shared/components/connect-nft-modal/connect-nft-modal.component';
import { WalletEnum, WalletsSupportSwitchChain } from '@src/app/shared/types/wallet-chain.model';
import { getChainInfoByChain } from '@src/app/shared/utils/wallet-chain.util';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { CampaignStatus, SocialMedia } from '../../admin/campaign/campaign.model';
import { CreateCampaign } from '../../main/advanced-customization/advanced-customization-state.service';
import { TransactionStatusEnum } from '../../main/advanced-customization/campaign-detail/campaign-detail.service';
import { FrontEndProjectCampaign } from '../../main/advanced-customization/campaign-list/campaign-list.component';
import { NidavellirService } from '../../main/nidavellir.service';
import { WalletStatusService } from '../../main/wallet-status.service';
import { W3W_CAMPAIGN_CUSTOM_EVENT_ENUM } from '../campaign-challenge/campaign-challenge.service';

export interface MocaToken {
    tribe: string;
    image: string;
    wallet_address: string;
    token_id: string;
    name: string;
    xp_point: number;
}

@Injectable()
export class RequiredCampaignDataService {
    campaignId: number;
    referrer: string; // 通过share campaign 链接进入的用户会在 Url 中带有 referrer 参数
    campaignInfo: FrontEndProjectCampaign;
    campaignInfo$ = new BehaviorSubject<FrontEndProjectCampaign>(null);
    outsideWebsiteUrl: string = sessionStorage.getItem('outsideWebsiteUrl');

    subscriptions = {
        socialMediaWindow: null,
        pageLeave: new Subject(),
    };

    combinedWalletList$ = new BehaviorSubject<
        {
            walletType: NFTPlatform;
            walletAddr: string;
            encryptedWalletAddr: string;
        }[]
    >([]);

    get discordUserInfo() {
        return this.discordUserInfo$.getValue();
    }

    get twitterUserInfo() {
        return this.twitterUserInfo$.getValue();
    }

    get telegramUserInfo() {
        return this.telegramUserInfo$.getValue();
    }

    get discordUserInfo$() {
        return this.socialMediaConnectService.discordUserInfo$;
    }

    get twitterUserInfo$() {
        return this.socialMediaConnectService.twitterUserInfo$;
    }

    get telegramUserInfo$() {
        return this.socialMediaConnectService.telegramUserInfo$;
    }

    constructor(
        private walletStatusService: WalletStatusService,
        private socialMediaConnectService: SocialMediaConnectService,
        private nidavellirService: NidavellirService,
        private requestService: RequestService
    ) {}

    getSocialMediaInfo(type: any, queryParams: any) {
        if (type === SocialMedia.TWITTER) {
            return this.socialMediaConnectService.getTwitterUserInfo(queryParams).then((res: any) => {
                window.localStorage.setItem('twitterUserInfo', JSON.stringify(res));
                this.twitterUserInfo$.next(res);
            });
        }

        if (type === SocialMedia.DISCORD) {
            return this.socialMediaConnectService.getUserInfoByParams(type, queryParams).then((res: any) => {
                window.localStorage.setItem('discordUserInfo', JSON.stringify(res));
                this.discordUserInfo$.next(res);
            });
        }

        return null;
    }

    connectWallet(walletList: NFTPlatform[] = null, triggerType: 'header_account' | 'connect_wallet' | 'combine_wallet' = null) {
        this.walletStatusService.connectWallet(
            { type: 'customer' },
            {
                enabledNFTPlatforms: walletList || this.campaignInfo.supported_wallet,
                triggerType,
            },
            () => this.switchChain()
        );
    }

    async switchChain() {
        if (!WalletsSupportSwitchChain.includes(this.walletStatusService.walletType)) {
            return null;
        }

        let provider: Web3Provider;
        const requiredChainId = getChainInfoByChain(this.campaignInfo.chain, 'chainId');
        const infoUsedToSendRequest = getChainInfoByChain(this.campaignInfo.chain, 'infoUsedConnectWallet');

        if (this.walletStatusService.walletType === NFTPlatform.META_MASK) {
            // @ts-ignore
            provider = new Web3Provider(window?.ethereum?.providers?.find(p => p.isMetaMask) || window.ethereum);
        }

        if ([WalletEnum.TRUST_WALLET].includes(this.walletStatusService.walletType)) {
            provider = new Web3Provider(window.ethereum);
        }

        if (this.walletStatusService.walletType === NFTPlatform.COINBASE) {
            const coinbaseWallet = new CoinbaseWalletSDK({
                appName: 'Lifo INC',
                darkMode: false,
            });

            const providerCoinbase = coinbaseWallet.makeWeb3Provider('https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a', 1);
            provider = new Web3Provider(providerCoinbase);
        }

        try {
            await provider.send('wallet_switchEthereumChain', [{ chainId: requiredChainId }]);

            this.walletStatusService.selectedWalletChainId = requiredChainId;
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if ([4902, -32603].includes(switchError.code)) {
                try {
                    await provider.send('wallet_addEthereumChain', [infoUsedToSendRequest]);
                    this.walletStatusService.selectedWalletChainId = requiredChainId;
                } catch (addError) {
                    // this.postMessageToParentWindow(W3W_CAMPAIGN_CUSTOM_EVENT_ENUM.ADD_CHAIN_FAILED);
                }
            } else {
                // this.postMessageToParentWindow(W3W_CAMPAIGN_CUSTOM_EVENT_ENUM.SWITCH_CHAIN_FAILED);
            }
        }

        return provider;
    }

    async setWalletChain() {
        const connectedWalletIsSupported = WalletsSupportSwitchChain.includes(this.walletStatusService.walletType);

        if (connectedWalletIsSupported && this.walletStatusService.walletAddress && this.walletStatusService.walletType) {
            // Set walletChain
            let provider: any = window.ethereum;
            if (this.walletStatusService.walletType === NFTPlatform.META_MASK) {
                // @ts-ignore
                provider = window?.ethereum?.providers?.find(p => p.isMetaMask) || window.ethereum;
                try {
                    this.walletStatusService.selectedWalletChainId = await provider?.request({ method: 'eth_chainId' });
                } catch (err) {
                    console.warn(err);
                }
            }

            if (this.walletStatusService.walletType === NFTPlatform.TRUST_WALLET) {
                try {
                    this.walletStatusService.selectedWalletChainId = await provider?.request({ method: 'eth_chainId' });
                } catch (err) {
                    console.warn(err);
                }
            }

            if (this.walletStatusService.walletType === NFTPlatform.COINBASE) {
                const coinbaseWallet = new CoinbaseWalletSDK({
                    appName: 'Lifo INC',
                    darkMode: false,
                });

                const providerCoinbase = coinbaseWallet.makeWeb3Provider(
                    'https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a',
                    1
                );
                provider = new Web3Provider(providerCoinbase);
                try {
                    this.walletStatusService.selectedWalletChainId = await provider?.send('eth_chainId', []);
                } catch (err) {
                    console.warn(err);
                }
            }
        }
    }

    joinCampaign(
        campaignForm: FormGroup,
        duplicateSocialMedias: Set<SocialMedia>,
        selectedCombinedWallets: { walletAddr: string; walletType: WalletEnum; encryptedWalletAddr: string }[],
        customFields: { challenge_id: any; [key: string]: any }[]
    ) {
        campaignForm.get('wallet_type').setValue(this.walletStatusService.walletType);
        campaignForm.get('wallet_address').setValue(this.walletStatusService.walletAddress);

        if (selectedCombinedWallets.length) {
            campaignForm.get('wallet_list').setValue(
                selectedCombinedWallets.map(item => ({
                    wallet_address: item.walletAddr,
                    wallet_type: item.walletType,
                    encrypt_wallet_address: item.encryptedWalletAddr,
                }))
            );
        } else {
            campaignForm.get('wallet_list').setValue([]);
        }

        const formRawData = campaignForm.getRawValue();
        const data: CreateCampaign = {
            campaign_id: formRawData.campaign_id,
            wallet_type: formRawData.wallet_type,
            wallet_address: formRawData.wallet_address,
            discord_id: formRawData.discord_id,
            twitter_id: formRawData.twitter_id,
            telegram_id: formRawData.telegram_id,
            email: formRawData.email,
            recaptcha_result: formRawData.recaptcha_result,
            wallet_list: formRawData.wallet_list,
            custom_input: customFields?.map(item => ({
                value: campaignForm.get(`custom_input_${item.challenge_id}`).value,
                challenge_id: item.challenge_id,
            })),
        };

        return this.nidavellirService
            .subscribeWalletAddressUpdate({
                chain: 'ETH',
                walletList: selectedCombinedWallets,
            })
            .then(() => this.nidavellirService.entryNftCampaign(data))
            .then(() => {
                this.postMessageToParentWindow(W3W_CAMPAIGN_CUSTOM_EVENT_ENUM.CAMPAIGN_JOIN_SUCCESS);
                this.nidavellirService
                    .getUserInfo(this.walletStatusService.walletAddress)
                    .then((userInfo: any) => this.walletStatusService.userInfo$.next(userInfo));
                return true;
            })
            .catch(err => {
                if (err.status === 400 && err.body?.details) {
                    err.body?.details.forEach((item: { contact_type: SocialMedia; contact: string; is_duplicate: boolean }) => {
                        duplicateSocialMedias?.add(item.contact_type);
                    });
                } else {
                    this.postMessageToParentWindow(W3W_CAMPAIGN_CUSTOM_EVENT_ENUM.CAMPAIGN_JOIN_FAILED);
                }

                if (dayjs(this.campaignInfo.start_date).isAfter(dayjs())) {
                    this.campaignInfo.status = CampaignStatus.UPCOMING;
                } else if (dayjs(this.campaignInfo.end_date).isBefore(dayjs())) {
                    this.campaignInfo.status = CampaignStatus.ENDED;
                }
                return false;
            });
    }

    async connectDiscordOrTwitter(type: SocialMedia.DISCORD | SocialMedia.TWITTER, projectId: number) {
        this.socialMediaConnectService.connectDiscordOrTwitter(type, {
            walletAddress: this.walletStatusService.walletAddress,
            backPageUrl: this.outsideWebsiteUrl,
            projectId,
        });
    }

    connectTelegram() {
        this.socialMediaConnectService.connectTelegram();
    }

    createReferralAndClaim(data) {
        return this.nidavellirService.createReferralAndClaim<{
            code: number;
            msg: string;
            data: { status?: TransactionStatusEnum; txId?: string };
        }>(data);
    }

    postMessageToParentWindow(type: W3W_CAMPAIGN_CUSTOM_EVENT_ENUM, additionalData?: Object) {
        try {
            window.parent.postMessage(
                {
                    type,
                    data: {
                        campaignId: this.campaignId,
                        walletAddress: this.walletStatusService.walletAddress,
                        ...(additionalData || {}),
                    },
                },
                '*'
            );
        } catch (err) {
            console.log(err);
            console.error('No parent window');
        }
    }

    getColdWalletsAndTokensMocaXP(walletAddress: string) {
        return this.requestService.sendRequest<{
            tokens: { tribe: string; token_id: string; image: string; name: string; wallet_address: string; xp_point: number }[];
            wallet_address: string;
            wallet_list: { wallet_address: string; wallet_type: 'cold_wallet' }[];
        }>({
            method: 'GET',
            url: `/customer/campaign/wallet_token?address=${walletAddress}`,
            api: 'admin-api',
        });
    }

    getAdditionalCampaignInfoOfUser(walletAddress: string, campaignId: number) {
        return this.requestService.sendRequest<{
            campaign_id: string;
            campaign_name: string;
            wallet_address: string;
            is_joined: boolean;
            joined_at: string;
            is_selected: boolean;
            tokens: (MocaToken & { is_selected: boolean })[];
        }>({
            method: 'GET',
            url: `/customer/campaign/entry?address=${walletAddress}&campaign_id=${campaignId}`,
            api: 'admin-api',
        });
    }
}
