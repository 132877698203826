import { get } from 'lodash';
import { ChainEnum, ChainInfo, ChainInfoMap, WalletEnum } from '../types/wallet-chain.model';

export function getWalletImg(wallet: WalletEnum) {
    switch (wallet) {
        case WalletEnum.WALLET_CONNECT:
            return '/assets/imgs/wc.png';
        case WalletEnum.DAPPER:
            return '/assets/svg/dapper.svg';
        case WalletEnum.META_MASK:
            return '/assets/imgs/mm.png';
        case WalletEnum.COINBASE:
            return '/assets/imgs/home/coinbase.png';
        case WalletEnum.PHANTOM:
            return '/assets/svg/phantom.svg';
        case WalletEnum.BLOCTO:
            // eslint-disable-next-line max-len
            return 'https://www.gitbook.com/cdn-cgi/image/width=40,height=40,fit=contain,dpr=2,format=auto/https%3A%2F%2F4111966839-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-legacy-files%2Fo%2Fspaces%252F-MFJEAgz-LrhDYkRm4sv%252Favatar-1600919464132.png%3Fgeneration%3D1600919464422284%26alt%3Dmedia';
        case WalletEnum.UNSTOPPABLE_DOMAINS:
            return '/assets/svg/unstoppable-domains.svg';
        case WalletEnum.NEAR:
            return '/assets/imgs/home/near-wallet.png';
        case WalletEnum.TRUST_WALLET:
            return '/assets/svg/trust-wallet.svg';
        case WalletEnum.PARTICLE:
            return '/assets/imgs/home/particle.png';
    }
}

export function getScanUrl(network: ChainEnum, address: string) {
    switch (network) {
        case ChainEnum.ETHEREUM:
            return `https://etherscan.io/address/${address}`;
        case ChainEnum.SOLANA:
            return `https://explorer.solana.com/address/${address}`;
        case ChainEnum.POLYGON:
            return `https://polygonscan.com/address/${address}`;
        default:
            return `https://etherscan.io/address/${address}`;
    }
}

export function getChainInfoByChain(chain: ChainEnum, infoField: keyof ChainInfo | keyof ChainInfo['infoUsedConnectWallet']) {
    const level1Value = get(ChainInfoMap.get(chain), infoField);
    if (level1Value) {
        return level1Value;
    }

    const level2Value = get(ChainInfoMap.get(chain), `infoUsedConnectWallet.${infoField}`);
    if (level2Value) {
        return level2Value;
    }

    return null;
}

export function getChainByChainId(chainId: number | string) {
    if (typeof chainId === 'number') {
        switch (chainId) {
            case 1:
                return ChainEnum.ETHEREUM;
            // GK HIDE BNB/BSC
            // case 56:
            //     return ChainEnum.BNB;
            case 137:
                return ChainEnum.POLYGON;
        }
    }

    if (typeof chainId === 'string') {
        switch (chainId) {
            case '0x1':
                return ChainEnum.ETHEREUM;
            // GK HIDE BNB/BSC
            // case '0x38':
            //     return ChainEnum.BNB;
            case '0x89':
                return ChainEnum.POLYGON;
        }
    }

    return null;
}
