import { FormControl } from '@angular/forms';
import { ImageInfo } from '@modules/main/advanced-customization/merch-product-detail.service';
import { deprecate } from 'util';
export interface CrowdFundingDetail {
    created_at: string;
    created_by: string;
    end_date: string;
    id: number;
    item_sold: number;
    minimal_quantity: number;
    notes: string;
    order_id: number;
    start_date: string;
    updated_at: string;
    hide_from_store: boolean;
    product_id: any;
    link: string;
}

export interface IStandardProductListItem {
    created_at: string;
    customizable_packaging: string[];
    id: number;
    image_url: string;
    label: string;
    listing_status: string;
    product_name: string;
    description: string;
    file: { url: string; path: string; type: 'image' | 'video'; name?: string };
    sales_price: number;
    unit_cost: number;
    min_unit_cost: number;
    selling_tags: string[];
    label_list: string[];
    personal_status: {
        order_status: string;
        thumb_status: string;
    };
    product_attributes: string[];
}

export interface IMerchProductListItem {
    creater_at: string;
    catagory: string;
    sub_category: string;
    id: number;
    image_url: string;
    listing_status: string;
    product_name: string;
}

export interface IStandardProductDetails {
    airtable_key: string;
    body_html: string;
    category: string;
    created_at: string;
    created_by: null;
    customizable_packaging: string[];
    depth_in_cm: number;
    est_ordering_days: number;
    height_in_cm: number;
    id: number;
    images: {
        src: string;
        path: string;
    }[];
    labels: null;
    listing_status: string;
    moq: number;
    options: {
        name: string;
        values: string[];
    }[];
    product_name: string;
    product_sourcing_urls: string[];
    sourcing_record: string;
    sub_category: string;
    updated_at: string;
    updated_by: string;
    variants: IStandardProductVariant[];
    weight_in_gram: number;
    width_in_cm: number;
    selling_tags: string[];
    product_attributes?: string[];
    unit_cost: number;
    min_unit_cost: number;
    sales_price: number;
    is_listed: boolean;
}

export interface IMerchProductDetails {
    airtable_key: string;
    body_html: string;
    background_height: number;
    background_width: number;
    category: string;
    created_at: string;
    id: number;
    images: {
        src: string;
        path: string;
    }[];
    listing_status: string;
    logo_height: number;
    logo_left: number;
    logo_top: number;
    logo_width: number;
    moq: number;
    product_name: string;
    product_sourcing_urls: string[];
    sub_category: string;
    unit_cost: number;
    unit_cost_currency: string;
    updated_at: string;
    updated_by: string;
    variants: IMerchProductVariant[];
    version: number;
    is_color_blocking: boolean;
    front_image: string;
    color_blocking_settings: {
        color_settings: {
            name: string;
            class: string;
            color?: string;
            colorName?: string;
        }[];
        color_svg: string;
        color_svg_back: string;
    };
    front_images: { path: string; src: string }[];
    size_options: { moq: number; option: string }[];
}

export interface IStandardProductVariant {
    created_at: string;
    created_by: string;
    depth_in_cm: number;
    depth_in_inch: number;
    height_in_cm: number;
    height_in_inch: number;
    id: number;
    image: {
        path: string;
        src: string;
    };
    moq: number;
    option1: string;
    option2: string;
    option3: string;
    product_id: number;
    sku_sourcing_urls: string[];
    title: string;
    unit_cost: number;
    updated_at: string;
    updated_by: string;
    weight_in_gram: number;
    weight_in_lb: number;
    width_in_cm: number;
    width_in_inch: number;
    sales_price: number;
    quantity?: number;
}

export interface IMerchProductVariant {
    created_at: string;
    created_by: string;
    id: number;
    image: {
        src: string;
        path: string;
    };
    product_id: number;
    title: string;
    updated_at: string;
    updated_by: string;
}

export interface IStandardProductVariantToOrder {
    id: number;
    image: {
        path: string;
        src: string;
    };
    moq: number;
    title: string;
    amount: FormControl;
    unit_cost: number;
    sales_price: FormControl;
}

export interface IStandardProductOption {
    name: string;
    values: string[];
}

export interface IProductPackaging {
    product_id: number;
    variants: {
        id: number;
        amount: number;
        price: number;
    }[];
    packaging: {
        type: string;
        image_url: string;
    }[];
}

export interface IPrintOnDemand {
    category_id: number;
    template_id: number;
    variants: IPrintOnDemandVariant[];
}

export interface IPrintOnDemandVariant {
    option: string;
    amount: number;
    price: number;
    image_url: string;
    shop_in_shop: boolean;
    quantity?: number;
}

export interface ICreateProduct {
    merch_id: number;
    merch_variant_id: number;
    logos: any[];
    color_svg?: string;
    color_svg_back: string;
    price: number;
    order_detail: {
        option: string;
        quantity: number;
    }[];
    color_settings?: any;
}

export interface SizeQuantity {
    size: string;
    quantity: FormControl;
    moq: number;
}

export interface ProductInCartFromAPI {
    cart_json: ProductInCart;
    created_at: string;
    id: number;
    uid: string;
    updated_at: string;
    active: boolean;
    listing_status: 'active' | 'inactive';
}

export interface AdvancedCustomization {
    images: { name: string; path: string; src: string; tag?: string }[];
    logo_images: { name: string; path: string; src: string; tag?: string }[];
    options: { name: string; value: string; free_input?: string; color?: string; id?: number }[];
    customization_detail: any;
    link?: string;
    description?: string;
}

export interface ProductInCart {
    order_id?: number;
    id?: number;
    uid?: string;
    product_id: any;
    image_url: string;
    title: string;
    est_ordering_days: number;
    variants: {
        option: string;
        variant_id?: any;
        quantity: number;
        unit_cost: number;
    }[];
    photo_shoot_required?: boolean;
    is_sample?: boolean;
    packaging?: { type: string; image_url: string; product_id?: any }[];
    type: 'merch_product' | 'standard_product' | 'new_product' | 'advanced_customization_product' | 'mass_production' | 'pod_product';
    data: ICreateProduct | IProductPackaging | AdvancedCustomization;
    instruction?: string;
    category_id?: any;
    template_id?: number;
}

export interface FileInfo {
    name: string;
    path: string;
    url: string;
    uploading: boolean;
    isImage?: boolean;
    tag?: string;
}

export interface ColorItem {
    id: any;
    is_display: boolean;
    name: string;
    value: string;
}

export interface AdvancedCustomizedCategory {
    category: string;
    created_at: string;
    created_by: string;
    customization_detail: any;
    est_ordering_days: number;
    id: number;
    image: string;
    label: string;
    moq: number;
    options: {
        name: string;
        values: string[];
    }[];
    quote_cost: number;
    shipping_cost: number;
    size: any;
    sub_category: {
        name: string;
        values: {
            category_id: number;
            name: string;
            value: string;
        }[];
    };
    print_on_demand: boolean;
    unit_cost: number;
    updated_at: string;
    updated_by: string;
    est_sample_days: number;
    max_sample_days: number;
    max_quote_cost: number;
    max_unit_cost: number;
    max_moq: number;
    max_ordering_days: number;
    preview_background: PreviewBackground;
    description?: string;
}

export interface FramePattern {
    id: any;
    name: string;
    data: ImageInfo[];
    image_url: string;
    view_id: number;
    hide_qr_info: boolean;
    hide_owner_info: boolean;
}

export interface AdvancedCustomizedTemplate {
    category_id: number;
    customization_detail: any;
    id: number;
    image_url: string;
    options: { name: string; value: string; values: string[]; free_input?: string; color?: string; id?: number }[];
    product_link: string;
    product_name: string;
    requirements: string;
    sub_category: { name?: string; value?: string };
    preview_background: PreviewBackground;
    preview_foreground: PreviewBackground;
    preview_images: PreviewBackground[];
    size_chart: { src: string; path: string; device: 'mobile' | 'desktop' }[];
    logo_templates: ImageInfo[];
    tags: string[];
    placeholder_image: { src: string; path: string };
    description?: string;
    frame_pattern: FramePattern[];
    product_images: { src: string; path: string }[];
}

export interface PreviewBackground {
    background_path: string;
    background_src: string;
    foreground_path: string;
    foreground_src: string;
    background_width: number;
    background_height: number;
    logo_width: number;
    logo_height: number;
    logo_left: number;
    logo_top: number;
    id: number;
    name: string;
}
