<section class="general-modal-box edit-brand-name-box">
    <app-general-modal-header
        [title]="'Edit Brand Name'"
        [isBrandModal]="true"
        (cancel)="modalRef.triggerCancel()"
    ></app-general-modal-header>

    <div class="label">Brand Name *</div>

    <div class="content">
        <input nz-input type="text" placeholder="Enter your brand name" [formControl]="brandName" style="padding: 9px 11px" />
    </div>
    <div *ngIf="isAlreadyExists" style="color: #f44336; line-height: 22px">Brand name already exists</div>

    <div class="checkbox">
        <label nz-checkbox [(ngModel)]="isUpdateProjectKey">Update my existing page URL with {{locationOrigin}}/main/{{projectName}}</label>
    </div>
    <div *ngIf="isUpdateProjectKey" class="reminder-info">
        <span nz-icon nzType="info-circle" nzTheme="outline"></span>
        Reminder: the URL to your existing campaigns will also be updated
    </div>

    <footer class="d-flex justify-content-center align-items-center footer-btns">
        <button nz-button nzShape="round" (click)="modalRef.triggerCancel()" class="cancel-btn">CANCEL</button>
        <button
            nz-button
            nzType="primary"
            nzShape="round"
            [nzLoading]="submitting"
            [disabled]="!brandName.value || validatingProjectName || isAlreadyExists"
            (click)="modalRef.triggerOk()"
        >
            CONFIRM
        </button>
    </footer>
</section>
