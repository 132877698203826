import { Injectable } from '@angular/core';
import { LifoUploadedFile } from '@src/app/services/firebase/firebase-file-upload.service';
import { RequestService } from '@src/app/services/request.service';
import { ChainEnum } from '@src/app/shared/types/wallet-chain.model';
import { getChainInfoByChain, getScanUrl } from '@utils/wallet-chain.util';
import {
    CampaignEntry,
    CommunityTableItem,
    DataUsedToCreateCampaign,
    EmailSenderInfo,
    PerformanceOverview,
    ProjectCampaign,
    SocialMedia,
} from './campaign.model';

import { Subject } from 'rxjs';

export { ChainEnum as NetworkEnum } from '@src/app/shared/types/wallet-chain.model';

interface CommunityRequestBody {
    collection_list: any[]; // 选中的contract_list中的contract
    page_index: number;
    page_size: number;
    tag: 'Current Holder' | 'Campaign Participant' | 'Blue Chip Holder' | 'Whale';
    sort_by?: string;
    sort_order?: 'asc' | 'desc' | null | string;
    type?: 'Campaign Participant';
    advanced_filters?: any;
}

interface CommunitySummaryGamify {
    active_gamer_count: number;
    blue_chip_holders: number;
    bnb_gamer_count: number;
    campaign_participants: number;
    community_member_count: number;
    dormant_gamer_count: number;
    eth_gamer_count: number;
    frequent_gamer_count: number;
    gamer_count: number;
    loyal_gamer_count: number;
    non_gamer_count: number;
    recent_active_gamer_count: number;
    super_loyal_gamer_count: number;
    whales: number;
}

export interface CampaignModalInfo {
    id: number;
    name: string;
    cohostProjectId: number;
    invitationCode: string;
    isPublic: boolean;
    isNeedTweetLink: boolean;
}

export interface PickWinnerParams {
    id: number;
    select_count: number;
    pick_method: string;
    select_wallet_ids?: number[];
    advanced_filters?: any;
    pick_config?: {
        max_win_per_wallet: number;
        stake_at_week: number;
        whitelist_name: string;
        snapshot_date: string;
    };
}

@Injectable()
export class CampaignService {
    campaignModalInfo$: Subject<CampaignModalInfo> = new Subject();

    networkList = [
        {
            name: getChainInfoByChain(ChainEnum.ETHEREUM, 'name'),
            chain: ChainEnum.ETHEREUM,
            wallets: getChainInfoByChain(ChainEnum.ETHEREUM, 'wallets'),
        },
        {
            name: getChainInfoByChain(ChainEnum.SOLANA, 'name'),
            chain: ChainEnum.SOLANA,
            wallets: getChainInfoByChain(ChainEnum.SOLANA, 'wallets'),
        },
        // GK HIDE BNB/BSC
        // {
        //     name: getChainInfoByChain(ChainEnum.BNB, 'name'),
        //     chain: ChainEnum.BNB,
        //     wallets: getChainInfoByChain(ChainEnum.BNB, 'wallets'),
        // },
        {
            name: getChainInfoByChain(ChainEnum.POLYGON, 'name'),
            chain: ChainEnum.POLYGON,
            wallets: getChainInfoByChain(ChainEnum.POLYGON, 'wallets'),
        },
    ];

    constructor(private requestService: RequestService) {}

    getScanUrl(network: ChainEnum, address: string) {
        return getScanUrl(network, address);
    }

    getSocialMediaUserUrl(socialMedia: SocialMedia, name: string) {
        switch (socialMedia) {
            case SocialMedia.DISCORD:
                return `https://discord.com/users/${name}`;
            case SocialMedia.TELEGRAM:
                return `https://t.me/${name}`;
            case SocialMedia.TWITTER:
                return `https://twitter.com/${name}`;
            case SocialMedia.REDDIT:
                return `https://www.reddit.com/user/${name}`;
            default:
                return `https://discord.com/users/${name}`;
        }
    }

    judgeIfCampaignHasEmptyTweet(campaignInfo) {
        let res = false;
        campaignInfo?.campaign_challenges?.forEach(challenge => {
            if (['retweet', 'reply_tweet', 'quote_tweet'].includes(challenge.check_type) && !challenge.challenge_input) {
                res = true;
            }
        });

        return res;
    }

    createCampaign(data: DataUsedToCreateCampaign) {
        return this.requestService.sendRequest<ProjectCampaign>({
            method: 'POST',
            url: '/brand/campaign',
            api: 'admin-api',
            data,
        });
    }

    getCampaignList() {
        return this.requestService.sendRequest<ProjectCampaign[]>({
            method: 'GET',
            url: '/brand/campaign',
            api: 'admin-api',
        });
    }

    getCampaignPerformanceOverview() {
        return this.requestService.sendRequest<PerformanceOverview>({
            method: 'GET',
            url: '/brand/campaign/performance',
            api: 'admin-api',
        });
    }

    getCampaignSignUpsOverviewV2(data: {
        page_index: number;
        page_size: number;
        sort_by: string;
        sort_order: 'asc' | 'desc' | null;
        advanced_filters: any;
    }) {
        return this.requestService.sendRequest<{ page: number; size: number; total: number; results: CampaignEntry[] }>({
            method: 'POST',
            url: '/brand/campaign/entry',
            api: 'admin-api',
            data,
        });
    }

    getCampaignSignUpsByIdV2(data: {
        campaign_id: any;
        page_index: number;
        page_size: number;
        pick_winner?: boolean;
        winner_only?: boolean;
        sort_by?: string;
        sort_order?: any;
        advanced_filters?: any;
    }) {
        return this.requestService.sendRequest<{
            page: number;
            size: number;
            total: number;
            selected_winner_count: number;
            results: CampaignEntry[];
            eligible_campaign_entry: number;
            total_campaign_entry: number;
        }>({
            method: 'POST',
            url: '/brand/campaign/entry',
            api: 'admin-api',
            data,
        });
    }

    getCampaignAllWalletByEntryId(entryId: number) {
        return this.requestService.sendRequest<CampaignEntry[]>({
            method: 'GET',
            url: `/brand/campaign/entry/all_wallet?id=${entryId}`,
            api: 'admin-api',
        });
    }

    getCampaignPerformanceById(id: any) {
        return this.requestService.sendRequest<PerformanceOverview>({
            method: 'GET',
            url: `/brand/campaign/performance?id=${id}`,
            api: 'admin-api',
        });
    }

    getCampaignTrafficAcquisitionById(id: any) {
        return this.requestService.sendRequest<PerformanceOverview>({
            method: 'GET',
            url: `/influencer/campaign/campaign_traffic_acquisition/${id}`,
            api: 'data-api',
        });
    }

    getCampaignDetail(id: any) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: `/brand/campaign/detail?id=${id}`,
            api: 'admin-api',
        });
    }

    editCampaign(data: DataUsedToCreateCampaign, campaignId: number) {
        return this.requestService.sendRequest<any>({
            method: 'PUT',
            url: '/brand/campaign',
            api: 'admin-api',
            data: {
                ...data,
                id: campaignId,
            },
        });
    }

    endCampaign(campaignId: number) {
        return this.requestService.sendRequest<any>({
            method: 'PUT',
            url: '/brand/campaign',
            api: 'admin-api',
            data: {
                id: campaignId,
                end_date: new Date(),
            },
        });
    }

    removeCampaign(campaignId: number) {
        return this.requestService.sendRequest<any>({
            method: 'DELETE',
            url: `/brand/campaign?id=${campaignId}`,
            api: 'admin-api',
        });
    }

    pickWinner(data: PickWinnerParams) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/brand/campaign/winner/pick',
            api: 'admin-api',
            data,
        });
    }

    confirmWinner(id: number) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/brand/campaign/winner/confirm',
            api: 'admin-api',
            data: { id },
        });
    }

    pushWinnerNotification(data: { campaignId: number; discordServerId: number; skipTo?: string }) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/influencer/campaign/push_winner_notification',
            api: 'data-api',
            data,
        });
    }

    exportCampaignSignUpsTable(campaignId?: number) {
        return this.requestService.sendRequest<{ download_url: string }>({
            method: 'GET',
            url: `/brand/campaign/export${campaignId ? `?id=${campaignId}` : ''}`,
            api: 'admin-api',
        });
    }

    exportCampaignSignUpsTableV2(data: { campaign_id: any; advanced_filters: any; wallet_ids: number[] }) {
        return this.requestService.sendRequest<{ download_url: string }>({
            method: 'POST',
            url: '/brand/campaign/export',
            api: 'admin-api',
            data,
        });
    }

    getCommunitySummary(data: CommunityRequestBody) {
        return this.requestService.sendRequest$<
            {
                current_holders: number;
                previous_holders: number;
                ex_holders: number;
                manual_import_count: number;
                total_holders: number;
                campaign_participants: number;
                blue_chip_holders: number;
                whales: number;
                new_campaign_participants: number;
                avg_current_holder_score: number;
                community_bluechip_holders: number;
                none_community_bluechip_holders: number;
                community_whales: number;
                none_community_whales: number;
                holder_bluechip_holders: number;
                community_member_count: number;
                total_assets_bnb: number;
                total_assets_eth: number;
                total_assets_polygon: number;
                total_blue_chip_nft: number;
                total_project_nft: number;
            } & CommunitySummaryGamify
        >({
            method: 'POST',
            url: '/brand/community/summary',
            api: 'admin-api',
            data: data || {},
        });
    }

    getGamefiInsights(data: CommunityRequestBody) {
        return this.requestService.sendRequest$<{
            community_member_count: number;
            gamer_count: number;
            non_gamer_count: number;
            bnb_gamer_count: number;
            eth_gamer_count: number;
            recent_active_gamer_count: number;
            dormant_gamer_count: number;
            active_gamer_count: number;
            loyal_gamer_count: number;
            super_loyal_gamer_count: number;
            frequent_gamer_count: number;
            diamond_hand_count: number;
            active_spender_count: number;
            dedicate_gamer_count: number;
        }>({
            method: 'POST',
            url: '/brand/community/gamefi_insight',
            api: 'admin-api',
            data: data || {},
        });
    }

    getCommunityTopHolders(data: CommunityRequestBody) {
        return this.requestService.sendRequest$<any>({
            method: 'POST',
            url: '/brand/community/top_holders',
            api: 'admin-api',
            data: data || {},
        });
    }

    getCommunityList(data: CommunityRequestBody) {
        return this.requestService.sendRequest$<{
            page: number;
            size: number;
            total: number;
            community_total: number;
            results: CommunityTableItem[];
        }>({
            method: 'POST',
            url: '/brand/community/list_v2',
            api: 'admin-api',
            data: data || {},
        });
    }

    getMonitorSearchCommunityList(
        data: CommunityRequestBody & {
            discover_collection_ids: number[];
        }
    ) {
        return this.requestService.sendRequest$<{
            page: number;
            size: number;
            total: number;
            community_total: number;
            results: CommunityTableItem[];
        }>({
            method: 'POST',
            url: '/brand/monitor/discover_list',
            api: 'admin-api',
            data: data || {},
        });
    }

    getMonitorCommunityListByGroup(
        data: CommunityRequestBody & {
            monitor_group_id: number[];
        }
    ) {
        return this.requestService.sendRequest$<{
            page: number;
            size: number;
            total: number;
            community_total: number;
            results: CommunityTableItem[];
        }>({
            method: 'POST',
            url: '/brand/monitor/group_list',
            api: 'admin-api',
            data: data || {},
        });
    }

    exportCommunityTable(data: CommunityRequestBody & { wallet_ids: number[] }) {
        return this.requestService.sendRequest<{ download_url: string }>({
            method: 'POST',
            url: '/brand/community/export_v2',
            api: 'admin-api',
            data: data || {},
        });
    }

    getCommunityDetailProfile(address: string) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: `/brand/community/detail/${address}/profile`,
            api: 'admin-api',
        });
    }

    getCommunityDetailScore(address: string) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: `/brand/community/detail/${address}/score`,
            api: 'admin-api',
        });
    }

    getCommunityDetailScoreV2(address: string) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: `/brand/community/detail/${address}/score_v2`,
            api: 'admin-api',
        });
    }

    getCommunityDetailMerch(address: string) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: `/brand/community/detail/${address}/merch`,
            api: 'admin-api',
        });
    }

    getCommunityDetailHoldings(address: string) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: `/brand/community/detail/${address}/holdings`,
            api: 'admin-api',
        });
    }

    getCampaignEmailHistory(campaignId: any, params: { pageNo: number; pageSize: number }) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: `/influencer/campaign/history/${campaignId}?pageNo=${params.pageNo}&pageSize=${params.pageSize}`,
            api: 'data-api',
        });
    }

    getEmailHistoryDetail(id: any) {
        return this.requestService.sendRequest<any>({
            method: 'GET',
            url: `/influencer/campaign/history/detail/${id}`,
            api: 'data-api',
        });
    }

    getCampaignEventsAnalytics(campaignId: any) {
        return this.requestService.sendRequest<{
            eventCount: number;
            campaignId: number;
        }>({
            method: 'GET',
            url: `/influencer/campaign/view_campaign_events/${campaignId}`,
            api: 'data-api',
        });
    }

    sendEmail(data: {
        subject: string;
        campaignEntryIds?: any[] | null;
        campaignId: any;
        excludeEntryIds?: any[];
        htmlData?: { [key: string]: any };
        selectWinner?: boolean;
    }) {
        return this.requestService.sendRequest<any>({
            method: 'POST',
            url: '/influencer/campaign/send_email',
            api: 'data-api',
            data,
        });
    }

    searchNftPopularCollection(data: { searchValue: string; chain?: ChainEnum; onlyFullDataCollection?: boolean; nftType?: string }) {
        return this.requestService.sendRequest$<
            {
                id: number;
                image_url: string;
                name: string;
                slug: string;
                is_verified: boolean;
                platform: 'opensea' | 'magiceden' | 'element';
                marketplace_link: string;
                contract_name: string;
            }[]
        >({
            method: 'GET',
            url: `/brand/popular_collection?search=${data.searchValue}${data.chain ? `&chain=${data.chain}` : ''}${
                data.onlyFullDataCollection ? '&full_data=true' : ''
            }${data.nftType ? `&nft_type=${data.nftType}` : ''}`,
            api: 'admin-api',
        });
    }

    getFileUploadList(isCsv: boolean = false) {
        return this.requestService.sendRequest<LifoUploadedFile[]>({
            method: 'GET',
            url: `/brand/attachments?isCsv=${isCsv}`,
            api: 'admin-api',
        });
    }

    deleteUploadedFile(id: number) {
        return this.requestService.sendRequest({
            method: 'DELETE',
            url: `/brand/attachments/${id}`,
            api: 'admin-api',
        });
    }

    getCommunityAllWallet(data) {
        return this.requestService.sendRequest<number[]>({
            method: 'POST',
            url: '/brand/community/all_wallet',
            api: 'admin-api',
            data: data || {},
        });
    }

    getCampaignAllWallet(data) {
        return this.requestService.sendRequest<number[]>({
            method: 'POST',
            url: '/brand/campaign/all_wallet',
            api: 'admin-api',
            data: data || {},
        });
    }

    recreateChannel(serverId: any, channelName: 'announce-wins' | 'w3w-verification') {
        return this.requestService.sendRequest({
            method: 'POST',
            url: `/influencer/role_management/discord_servers/${serverId}/recreate_channel`,
            api: 'data-api',
            data: { channelName },
        });
    }

    getEmailSender() {
        return this.requestService.sendRequest<EmailSenderInfo>({
            method: 'GET',
            url: '/influencer/community/email_senders/current',
            api: 'data-api',
        });
    }

    sendVerificationEmail(data: { senderName: string; senderEmailAddress: string }) {
        return this.requestService.sendRequest<EmailSenderInfo>({
            method: 'POST',
            url: '/influencer/community/email_senders',
            api: 'data-api',
            data,
        });
    }

    editEmailSender(data: { senderName: string; senderEmailAddress: string }) {
        return this.requestService.sendRequest<EmailSenderInfo>({
            method: 'PUT',
            url: '/influencer/community/email_senders',
            api: 'data-api',
            data,
        });
    }

    resendVerificationEmail(senderId: number) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: `/influencer/community/email_senders/resend/${senderId}`,
            api: 'data-api',
        });
    }

    verifyEmailSender(data: { link: string }) {
        return this.requestService.sendRequest<EmailSenderInfo>({
            method: 'POST',
            url: '/influencer/community/email_senders/verify',
            api: 'data-api',
            data,
        });
    }

    getTopPercentHolders(data) {
        return this.requestService.sendRequest$<any>({
            method: 'POST',
            url: '/brand/community/top_percent_holders',
            api: 'admin-api',
            data,
        });
    }

    removeWalletAddress(walletId: number) {
        return this.requestService.sendRequest<any>({
            method: 'DELETE',
            url: `/influencer/community/delete_manual_input/${walletId}`,
            api: 'data-api',
        });
    }
}
