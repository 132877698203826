import { LifoChartApiData } from '@type/lifo-chart.type';
/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-top10-overlap-project',
    templateUrl: './top10-overlap-project.component.html',
    styleUrls: ['./top10-overlap-project.component.less'],
})
export class Top10OverlapProjectComponent implements OnInit {
    list2 = [];
    projectData: LifoChartApiData;

    constructor() {}

    ngOnInit(): void {
        this.projectData = {
            counter_id: 13,
            name: 'Top 10 Overlap Projects',
            type: 'Top',
            unit: null,
            data: [
                {
                    id: 1,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 1443076,
                    value: 'BNB48 Club Token (KOGE)',
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/bnb48club.png',
                    rank: 0,
                },
                {
                    id: 1,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 435234,
                    value: 'XEN Crypto (bXEN)',
                    rank: 1,
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/xen.png',
                },
                {
                    id: 1,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 80121,
                    value: 'Binance-Peg BSC-USD (BSC-USD) ',
                    rank: 2,
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/busdt.png',
                },
                {
                    id: 1,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 76064,
                    value: 'Binance-Peg BUSD Token (BUSD)',
                    rank: 3,
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/lifeform.png',
                },
                {
                    id: 1,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 66338,
                    value: 'Galxe OAT (OAT)',
                    rank: 4,
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/galxe.png',
                },
                {
                    id: 1,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 52779,
                    value: 'SPACE ID (SID)',
                    rank: 5,
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/spaceid.png',
                },
                {
                    id: 1,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 32271,
                    value: 'SPACE ID Voyage',
                    rank: 6,
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/spaceid.png',
                },
                {
                    id: 1,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 31145,
                    value: 'Leggi (Leggi)',
                    rank: 7,
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/empty.png',
                },
                {
                    id: 8,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 30043,
                    value: 'Link3 (LINK3)',
                    rank: 8,
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/link3.png',
                },
                {
                    id: 1,
                    start_date: '2022-03-03 00:00:00',
                    end_date: '2022-03-03 00:00:00',
                    count: 29237,
                    value: 'Minereum BSC (MNEB)',
                    rank: 9,
                    imageUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/mneb.png',
                },
            ],
        };
    }
}
