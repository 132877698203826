import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-community-landscape',
    templateUrl: './community-landscape.component.html',
    styleUrls: ['./community-landscape.component.less'],
})
export class CommunityLandscapeComponent {
    @Input() theme: 'light' | 'dark' = 'dark';

    cardList = [
        {
            name: 'TOTAL',
            count: 3158476,
            desc: 'MINTS',
            color: '#6AD9B6',
        },
        {
            name: 'TOTAL',
            count: 2918263,
            desc: 'HOLDERS',
            color: '#6AD9B6',
        },
        {
            name: 'TOTAL',
            count: 1564.7,
            desc: 'GAS FEE (BNB)',
            color: '#6AD9B6',
        },
        {
            name: 'WHALES',
            count: 18,
            desc: 'WALLETS',
            color: '#72BBFD',
        },
        {
            name: 'BLUE CHIP',
            count: 29,
            desc: 'HOLDERS',
            color: '#72BBFD',
        },
    ];

    constructor() {}
}
