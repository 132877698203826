import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root',
})
export class RouterChangeMonitorService {
    previousPath: string = '';
    currentPath: string = '';

    constructor(private router: Router, private utilService: UtilService) {}

    setupRouterEvents() {
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
            this.previousPath = this.currentPath;
            this.currentPath = (e as NavigationEnd).url;
            this.utilService.setPageInfo(false);
        });
    }
}
