/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { NetworkEnum } from '@src/app/modules/admin/campaign/campaign.service';

@Component({
    selector: 'app-holder-analysics',
    templateUrl: './holder-analysics.component.html',
    styleUrls: ['./holder-analysics.component.less'],
})
export class HolderAnalysicsComponent implements OnInit {
    selectedTab: 'blueChipHolders' | 'whales' = 'blueChipHolders';

    whales: {
        id: number;
        wallet_address: string;
        my_nft_count: number;
        eth_balance: number;
        bnb_balance: number;
        collections: any[];
    }[];

    blueChipHolders: {
        id: number;
        wallet_address: string;
        my_nft_count: number;
        eth_balance: number;
        bnb_balance: number;
        collections: any[];
    }[];

    get list1() {
        return this.selectedTab === 'blueChipHolders' ? this.blueChipHolders : this.whales;
    }

    constructor() {}

    ngOnInit(): void {
        fetch('/assets/json/lifeform_blue_chip.json').then(res => {
            res.json().then(data => (this.blueChipHolders = data));
        });
        fetch('/assets/json/lifeform_whale.json').then(res => {
            res.json().then(data => (this.whales = data));
        });
    }

    tabChange(tab) {
        this.selectedTab = tab;
    }
}
