import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-general-admin-modal',
    templateUrl: './general-admin-modal.component.html',
    styleUrls: ['./general-admin-modal.component.less'],
})
export class GeneralAdminModalComponent {
    @Input() title: string;
    @Input() description: string;
    @Input() cancelText = 'CANCEL';
    @Input() confirmText = 'CONFIRM';
    @Input() cancelVisible = true;
    @Input() iconType: 'warning' | 'success';

    submitting = false;

    constructor(public modalRef: NzModalRef) {}
}
