<section class="faq-sections">
    <div class="content-text">
        <ng-container *ngIf="isBrandPage">
            <h2 class="faq-title">USER GUIDE</h2>
            <button nz-button nzGhost nzShape="round" nzSize="large" class="visit-btn" (click)="visitW3wWhitepaper()">
                <i nz-icon nzType="antd:link-gradient" class="text-xl"></i>
                <span class="gradient-text">VISIT W3W WHITEPAPER</span>
                <i nz-icon nzType="antd:right-arrow-circle-gradient" class="fs-20"></i>
            </button>
        </ng-container>
        <h2 class="faq-title">Frequently <i nz-icon nzType="antd:faq-question" class="align-middle"></i><span> Asked</span> Questions</h2>
        <div class="topQuestion">
            <div class="filterList" style="min-height: 200px">
                <ng-container *ngFor="let faq of faqList">
                    <div class="fw-600 category-title" [id]="faq.id">{{faq.category}}</div>
                    <nz-collapse [nzExpandIconPosition]="'right'" [nzBordered]="false">
                        <nz-collapse-panel #p *ngFor="let item of faq.list" [nzHeader]="item.question" [nzExpandedIcon]="expandedIcon">
                            <div class="response-wrapper">
                                <div [innerHTML]="item.response" class="response-content"></div>
                            </div>
                            <ng-template #expandedIcon let-active>
                                <i
                                    nz-icon
                                    nzType="plus-circle"
                                    nzTheme="outline"
                                    class="expand-icon"
                                    [ngClass]="{collapsed: p.nzActive}"
                                ></i>
                            </ng-template>
                        </nz-collapse-panel>
                    </nz-collapse>
                </ng-container>
            </div>
        </div>
    </div>
</section>
