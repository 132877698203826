import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { AuthUserInfoByCode } from './socialmedia-connect.service';

@Injectable({
    providedIn: 'root',
})
export class DiscordService {
    projectId: number;
    discordUserInfo: AuthUserInfoByCode;

    /**
     *
     * @description 从 discord w3w bot verification 链接授权登录后获取的discord信息存放在此，用于login modal， 不要在其他地方使用。
     * 不要与social-media-connect.service中的discordUserInfo混淆
     */
    constructor(private requestService: RequestService) {}

    applyDiscordRole(data: { contactName: string; discordUserId: any; walletAddress: string; projectId: any }) {
        return this.requestService.sendRequest({
            api: 'data-api',
            url: '/external/role/apply_my_role',
            method: 'POST',
            data,
        });
    }
}
