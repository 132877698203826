export const environment = {
    production: true,
    supportedLocale: ['en-US', 'zh-CN', 'id'],
    campaignService: 'https://campaign.lifo.ai',
    storageUrl: 'https://storage.googleapis.com',
    shopApiService: 'https://shop-api.lifo.ai',
    dataApiUrl: 'https://data-api.lifo.ai',
    discordApiUrl: 'https://discord-api.lifo.ai',
    landingApiUrl: 'https://landing.web3wear.com',
    referralApiUrl: 'https://nft-data-prod-4lladlc2eq-uc.a.run.app',
    adminApiUrl: 'https://65ubnewyc4.us-east-1.awsapprunner.com', // https://admin-api.w3w.ai
    firebase: {
        apiKey: 'AIzaSyDPJcldPJzr3-UlpbxX9_qlHAZ7OSnqcBQ',
        authDomain: 'influencer-272204.firebaseapp.com',
        databaseURL: 'https://influencer-272204.firebaseio.com',
        projectId: 'influencer-272204',
        storageBucket: 'influencer-272204.appspot.com',
        messagingSenderId: '65044462485',
        appId: '1:65044462485:web:b59653f66408d40cc2549c',
        measurementId: 'G-PXG470EZX2',
    },
    stripe: {
        token: 'pk_live_51HP08RJVRQRRLfBwA5E6UkWAWbQ7z8i2BAF1j6G1mjs30tBeGl5OO6KX8xHsdurrVQO2UHlNMttwIzLcpXLGq1Bm00EOFgb1Zr',
    },
    paypal: {
        clientId: 'AeEYineiLSnfASbu_s27_LBCnbXqSiQStIelMvi8XQ23Uk1FEqvJcW0PZXP-vKN7lkr5ydGvAUQqjXZj',
        apiUrl: 'https://api.paypal.com',
        websiteUrl: 'https://www.paypal.com',
    },
    near: {
        networkId: 'mainnet',
        nodeUrl: 'https://rpc.mainnet.near.org',
        walletUrl: 'https://wallet.near.org',
        helperUrl: 'https://helper.near.org',
        explorerUrl: 'https://explorer.near.org',
    },
    plaid: {
        env: 'production',
    },
    shopHost: 'https://shop.lifo.ai',
    domainConfig: {
        prefix: 'https://',
        suffix: '.lifo.store',
    },
    shopify: {
        domain: 'checkout.w3w.ai',
        storefrontAccessToken: 'db367c08f70bfde792ec352cd82ab5b5',
    },
    creatorHubUrl: 'https://creator.lifo.ai',
    grecaptcha: {
        sitekey: '6Lczm-QgAAAAANEufUqi275YwAKN1L3BPuRAHgX0',
    },
};
