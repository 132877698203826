<div class="confirm-box">
    <div class="title" *ngIf="hasTitle">{{ title }}</div>
    <div class="close-icon active-mask cursor-pointer" (click)="discard()">
        <i nz-icon nzType="close"></i>
    </div>
    <p [ngClass]="{'content-style': hasTitle}">{{message}}</p>
    <div class="btns">
        <button nz-button nzType="default" (click)="discard()" [disabled]="submitting">{{cancelText}}</button>
        <button nz-button nzType="primary" (click)="confirm()" [nzLoading]="submitting">{{confirmText}}</button>
    </div>
</div>
