<section class="reset-password-modal">
    <app-general-modal-header
        [title]="'Reset Password'"
        (cancel)="modalRef.triggerCancel()"
        [isBrandModal]="true"
    ></app-general-modal-header>

    <form
        nz-form
        *ngIf="forgotEmail"
        [formGroup]="validateForm"
        class="reset-password-form"
        (ngSubmit)="resetPassword()"
        style="margin-top: 10px"
    >
        <!-- Send email -->
        <ng-container *ngIf="!code">
            <div class="labels">Your registration email</div>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="emailEmptyErrorTpl">
                    <input type="text" nz-input formControlName="email" placeholder="Enter Email" />
                    <ng-template #emailEmptyErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required') || control.hasError('email')">
                            <span class="input-tip">Please input your email</span>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        </ng-container>

        <!-- Reset password -->
        <ng-container *ngIf="code">
            <div class="labels">New Password</div>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please enter your password">
                    <input
                        type="password"
                        class="emails"
                        nz-input
                        formControlName="password"
                        placeholder="Password 8+characters"
                        (ngModelChange)="validateConfirmPassword()"
                    />
                </nz-form-control>
            </nz-form-item>
            <div class="labels">Confirm Password</div>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please confirm your password">
                    <input type="password" nz-input formControlName="confirmPassword" placeholder="Confirm your password" />
                </nz-form-control>
            </nz-form-item>
            <div class="success-tips" *ngIf="isSuccess">
                <i nz-icon nzType="check-circle" nzTheme="fill"></i>
                Your new password is set!
            </div>
        </ng-container>
        <div class="text-align-center">
            <button
                nz-button
                class="reset-password-form-button"
                nzSize="large"
                nzShape="round"
                [nzType]="'primary'"
                [nzLoading]="submitting"
                [disabled]="!validateForm.valid"
            >
                SEND EMAIL
            </button>
        </div>
        <div class="text-align-center sign-in"><span>Back to</span><a (click)="goSignIn()">Log In</a></div>
    </form>

    <div *ngIf="!forgotEmail" class="send-email-success">
        <div class="img">
            <i nz-icon nzType="antd:send-mail-logo"></i>
            <h4>Email Sent</h4>
        </div>
        <div class="send-mail-title">Please check your inbox for the link and instruction to reset your password.</div>
        <button nz-button nzShape="round" class="login-form-button" [nzType]="'primary'" (click)="goSignIn()">BACK TO LOG IN</button>
    </div>
</section>
