import { CampaignService } from '@admin/campaign/campaign.service';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { ChainEnum } from '@src/app/shared/types/wallet-chain.model';
import { cloneDeep } from 'lodash';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TempCollection } from '../select-nft-collection/select-nft-collection.component';

@Component({
    selector: 'app-select-nft-token',
    templateUrl: './select-nft-token.component.html',
    styleUrls: ['./select-nft-token.component.less'],
})
export class SelectNftTokenComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() selectedNftTokens: TempCollection[];
    @Input() chain: ChainEnum = ChainEnum.ETHEREUM;

    @ViewChild('searchInput') searchInput: ElementRef;

    searchVal: string = '';
    tokenList: TempCollection[];
    tempSelectedNftTokens: TempCollection[];
    tempSelectedNftTokenIds = new Set<number>();

    fetchingCollectionList = false;

    destroy$ = new Subject();

    constructor(private campaignService: CampaignService, public modalRef: NzModalRef, public screenService: CommonService) {}

    ngOnInit(): void {
        this.tempSelectedNftTokenIds = new Set(this.selectedNftTokens.map(item => item.id));
        this.tempSelectedNftTokens = cloneDeep(this.selectedNftTokens);
        this.getSearchResult();
    }

    ngAfterViewInit() {
        fromEvent(this.searchInput.nativeElement, 'input')
            .pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe(() => this.getSearchResult());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getSearchResult() {
        this.fetchingCollectionList = true;
        this.campaignService
            .searchNftPopularCollection({
                chain: this.chain,
                searchValue: this.searchVal,
                nftType: 'token',
            })
            .subscribe(
                data => {
                    this.tokenList = data.map(c => ({
                        id: c.id,
                        name: c.name,
                        image: c.image_url,
                        isVerified: c.is_verified,
                        platform: c.platform,
                        marketplaceLink: c.marketplace_link,
                        contractName: c.contract_name,
                    }));
                    this.fetchingCollectionList = false;
                },
                () => (this.fetchingCollectionList = false)
            );
    }

    removeCollection(collection: TempCollection) {
        this.tempSelectedNftTokenIds.delete(collection.id);
        this.tempSelectedNftTokens = this.tempSelectedNftTokens.filter(c => c.id !== collection.id);
    }

    checkStatusChange(item: TempCollection, checked: boolean) {
        if (checked) {
            this.tempSelectedNftTokenIds.add(item.id);
            this.tempSelectedNftTokens.push(item);
        } else {
            this.removeCollection(item);
        }
    }
}
