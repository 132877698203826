import { Component, Input, OnDestroy } from '@angular/core';
import { SocialMedia, SocialMediaConnectService } from '@services/socialmedia-connect.service';
import { CommonService } from '@src/app/services/common.service';
import { AdminCustomEventEnum, FirebaseEventsService } from '@src/app/services/firebase/firebase-event.service';
import { SESSION_STORAGE_KEY_ENUM } from '@src/app/shared/types/general.type';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-verification-content',
    templateUrl: './verification-content.component.html',
    styleUrls: ['./verification-content.component.less'],
})
export class VerificationContentComponent implements OnDestroy {
    @Input() contractInfo;

    verificationMaterials = false;
    content: string;

    subscriptions = {
        socialMediaWindow: null,
        pageLeave: new Subject(),
    };

    get twitterUserInfo() {
        return this.socialMediaConnectService.twitterUserInfo$.value;
    }

    get twitterUserInfo$() {
        return this.socialMediaConnectService.twitterUserInfo$;
    }

    constructor(
        private screenService: CommonService,
        private socialMediaConnectService: SocialMediaConnectService,
        private firebaseEventService: FirebaseEventsService
    ) {}

    ngOnDestroy() {
        this.subscriptions.pageLeave.next();
        this.subscriptions.pageLeave.complete();
    }

    connectTwitter() {
        if (this.twitterUserInfo) return;

        this.firebaseEventService.logEvent({
            name: AdminCustomEventEnum.HOME_PAGE_FEATURE_CLICK,
            properties: {
                type: 'verify_popup_twitter_verify',
            },
        });
        this.connectSocialMedia(SocialMedia.TWITTER);
    }

    changeTwitterAccount() {
        this.connectSocialMedia(SocialMedia.TWITTER);
    }

    async connectSocialMedia(type: SocialMedia.DISCORD | SocialMedia.TWITTER) {
        if (this.screenService.isMobile()) {
            sessionStorage.setItem(SESSION_STORAGE_KEY_ENUM.CONTRACT_INFO, JSON.stringify(this.contractInfo));
        }
        this.socialMediaConnectService.connectDiscordOrTwitter(type, { backPageUrl: window.location.href });
    }

    disconnectTwitter(e) {
        this.socialMediaConnectService.clearSocialMediaInfo(SocialMedia.TWITTER);
        e.stopPropagation();
    }
}
