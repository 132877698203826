import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShopifyCountry } from '@modules/main/advanced-customization/advanced-customization.model';
import { NidavellirService } from '@modules/main/nidavellir.service';
import { CommonService } from '@src/app/services/common.service';
import { AsYouType, isValidPhoneNumber, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SESSION_STORAGE_KEY_ENUM } from '../../types/general.type';

@Component({
    selector: 'app-shipping-detail',
    templateUrl: './shipping-detail.component.html',
    styleUrls: ['./shipping-detail.component.less'],
})
export class ShippingDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('addressText') addressText: ElementRef<HTMLInputElement>;

    shopifyCountryList: ShopifyCountry[];

    countryCode: any;

    addressForm: FormGroup;

    submitting = false;

    fetchingCountryInfo = false;

    destroy$ = new Subject();
    provinceList: any[];

    get isNoState() {
        return !this.provinceList?.length;
    }

    get isHongKong() {
        const countryRegion = this.addressForm.get('country').value;
        return ['Hong Kong SAR'].includes(countryRegion);
    }

    constructor(
        private fb: FormBuilder,
        private ref: ChangeDetectorRef,
        private nidavellirService: NidavellirService,
        private messageService: CommonService
    ) {}

    ngOnInit(): void {
        this.addressForm = this.fb.group({
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            addressLine1: [null, [Validators.required]],
            addressLine2: [null],
            city: [null, [Validators.required]],
            state: [null, [this.stateValidator]],
            country: [null, [Validators.required]],
            zipCode: [null, [Validators.required]],
            phoneNumber: [null, [Validators.required]],
            email: [null, [Validators.required, Validators.email]],
        });

        this.fetchingCountryInfo = true;
        this.nidavellirService
            .getCountryList()
            .then(data => {
                this.shopifyCountryList = data;
                this.setCachedDataToForm();
            })
            .catch(() => this.messageService.error('Fetch country list failed, please refresh page and try again later.'))
            .finally(() => (this.fetchingCountryInfo = false));

        // Country Change
        this.addressForm
            .get('country')
            .valueChanges.pipe(
                takeUntil(this.destroy$),
                filter(item => !!item)
            )
            .subscribe(val => {
                const countryItem = this.shopifyCountryList.find(item => item.name === val);
                const phoneNumber = this.addressForm.get('phoneNumber').value;
                this.countryCode = countryItem.code;

                this.provinceList = countryItem.provinces;
                if (phoneNumber) {
                    this.validatePhoneNumber(phoneNumber);
                }
            });

        // Phone Number Validator
        this.addressForm
            .get('phoneNumber')
            .valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val: string) => this.validatePhoneNumber(val));
    }

    ngAfterViewInit() {
        this.initGoogleMap();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setCachedDataToForm() {
        const cacheRawDataStr = sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.SHIPPING_DETAIL_CACHE);
        if (cacheRawDataStr) {
            const rawData = JSON.parse(cacheRawDataStr);
            this.addressForm.patchValue({
                firstName: rawData.firstName,
                lastName: rawData.lastName,
                addressLine1: rawData.addressLine1,
                addressLine2: rawData.addressLine2,
                city: rawData.city,
                state: rawData.state,
                country: rawData.country,
                zipCode: rawData.zipCode,
                phoneNumber: rawData.phoneNumber,
                email: rawData.email,
            });
        }
    }

    stateValidator = (): { [s: string]: boolean } => {
        const currentState = this.addressForm?.get('state').value;

        if (this.provinceList?.length) {
            return currentState ? null : { required: true };
        }

        return null;
    };

    validatePhoneNumber(val: string) {
        const country = this.countryCode || 'US';
        const handledVal = new AsYouType(country).input(val || '');
        const lengthCode = validatePhoneNumberLength(handledVal, country);
        const phoneNumberControl = this.addressForm.get('phoneNumber');

        if (!handledVal.includes(val)) {
            phoneNumberControl.patchValue(handledVal);
        }

        switch (lengthCode) {
            case 'TOO_SHORT':
                phoneNumberControl.setErrors({ valid: 'Too Short' });
                break;

            // Phone Number is too long, so I will slice it
            case 'TOO_LONG':
                phoneNumberControl.patchValue(handledVal.slice(0, handledVal.length - 1));
                break;
            case 'INVALID_LENGTH':
                phoneNumberControl.setErrors({ valid: 'Incorrect Format' });
                break;

            // Valid Phone Number
            case undefined:
                phoneNumberControl.clearValidators();
        }

        if (!phoneNumberControl.getError('valid') && !isValidPhoneNumber(phoneNumberControl.value, country)) {
            phoneNumberControl.setErrors({ valid: 'Incorrect Format' });
        }
    }

    getHandledAddressInfo() {
        const rawValue = this.addressForm.getRawValue();

        return {
            address_line_1: rawValue.addressLine1,
            address_line_2: rawValue.addressLine2,
            first_name: rawValue.firstName,
            last_name: rawValue.lastName,
            city: rawValue.city,
            state: this.provinceList ? rawValue.state : null,
            zip: rawValue.zipCode,
            country: rawValue.country,
            phone_number: parsePhoneNumber(rawValue.phoneNumber, this.countryCode).format('E.164'),
            email: rawValue.email,
        };
    }

    setFormStatus() {
        Array.from(Object.keys(this.addressForm.controls)).forEach(key => {
            const item = this.addressForm.get(key);
            item.markAsDirty();
            item.updateValueAndValidity();
        });
    }

    initGoogleMap() {
        // @ts-ignore
        const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement, {
            types: ['address'], // 'establishment' / 'address' / 'geocode'
            strictBounds: true,
        });
        // @ts-ignore
        google?.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();

            const googleCountry = place.address_components.find(item => item.types[0] === 'country');
            const googleState = place.address_components.find(item => item.types[0] === 'administrative_area_level_1');
            const googleZipCode = place.address_components.find(item => item.types[0] === 'postal_code');
            const googleCity = place.address_components.find(item => item.types[0] === 'locality');

            const shopifyCountry = this.shopifyCountryList.find(item => item.code === googleCountry?.short_name);
            const shopifyProvince = shopifyCountry?.provinces?.find(item => item.code === googleState?.short_name);

            this.addressForm.patchValue({
                zipCode: googleZipCode?.short_name,
                country: shopifyCountry?.name,
                city: googleCity?.long_name,
                addressLine1: place.name,
            });

            this.addressForm.get('state').patchValue(shopifyProvince?.name || googleState?.long_name);

            this.ref.markForCheck();
            this.ref.detectChanges();
        });
    }
}
