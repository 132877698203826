import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { Web3Provider } from '@ethersproject/providers';
import { NFTPlatform } from '@shared/components/connect-nft-modal/connect-nft-modal.component';
import { CommonService } from '@src/app/services/common.service';
import { CustomEventEnum, FirebaseEventsService } from '@src/app/services/firebase/firebase-event.service';
import { WalletEnum, WalletsSupportSwitchChain } from '@src/app/shared/types/wallet-chain.model';
import { getChainInfoByChain } from '@utils/wallet-chain.util';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { FrontEndProjectCampaign } from '../campaign-list/campaign-list.component';
import { CampaignStatus, SocialMedia } from './../../../admin/campaign/campaign.model';
import { NidavellirService } from './../../nidavellir.service';
import { WalletStatusService } from './../../wallet-status.service';
import { AdvancedCustomizationStateService, CreateCampaign } from './../advanced-customization-state.service';

export const walletImgMap = new Map([
    [NFTPlatform.META_MASK, 'assets/imgs/home/metamask.png'],
    [NFTPlatform.COINBASE, 'assets/imgs/home/coinbase.png'],
    [NFTPlatform.WALLET_CONNECT, 'assets/imgs/home/walletconnect.png'],
    [NFTPlatform.BLOCTO, 'assets/imgs/home/blocto.png'],
    [NFTPlatform.DAPPER, 'assets/imgs/home/dapper.png'],
    [NFTPlatform.PHANTOM, 'assets/svg/phantom.svg'],
    [NFTPlatform.NEAR, 'assets/imgs/admin/near-circle.png'],
    [NFTPlatform.UNSTOPPABLE_DOMAINS, 'assets/svg/unstoppable-domains.svg'],
    [NFTPlatform.TRUST_WALLET, 'assets/svg/trust-wallet.svg'],
    [NFTPlatform.PARTICLE, 'assets/imgs/home/particle.png'],
]);

export enum TransactionStatusEnum {
    PROCESSING = 0,
    AVAILABLE = 1,
    UNAVAILABLE = 2,
}

/**
 * Only used for campaign-detail.component
 */
@Injectable()
export class CampaignDetailService {
    campaignId: number;
    referrer: string; // 通过share campaign 链接进入的用户会在 Url 中带有 referrer 参数
    campaignInfo: FrontEndProjectCampaign;
    campaignInfo$ = new BehaviorSubject<FrontEndProjectCampaign>(null);

    campaignJoinedResult$ = new BehaviorSubject<'success' | 'fail'>(null);

    subscriptions = {
        socialMediaWindow: null,
        pageLeave: new Subject(),
    };

    constructor(
        private walletStatusService: WalletStatusService,
        private firebaseEventService: FirebaseEventsService,
        private nidavellirService: NidavellirService,
        private messageService: CommonService,
        private acStateService: AdvancedCustomizationStateService
    ) {}

    connectWallet(walletList: NFTPlatform[] = null, triggerType: 'header_account' | 'connect_wallet' | 'combine_wallet' = null) {
        this.walletStatusService.connectWallet(
            { type: 'customer' },
            {
                enabledNFTPlatforms: walletList || this.campaignInfo.supported_wallet,
                triggerType,
            },
            () => this.switchChain()
        );
    }

    async switchChain() {
        if (!WalletsSupportSwitchChain.includes(this.walletStatusService.walletType)) {
            return null;
        }

        let provider: Web3Provider;
        const requiredChainId = getChainInfoByChain(this.campaignInfo.chain, 'chainId');
        const infoUsedToSendRequest = getChainInfoByChain(this.campaignInfo.chain, 'infoUsedConnectWallet');

        if (this.walletStatusService.walletType === NFTPlatform.META_MASK) {
            // @ts-ignore
            provider = new Web3Provider(window?.ethereum?.providers?.find(p => p.isMetaMask) || window.ethereum);
        }

        if ([WalletEnum.TRUST_WALLET].includes(this.walletStatusService.walletType)) {
            provider = new Web3Provider(window.ethereum);
        }

        if (this.walletStatusService.walletType === NFTPlatform.COINBASE) {
            const coinbaseWallet = new CoinbaseWalletSDK({
                appName: 'Lifo INC',
                darkMode: false,
            });

            const providerCoinbase = coinbaseWallet.makeWeb3Provider('https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a', 1);
            provider = new Web3Provider(providerCoinbase);
        }

        try {
            await provider.send('wallet_switchEthereumChain', [{ chainId: requiredChainId }]);

            this.walletStatusService.selectedWalletChainId = requiredChainId;
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if ([4902, -32603].includes(switchError.code)) {
                try {
                    await provider.send('wallet_addEthereumChain', [infoUsedToSendRequest]);
                    this.walletStatusService.selectedWalletChainId = requiredChainId;
                } catch (addError) {
                    this.messageService.error(addError?.message);
                }
            } else {
                this.messageService.error(switchError.message);
            }

            console.log(switchError);
        }

        return provider;
    }

    async setWalletChain() {
        const connectedWalletIsSupported = WalletsSupportSwitchChain.includes(this.walletStatusService.walletType);

        if (connectedWalletIsSupported && this.walletStatusService.walletAddress && this.walletStatusService.walletType) {
            // Set walletChain
            let provider: any = window.ethereum;
            if (this.walletStatusService.walletType === NFTPlatform.META_MASK) {
                // @ts-ignore
                provider = window?.ethereum?.providers?.find(p => p.isMetaMask) || window.ethereum;
                try {
                    this.walletStatusService.selectedWalletChainId = await provider?.request({ method: 'eth_chainId' });
                } catch (err) {
                    console.warn(err);
                }
            }

            if (this.walletStatusService.walletType === NFTPlatform.TRUST_WALLET) {
                try {
                    this.walletStatusService.selectedWalletChainId = await provider?.request({ method: 'eth_chainId' });
                } catch (err) {
                    console.warn(err);
                }
            }

            if (this.walletStatusService.walletType === NFTPlatform.COINBASE) {
                const coinbaseWallet = new CoinbaseWalletSDK({
                    appName: 'Hemera',
                    darkMode: false,
                });

                const providerCoinbase = coinbaseWallet.makeWeb3Provider(
                    'https://mainnet.infura.io/v3/005233ef0d61464a98a17a1230d7c59a',
                    1
                );
                provider = new Web3Provider(providerCoinbase);
                try {
                    this.walletStatusService.selectedWalletChainId = await provider?.send('eth_chainId', []);
                } catch (err) {
                    console.warn(err);
                }
            }
        }
    }

    joinCampaign(
        campaignForm: FormGroup,
        duplicateSocialMedias: Set<SocialMedia>,
        selectedCombinedWallets: { walletAddr: string; walletType: WalletEnum; encryptedWalletAddr: string }[],
        customFields: { challenge_id: any; [key: string]: any }[]
    ) {
        campaignForm.get('wallet_type').setValue(this.walletStatusService.walletType);
        campaignForm.get('wallet_address').setValue(this.walletStatusService.walletAddress);

        if (selectedCombinedWallets.length) {
            campaignForm.get('wallet_list').setValue(
                selectedCombinedWallets.map(item => ({
                    wallet_address: item.walletAddr,
                    wallet_type: item.walletType,
                    encrypt_wallet_address: item.encryptedWalletAddr,
                }))
            );
        } else {
            campaignForm.get('wallet_list').setValue([]);
        }

        const formRawData = campaignForm.getRawValue();
        const data: CreateCampaign = {
            campaign_id: formRawData.campaign_id,
            wallet_type: formRawData.wallet_type,
            wallet_address: formRawData.wallet_address,
            discord_id: formRawData.discord_id,
            twitter_id: formRawData.twitter_id,
            telegram_id: formRawData.telegram_id,
            email: formRawData.email,
            recaptcha_result: formRawData.recaptcha_result,
            wallet_list: formRawData.wallet_list,
            custom_input: customFields?.map(item => ({
                value: campaignForm.get(`custom_input_${item.challenge_id}`).value,
                challenge_id: item.challenge_id,
            })),
        };

        return this.nidavellirService
            .subscribeWalletAddressUpdate({
                chain: 'ETH',
                walletList: selectedCombinedWallets,
            })
            .then(() => this.nidavellirService.entryNftCampaign(data))
            .then(() => {
                this.campaignJoinedResult$.next('success');
                this.acStateService.isJoinedCampaign$.next(true);
                this.nidavellirService
                    .getUserInfo(this.walletStatusService.walletAddress)
                    .then((userInfo: any) => this.walletStatusService.userInfo$.next(userInfo));
                this.firebaseEventService.logEvent({
                    name: CustomEventEnum.JOIN_CAMPAIGN,
                    properties: { campaignId: this.campaignId, referrer: this.referrer || null },
                });
            })
            .catch(err => {
                if (err.status === 400 && err.body?.details) {
                    err.body?.details.forEach((item: { contact_type: SocialMedia; contact: string; is_duplicate: boolean }) => {
                        duplicateSocialMedias?.add(item.contact_type);
                    });
                } else {
                    // this.messageService.error(err.body?.code === 400 ? err.body.message : null);
                    this.campaignJoinedResult$.next('fail');
                }

                if (dayjs(this.campaignInfo.start_date).isAfter(dayjs())) {
                    this.campaignInfo.status = CampaignStatus.UPCOMING;
                } else if (dayjs(this.campaignInfo.end_date).isBefore(dayjs())) {
                    this.campaignInfo.status = CampaignStatus.ENDED;
                }
            });
    }

    shareToTwitter() {
        const text = encodeURIComponent(`Check Out This Campaign With Hemera!\n${this.generateShareCampaignLink()}`);
        const url = `https://twitter.com/intent/tweet?text=${text}`;
        window.open(url, '_blank');
    }

    generateShareCampaignLink() {
        const params = {
            projectKey: this.acStateService.projectKey,
            campaignId: this.campaignId,
            invitationCode: this.walletStatusService.userInfo?.invitationCode,
        };
        let shareLink = `${location.protocol}//${location.host}/main/${params.projectKey}/${params.campaignId}`;
        shareLink = params.invitationCode ? `${shareLink}?referrer=${params.invitationCode}` : shareLink;
        return shareLink;
    }

    createReferralAndClaim(data) {
        return this.nidavellirService.createReferralAndClaim<{
            code: number;
            msg: string;
            data: { status?: TransactionStatusEnum; txId?: string };
        }>(data);
    }
}
