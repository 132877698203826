<section class="sign-in-modal">
    <div class="left">
        <img src="/assets/imgs/sign-up-bg.png" style="height: 100%" />
    </div>

    <div class="right">
        <div class="mb-20">
            <app-general-modal-header
                [title]="'Brand Log In'"
                (cancel)="modalRef.triggerCancel()"
                [isBrandModal]="true"
            ></app-general-modal-header>
        </div>

        <form nz-form [formGroup]="validateForm" class="sign-in-form" (ngSubmit)="submitForm()">
            <div class="labels">Email</div>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="emailEmptyErrorTpl">
                    <input type="text" nz-input formControlName="email" placeholder="Email" />
                    <ng-template #emailEmptyErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            <span class="input-tip">Please enter your email address</span>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <div class="labels">Password</div>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="passwordEmptyErrorTpl">
                    <input type="password" nz-input formControlName="password" placeholder="Password" />
                    <ng-template #passwordEmptyErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            <span class="input-tip">Please enter your password</span>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <div nz-row class="d-flex justify-content-between remember-me">
                <div>
                    <label nz-checkbox formControlName="remember">
                        <span style="margin-left: 5px">Remember Me</span>
                    </label>
                </div>
                <div class="forgot-password">
                    <a (click)="goForgotPassword()">Forgot Password?</a>
                </div>
            </div>
            <div class="text-align-center">
                <button
                    nz-button
                    class="sign-in-form-button"
                    [nzType]="'primary'"
                    nzSize="large"
                    [nzLoading]="submitting"
                    [disabled]="!validateForm.valid"
                >
                    LOG IN
                </button>
            </div>
            <div class="text-align-center sign-up"><span>Don’t have an account?</span><a (click)="goSignUp()">Sign Up</a></div>
        </form>
    </div>
</section>
