import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AntdModulesModule } from './antd-modules.module';
import { ComponentsModule } from './components/components.module';
import { OtherLibsModule } from './other-libs.module';
import { DirectivePipeModule } from './directive-pipe.module';

@NgModule({
    declarations: [],
    imports: [CommonModule, AntdModulesModule, ComponentsModule, OtherLibsModule, DirectivePipeModule],
    exports: [AntdModulesModule, ComponentsModule, OtherLibsModule, DirectivePipeModule],
})
export class SharedModule {}
