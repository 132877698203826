import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Antd module
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPipesModule } from 'ng-zorro-antd/pipes';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NzButtonModule,
        NzIconModule,
        NzTableModule,
        NzInputModule,
        NzInputNumberModule,
        NzCheckboxModule,
        NzSelectModule,
        NzSwitchModule,
        NzMenuModule,
        NzDividerModule,
        NzPopoverModule,
        NzMessageModule,
        NzModalModule,
        NzSkeletonModule,
        NzFormModule,
        NzSpinModule,
        NzUploadModule,
        NzProgressModule,
        NzToolTipModule,
        NzPaginationModule,
        NzRadioModule,
        NzCollapseModule,
        NzTabsModule,
        NzResultModule,
        NzAlertModule,
        NzDropDownModule,
        NzDatePickerModule,
        NzAnchorModule,
        NzPipesModule,
        NzImageModule,
        NzDrawerModule,
        NzBadgeModule,
        NzTagModule,
        NzSliderModule,
        NzCarouselModule,
        NzSpaceModule,
    ],
    exports: [
        NzButtonModule,
        NzIconModule,
        NzTableModule,
        NzInputModule,
        NzInputNumberModule,
        NzSelectModule,
        NzSwitchModule,
        NzMenuModule,
        NzDividerModule,
        NzPopoverModule,
        NzMessageModule,
        NzCheckboxModule,
        NzModalModule,
        NzSkeletonModule,
        NzFormModule,
        NzSpinModule,
        NzUploadModule,
        NzProgressModule,
        NzToolTipModule,
        NzPaginationModule,
        NzRadioModule,
        NzCollapseModule,
        NzTabsModule,
        NzResultModule,
        NzAlertModule,
        NzDropDownModule,
        NzDatePickerModule,
        NzAnchorModule,
        NzPipesModule,
        NzImageModule,
        NzDrawerModule,
        NzBadgeModule,
        NzTagModule,
        NzSliderModule,
        NzCarouselModule,
        NzSpaceModule,
    ],
})
export class AntdModulesModule {}
