import { GeneralReportChartConfig } from '@shared/types/general.type';
import { EchartsOption } from '@src/app/shared/directives/echarts.directive';

export interface LifoListChartItem {
    name: string;
    count: number;
    percent: number;
    color?: string;
    tooltip?: string;
    img?: string;
}

export interface LifoChartConfig extends Partial<GeneralReportChartConfig> {
    /**
     * @description
     * echarts: echarts chart
     * textBarOneLine: text bar chart with one line
     * textBarTwoLine: text bar chart with two lines
     */
    chartType: 'echarts' | 'textBarOneLine' | 'textBarTwoLine';

    sectionName?: string;

    /**
     * @description
     * format: { 'color': '#333' }
     */
    sectionNameStyle?: { [key: string]: string };

    /**
     * @description
     * URL of the image
     */
    sectionNameImgLink?: string;

    sectionNameTooltip?: string;

    /**
     * @description
     * 如果有此项，优先于sectionName展示
     */
    sectionNameHTML?: string;

    sectionDescription?: string;

    /**
     * @description
     * format: { 'color': '#333' }
     */
    sectionDescriptionStyle?: { [key: string]: string };

    /**
     * Only ued for pie/donut chart
     * @example
     * [{
     *    name: 'Legend Name 1',
     *    count: 100,
     *    percent: 0.5,
     * },
     * {
     *    name: 'Legend Name 2',
     *    count: 120,
     *    percent: 0.23,
     * }]
     */
    legendConfig?: { key?: string | number; name: string; count: number; percent: number }[];

    /**
     * @description
     * only used for echarts chart
     */
    chartConfig?: EchartsOption;

    /**
     * @description
     * only used for textBarOneLine/textBarTwoLine chart
     */
    customListChartConfig?: LifoListChartItem[];

    chartHeight?: string;

    /**
     * @description
     * only used for echarts chart
     */
    donutChartCenterInfo?: {
        img?: string;
        name: string;
        count: number | string;
    };

    topRightInfo?:
        | {
              type: 'count';
              text: string;
              count: number;
          }
        | {
              type: 'select';
              options: { label: string; value: string }[];
              placeholder?: string;
          }
        | {
              type: 'increasedCount';
              count: number;
              increasedString: string;
              increasedType: 'positive' | 'negative';
          };

    theme?: 'light' | 'dark';

    /**
     * @description
     * 如果是true, 则最大项的比例为实际值。如果是false, 则最大项的比例为100%，其他项比例以最大项数值计算。
     */
    makeMaxCountItemAsRealPercent?: boolean;

    colors?: string[];
}

export abstract class AbstractLifoChartComponent {
    abstract initialOptions: EchartsOption;
    abstract lifoChartConfig: LifoChartConfig;

    constructor() {}
}
