<section
    class="report-section"
    [ngClass]="{
        'dark':theme === 'dark',
        'light':theme==='light'
    }"
>
    <div class="section-title">Community Landscape</div>

    <div class="section-content">
        <div *ngFor="let item of cardList" class="card-item" [style.backgroundColor]="item.color">
            <div class="inner-card-item">
                <span>{{item.name | uppercase}}</span>
                <span class="count">{{item.count|number:'1.0-0'}}</span>
                <span>{{item.desc | uppercase}}</span>
            </div>
        </div>
    </div>
</section>
