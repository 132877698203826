<section class="connect-nft-modal">
    <header class="connect-header">
        <ng-container *ngIf="modalConfig.type === 'customer'">
            <div class="user-info" *ngIf="discordService.discordUserInfo">
                <h1>Hi, {{discordService.discordUserInfo.originalResponse.user.username}}. Welcome!</h1>
                <p>Connect wallet to combine your address with {{projectName}}.</p>
            </div>

            <h2 *ngIf="!discordService.discordUserInfo" class="mb-20">Connect Wallet</h2>
        </ng-container>

        <ng-container *ngIf="modalConfig.type === 'general'">
            <h2 class="mb-20">Consumer Log In</h2>
        </ng-container>

        <div class="close-icon active-mask cursor-pointer" (click)="modalRef.triggerCancel()">
            <i nz-icon nzType="close"></i>
        </div>
    </header>

    <div class="wallet-list">
        <div *ngIf="enabledNFTPlatforms.includes(NFTPlatform.META_MASK)" class="item active-mask" (click)="connect(NFTPlatform.META_MASK)">
            <img src="assets/imgs/mm.png" alt="MetaMask" />
            <div style="line-height: 1.2">MetaMask</div>
        </div>
        <div *ngIf="enabledNFTPlatforms.includes(NFTPlatform.COINBASE)" class="item active-mask" (click)="connect(NFTPlatform.COINBASE)">
            <img src="assets/imgs/home/coinbase.png" alt="Coinbase Wallet" />
            <div style="line-height: 1.2">Coinbase Wallet</div>
        </div>
        <div
            *ngIf="enabledNFTPlatforms.includes(NFTPlatform.WALLET_CONNECT)"
            class="item active-mask"
            (click)="connect(NFTPlatform.WALLET_CONNECT)"
        >
            <img src="assets/imgs/wc.png" alt="WalletConnect" />
            <div style="line-height: 1.2">WalletConnect</div>
        </div>
        <div *ngIf="enabledNFTPlatforms.includes(NFTPlatform.DAPPER)" class="item active-mask" (click)="connect(NFTPlatform.DAPPER)">
            <img src="assets/svg/dapper.svg" alt="MetaMask" />
            <div style="line-height: 1.2">Dapper</div>
        </div>

        <div *ngIf="enabledNFTPlatforms.includes(NFTPlatform.PHANTOM)" class="item active-mask" (click)="connect(NFTPlatform.PHANTOM)">
            <img src="assets/svg/phantom.svg" alt="Phantom" />
            <div style="line-height: 1.2">Phantom</div>
        </div>

        <div *ngIf="enabledNFTPlatforms.includes(NFTPlatform.BLOCTO)" class="item active-mask" (click)="connect(NFTPlatform.BLOCTO)">
            <img
                src="https://www.gitbook.com/cdn-cgi/image/width=40,height=40,fit=contain,dpr=2,format=auto/https%3A%2F%2F4111966839-files.gitbook.io%2F~%2Ffiles%2Fv0%2Fb%2Fgitbook-legacy-files%2Fo%2Fspaces%252F-MFJEAgz-LrhDYkRm4sv%252Favatar-1600919464132.png%3Fgeneration%3D1600919464422284%26alt%3Dmedia"
                alt="Blocto"
            />
            <div style="line-height: 1.2">Blocto</div>
        </div>

        <div *ngIf="enabledNFTPlatforms.includes(NFTPlatform.NEAR)" class="item active-mask" (click)="connect(NFTPlatform.NEAR)">
            <img src="assets/imgs/home/near-wallet.png" alt="Near" style="width: 24px; height: 24px" />
            <div style="line-height: 1.2">Near</div>
        </div>

        <div
            *ngIf="enabledNFTPlatforms.includes(NFTPlatform.UNSTOPPABLE_DOMAINS)"
            class="item active-mask"
            (click)="connect(NFTPlatform.UNSTOPPABLE_DOMAINS)"
        >
            <img src="/assets/svg/unstoppable-domains.svg" alt="Unstoppable Domains" style="width: 24px; height: 24px" />
            <div style="line-height: 1.2">Unstoppable Domains</div>
        </div>

        <div
            *ngIf="enabledNFTPlatforms.includes(NFTPlatform.TRUST_WALLET)"
            class="item active-mask"
            (click)="connect(NFTPlatform.TRUST_WALLET)"
        >
            <img src="/assets/svg/trust-wallet.svg" alt="Trust Wallet" />
            <div style="line-height: 1.2">Trust Wallet</div>
        </div>

        <div *ngIf="enabledNFTPlatforms.includes(NFTPlatform.PARTICLE)" class="item active-mask" (click)="connect(NFTPlatform.PARTICLE)">
            <img src="/assets/imgs/home/particle.png" alt="Particle" />
            <div style="line-height: 1.2">Particle</div>
        </div>
    </div>
</section>
