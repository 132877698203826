<section class="banner">
    <div *ngIf="projectInfo?.banner && projectInfo.banner?.text && !bannerExpired && !isAdminPage" class="announcement-bar">
        <span [innerHTML]="projectInfo.banner.text"></span>
        <a [href]="projectInfo.banner.link_route">{{projectInfo.banner.link_text}}</a>
        <div class="d-flex count-down-box" *ngIf="projectInfo.banner?.end_time">
            <div *ngFor="let item of timeArray" class="count-down-item" [ngStyle]="{'margin-right': item.name === 'Secs' ? '0px' : '8px'}">
                <div class="d-flex">
                    <div *ngFor="let num of item.numbers" class="count-item" style="margin-right: 2px">{{num}}</div>
                </div>
                <div class="name">{{item.name}}</div>
                <div class="split" *ngIf="item.name !== 'Secs'">:</div>
            </div>
        </div>
    </div>

    <div class="img-section" [class.admin-img-section]="isAdminPage">
        <div class="back-section" *ngIf="isShowBack">
            <div class="page-width">
                <button nz-button nzType="primary" nzGhost class="back-btn" backButton [backLink]="backUrl">
                    <i nz-icon nzType="arrow-left" nzTheme="outline"></i>Back
                </button>
            </div>
        </div>
        <div class="top-bg">
            <img
                class="object-fit-cover"
                [src]="projectInfo.resource.banner_bg || '/assets/imgs/default_banner.png'"
                width="100%"
                height="100%"
                style="background: #000"
            />
            <nz-upload
                *ngIf="isAdminPage && isAdminRole"
                [nzShowUploadList]="false"
                [nzBeforeUpload]="bannerBeforeUpload"
                [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
            >
                <div class="upload-banner-box d-flex align-items-center justify-content-center">
                    <div class="content">
                        <i class="edit-icon" nz-icon nzType="antd:edit" style="color: #fff"></i>
                        Click to change banner
                    </div>
                </div>
            </nz-upload>
        </div>

        <div class="gradient-bg"></div>

        <div class="relative page-width height-100p width-100p">
            <div class="social-medias">
                <div class="link-list">
                    <a *ngIf="projectInfo?.link" class="link-item" [href]="projectInfo.link" target="_blank">
                        <img
                            [nzTooltipColor]="'#353D46'"
                            [nzTooltipTrigger]="isMobile ? null : 'hover'"
                            nz-tooltip
                            nzTooltipPlacement="top"
                            nzTooltipTitle="Website"
                            src="assets/imgs/official-site.png"
                        />
                    </a>
                    <a *ngFor="let item of projectInfo.social_medias" class="link-item" [href]="item.link" target="_blank">
                        <i
                            [nzTooltipColor]="'#353D46'"
                            [nzTooltipTitle]="mediaLabelMap[item.name]"
                            [nzTooltipTrigger]="isMobile ? null : 'hover'"
                            [nzType]="'antd:' + item.name"
                            nz-icon
                            nz-tooltip
                            nzTooltipPlacement="top"
                            style="vertical-align: middle; color: #fff"
                        ></i>
                    </a>
                </div>

                <button *ngIf="isAdminPage && isAdminRole" class="add-social" nz-button nzShape="round" (click)="showAddSocialMediaModal()">
                    <i nz-icon class="fs-16" nzType="antd:add-collection"></i>
                    <span>Add Social</span>
                </button>

                <button *ngIf="isAdminPage" class="view-store" nz-button nzShape="round" (click)="viewStore()">
                    <i nz-icon class="fs-16" nzType="antd:eye"></i>
                    <span>View Page</span>
                </button>
            </div>

            <div class="info-section">
                <div class="logo" [ngStyle]="{'padding': projectInfo.resource.brand_avatar ? '6px' : '0'}">
                    <img [src]="projectInfo.resource.brand_avatar || '/assets/svg/default-brand-avatar.svg'" />

                    <nz-upload
                        *ngIf="isAdminPage && isAdminRole"
                        [nzShowUploadList]="false"
                        [nzBeforeUpload]="logoBeforeUpload"
                        [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
                    >
                        <div class="edit-icon">
                            <i nz-icon nzType="antd:edit" style="color: #fff"></i>
                        </div>
                    </nz-upload>
                </div>
                <div class="info-section-right">
                    <div
                        class="brand-name"
                        [ngClass]="{'cursor-pointer': isAdminPage && (projectInfo.status === BrandProjectStatus.UNVERIFIED || projectInfo.status === BrandProjectStatus.DRAFT)}"
                        (click)="(isAdminPage && isAdminRole && (projectInfo.status === BrandProjectStatus.UNVERIFIED || projectInfo.status === BrandProjectStatus.DRAFT)) ? showEditBrandNameModal() : null"
                    >
                        <img
                            *ngIf="projectInfo && projectInfo?.status === BrandProjectStatus.ACTIVE "
                            [src]="isAdminPage ? 'assets/imgs/certification-brand.png' : 'assets/imgs/certification-brand.png'"
                        />
                        <span class="overflow-ellipsis">{{projectInfo.name}}</span>

                        <i
                            *ngIf="isAdminPage && isAdminRole && (projectInfo.status === BrandProjectStatus.UNVERIFIED || projectInfo.status === BrandProjectStatus.DRAFT)"
                            class="edit-icon"
                            nz-icon
                            nzType="antd:edit"
                            style="color: #fff"
                        ></i>

                        <button *ngIf="isAdminPage" nz-button nzType="primary" nzShape="round" class="current-plan-btn">
                            <!-- 试用中 && 未绑卡 && enterprise 账号显示 -->
                            <ng-container
                                *ngIf="shopProfileService.isEnterprise && shopProfileService.isInFreeTrial && !shopProfileService.projectPlan.billing_card; else planTemp"
                            >
                                Current Plan: {{shopProfileService.getSubscriptionName(this.shopProfileService.projectPlan.sub_plan)}}. Free
                                trial ends on {{shopProfileService.projectPlan.trial_end_date | date: 'MMM dd,YYYY'}}. &nbsp;
                                <span class="fw-700 text-decoration-underline cursor-pointer" [routerLink]="['/admin/subscription']">
                                    Pay Now
                                </span>
                            </ng-container>

                            <!-- 其他 -->
                            <ng-template #planTemp>
                                Current Plan: {{shopProfileService.getSubscriptionName(this.shopProfileService.projectPlan.sub_plan)}}.
                                &nbsp;
                                <span
                                    *ngIf="shopProfileService.projectPlan.trial_end_date && !shopProfileService.isEnterprise"
                                    class="fw-700 text-decoration-underline cursor-pointer"
                                    [routerLink]="['/admin/subscription']"
                                >
                                    Upgrade
                                </span>
                                <span
                                    *ngIf="!shopProfileService.projectPlan.trial_end_date"
                                    class="fw-700 text-decoration-underline cursor-pointer"
                                    [routerLink]="['/admin/subscription']"
                                >
                                    Start Your Free Trial
                                </span>
                            </ng-template>
                        </button>
                    </div>
                    <button *ngIf="isShowVerifyBtn" nz-button nzGhost nzShape="round" (click)="verifyProject()">
                        <span class="colorful-text">Verify</span>
                    </button>
                    <div
                        *ngIf="this.isAdminPage && projectInfo.status === BrandProjectStatus.PENDING"
                        class="brand-pending-status colorful-text"
                    >
                        Pending for review
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isShowDescription">
        <div
            *ngIf="isAdminPage"
            class="brand-description cursor-pointer page-width"
            (click)="isAdminRole ? showEditDescription($event) : null"
        >
            <div>
                {{projectInfo.description}}
                <ng-container *ngIf="isAdminRole">
                    <ng-container *ngIf="!projectInfo.description">Click to add description</ng-container>
                    <i class="edit-icon" nz-icon nzType="antd:edit"></i
                ></ng-container>
            </div>
        </div>

        <div *ngIf="!isAdminPage" class="brand-description page-width" (click)="expanded = !expanded">
            <div [ngClass]="{'ellipsis-description':!expanded}">
                {{projectInfo.description}}
                <i *ngIf="expanded && isOverflow" class="cursor-pointer" nz-icon nzType="up" nzTheme="outline"></i>
            </div>
            <span class="expand-icon" *ngIf="!expanded && isOverflow">
                <i nz-icon nzType="down" nzTheme="outline"></i>
            </span>
        </div>
    </ng-container>
</section>
