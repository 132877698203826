import { Routes } from '@angular/router';
import { OldDiscordAuthComponent } from './app.component';

export const redirectRouteList: Routes = [
    {
        path: 'home',
        redirectTo: '/main/home',
    },
    {
        path: 'design',
        redirectTo: '/main/design',
    },
    {
        path: 'faq',
        redirectTo: '/main/faq',
    },
    {
        path: 'referral',
        redirectTo: '/main/referral',
    },
    {
        path: 'invite/:inviteCoupon',
        redirectTo: '/main/invite/:inviteCoupon',
    },
    {
        path: 'policy/:page',
        redirectTo: '/main/policy/:page',
    },
    {
        path: 'token/:contract_address/:token_id/:owner_address',
        redirectTo: '/main/token/:contract_address/:token_id/:owner_address',
    },
    {
        path: 'token/ballerz/:contract_address/:token_id/:owner_address',
        redirectTo: '/main/token/ballerz/:contract_address/:token_id/:owner_address',
    },
    {
        path: 'redeem-points',
        redirectTo: '/main/redeem-points',
    },
    {
        path: 'discord/:project',
        component: OldDiscordAuthComponent,
    },
    {
        path: 'brand',
        children: [
            {
                path: 'quick-start',
                redirectTo: '/main/brand/quick-start',
            },
            {
                path: 'all-brands',
                redirectTo: '/main/brand/all-brands',
            },
            {
                path: '*',
                redirectTo: '/main/home',
            },
        ],
    },
    {
        path: ':project',
        redirectTo: '/main/:project',
    },
    {
        path: '*',
        redirectTo: '/main/home',
    },
];
