<div class="report-section">
    <div class="section-title">
        <span [class.active]="selectedTab === 'blueChipHolders'" (click)="tabChange('blueChipHolders')">
            <span> Blue Chip Holders</span>
            <span class="count">{{blueChipHolders?.length}}</span>
        </span>
        <span [class.active]="selectedTab === 'whales'" (click)="tabChange('whales')">
            <span>Whales</span>
            <span class="count">{{whales?.length}}</span>
        </span>
    </div>

    <div class="content">
        <div [hidden]="selectedTab !== 'blueChipHolders'">
            <div *ngFor="let item of blueChipHolders;let i = index" class="list-item">
                <span>{{i + 1}}</span>
                <span class="addr">{{item.wallet_address | omit:5:4}}</span>
                <div class="avatars">
                    <div *ngFor="let item of item.collections" class="avatar-item">
                        <img
                            nz-tooltip
                            [nzTooltipTitle]="item.name"
                            errorImage="assets/imgs/break-img.png"
                            appImgLoadSmooth
                            [lazyLoad]="item.imageUrl"
                        />
                        <span>x{{item.nftCount > 99 ? '99+': item.nftCount}}</span>
                        <!-- GK HIDE BNB/BSC -->
                        <!-- [ngClass]="{
                            'bnb': item.chain === 'bnb',
                            'eth': item.chain === 'eth'
                        }" -->
                        <div class="chain-badge" [ngClass]="{'eth': item.chain === 'eth'}">
                            <!-- GK HIDE BNB/BSC -->
                            <!-- <i *ngIf="item.chain === 'bnb'" nz-icon nzType="antd:binance"></i> -->
                            <img *ngIf="item.chain === 'eth'" src="/assets/imgs/home/wallets/ethereum.png" />
                        </div>
                    </div>
                </div>
                <span>{{item.my_nft_count}} NFT(s)</span>
            </div>
        </div>

        <div [hidden]="selectedTab !== 'whales'">
            <div *ngFor="let item of whales;let i = index" class="list-item">
                <span>{{i + 1}}</span>
                <span class="addr">{{item.wallet_address | omit:5:4}}</span>
                <div class="avatars">
                    <div *ngFor="let item of item.collections" class="avatar-item">
                        <img
                            nz-tooltip
                            [nzTooltipTitle]="item.name"
                            errorImage="assets/imgs/break-img.png"
                            appImgLoadSmooth
                            [lazyLoad]="item.imageUrl"
                        />
                        <span>x{{item.nftCount > 99 ? '99+': item.nftCount}}</span>
                        <!-- GK HIDE BNB/BSC -->
                        <!-- [ngClass]="{
                            'bnb': item.chain === 'bnb',
                            'eth': item.chain === 'eth'
                        }" -->
                        <div class="chain-badge" [ngClass]="{'eth': item.chain === 'eth'}">
                            <!-- GK HIDE BNB/BSC -->
                            <!-- <i *ngIf="item.chain === 'bnb'" nz-icon nzType="antd:binance"></i> -->
                            <img *ngIf="item.chain === 'eth'" src="/assets/imgs/home/wallets/ethereum.png" />
                        </div>
                    </div>
                </div>
                <span>{{item.my_nft_count}} NFT(s)</span>
            </div>
        </div>
    </div>
</div>
