import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject } from 'rxjs';

export enum AdminRoleEnum {
    ADMIN = 1,
    SUPERVISOR = 2,
    USER = 3,
    OPERATOR = 4,
}

@Injectable({
    providedIn: 'root',
})
export class AuthTokenService {
    isLoggedIn = false;

    tokenInfo: { role: number; [key: string]: any };

    role$ = new BehaviorSubject<AdminRoleEnum>(null);

    get role() {
        return this.role$.value;
    }

    constructor(private auth: AngularFireAuth) {
        this.auth.idTokenResult.subscribe(tokenResult => {
            this.tokenInfo = tokenResult?.claims as any;
            if (this.tokenInfo?.role === 4) {
                this.role$.next(AdminRoleEnum.OPERATOR);
            } else {
                this.role$.next(AdminRoleEnum.ADMIN);
            }
        });

        this.auth.authState.subscribe(user => {
            if (user) {
                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;
            }
        });
    }
}
