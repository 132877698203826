<section class="verification-submit-modal" [class.verification-guide]="isFirstImportProject">
    <ng-container *ngIf="!isFirstImportProject">
        <app-general-modal-header [title]="'Brand Ownership Verification'" [isBrandModal]="true" (cancel)="modalRef.triggerCancel()"></app-general-modal-header>

        <p class="desc mt-2.5">Publish your project page & earn ‘Verified’ badge by verifying your ownership of this collection</p>
    </ng-container>

    <ng-container *ngIf="isFirstImportProject">
        <header>
            <h2 class="mb-5">
                <span nz-icon nzType="check-circle" nzTheme="fill" style="color: #72bbfd"></span>
                Congrats! Collection Imported. Verify Your Ownership To
            </h2>

            <div class="modal-close-icon" (click)="modalRef.triggerCancel()">
                <i nz-icon nzType="close"></i>
            </div>
        </header>

        <div class="fs-16 text-align-center colorful-text" style="margin: 20px 0 5px">Next Step: Verify your ownership to this collection to get the following privileges:</div>

        <div class="marketing-content">
            <img src="/assets/imgs/admin/verify-guide-01.png" class="item" />
            <img src="/assets/imgs/admin/verify-guide-02.png" class="item" />
            <img src="/assets/imgs/admin/verify-guide-03.png" class="item" />
            <img src="/assets/imgs/admin/verify-guide-04.png" class="item" />
        </div>
    </ng-container>

    <div class="verification-content-wrap">
        <div class="section-title">Brand Name *</div>
        <div class="d-flex justify-content-between input-box">
            <div>
                <input nz-input type="text" placeholder="Enter your brand name" [formControl]="brandName" />
                <div *ngIf="isAlreadyExists" style="color: #f44336; line-height: 22px">Brand name already exists</div>
            </div>
            <div class="submit-tip">Cannot be changed once submitted</div>
        </div>

        <app-verification-content #verifyContentCmp [contractInfo]="contractInfo"></app-verification-content>

        <ng-container *ngIf="brandName.value && brandName.value !== name">
            <div class="checkbox">
                <label nz-checkbox [(ngModel)]="isUpdateProjectKey"> Update my existing page URL with {{locationOrigin}}/main/{{projectName}} </label>
            </div>
            <div *ngIf="!isFirstImportProject && isUpdateProjectKey" class="reminder-info">
                <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                Reminder: the URL to your existing campaigns will also be updated
            </div>
        </ng-container>
    </div>

    <div class="button-group">
        <button *ngIf="isFirstImportProject" class="cancel-btn" nz-button nzShape="round" (click)="modalRef.triggerCancel()">DO IT LATER</button>
        <button class="active-mask" nz-button nzType="primary" nzShape="round" [disabled]="disabledSubmitBtn" [nzLoading]="submitting" (click)="goVerifyProject()">
            {{isFirstImportProject?'VERIFY':'CONFIRM'}}
        </button>
    </div>
</section>
