<section appLoading [loading]="fetchingCountryInfo" class="shipping-detail-modal">
    <div class="form-box">
        <form nz-form [formGroup]="addressForm" nzLayout="vertical">
            <nz-form-label nzFor="firstName">Full Name *</nz-form-label>
            <div class="form-items d-flex justify-content-between align-items-start">
                <nz-form-item class="flex-1">
                    <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                        <input nz-input formControlName="firstName" id="firstName" placeholder="First name" />
                    </nz-form-control>
                </nz-form-item>
                <div class="divider" style="width: 20px"></div>
                <nz-form-item class="flex-1">
                    <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                        <input nz-input id="lastName" formControlName="lastName" placeholder="Last name" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <div class="form-items d-flex justify-content-between align-items-start">
                <nz-form-item class="flex-1">
                    <nz-form-label nzFor="phoneNumber">Phone Number *</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                        <input nz-input id="phoneNumber" formControlName="phoneNumber" placeholder="Phone" />
                    </nz-form-control>
                </nz-form-item>

                <div class="divider" style="width: 20px"></div>

                <nz-form-item class="flex-1">
                    <nz-form-label nzFor="email">Email *</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                        <input nz-input id="email" formControlName="email" placeholder="Email" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <nz-form-item>
                <nz-form-label nzFor="addressLine1">Shipping Address *</nz-form-label>
                <div class="form-items d-flex justify-content-between align-items-start country-box">
                    <nz-form-item class="flex-1">
                        <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                            <nz-select
                                nzSize="large"
                                nzPlaceHolder="Country/region"
                                id="country"
                                formControlName="country"
                                nzShowSearch
                                [nzLoading]="fetchingCountryInfo"
                            >
                                <nz-option *ngFor="let item of shopifyCountryList" [nzValue]="item.name" [nzLabel]="item.name"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>

                    <div class="divider" style="width: 20px"></div>

                    <ng-container *ngIf="!isNoState">
                        <nz-form-item class="flex-1 state-form-item">
                            <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                                <nz-select
                                    nzSize="large"
                                    [nzPlaceHolder]="isHongKong ? 'Region' : 'State'"
                                    id="state"
                                    formControlName="state"
                                    [nzLoading]="fetchingCountryInfo"
                                    nzShowSearch
                                >
                                    <nz-option *ngFor="let item of provinceList" [nzValue]="item.name" [nzLabel]="item.name"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>

                        <div class="divider" style="width: 20px"></div>
                    </ng-container>

                    <nz-form-item class="flex-1 city">
                        <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                            <input nz-input id="city" formControlName="city" id="city" placeholder="City" />
                        </nz-form-control>
                    </nz-form-item>
                </div>

                <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                    <input #addressText nz-input id="addressLine1" formControlName="addressLine1" placeholder="Address line 1" />
                </nz-form-control>
            </nz-form-item>

            <div class="form-items d-flex justify-content-between align-items-start">
                <nz-form-item class="flex-1">
                    <nz-form-control>
                        <input nz-input id="addressLine2" formControlName="addressLine2" placeholder="Address line 2 (Optional)" />
                    </nz-form-control>
                </nz-form-item>

                <div class="divider" style="width: 20px"></div>

                <nz-form-item class="desktop-zip flex-1">
                    <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                        <input nz-input id="zipCode" formControlName="zipCode" placeholder="Zip Code" />
                    </nz-form-control>
                </nz-form-item>
            </div>

            <nz-form-item class="mobile-zip flex-1">
                <nz-form-label nzFor="zipCode">Zip code</nz-form-label>
                <nz-form-control nzHasFeedback [nzErrorTip]="errorTpl">
                    <input nz-input formControlName="zipCode" placeholder="Zip Code" />
                </nz-form-control>
            </nz-form-item>

            <ng-template #errorTpl let-control>
                <ng-container *ngIf="control.hasError('customError')">{{control.errors?.customError}}</ng-container>
            </ng-template>
        </form>
    </div>
</section>
