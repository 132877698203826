<section class="report-section">
    <div class="section-title">
        <span>Mints Summary</span>
        <span>
            <span style="font-size: 12px; font-weight: normal">Total Mints</span>
            {{total | number:'1.0-0'}}</span
        >
    </div>

    <main class="content">
        <section class="chart" appEcharts [initialOption]="initialOption" [optionToBeUpdate]="optionToBeUpdate"></section>
    </main>
</section>
