import { NetworkEnum } from './../campaign/campaign.service';
import { BehaviorSubject } from 'rxjs';
import { BrandNftMerchProduct } from './admin.service';
import { Injectable } from '@angular/core';
import { ProjectDetail } from '@modules/main/advanced-customization/advanced-customization-state.service';

export interface BrandProjectDetail extends ProjectDetail {
    merch_options?: number[];
    resource: {
        logo_black_path: string;
        logo_black_src: string;
        logo_white_path: string;
        logo_white_src: string;
        banner_bg?: string;
        brand_avatar?: string;
    };
    status: BrandProjectStatus;
    project_key: string;
    contract_list: ContractItem[];
    enable_community: boolean;
    enable_shop: boolean;
    project_type: string;
    animoca_demo: boolean;
    customize_column_config?: {
        customize_column_table: string;
        columns: {
            name: string;
            type: 'number' | 'enum';
            is_array: boolean;
            allow_sort: boolean;
            allow_filter: boolean;
            display_community_table: boolean;
            enum_values?: { key: number; value: any }[];
        }[];
    };
}

export interface ContractItem {
    chain: NetworkEnum;
    collection_id: number;
    contract_address: string;
    contract_image_url: string;
    contract_name: string;
    id: number;
    project_id: number;
    status: BrandProjectStatus;
    is_processing: boolean;
    enable_community: boolean;
    nft_type: string;
}

export enum BrandProjectStatus {
    ACTIVE = 'active',
    PENDING = 'pending',
    UNVERIFIED = 'unverified',
    DRAFT = 'draft',
}

export interface ProductSalesItem {
    productName: string;
    totalOrders: number;
}

export interface AddressSalesItem {
    address: string;
    totalOrders: number;
}

export interface QRCodeScannedItem {
    count: number;
    tokenId: string;
    walletAddress: string;
}

export interface PopularNFTItem {
    address: string;
    stickerImage: string;
    tokenId: string;
    totalOrders: number;
}

@Injectable()
export class AdminDataService {
    nftProductList: BrandNftMerchProduct[] = [];
    generalProductList: BrandNftMerchProduct[] = [];

    listedProductSet = new Set<BrandNftMerchProduct>();

    projectInfo: BrandProjectDetail;

    accountBalance$ = new BehaviorSubject(null);

    get accountBalance() {
        return this.accountBalance$.getValue();
    }

    get productList() {
        return this.pProductList;
    }
    set productList(val) {
        this.nftProductList = val.filter(item => item.is_nft_merch);
        this.generalProductList = val.filter(item => !item.is_nft_merch);
        this.pProductList = val;
    }

    get noSmartContract() {
        return !this.projectInfo?.contract_name || !this.projectInfo?.contract_address;
    }

    private pProductList: BrandNftMerchProduct[] = [];

    constructor() {}
}
