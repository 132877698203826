import { EchartsOption } from '../directives/echarts.directive';

export enum SESSION_STORAGE_KEY_ENUM {
    ACCESS_TOKEN = 'access_token',
    CONTRACT_INFO = 'contractInfo',
    SELECTED_COMMUNITY_ADDRESS_LIST = 'selected_community_address_list',
    SESSION_ROLE_DISCORD_INFO_KEY = 'session_role_discord_info_key',
    INVITE_COUPON = 'inviteCoupon',
    SUBSCRIBE_SHOWN = 'subscribeShown',
    WALLET_ADDR = 'walletAddr',
    ENCRYPTED_WALLET_ADDR = 'encryptedWalletAddr',
    WALLET_TYPE = 'walletType',
    SHIPPING_DETAIL_CACHE = 'shippingDetailCache',
    COMBINE_WALLET_LIST = 'combinedWalletList',
    TEMPORARY_DISCORD_USER_INFO = 'temporaryDiscordUserInfo',
    TEMPORARY_PROJECT_ID = 'temporaryProjectId',
    IS_FIRST_IMPORT_PROJECT = 'isFirstImportProject',
    COMMUNITY_TABLE_COLUMN_KEY_SORT = 'sortOfColumnKeys',
}

export enum LOCAL_STORAGE_KEY_ENUM {
    TWITTER_USER_INFO = 'twitterUserInfo', // 类似near的钱包都是当前页面跳转，所以service中信息会丢失，必须用web storage 的方式记下来
    DISCORD_USER_INFO = 'discordUserInfo', // 类似near的钱包都是当前页面跳转，所以service中信息会丢失，必须用web storage 的方式记下来
    TELEGRAM_USER_INFO = 'telegramUserInfo', // 类似near的钱包都是当前页面跳转，所以service中信息会丢失，必须用web storage 的方式记下来
    CAMPAIGN_ID = 'campaignId', // 目前用在GA Event. app内置浏览器中用户在社交媒体授权后是页面跳转，所以需要在跳转前记录campaignId
    SOCIAL_MEDIA_TYPE = 'socialMediaType', // 社交媒体授权跳转前的社交媒体类型
    SOCIAL_MEDIA_AUTH = 'social_media_auth', // 社交媒体授权跳转前的社交媒体类型
}

export const LIFO_CHART_COLORS = [
    '#1271F1',
    '#5A3FFF',
    '#766FFA',
    '#45F4FF',
    '#72BBFD',
    '#6AD9B6',
    '#007B79',
    '#EBD671',
    '#F8833E',
    '#596D8F',
];

export interface GeneralReportChartConfig {
    sectionName: string;
    sectionDescription: string;
    legendConfig: {
        name: string;
        count: number;
        percent: number;
    }[];
    chartConfig?: EchartsOption;
    sectionNameImgLink?: string;
    donutChartCenterInfo?: {
        img?: string;
        name: string;
        count: number | string;
    };
    theme?: 'light' | 'dark';
}

export interface CustomChartDataItem {
    id: number;
    start_date: string;
    end_date: string;
    count: number;
    value: string;
    rank: number;
}
