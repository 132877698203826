import { AfterViewInit, Component } from '@angular/core';
import { CommonService } from '@services/common.service';
import { EchartsOption } from '@src/app/shared/directives/echarts.directive';
import { LIFO_CHART_COLORS } from '@src/app/shared/types/general.type';
import { format } from 'date-fns';

@Component({
    selector: 'app-mints-summary',
    templateUrl: './mints-summary.component.html',
    styleUrls: ['./mints-summary.component.less'],
})
export class MintsSummaryComponent implements AfterViewInit {
    total = 3158476;

    initialOption: EchartsOption = {
        title: {
            show: false,
        },
        color: LIFO_CHART_COLORS,
        backgroundColor: 'transparent',
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985',
                },
            },
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            formatter: params => {
                // Echarts tooltip 百分比显示
                let html = `<div style="color:#fff">${params[0].name}<br/>`;

                for (let i = 0; i < params.length; i++) {
                    html += `<div style="white-space:nowrap;">
                        <span>${params[i].marker}</span>
                        <span>${params[i].seriesName}</span>
                        <span style="float:right;padding-left:10px;">
                            ${params[i].value[1]}
                        </span>
                    </div>`;
                }
                // eslint-disable-next-line prefer-template
                return html + '</div>';
            },
        },
        legend: {
            type: 'scroll',
            left: 0,
            top: 0,
            data: null,
            tooltip: {
                show: true,
                textStyle: {
                    color: '#fff',
                },
            },
        },

        grid: {
            top: '50',
            left: '10',
            right: '10',
            bottom: '10',
            containLabel: true,
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
            data: null,
            axisLabel: {
                hideOverlap: true,
                formatter: '{MM}/{dd}',
            },
            axisPointer: {
                label: {
                    formatter: (params: any) => format(params.value, 'yyyy-MM-dd'),
                },
            },
            axisLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: [
            {
                type: 'value',
                axisPointer: {
                    label: {
                        precision: 0,
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: '#596175',
                        type: 'dashed',
                        width: 0.5,
                    },
                },
            },
            {
                type: 'value',
                axisPointer: {
                    label: {
                        precision: 0,
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: '#596175',
                        type: 'dashed',
                        width: 0.5,
                    },
                },
            },
        ],
    };

    optionToBeUpdate: EchartsOption;

    constructor(private screenService: CommonService) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.setupUpdatedOption();
        });
    }

    setupUpdatedOption() {
        this.optionToBeUpdate = {};
    }
}
