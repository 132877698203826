import { Component, Input } from '@angular/core';
import { EchartsOption } from '@shared/directives/echarts.directive';
import { LIFO_CHART_COLORS } from '@shared/types/general.type';
import { isNumber, round } from 'lodash';
import { LifoChartConfig } from '../general-lifo-chart/abstract-lifo-chart.component';

@Component({
    selector: 'app-general-pie-chart',
    templateUrl: './general-pie-chart.component.html',
    styleUrls: ['./general-pie-chart.component.less'],
})
export class GeneralPieChartComponent {
    @Input() config: LifoChartConfig;

    LIFO_CHART_COLORS = LIFO_CHART_COLORS;
    isNumber = isNumber;

    initialOption: EchartsOption = {
        tooltip: {
            trigger: 'item',
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'transparent',
            formatter: params => {
                const html = `<div style="color:#fff;font-family: 'Kanit'">
                                <div
                                    style="
                                        color: rgba(255, 255, 255, 0.8); line-height: 16px;
                                        margin-bottom: 7px;
                                    "
                                >
                                    ${params.name}
                                </div>
                                <div style="display: flex; align-items: center; height: 16px; line-height: 16px">
                                    <span style="padding-right: 6px; border-right: 1px solid #353D46">
                                        ${round(params.value, 0)}
                                    </span>
                                    <span style="padding-left: 5px">${round(params.percent, 1)}%</span>
                                </div>
                            </div>`;
                return html;
            },
        },
        legend: {
            show: false,
        },
        backgroundColor: 'transparent',
        color: LIFO_CHART_COLORS,
    };

    constructor() {}
}
