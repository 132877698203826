import { Component, Input } from '@angular/core';
import { PerformanceCardData } from '../../../modules/admin/campaign/campaign.model';

@Component({
    selector: 'app-performance-card',
    templateUrl: './performance-card.component.html',
    styleUrls: ['./performance-card.component.less'],
})
export class PerformanceCardComponent {
    @Input() cardInfo: PerformanceCardData;

    constructor() {}
}
