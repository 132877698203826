import { CampaignService } from '@admin/campaign/campaign.service';
import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@services/common.service';
import { LifoUploadedFile } from '@src/app/services/firebase/firebase-file-upload.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-select-csv-list',
    templateUrl: './select-csv-list.component.html',
    styleUrls: ['./select-csv-list.component.less'],
})
export class SelectCsvListComponent implements OnInit {
    @Input() isCsv: boolean = false;
    @Input() selectedFileIds: number[];
    fileList: LifoUploadedFile[];
    fetchingFileList = false;

    selectedFileSet = new Set<LifoUploadedFile>();

    removingFileSet = new Set<LifoUploadedFile>();

    constructor(
        private campaignService: CampaignService,
        private messageService: CommonService,
        public modalRef: NzModalRef,
        public screenService: CommonService
    ) {}

    ngOnInit(): void {
        this.fetchingFileList = true;
        this.campaignService
            .getFileUploadList(this.isCsv)
            .then(res => {
                this.fileList = res?.filter(item => item.fileType === 'csv');
                this.selectedFileIds?.forEach(id => {
                    const file = this.fileList.find(item => item.id === id);
                    if (file) {
                        this.selectedFileSet.add(file);
                    }
                });
            })
            .finally(() => (this.fetchingFileList = false));
    }

    checkStatusChange(file: LifoUploadedFile, checked: boolean) {
        if (checked) {
            this.selectedFileSet.add(file);
        } else {
            this.selectedFileSet.delete(file);
        }
    }

    removeFile(file: LifoUploadedFile) {
        this.removingFileSet.add(file);
        this.campaignService
            .deleteUploadedFile(file.id)
            .then(() => {
                this.fileList = this.fileList.filter(item => item.id !== file.id);
                this.selectedFileSet.delete(file);
            })
            .catch(err => this.messageService.errorMessageByResponse(err))
            .finally(() => this.removingFileSet.delete(file));
    }

    selectAll() {
        if (this.fileList.length === this.selectedFileSet.size) {
            this.selectedFileSet.clear();
        } else {
            this.selectedFileSet = new Set(this.fileList);
        }
    }
}
