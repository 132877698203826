export const getObjectURL = file => {
    let url = null;
    if (window?.URL) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file);
    } else if (window?.webkitURL) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
    }
    return url;
};
