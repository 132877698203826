import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-bullet-point1',
    templateUrl: './bullet-point1.component.html',
    styleUrls: ['./bullet-point1.component.less'],
})
export class BulletPoint1Component {
    @Input() theme: 'light' | 'dark' = 'dark';

    bulletPointList = [
        {
            imgUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/lifeform.png',
            name: 'Lifeform Cartoon Avatar',
            count: 3158476,
        },
        {
            imgUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/starsharks.png',
            name: 'Star Sharks',
            count: 682882,
        },
        {
            imgUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/polkamon.png',
            name: 'Polkamon',
            count: 570827,
        },
        {
            imgUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/spaceid.png',
            name: 'SpaceID',
            count: 415328,
        },
        {
            imgUrl: 'https://storage.googleapis.com/influencer-272204.appspot.com/public/lifeform-report/cyberconnect.png',
            name: 'CyberConnect',
            count: 204579,
        },
    ];

    constructor() {}
}
