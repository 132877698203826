<div
    class="report-section"
    [ngClass]="{
        'dark':config?.theme === 'dark',
        'light':config?.theme==='light'
    }"
>
    <div class="section-title">{{config.sectionName}}</div>
    <div class="section-content">
        <div *ngFor="let item of bulletPointList" class="content-item">
            <div class="line1">
                <img src="{{item.imgUrl}}" />
                <span>{{item.name}}</span>
            </div>

            <div class="line2" [style.backgroundColor]="item.color" [style.width]="item.percent*300 + '%'">
                <span>{{item.count}}</span>
                <div class="divider"></div>
                <span>{{item.percent | percent:'1.0-0'}}</span>
            </div>
        </div>
    </div>
</div>
