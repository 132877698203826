import { DOCUMENT, Location } from '@angular/common';
import { Directive, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RouterChangeMonitorService } from '@src/app/services/router-change-monitor.service';

@Directive({
    selector: '[backButton]',
})
export class BackButtonDirective {
    @Input() backLink: string | (string | number)[];

    private validHostSet = new Set(['localhost:4300', 'nft-mpc-test.lifo.ai', 'w3w.ai', 'web3wear.com']);

    constructor(
        private location: Location,
        private router: Router,
        private routeMonitorService: RouterChangeMonitorService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    @HostListener('click')
    onClick() {
        try {
            if (this.routeMonitorService.previousPath) {
                this.location.back();
            } else {
                throw new Error('');
            }
        } catch (err) {
            // Referrer is not a valid URL or empty
            if (typeof this.backLink === 'string') {
                this.router.navigate([this.backLink], { replaceUrl: true });
            } else {
                this.router.navigate(this.backLink, { replaceUrl: true });
            }

            console.warn(err);
        }
    }
}
