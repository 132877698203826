<div class="general-modal-box">
    <app-general-modal-header
        *ngIf="title"
        [title]="title"
        [isBrandModal]="true"
        (cancel)="modalRef.triggerCancel()"
    ></app-general-modal-header>
    <span *ngIf="!title" nz-icon nzType="close" class="close-icon" (click)="modalRef.triggerCancel()"></span>

    <div *ngIf="iconType" class="text-align-center">
        <span
            *ngIf="iconType === 'warning'"
            nz-icon
            nzType="exclamation-circle"
            nzTheme="fill"
            style="font-size: 35px; color: #f2c94c"
        ></span>
        <span *ngIf="iconType === 'success'" nz-icon nzType="check-circle" nzTheme="fill" style="font-size: 35px; color: #72bbfd"></span>
    </div>

    <p class="description" *ngIf="description" [innerHTML]="description | safeHtml"></p>

    <div class="btns">
        <button
            *ngIf="cancelVisible"
            class="cancel"
            nz-button
            nzType="default"
            nzShape="round"
            nzSize="large"
            (click)="modalRef.triggerCancel()"
            [disabled]="submitting"
        >
            {{cancelText}}
        </button>

        <button
            class="confirm"
            nz-button
            nzType="primary"
            nzShape="round"
            nzSize="large"
            (click)="modalRef.triggerOk()"
            [nzLoading]="submitting"
        >
            {{confirmText}}
        </button>
    </div>
</div>
