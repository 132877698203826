import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SocialMedia, SocialMediaConnectService } from '@src/app/services/socialmedia-connect.service';
import { LOCAL_STORAGE_KEY_ENUM } from '@src/app/shared/types/general.type';
import { NidavellirService } from '../../main/nidavellir.service';

@Component({
    selector: 'app-general-close-window',
    templateUrl: './general-close-window.component.html',
    styleUrls: ['./general-close-window.component.less'],
})
export class GeneralCloseWindowComponent implements OnInit {
    pageMode: 'redirect' | 'close' = 'redirect';
    constructor(
        private activatedRoute: ActivatedRoute,
        private socialMediaConnectService: SocialMediaConnectService,
        private nidavellirService: NidavellirService
    ) {}

    async ngOnInit() {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        const socialMediaType: any =
            this.activatedRoute.snapshot.paramMap.get('social_media') ||
            (localStorage.getItem(LOCAL_STORAGE_KEY_ENUM.SOCIAL_MEDIA_TYPE) as SocialMedia.DISCORD) ||
            SocialMedia.TWITTER;

        window.localStorage.setItem(
            LOCAL_STORAGE_KEY_ENUM.SOCIAL_MEDIA_AUTH,
            JSON.stringify({ socialMedia: socialMediaType, queryParams })
        );

        // 如果500ms后弹窗未关闭，可能是浏览器阻止了弹窗，在当前页面打开的授权页面。
        // 就直接在当前窗口获取授权信息，并跳转回原先的页面
        // setTimeout(async () => {
        try {
            if (queryParams) {
                const res = await this.socialMediaConnectService.getSocialMediaInfo(socialMediaType, queryParams);

                if (res.additionalParameters?.walletAddress) {
                    await this.nidavellirService.updateUserInfo({
                        walletAddress: res.additionalParameters.walletAddress,
                        contactList: [
                            {
                                contactId: res.principalName,
                                contact: res.name,
                                contactType: socialMediaType,
                            },
                        ],
                    });
                }

                if (res.additionalParameters?.backPageUrl) {
                    window.close();
                    window.location.href = res.additionalParameters.backPageUrl;
                    return;
                } else {
                    this.pageMode = 'close';
                    window.close();
                }
            } else {
                this.pageMode = 'close';
                window.close();
            }
        } catch (err) {
            console.warn(err);
            this.pageMode = 'close';
            window.close();
        }
        // }, 500);
    }
}
