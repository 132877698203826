<div
    class="report-section"
    [ngClass]="{
        'dark':theme === 'dark',
        'light':theme==='light'
    }"
>
    <div class="section-title">Total Mints</div>
    <div class="section-content">
        <div *ngFor="let item of bulletPointList;let i = index" class="bulletpoint-item" [ngClass]="['bullet-item-'+(i+1)]">
            <section class="performance-card">
                <div class="circle-box relative">
                    <img class="outer" src="assets/imgs/admin/circle/1.png" />
                    <img class="middle" src="assets/imgs/admin/circle/2.png" />
                    <img class="middle" src="assets/imgs/admin/circle/3.png" />
                    <img class="middle" src="assets/imgs/admin/circle/4.png" />
                    <img class="inner" src="assets/imgs/admin/circle/1.png" />

                    <div class="img-info relative">
                        <img [src]="item.imgUrl" />
                    </div>
                </div>
            </section>

            <div class="text-info">
                <div class="name">{{item.name}}</div>
                <div class="count">{{item.count|number:'1.0-0'}}</div>
            </div>
        </div>
    </div>
</div>
