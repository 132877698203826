<section class="quick-start-page page-width">
    <ng-container *ngIf="pageType !== 'admin' || (shopDetail && !shopDetail.nft_project)">
        <div class="section-title">Set Up</div>
        <!-- *ngIf="pageType === 'admin'" -->
        <div class="choose-plan">
            <div class="form-item">
                <div class="choose-plan-title">
                    <div class="form-label">
                        Choose Your Plan *
                        <span
                            *ngIf="pageType === 'admin'"
                            style="color: #72bbfd"
                            class="fw-400 cursor-pointer active-mask"
                            [routerLink]="['/','admin','preview-subscription']"
                            [queryParams]="{preview : 1}"
                        >
                            View details
                        </span>
                    </div>

                    <div class="payment-option" *ngIf="pageType === 'admin'">
                        <div>
                            <nz-switch
                                [ngModel]="paymentPeriod === 'annually'"
                                [nzSize]="'small'"
                                (ngModelChange)="periodSwitchChange($event)"
                            ></nz-switch>
                            <span class="fw-700 fs-16">Pay Annually</span>
                        </div>
                        <div class="save">(Save Up to 20%)</div>
                    </div>
                </div>

                <div class="plan-list">
                    <div *ngFor="let plan of PLANS" class="plan-item" [class.selected]="selectedPlan === plan" (click)="selectPlan(plan)">
                        <div *ngIf="plan.id !== SubscriptionPlanEnum.FREE" class="left">
                            <img *ngIf="plan.id === SubscriptionPlanEnum.ADVANCED" src="/assets/imgs/star.png" width="32" />
                            <img
                                *ngIf="plan.id === SubscriptionPlanEnum.PREMIUM"
                                src="/assets/imgs/admin/upgrade-premium/diamond.png"
                                width="44"
                            />
                            <img *ngIf="plan.id === SubscriptionPlanEnum.ENTERPRISE" src="/assets/imgs/enterprise.png" width="48" />
                        </div>
                        <div class="right">
                            <div class="name fs-14 fw-700">
                                <span>{{plan.name}}</span>
                                <div *ngIf="plan.id !== SubscriptionPlanEnum.FREE" class="free-trial">14 Days Free</div>
                            </div>
                            <div class="fs-14 desc">
                                <div
                                    class="price"
                                    [class.advanced]="plan.id === SubscriptionPlanEnum.ADVANCED"
                                    [class.premium]="plan.id === SubscriptionPlanEnum.PREMIUM"
                                >
                                    <div *ngIf="plan.price > 0" class="price">
                                        <span class="amount">
                                            {{(paymentPeriod==='annually' ? plan.annuallyPrice : plan.price) |
                                            currency:'USD':'symbol':'1.0-0'}}
                                        </span>
                                        <span class="period">/{{paymentPeriod === 'annually' ? 'year' : 'month'}}</span>
                                    </div>
                                </div>
                                <span *ngIf="plan.id === SubscriptionPlanEnum.FREE">
                                    <ng-container *ngIf="selectedPlan.id === SubscriptionPlanEnum.FREE">Limited features</ng-container>
                                    <ng-container *ngIf="selectedPlan.id !== SubscriptionPlanEnum.FREE">
                                        Up to {{plan.numWalletsManagement|number:'1.0-0'}} wallets
                                    </ng-container>
                                </span>
                                <span *ngIf="[SubscriptionPlanEnum.ADVANCED, SubscriptionPlanEnum.PREMIUM].includes(plan.id)">
                                    Up to {{plan.numWalletsManagement|number:'1.0-0'}} wallets
                                </span>
                                <span *ngIf="plan.id === SubscriptionPlanEnum.ENTERPRISE">Offline customization</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-item">
            <div class="form-label">Choose Your Dashboard Type*</div>
            <div class="airdrop-type">
                <div class="airdrop-type-item" [class.active]="projectType === 'normal'" (click)="changeProjectType('normal')">
                    <div class="left">
                        <div class="type-name">dApp (General)</div>
                        <div class="type-desc">Recommended for NFT Projects and Consumer Brands</div>
                    </div>

                    <div class="right">
                        <img src="/assets/imgs/airdrop-nft-airdrop.png" />
                    </div>
                </div>

                <div
                    class="airdrop-type-item"
                    [class.active]="projectType === 'gamify'"
                    [class.disabled]="selectedPlan.id === SubscriptionPlanEnum.FREE"
                    (click)="changeProjectType('gamify')"
                >
                    <div class="paid-only">Advanced/Premium Only</div>
                    <div class="left">
                        <div class="type-name">GameFi</div>
                        <div class="type-desc">Recommended for Gaming Projects</div>
                    </div>

                    <div class="right">
                        <img src="/assets/imgs/airdrop-gamefi-airdrop.png" />
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="d-flex align-items-center section-title">
        Import Your On-Chain Collection
        <ng-container *ngIf="pageType !== 'admin'"> (Optional)</ng-container>
    </div>
    <section class="form-container">
        <div class="form-item">
            <div class="form-label">Collection Type *</div>
            <div class="form-control type-list">
                <div
                    class="type-item"
                    [class.active]="!isSkip && collectionType === 'erc_721'"
                    (click)="isSkip = false; changeCollection('erc_721')"
                >
                    ERC-721 & ERC-1155
                </div>
                <div
                    *ngIf="projectType === 'gamify' || isFree"
                    class="type-item"
                    [class.active]="!isSkip && collectionType === 'erc_20'"
                    [class.disabled]="isFree"
                    (click)="isSkip = false; isFree ? goSubscriptionPage() : changeCollection('erc_20')"
                >
                    ERC-20
                </div>
                <div
                    *ngIf="pageType !== 'admin' || shopDetail && !shopDetail.nft_project"
                    class="type-item"
                    [class.active]="isSkip"
                    (click)="isSkip = true"
                >
                    I dont have a collection now
                </div>
            </div>
        </div>
        <ng-container *ngIf="!isSkip">
            <div class="form-item">
                <div class="form-label">Import From *</div>
                <div class="form-control type-list">
                    <ng-container *ngIf="collectionType === 'erc_721'">
                        <div
                            class="type-item"
                            [class.active]="importProject === ImportProjectType.OPENSEA"
                            (click)="importProject = ImportProjectType.OPENSEA"
                        >
                            From OpenSea
                        </div>
                        <div
                            class="type-item"
                            [class.active]="importProject === ImportProjectType.ELEMENT"
                            (click)="importProject = ImportProjectType.ELEMENT"
                        >
                            From Element
                        </div>
                        <div
                            *ngIf="!isColdStart"
                            class="type-item"
                            [class.active]="importProject === ImportProjectType.MAGIC_EDEN"
                            (click)="importProject = ImportProjectType.MAGIC_EDEN"
                        >
                            From Magic Eden
                        </div>
                    </ng-container>

                    <ng-container *ngIf="collectionType === 'erc_20'">
                        <div
                            class="type-item"
                            [class.active]="importProject === ImportProjectType.COINMARKETCAP"
                            (click)="importProject = ImportProjectType.COINMARKETCAP"
                        >
                            From CoinMarketCap
                        </div>
                    </ng-container>

                    <div
                        *ngIf="!isColdStart"
                        class="type-item"
                        [class.active]="importProject === ImportProjectType.MANUAL"
                        (click)="importProject = ImportProjectType.MANUAL"
                    >
                        Manual Input
                    </div>
                </div>
            </div>
            <div class="form-item" [hidden]="importProject !== ImportProjectType.OPENSEA">
                <div class="form-label">OpenSea Collection URL *</div>
                <div class="form-control">
                    <input nz-input placeholder="Your Collection URL In OpenSea" [formControl]="searchOpenseaUrl" />
                    <div class="error-tip" *ngIf="isInvalidOpenseaUrl">Invalid OpenSea Link</div>
                </div>
            </div>
            <div class="form-item" [hidden]="importProject !== ImportProjectType.ELEMENT">
                <div class="form-label">Element Collection URL *</div>
                <div class="form-control">
                    <input nz-input placeholder="Your Collection URL In Element" [formControl]="searchElementUrl" />
                    <div class="error-tip" *ngIf="isInvalidElementUrl">Invalid Element Marketplace Link</div>
                </div>
            </div>
            <div class="form-item" [hidden]="importProject !== ImportProjectType.MAGIC_EDEN">
                <div class="form-label">Magic Eden Collection URL *</div>
                <div class="form-control">
                    <input nz-input placeholder="Your Collection URL In Magic Eden" [formControl]="searchMagicEdenUrl" />
                    <div class="error-tip" *ngIf="isInvalidMagicEdenUrl">Invalid Magic Eden Link</div>
                </div>
            </div>
            <div class="form-item" [hidden]="importProject !== ImportProjectType.COINMARKETCAP">
                <div class="form-label">CoinMarketCap Collection URL *</div>
                <div class="form-control">
                    <input
                        nz-input
                        placeholder="CoinMarketCap URL, i.e https://coinmarketcap.com/currencies/genopets"
                        [formControl]="searchCoinMarketCapUrl"
                    />
                    <div class="error-tip" *ngIf="isInvalidCoinMarketCapUrl">Invalid CoinMarketCap Link</div>
                </div>
            </div>

            <div [hidden]="importProject !== ImportProjectType.MANUAL">
                <div class="form-item" *ngIf="this.pageType !== 'admin'">
                    <div class="form-label d-flex align-items-center">Project Name *</div>
                    <div class="form-control">
                        <input nz-input placeholder="Project Name" [(ngModel)]="projectFormParams.name" />
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-label">Blockchain</div>
                    <div class="form-control">
                        <nz-select
                            class="select-box"
                            nzSize="large"
                            nzSuffixIcon="caret-down"
                            nzPlaceHolder="Select blockchain"
                            [(ngModel)]="projectFormParams.chain"
                        >
                            <nz-option nzLabel="ETH" nzValue="eth"></nz-option>
                            <nz-option nzLabel="Solana" nzValue="solana"></nz-option>
                            <nz-option nzLabel="Flow" nzValue="flow"></nz-option>
                            <!-- GK HIDE BNB/BSC -->
                            <!-- <nz-option nzLabel="BNB Chain" nzValue="bnb"></nz-option> -->
                            <nz-option nzLabel="Polygon" nzValue="polygon"></nz-option>
                        </nz-select>
                    </div>
                </div>
                <div class="form-items">
                    <div class="form-item">
                        <div class="form-label d-flex align-items-center">
                            Contract Name *
                            <i
                                nz-icon
                                nzType="info-circle"
                                nzTheme="outline"
                                [nzTooltipTitle]="contractInfoTooltip"
                                [nzTooltipOverlayClassName]="'contract-info-tooltip'"
                                nzTooltipPlacement="bottom"
                                nz-tooltip
                            ></i>
                        </div>
                        <div class="form-control">
                            <input nz-input placeholder="Contract Name" [(ngModel)]="projectFormParams.contract_name" />
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="form-label d-flex align-items-center">
                            Contract Address *
                            <i
                                nz-icon
                                nzType="info-circle"
                                nzTheme="outline"
                                [nzTooltipTitle]="contractInfoTooltip"
                                [nzTooltipOverlayClassName]="'contract-info-tooltip'"
                                [nzTooltipVisible]="false"
                                nzTooltipPlacement="bottom"
                                nz-tooltip
                            ></i>
                        </div>
                        <div class="form-control">
                            <input nz-input placeholder="Contract Address" [(ngModel)]="projectFormParams.contract_address" />
                        </div>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-label">Project Logo *</div>
                    <div class="form-control project-logo">
                        <div class="logo-image" *ngIf="logoFileBgUrl" [ngStyle]="{'background-image': 'url(' + logoFileBgUrl + ')' }">
                            <div class="progress-box" *ngIf="logoFile">
                                <i nz-icon [nzType]="'loading'"></i>
                            </div>
                            <i
                                *ngIf="projectFormParams.resource.brand_avatar"
                                class="remove"
                                nz-icon
                                nzType="close-circle"
                                nzTheme="outline"
                                (click)="removeImg('brand_avatar')"
                            ></i>
                        </div>
                        <div *ngIf="!(projectFormParams.resource.brand_avatar || logoFile)" class="upload-logo">
                            <nz-upload
                                [nzShowUploadList]="false"
                                [nzBeforeUpload]="logoBeforeUpload"
                                [nzAccept]="'image/jpeg,image/jpg,image/gif,image/png'"
                            >
                                <div class="content">
                                    <i nz-icon nzType="svg:upload-pic" style="font-size: 30px"></i>
                                </div>
                            </nz-upload>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="form-item" *ngIf="isSkip && pageType !== 'admin'">
            <div class="form-label d-flex align-items-center">Project Name *</div>
            <div class="form-control">
                <input nz-input placeholder="Project Name" [(ngModel)]="projectFormParams.name" />
            </div>
        </div>
        <div class="form-item" *ngIf="this.pageType !== 'admin'">
            <div class="form-label">Add Your NFT Merch</div>
            <div class="form-control d-flex nft-merch-list">
                <div *ngFor="let data of productList" class="nft-merch-item">
                    <label class="img-box" nz-checkbox [(ngModel)]="data.checked" (ngModelChange)="chooseNFTMerch($event, data)">
                        <img [src]="data.image_url" />
                        <img src="/assets/svg/nft-icon.svg" class="w3w-icon" />
                    </label>
                    <div class="merch-name">{{data.name}}</div>
                </div>
            </div>
        </div>

        <div
            class="form-item"
            *ngIf="!isSkip && pageType === 'admin' && shopDetail?.nft_project && ![BrandProjectStatus.PENDING, BrandProjectStatus.ACTIVE].includes(shopDetail.nft_project.status)"
        >
            <label nz-checkbox [(ngModel)]="updateProject">
                Update my brand page with the information from this collection (i.e. profile photo and project page URL).
            </label>
            <div class="reminder-info">
                <span nz-icon nzType="info-circle" nzTheme="outline"></span>
                Reminder: the URL to your existing campaigns will also be updated
            </div>
        </div>

        <div class="footer-btns">
            <ng-container *ngIf="this.pageType === 'admin' && shopDetail">
                <button
                    *ngIf="shopDetail.nft_project"
                    class="mr-10 back-btn"
                    nz-button
                    nzShape="round"
                    backButton
                    [backLink]="['/','admin','home']"
                    (click)="logBackEvent()"
                >
                    BACK
                </button>
                <button nz-button nzType="primary" [disabled]="disabledCreateBtn" (click)="createShopProject()">CONTINUE</button>
            </ng-container>

            <button *ngIf="this.pageType !== 'admin'" nz-button nzType="primary" [disabled]="disabledCreateBtn" (click)="createProject()">
                CREATE
            </button>
        </div>
    </section>
</section>

<nz-modal
    [(nzVisible)]="contactUsVisible"
    [nzFooter]="null"
    [nzClosable]="false"
    nzWrapClassName="no-bg no-padding"
    [nzCentered]="true"
    [nzWidth]="'848px'"
>
    <section class="contact-us-modal" *nzModalContent>
        <div class="title">Oops, Something Went Wrong</div>
        <div class="close-icon active-mask cursor-pointer" (click)="contactUsVisible = false">
            <i nz-icon nzType="close"></i>
        </div>

        <div class="error-img">
            <i nz-icon nzType="close-circle" nzTheme="outline"></i>
        </div>
        <div class="content">
            <ng-container *ngIf="createErrorMsg; else defaultErrorMsg">{{createErrorMsg}}</ng-container>
            <ng-template #defaultErrorMsg>
                Sorry for the inconvenience. <br />
                If retry cannot help, please contact our business support team:
                <a style="color: #72bbfd" href="mailto:business@w3w.ai">business@w3w.ai</a>
                for help.
            </ng-template>
        </div>
        <div class="footer-btns">
            <button nz-button nzType="primary" nzShape="round" (click)="contactUsVisible = false">CLOSE</button>
        </div>
    </section>
</nz-modal>

<ng-template #contractInfoTooltip>
    <div class="mb-20">
        We need your public contract info to understand how to load the data for your NFT collection. Where to find this info:
    </div>
    <div class="mb-20">
        <b>If your project is in Flow</b>, your collection’s URL may look like: https://flowscan.org/contract/A.8b148183c28ff88f.Gaia. In
        this case, your <b>Contract Name</b> is ‘Gaia’, while your <b>Contract Address</b> is ‘8b148183c28ff88f.
    </div>
    <div><b>For collections in other chains</b>, you may need to check with your chain settings.</div>
</ng-template>
