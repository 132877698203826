import { CampaignStatus, RaffleTypeEnum, SocialMedia } from '@admin/campaign/campaign.model';
import { DOCUMENT } from '@angular/common';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NidavellirService } from '@main/nidavellir.service';
import { WalletStatusService } from '@main/wallet-status.service';
import { CommonService } from '@services/common.service';
import { DateTimeService } from '@services/date-time.service';
import { walletImgMap } from '@src/app/modules/main/advanced-customization/campaign-detail/campaign-detail.service';
import { CustomEventEnum, FirebaseEventsService } from '@src/app/services/firebase/firebase-event.service';
import { SESSION_STORAGE_KEY_ENUM } from '@src/app/shared/types/general.type';
import { WalletEnum, WalletsSupportSwitchChain } from '@src/app/shared/types/wallet-chain.model';
import { encrypt } from '@src/app/shared/utils/pem';
import { getChainInfoByChain } from '@src/app/shared/utils/wallet-chain.util';
import { environment } from '@src/environments/environment';
import * as _ from 'lodash';
import { uniq } from 'lodash';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { W3W_CAMPAIGN_CUSTOM_EVENT_ENUM } from '../../campaign-challenge/campaign-challenge.service';
import { MocaToken, RequiredCampaignDataService } from '../required-campaign-data.service';

@Component({
    selector: 'app-public-campaign-form',
    templateUrl: './public-campaign-form.component.html',
    styleUrls: ['./public-campaign-form.component.less'],
})
export class PublicCampaignFormComponent implements OnInit, AfterViewInit, AfterContentChecked {
    public walletImgMap = walletImgMap;
    public SocialMedia = SocialMedia;
    public CampaignStatus = CampaignStatus;
    public RaffleTypeEnum = RaffleTypeEnum;
    public getChainInfoByChain = getChainInfoByChain;
    public ErrorIconName = 'close-circle';

    campaignForm: FormGroup;

    socialMediaArr: SocialMedia[] = [];

    requiredSocialMediaArr: SocialMedia[] = [];

    walletEligibleProcessing = false;
    checkingCampaignTask = false;
    submittingJoinForm = false;
    copied = false;
    fetchingMocaXPInfo = false;

    incompleteCampaignTask = new Set<number>();
    incompleteAtAccountsTask = new Set<number>();
    incompleteHashtagTask = new Set<number>();
    incompleteDiscordRoleTask = new Set<number>();

    campaignChallengeSubscribe$ = new Subject();

    validateCampaignManual$ = new Subject();

    duplicateSocialMedias = new Set<SocialMedia>();

    unselectedCombinedWallets = new Set<string>();

    joinedCampaignWallets = new Set<string>();

    walletAssetsEligible = false;

    hasUnJoinedCampaignWallet = false;

    hasEmptyChallenge = false;

    fetchingWonTokens = false;

    isCampaignWinner = false;

    hotWalletJoined = false;

    fetchingUserInfo = false;

    checkBtnClicked = false;

    campaignRequirementsType: 'onlyCollection' | 'onlySpecificAddress' | 'all' = 'all';

    wallets = [WalletEnum.META_MASK, WalletEnum.COINBASE, WalletEnum.WALLET_CONNECT];

    customFields$ = new BehaviorSubject<{ challenge_id: any; name: string }[]>([]);

    originalTokens: MocaToken[];
    shownTokens: MocaToken[];
    wonTokens: MocaToken[];

    currentPageHeight = null;

    walletEligibleStatus = {};

    errors: string[] = [];

    get isNoMocaCampaign() {
        return !this.campaignInfo.moca_only_campaign;
    }

    get mocaXPPoint() {
        return this.shownTokens?.reduce((acc, item) => acc + (item.xp_point || 0), 0);
    }

    get campaignInfo() {
        return this.requiredCampaignDataService.campaignInfo;
    }

    get walletAddress() {
        return this.walletStatusService.selectedWalletAddr$.getValue();
    }

    get isChainMatch() {
        const campaignChainId = getChainInfoByChain(this.campaignInfo?.chain, 'chainId');
        const connectChainId = this.walletStatusService.selectedWalletChainId;
        return (
            !WalletsSupportSwitchChain.includes(this.walletStatusService.walletType) ||
            !campaignChainId ||
            (campaignChainId && connectChainId === campaignChainId)
        );
    }

    get subscriptions() {
        return this.requiredCampaignDataService.subscriptions;
    }

    get discordUserInfo() {
        return this.discordUserInfo$.getValue();
    }

    get twitterUserInfo() {
        return this.twitterUserInfo$.getValue();
    }

    get telegramUserInfo() {
        return this.telegramUserInfo$.getValue();
    }

    get discordUserInfo$() {
        return this.requiredCampaignDataService.discordUserInfo$;
    }

    get twitterUserInfo$() {
        return this.requiredCampaignDataService.twitterUserInfo$;
    }

    get telegramUserInfo$() {
        return this.requiredCampaignDataService.telegramUserInfo$;
    }

    get campaignId() {
        return this.requiredCampaignDataService.campaignId;
    }

    get discordChallengeList() {
        return this.campaignInfo?.campaign_challenges?.filter(item => item.challenge_type === SocialMedia.DISCORD);
    }

    get telegramChallengeList() {
        return this.campaignInfo?.campaign_challenges?.filter(item => item.challenge_type === SocialMedia.TELEGRAM);
    }

    get twitterChallengeList() {
        return this.campaignInfo?.campaign_challenges?.filter(item => item.challenge_type === SocialMedia.TWITTER);
    }

    get disableRegisterBtn() {
        return (
            !this.walletStatusService.walletType ||
            !this.walletStatusService.walletAddress ||
            !this.walletAssetsEligible ||
            !this.hasUnJoinedCampaignWallet ||
            this.campaignForm.invalid ||
            this.campaignInfo.status !== CampaignStatus.ACTIVE
        );
    }

    get formButtonHidden() {
        return this.campaignInfo?.status === CampaignStatus.UPCOMING || !this.walletStatusService.isLoggedIn;
    }

    get hasSocialMedia() {
        return !!this.socialMediaArr?.length;
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private fb: FormBuilder,
        private nidavellirService: NidavellirService,
        private firebaseEventService: FirebaseEventsService,
        private messageService: CommonService,
        private dateTimeService: DateTimeService,
        private cdr: ChangeDetectorRef,
        public walletStatusService: WalletStatusService,
        public requiredCampaignDataService: RequiredCampaignDataService
    ) {}

    ngAfterContentChecked() {
        this.postPageSizeToParentWindow();
    }

    ngOnInit(): void {
        this.initCampaignForm();
        this.setupMessageListener();
        this.setupCampaignValidate();
        this.setupCampaignChallenges();
        // this.setupCampaignChallengesUpdateListener();
        this.setupSocialMediaInfoUpdateListener();
        this.setupUserInfoUpdateListener();
        this.setupUserDisconnectWalletListener();

        combineLatest([this.walletStatusService.selectedWalletAddr$, this.requiredCampaignDataService.campaignInfo$])
            .pipe(filter(([, campaignInfo]) => !!campaignInfo))
            .subscribe(([walletAddr, campaignInfo]) => {
                if (walletAddr) {
                    this.getUserInfo(walletAddr);
                    this.getColdWalletsAndMocaXpTokens();
                } else {
                    // 清除 SocialMedia 信息
                    this.twitterUserInfo$.next(null);
                    this.discordUserInfo$.next(null);
                    this.telegramUserInfo$.next(null);
                    this.campaignForm?.get('discord_id').setValue('');
                    this.campaignForm?.get('twitter_id').setValue('');
                    this.campaignForm?.get('telegram_id').setValue('');
                    window.localStorage.removeItem('discordUserInfo');
                    window.localStorage.removeItem('twitterUserInfo');
                    window.localStorage.removeItem('telegramUserInfo');

                    // Challenge 信息
                    campaignInfo.campaign_challenges?.forEach(item => {
                        this.incompleteCampaignTask.add(item.id);
                        this.incompleteAtAccountsTask.add(item.id);
                        this.incompleteHashtagTask.add(item.id);
                        this.incompleteDiscordRoleTask.add(item.id);
                    });

                    this.requiredCampaignDataService.combinedWalletList$.next([]);
                    this.originalTokens = [];
                    this.shownTokens = [];
                    this.wonTokens = [];
                }
            });
    }

    ngAfterViewInit() {
        this.setGreCaptcha();
    }

    @HostListener('window:resize')
    postPageSizeToParentWindow() {
        const data: any = {};
        for (const key in this.document.body) {
            if (_.isNumber(this.document.body[key] || _.isString(this.document.body[key]))) {
                data[key] = this.document.body[key];
            }
        }

        if (this.currentPageHeight === data.scrollHeight) {
            return;
        } else {
            this.requiredCampaignDataService.postMessageToParentWindow(W3W_CAMPAIGN_CUSTOM_EVENT_ENUM.CAMPAIGN_PAGE_SIZE_CHANGED, data);
            this.currentPageHeight = data.scrollHeight;
        }
    }

    getUserInfo(addr: string) {
        this.fetchingUserInfo = true;
        return this.nidavellirService.getUserInfo(addr).then((userInfo: any) => {
            if (!userInfo.walletAddress) {
                this.nidavellirService
                    .updateUserInfo({ walletAddress: addr })
                    .then(data => {
                        this.walletStatusService.userInfo$.next(data);
                    })
                    .catch(() => this.walletStatusService.userInfo$.next(null))
                    .finally(() => (this.fetchingUserInfo = false));
            } else {
                this.walletStatusService.userInfo$.next(userInfo);

                if (
                    window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.INVITE_COUPON) ===
                    this.walletStatusService.userInfo.invitationCode
                ) {
                    window.sessionStorage.removeItem(SESSION_STORAGE_KEY_ENUM.INVITE_COUPON);
                }

                this.fetchingUserInfo = false;
            }
        });
    }

    private setupUserDisconnectWalletListener() {
        // when user disconnect wallet, clear social media info and challenge info
        combineLatest([this.walletStatusService.selectedWalletAddr$, this.requiredCampaignDataService.campaignInfo$])
            .pipe(
                distinctUntilChanged((a, b) => _.isEqual(a[0], b[0])),
                filter(([walletAddr, campaignInfo]) => !walletAddr && !!campaignInfo),
                takeUntil(this.subscriptions.pageLeave)
            )
            .subscribe(([, campaignInfo]) => {
                // 清除 SocialMedia 信息
                this.twitterUserInfo$.next(null);
                this.discordUserInfo$.next(null);
                this.telegramUserInfo$.next(null);
                this.campaignForm?.get('discord_id').setValue('');
                this.campaignForm?.get('twitter_id').setValue('');
                this.campaignForm?.get('telegram_id').setValue('');
                window.localStorage.removeItem('discordUserInfo');
                window.localStorage.removeItem('twitterUserInfo');
                window.localStorage.removeItem('telegramUserInfo');

                // Challenge 信息
                campaignInfo.campaign_challenges?.forEach(item => {
                    this.incompleteCampaignTask.add(item.id);
                    this.incompleteAtAccountsTask.add(item.id);
                    this.incompleteHashtagTask.add(item.id);
                    this.incompleteDiscordRoleTask.add(item.id);
                });
            });
    }

    private setupUserInfoUpdateListener() {
        this.walletStatusService.userInfo$
            .pipe(
                filter(data => !!data),
                takeUntil(this.subscriptions.pageLeave)
            )
            .subscribe(data => {
                if (data.walletContactList) {
                    const discordInfo: any = _.last(
                        data.walletContactList.filter(item => item.contactType === SocialMedia.DISCORD && item.contactId)
                    );
                    const twitterInfo: any = _.last(
                        data.walletContactList.filter(item => item.contactType === SocialMedia.TWITTER && item.contactId)
                    );
                    const telegramInfo: any = _.last(
                        data.walletContactList.filter(item => item.contactType === SocialMedia.TELEGRAM && item.contactId)
                    );
                    const emailInfo: any = _.last(data.walletContactList.filter(item => item.contactType === SocialMedia.EMAIL));

                    if (discordInfo) {
                        this.discordUserInfo$.next({
                            name: discordInfo.contact,
                            principalName: null,
                        });
                    }

                    if (twitterInfo) {
                        this.twitterUserInfo$.next({
                            name: twitterInfo.contact,
                            principalName: null,
                        });
                    }

                    if (telegramInfo) {
                        this.telegramUserInfo$.next({
                            username: telegramInfo.contact,
                            id: null,
                        });
                    }

                    if (emailInfo) {
                        this.campaignForm.get('email').patchValue(emailInfo.contact);
                    } else {
                        this.campaignForm.get('email').patchValue(null);
                        this.campaignForm.get('email').enable();
                    }
                }

                this.isCampaignWinner = !!data.campaignEntryList?.find(item => item.campaignId === this.campaignId)?.isSelected;

                if (this.isCampaignWinner) {
                    this.fetchWonTokens();
                }
            });
    }

    private setupCampaignChallenges() {
        this.requiredCampaignDataService.campaignInfo$
            .pipe(
                filter(item => !!item),
                takeUntil(this.subscriptions.pageLeave)
            )
            .subscribe(campaignInfo => {
                const customFields = [];
                campaignInfo.required_social_media.forEach(ele => {
                    this.socialMediaArr.push(ele.type);
                    if (ele.is_required) {
                        this.requiredSocialMediaArr.push(ele.type);
                        this.campaignForm.get(`${ele.type}_id`).setValidators(Validators.required);
                    }
                });
                campaignInfo.campaign_challenges?.forEach(item => {
                    this.incompleteCampaignTask.add(item.id);
                    this.incompleteAtAccountsTask.add(item.id);
                    this.incompleteHashtagTask.add(item.id);
                    this.incompleteDiscordRoleTask.add(item.id);

                    if (['quote_tweet', 'reply_tweet', 'retweet'].includes(item.check_type) && !item.challenge_input) {
                        this.hasEmptyChallenge = true;
                    }

                    if (item.challenge_type === 'custom_input') {
                        customFields.push({
                            challenge_id: item.id,
                            name: item.challenge_input,
                        });
                        this.campaignForm.addControl(`custom_input_${item.id}`, new FormControl('', Validators.required));
                    }
                });

                this.validateCampaignManual$.next();
                this.customFields$.next(customFields);

                // 新增：2022/12/07 获取到campaignInfo 时，判定 campaignRequirementsType
                const collectionList = campaignInfo.campaign_requirements?.filter(item => item.type === 'collection');
                if (collectionList.length && collectionList.length === campaignInfo.campaign_requirements.length) {
                    this.campaignRequirementsType = 'onlyCollection';
                } else if (campaignInfo.campaign_requirements?.length && !collectionList.length) {
                    this.campaignRequirementsType = 'onlySpecificAddress';
                } else if (collectionList.length && collectionList.length !== campaignInfo.campaign_requirements.length) {
                    this.campaignRequirementsType = 'all';
                }
            });
    }

    private setupCampaignChallengesUpdateListener() {
        this.campaignChallengeSubscribe$.pipe(debounceTime(300), takeUntil(this.subscriptions.pageLeave)).subscribe(() => {
            this.checkCampaignChallenge();
        });

        combineLatest([
            this.walletStatusService.selectedWalletAddr$,
            this.discordUserInfo$,
            this.twitterUserInfo$,
            this.telegramUserInfo$,
            this.requiredCampaignDataService.campaignInfo$,
        ])
            .pipe(debounceTime(100), takeUntil(this.subscriptions.pageLeave))
            .subscribe(([walletAddress, discordUserInfo, twitterUserInfo, telegramUserInfo, campaignInfo]) => {
                const onlyCustomFields = campaignInfo?.campaign_challenges?.every(item => item.challenge_type === 'custom_input');

                if (
                    walletAddress &&
                    !twitterUserInfo?.principalName &&
                    !telegramUserInfo?.id &&
                    !discordUserInfo?.principalName &&
                    campaignInfo &&
                    (discordUserInfo || twitterUserInfo || telegramUserInfo || onlyCustomFields)
                ) {
                    this.campaignChallengeSubscribe$.next();
                }
            });
    }

    private setupSocialMediaInfoUpdateListener() {
        combineLatest([this.walletStatusService.selectedWalletAddr$, this.discordUserInfo$, this.twitterUserInfo$, this.telegramUserInfo$])
            .pipe(takeUntil(this.subscriptions.pageLeave))
            .subscribe(async ([walletAddress, discordUserInfo, twitterUserInfo, telegramUserInfo]) => {
                const contactList = [];
                if (discordUserInfo?.principalName) {
                    contactList.push({
                        contactId: discordUserInfo.principalName,
                        contact: discordUserInfo.name,
                        contactType: SocialMedia.DISCORD,
                    });
                }
                if (twitterUserInfo?.principalName) {
                    contactList.push({
                        contactId: twitterUserInfo.principalName,
                        contact: twitterUserInfo.name,
                        contactType: SocialMedia.TWITTER,
                    });
                }
                if (telegramUserInfo?.id) {
                    contactList.push({
                        contactId: telegramUserInfo.id,
                        contact: telegramUserInfo.username,
                        contactType: SocialMedia.TELEGRAM,
                    });
                }

                if (contactList.length > 0 && walletAddress) {
                    this.nidavellirService
                        .updateUserInfo({
                            walletAddress,
                            contactList,
                        })
                        .then(res => {
                            this.campaignForm.get('discord_id').patchValue(discordUserInfo?.name || '');
                            this.campaignForm.get('twitter_id').patchValue(twitterUserInfo?.name || '');
                            this.campaignForm.get('telegram_id').patchValue(telegramUserInfo?.username || '');

                            // Remove social media info from local storage
                            window.localStorage.removeItem('twitterUserInfo');
                            window.localStorage.removeItem('discordUserInfo');
                            window.localStorage.removeItem('telegramUserInfo');
                            this.campaignChallengeSubscribe$.next();
                            this.walletStatusService.userInfo$.next(res);
                        });
                }

                if (discordUserInfo || twitterUserInfo || telegramUserInfo) {
                    this.campaignForm?.get('discord_id').setValue(discordUserInfo?.name || '');
                    this.campaignForm?.get('twitter_id').setValue(twitterUserInfo?.name || '');
                    this.campaignForm?.get('telegram_id').setValue(telegramUserInfo?.username || '');
                }
            });
    }

    public async connectSocialMedia(type: SocialMedia) {
        this.duplicateSocialMedias.delete(type);

        this.firebaseEventService.logEvent({
            name: CustomEventEnum.START_CONNECT_SOCIAL_MEDIA,
            properties: {
                type,
                wallet_address: this.walletAddress || null,
                campaignId: this.requiredCampaignDataService.campaignId,
            },
        });

        // 在metamask等浏览器，会是当前页面跳转，所以记录在localStorage
        window.localStorage.setItem('campaignId', String(this.requiredCampaignDataService.campaignId));

        if (type === SocialMedia.DISCORD || type === SocialMedia.TWITTER) {
            this.requiredCampaignDataService.connectDiscordOrTwitter(type, this.campaignInfo.project_id);
        }

        if (type === SocialMedia.TELEGRAM) {
            this.requiredCampaignDataService.connectTelegram();
        }
    }

    public removeSocialMediaInfo(type: SocialMedia) {
        switch (type) {
            case SocialMedia.DISCORD:
                this.requiredCampaignDataService.discordUserInfo$.next(null);
                window.localStorage.removeItem('discordUserInfo');
                break;
            case SocialMedia.TWITTER:
                this.requiredCampaignDataService.twitterUserInfo$.next(null);
                window.localStorage.removeItem('twitterUserInfo');
                break;
            case SocialMedia.TELEGRAM:
                this.requiredCampaignDataService.telegramUserInfo$.next(null);
                window.localStorage.removeItem('telegramUserInfo');
                break;
        }
    }

    public followTwitter(challenge) {
        window.open(this.getTwitterFollowLink(challenge), '_blank');
    }

    public retweetTwitter(challenge) {
        if (!challenge.challenge_input) return;
        window.open(this.getTwitterRetweetLink(challenge), '_blank');
    }

    public quoteOrReplyTweet(challenge) {
        if (!challenge.challenge_input) return;
        window.open(challenge.challenge_input, '_blank');
    }

    public joinDiscordOrTelegram(challenge) {
        window.open(challenge.challenge_input, '_blank');
    }

    public async joinCampaign() {
        if (!this.campaignForm.get('recaptcha_result').value) {
            this.messageService.error('Failed to pass recaptcha');
            return;
        }

        if (!this.campaignForm.get('email').value) {
            this.messageService.error('Please input your email');
            return;
        }

        this.submittingJoinForm = true;
        try {
            const campaignChallengeStatus = await this.checkCampaignChallenge();
            if (!campaignChallengeStatus.success) {
                this.submittingJoinForm = false;
                return;
            }
        } catch (err) {
            this.submittingJoinForm = false;
            return;
        }

        const selectedCombinedWallets = (this.requiredCampaignDataService.combinedWalletList$.value || []).filter(
            item => !this.unselectedCombinedWallets.has(item.walletAddr)
        );
        if (!this.joinedCampaignWallets.has(this.walletAddress)) {
            const encryptedWalletAddr = environment.production
                ? window.sessionStorage.getItem(SESSION_STORAGE_KEY_ENUM.ENCRYPTED_WALLET_ADDR) || encrypt('')
                : encrypt(this.walletAddress);
            selectedCombinedWallets.unshift({
                walletAddr: this.walletAddress,
                walletType: this.walletStatusService.walletType,
                encryptedWalletAddr,
            });
        }

        this.requiredCampaignDataService
            .joinCampaign(this.campaignForm, this.duplicateSocialMedias, selectedCombinedWallets, this.customFields$.value)
            .then(success => {
                if (success) {
                    this.hotWalletJoined = true;
                }
            })
            .finally(() => (this.submittingJoinForm = false));
    }

    public formatDateToW3WStandardDate(date: string) {
        return this.dateTimeService.formatToW3WStandardDate(date);
    }

    public connect() {
        this.requiredCampaignDataService.postMessageToParentWindow(W3W_CAMPAIGN_CUSTOM_EVENT_ENUM.CAMPAIGN_CONNECT_WALLET);
    }

    public disconnect() {
        this.requiredCampaignDataService.postMessageToParentWindow(W3W_CAMPAIGN_CUSTOM_EVENT_ENUM.CAMPAIGN_DISCONNECT_WALLET);
        this.walletStatusService.disconnectWallet();
    }

    public getCheckIconType(challengeId: number, type: 'campaignTask' | 'roleTask' | 'atAccountsTask' | 'hashtagTask') {
        if (!this.checkBtnClicked) {
            return 'antd:checked-current-color';
        } else {
            let incomplete = false;

            if (type === 'campaignTask') {
                incomplete = this.incompleteCampaignTask.has(challengeId);
            } else if (type === 'roleTask') {
                incomplete = this.incompleteDiscordRoleTask.has(challengeId);
            } else if (type === 'atAccountsTask') {
                incomplete = this.incompleteAtAccountsTask.has(challengeId);
            } else if (type === 'hashtagTask') {
                incomplete = this.incompleteHashtagTask.has(challengeId);
            }

            if (this.campaignInfo && !this.checkingCampaignTask && incomplete) {
                return this.ErrorIconName;
            } else {
                return 'antd:checked-current-color';
            }
        }
    }

    getTwitterFollowLink(challenge) {
        const screenName = challenge.challenge_input;
        const originalReferer = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
        return `https://twitter.com/intent/follow?screen_name=${screenName}&original_referer=${originalReferer}`;
    }

    getTwitterRetweetLink(challenge) {
        const retweetId = challenge.challenge_input?.split('?')[0].split('/').pop();
        const originalReferer = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`;
        return `https://twitter.com/intent/retweet?tweet_id=${retweetId}&original_referer=${originalReferer}`;
    }

    checkCampaignChallenge() {
        if (this.campaignInfo?.campaign_challenges.length && this.walletStatusService.walletAddress && !this.hasEmptyChallenge) {
            this.checkingCampaignTask = true;
            this.checkBtnClicked = true;

            return this.nidavellirService
                .checkCampaignChallengeStatus({
                    campaignId: this.campaignInfo.id,
                    walletAddress: this.walletStatusService.walletAddress,
                    projectId: this.campaignInfo.project_id,
                })
                .then(res => {
                    this.incompleteCampaignTask.clear();
                    this.incompleteAtAccountsTask.clear();
                    this.incompleteHashtagTask.clear();
                    this.incompleteDiscordRoleTask.clear();

                    if (!res.success) {
                        const errors: string[] = [];
                        res.checkDetails
                            .filter(item => !item.result)
                            .forEach(failedItem => {
                                // 如果是400就不进行后面的数据判断，直接设置对应的challenge 位未完成
                                if (failedItem.status === 400) {
                                    this.incompleteCampaignTask.add(failedItem.originalChallenge.id);

                                    if (failedItem.originalChallenge?.roles?.length) {
                                        this.incompleteDiscordRoleTask.add(failedItem.originalChallenge.id);
                                    }

                                    if (failedItem.originalChallenge?.hashtags?.length) {
                                        this.incompleteHashtagTask.add(failedItem.originalChallenge.id);
                                    }

                                    if (failedItem.originalChallenge.atAccounts) {
                                        this.incompleteAtAccountsTask.add(failedItem.originalChallenge.id);
                                    }
                                } else {
                                    if (!failedItem.serverJoin) {
                                        this.incompleteCampaignTask.add(failedItem.originalChallenge.id);
                                    }

                                    if (
                                        failedItem.originalChallenge.atAccounts &&
                                        failedItem.atAccounts !== failedItem.originalChallenge.atAccounts
                                    ) {
                                        this.incompleteAtAccountsTask.add(failedItem.originalChallenge.id);
                                    }
                                    if (
                                        Object.values(failedItem.hashtagChecks).some(item => !item) ||
                                        // post_tweet 中可能有hashtag challenge，但是api只校验整个challenge不会校验sub challenge. 此时hashtagChecks为{}
                                        // 为了避免出现这种情况，如果hashtagChecks为空，也认为是未完成
                                        Object.values(failedItem.hashtagChecks)?.length === 0
                                    ) {
                                        this.incompleteHashtagTask.add(failedItem.originalChallenge.id);
                                    }
                                    if (Object.values(failedItem.verifyRoleChecks).some(item => !item)) {
                                        this.incompleteDiscordRoleTask.add(failedItem.originalChallenge.id);
                                    }
                                }

                                // 非正常(任务未完成)错误
                                switch (failedItem.status) {
                                    case 401:
                                        errors.push(
                                            // eslint-disable-next-line max-len
                                            `Sorry, ${failedItem.originalChallenge.challengeType} connection expired. Please reconnect again.`
                                        );
                                        break;
                                    case 429:
                                        errors.push('Sorry, Refreshing is too frequent. Please try again after 15 minutes.');
                                        break;
                                }
                            });

                        this.errors = uniq(errors);
                        this.errors.forEach(error => this.messageService.error(error, { nzDuration: 10000 }));
                    }
                    return res;
                })
                .catch(err => {
                    this.errors = [...this.errors, 'Oops! Something went wrong. Please try again.'];
                    this.messageService.error();
                    return { success: false };
                })
                .finally(() => {
                    this.checkingCampaignTask = false;
                    setTimeout(() => (this.errors = []), 15000);
                });
        }

        return { success: true };
    }

    selectCombineWallet(walletAddr: string, status: boolean) {
        if (status) {
            this.unselectedCombinedWallets.delete(walletAddr);
        } else {
            this.unselectedCombinedWallets.add(walletAddr);
        }

        this.setShownTokens();
    }

    getHexColor(color: number) {
        return `#${color?.toString(16)}`;
    }

    purchaseMoca() {
        window.open('https://opensea.io/collection/mocaverse', '_blank');
    }

    private setGreCaptcha() {
        const renderFunc = () => {
            try {
                window['grecaptcha'].render('robot', {
                    sitekey: environment.grecaptcha.sitekey,
                    theme: 'dark',
                    callback: token => this.campaignForm.get('recaptcha_result').setValue(token), // 验证成功回调
                    'expired-callback': () => this.campaignForm.get('recaptcha_result').setValue(null), // 验证过期回调
                    'error-callback': () => {
                        this.messageService.error();
                        this.campaignForm.get('recaptcha_result').setValue(null);
                    }, // 验证错误回调
                });
            } catch (e) {
                setTimeout(() => {
                    renderFunc();
                }, 1000);
                this.campaignForm.get('recaptcha_result').removeValidators(Validators.required);
                this.campaignForm.get('recaptcha_result').updateValueAndValidity();
            }
        };

        combineLatest([this.requiredCampaignDataService.campaignInfo$, this.walletStatusService.selectedWalletAddr$])
            .pipe(
                filter(([campaignInfo, addr]) => !!campaignInfo && !!addr),
                takeUntil(this.subscriptions.pageLeave)
            )
            .subscribe(() => {
                this.cdr.detectChanges();

                setTimeout(() => {
                    renderFunc();
                }, 1000);
            });
    }

    private initCampaignForm() {
        this.campaignForm = this.fb.group({
            campaign_id: Number(this.requiredCampaignDataService.campaignId),
            wallet_type: [null],
            wallet_address: [null],
            discord_id: [''],
            twitter_id: [''],
            telegram_id: [''],
            email: ['', [Validators.required, Validators.email]],
            recaptcha_result: [null, [Validators.required]],
            wallet_list: [[]],
        });
    }

    private setupCampaignValidate() {
        combineLatest([
            this.walletStatusService.selectedWallet$,
            this.walletStatusService.selectedWalletAddr$,
            this.requiredCampaignDataService.combinedWalletList$,
            this.validateCampaignManual$,
        ])
            .pipe(
                filter(data => !!data[0] && !!data[1]),
                takeUntil(this.subscriptions.pageLeave)
            )
            .subscribe(data => {
                this.walletEligibleProcessing = true;
                const [type, addr] = data;
                const allCombinedWallet = this.requiredCampaignDataService.combinedWalletList$.getValue() || [];
                const walletList = [{ wallet_type: type, wallet_address: addr, encrypt_wallet_address: encrypt(addr) }];

                allCombinedWallet
                    .filter(item => !this.unselectedCombinedWallets.has(item.walletAddr))
                    .forEach(wallet => {
                        walletList.push({
                            wallet_type: wallet.walletType,
                            wallet_address: wallet.walletAddr,
                            encrypt_wallet_address: wallet.encryptedWalletAddr,
                        });
                    });

                this.nidavellirService
                    .validateAddressMultiple({
                        campaign_id: this.campaignInfo.id,
                        wallet_list: walletList,
                    })
                    .then(res => {
                        this.walletAssetsEligible = res.is_eligible;

                        res.wallet_list?.forEach(wallet => {
                            if (wallet.is_joined) {
                                this.joinedCampaignWallets.add(wallet.wallet_address);
                                this.unselectedCombinedWallets.add(wallet.wallet_address);
                            }

                            this.walletEligibleStatus[wallet.wallet_address] = wallet.is_eligible;
                        });

                        // Set hasUnJoinedCampaignWallet
                        const combinedWalletAddressList = (this.requiredCampaignDataService.combinedWalletList$.getValue() || []).map(
                            item => item.walletAddr
                        );
                        const allConnectedAddressList = [this.walletAddress, ...combinedWalletAddressList];
                        const allCheckedAddressList = allConnectedAddressList.filter(
                            address => !this.unselectedCombinedWallets.has(address)
                        );
                        this.hasUnJoinedCampaignWallet = allCheckedAddressList.some(
                            item => !res.wallet_list?.find(w => w.wallet_address === item)?.is_joined
                        );

                        if (this.hasUnJoinedCampaignWallet) {
                            this.campaignForm.get('email').enable();
                        } else {
                            this.campaignForm.get('email').disable();
                        }

                        this.hotWalletJoined = res.wallet_list?.find(item => item.wallet_address === this.walletAddress)?.is_joined;
                    })
                    .finally(() => (this.walletEligibleProcessing = false));
            });
    }

    private setupMessageListener() {
        window.addEventListener('message', event => {
            const messageData: {
                type: 'w3wWalletAddressUpdate';
                data: {
                    walletAddress: string;
                    walletType: WalletEnum;
                    href: string;
                };
            } = event.data;

            if (messageData.type === 'w3wWalletAddressUpdate') {
                this.walletStatusService.setSessionData(
                    messageData.data.walletType || WalletEnum.META_MASK,
                    messageData.data.walletAddress || null
                );
                this.requiredCampaignDataService.outsideWebsiteUrl = messageData.data.href;
                sessionStorage.setItem('outsideWebsiteUrl', messageData.data.href);
            }
        });
    }

    private getColdWalletsAndMocaXpTokens() {
        this.fetchingMocaXPInfo = true;
        this.requiredCampaignDataService
            .getColdWalletsAndTokensMocaXP(this.walletAddress)
            .then(res => {
                this.originalTokens = res.tokens;
                this.setShownTokens();
                res.wallet_list?.forEach(wallet => {
                    this.walletStatusService.appendCombineWalletSessionData(WalletEnum.META_MASK, wallet.wallet_address);
                });
                this.requiredCampaignDataService.combinedWalletList$.next(
                    res.wallet_list?.map(item => ({
                        walletType: WalletEnum.META_MASK,
                        walletAddr: item.wallet_address,
                        encryptedWalletAddr: encrypt(item.wallet_address),
                    })) || []
                );
            })
            .finally(() => (this.fetchingMocaXPInfo = false));
    }

    private setShownTokens() {
        this.shownTokens = this.originalTokens.filter(token => !this.unselectedCombinedWallets.has(token.wallet_address));
    }

    private fetchWonTokens() {
        this.fetchingWonTokens = true;

        this.requiredCampaignDataService
            .getAdditionalCampaignInfoOfUser(this.walletAddress, this.campaignId)
            .then(data => {
                this.wonTokens = data.tokens?.filter(item => item.is_selected);
            })
            .finally(() => (this.fetchingWonTokens = false));
    }
}

@Component({
    selector: 'app-campaign-share-menu',
    template: `
        <ul nz-menu class="share-campaign-menu">
            <li class="twitter active-mask" nz-menu-item (click)="shareToTwitter()">
                <i nz-icon nzType="antd:twitter"></i>
                Share to Twitter
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item class="active-mask" (click)="copy()">
                <i nz-icon nzType="link"></i>
                Copy Link
            </li>
        </ul>
    `,
    styles: [
        `
            .share-campaign-menu {
                background-color: #1f1f23;
                border-radius: 20px 20px 0 0;
                font-size: 14px;
                padding: 10px 0 10px;
                overflow: hidden;
                border: none;

                .ant-menu-item {
                    text-align: center;
                }

                .twitter i {
                    color: #55acee;
                }

                i {
                    margin-right: 8px;
                }

                .ant-dropdown-menu-item-divider {
                    background: rgba(255, 255, 255, 0.3);
                }
            }
        `,
    ],
    providers: [],
})
export class CampaignShareMenuComponent {
    constructor(private drawerRef: NzDrawerRef) {}

    shareToTwitter() {
        this.drawerRef.close('shareTwitter');
    }

    copy() {
        this.drawerRef.close('copy');
    }
}
