import { Component, Input } from '@angular/core';
import { isNumber } from 'lodash';

@Component({
    selector: 'app-landscape-chart-card',
    templateUrl: './landscape-chart-card.component.html',
    styleUrls: ['./landscape-chart-card.component.less'],
})
export class LandscapeChartCardComponent {
    @Input() cardInfo: {
        name?: string;
        count: number;
        desc?: string;
        color?: string;
        countType?: string; // default | percent
    };

    isNumber = isNumber;

    constructor() {}
}
