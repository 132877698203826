import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { random } from 'lodash';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { RequestService } from '../request.service';

export interface LifoUploadedFile {
    publicLink: string;
    fileName: string;
    uploadPath: string;
    id: number;
    fileType: string;
    createdAt: string;
}

@Injectable({
    providedIn: 'root',
})
export class FirebaseFileUploadService {
    private firebaseBaseUrlPrefix = 'https://storage.googleapis.com/influencer-272204.appspot.com';

    constructor(private storage: AngularFireStorage, private requestService: RequestService) {}

    startUpload(
        file: File,
        originUploadPath = '/public/images',
        name?: string
    ): { file: File; uploadTask: Promise<string>; taskPercentage$: Observable<number>; path: string } {
        const originPath = `${originUploadPath}/${Date.now()}/${random(0, 100000)}`;
        let path = '';

        if (name) {
            path = `${originPath}/${name}`;
        } else {
            path = `${originPath}/${random(0, 1000)}.${file.name?.split('.').pop() || 'png'}`;
        }

        // Reference to storage bucket
        const ref = this.storage.ref(path);

        // The main task
        const task$ = this.storage.upload(path, file);
        const uploadTask = new Promise<string>(resolve => {
            task$
                .snapshotChanges()
                .pipe(
                    finalize(async () => {
                        const downloadUrl: string = await ref.getDownloadURL().toPromise();
                        resolve(downloadUrl);
                    })
                )
                .subscribe();
        });
        const taskPercentage$ = task$.percentageChanges();

        return {
            file,
            uploadTask,
            taskPercentage$,
            path,
        };
    }

    uploadByLifoApi(data: FormData) {
        return this.requestService.sendRequest<LifoUploadedFile>({
            method: 'POST',
            url: '/brand/attachments/upload',
            api: 'admin-api',
            data,
        });
    }

    getUrlByName(name: string) {
        return `${this.firebaseBaseUrlPrefix}/public/static_images/${name}`;
    }

    /**
     * @param path example: 'public/static_images/example.png'
     * @returns string
     */
    getUrlByPath(path: string) {
        return `${this.firebaseBaseUrlPrefix}/${path}`;
    }
}
