import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { redirectRouteList } from './app-routing.data';
import { CampaignChallengeComponent } from './modules/public/campaign-challenge/campaign-challenge.component';
import { GeneralCloseWindowComponent } from './modules/public/general-close-window/general-close-window.component';
import { RequiredCampaignDataComponent } from './modules/public/required-campaign-data/required-campaign-data.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/main/home',
        pathMatch: 'full',
    },
    {
        path: 'public',
        children: [
            {
                path: '',
                redirectTo: '/main/home',
                pathMatch: 'full',
            },
            {
                path: 'campaign-challenge/:campaignId',
                component: CampaignChallengeComponent,
            },
            {
                path: 'mocaverse-campaign-challenge/:campaign_id',
                component: RequiredCampaignDataComponent,
            },

            {
                path: 'completed-window/:social_media',
                component: GeneralCloseWindowComponent,
            },
        ],
    },
    {
        path: 'main',
        loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    },
    ...redirectRouteList,
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            initialNavigation: 'enabledBlocking',
            anchorScrolling: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
