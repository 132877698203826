import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class ReuseService implements RouteReuseStrategy {
    static storedRouteHandles = new Map<string, DetachedRouteHandle>();

    // Used to determine whether the router needs to be stored when navigating
    from = '';
    to = '';

    // Used to determine whether to read the previously stored router when navigating
    reuseFrom = '';
    reuseTo = '';

    projectKey;
    campaignId;

    static removeRouteCache(url: string) {
        ReuseService.storedRouteHandles.delete(url);
    }

    shouldReuseRoute(from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot): boolean {
        if (from.routeConfig) {
            this.from = this.getPath(from);
        }
        if (to.routeConfig) {
            this.to = this.getPath(to);
        }
        return from.routeConfig === to.routeConfig;
    }

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        // Judge if do store
        if (this.shouldStore()) {
            this.reuseFrom = this.to;
            this.reuseTo = this.from;
        }
        return this.shouldStore();
    }

    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
        // Store router
        ReuseService.storedRouteHandles.set(this.getPath(route), detachedTree);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
        if (!route.component) {
            return null;
        }

        if (this.from === this.reuseFrom && this.to === this.reuseTo) {
            // Read reused router
            return ReuseService.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
        } else {
            return null;
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        if (this.reuseFrom && this.reuseTo && this.from && this.to) {
            return this.from === this.reuseFrom && this.to === this.reuseTo && ReuseService.storedRouteHandles.has(this.getPath(route));
        }

        return false;
    }

    private shouldStore() {
        return (
            (this.from === '/admin/campaign/community-detail' && this.to === '/admin/campaign/community-list') ||
            (this.from === '/admin/campaign/community-list/import-address' && this.to === '/admin/campaign/community-list') ||
            (this.from === '/admin/campaign/community-detail' && this.to === '/admin/campaign/overview') ||
            (this.from === '/admin/campaign/community-detail' && this.to === '/admin/shop') ||
            // (this.from === '/admin/campaign/community-detail' && this.to === '/admin/reports') ||
            (this.from === `/main/${this.projectKey}/${this.campaignId}` && this.to === '/main/campaigns') ||
            (this.from === `/main/${this.projectKey}/${this.campaignId}` && this.to === '/main/my-campaigns') ||
            (this.from === '/admin/send-collab-request' && this.to === '/admin/perk') ||
            (this.from === '/admin/campaign/custom-email-notification' && this.to === '/admin/campaign/community-list') ||
            (this.from === '/main/apps/app-detail' && this.to === '/main/apps') ||
            (this.from === '/admin/subscription' && this.to === '/admin/brand-quick-start') ||
            (this.from === '/admin/campaign/community-detail' && this.to === '/admin/monitor/dashboard')
        );
    }

    private getPath(route: ActivatedRouteSnapshot): string {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        let url: string = route['_routerState'].url;

        if (url.startsWith('/admin/campaign/community-list/import-address')) {
            url = '/admin/campaign/community-list/import-address';
        } else if (url.startsWith('/admin/shop/nft-airdrop/send-airdrop')) {
            url = '/admin/shop/nft-airdrop/send-airdrop';
        } else if (url.startsWith('/admin/campaign/community-list')) {
            url = '/admin/campaign/community-list';
        } else if (url.startsWith('/admin/campaign/community-detail')) {
            url = '/admin/campaign/community-detail';
        } else if (url.startsWith('/admin/campaign/overview')) {
            url = '/admin/campaign/overview';
        } else if (url.startsWith('/admin/monitor/dashboard')) {
            url = '/admin/monitor/dashboard';
        } else if (url.startsWith('/admin/shop')) {
            url = '/admin/shop';
        } else if (url.startsWith('/admin/campaign/detail')) {
            url = '/admin/campaign/detail';
        } else if (
            url.startsWith('/main') &&
            route.routeConfig.path === ':project' &&
            route.children[0]?.routeConfig.path === ':campaign_id'
        ) {
            this.projectKey = route.params.project;
            this.campaignId = route.children[0].params.campaign_id;
            url = `/main/${this.projectKey}/${this.campaignId}`;
        } else if (url.startsWith('/main/campaigns')) {
            url = '/main/campaigns';
        } else if (url.startsWith('/admin/perk')) {
            url = '/admin/perk';
        } else if (url.startsWith('/admin/send-collab-request')) {
            url = '/admin/send-collab-request';
        } else if (url.startsWith('/admin/campaign/custom-email-notification')) {
            url = '/admin/campaign/custom-email-notification';
        } else if (url.startsWith('/main/apps/')) {
            url = '/main/apps/app-detail';
        } else if (url.startsWith('/main/apps')) {
            url = '/main/apps';
        }

        return url.split('?')[0];
    }
}
