import { Injectable } from '@angular/core';
import { CommonService } from '@src/app/services/common.service';
import { RequestService } from '@src/app/services/request.service';
import { NzModalService } from 'ng-zorro-antd/modal';
// eslint-disable-next-line max-len
import { ServiceRequestModalComponent } from '../airdrop/brand-project-shop/account-operate/service-request-modal/service-request-modal.component';

@Injectable()
export class RequestLifoServiceService {
    constructor(
        private screenService: CommonService,
        private modalService: NzModalService,
        private requestService: RequestService,
        private messageService: CommonService
    ) {}

    showRequestServiceModal(defaultSelect = null) {
        if (this.screenService.isMobile()) {
            this.modalService.create({
                nzContent: ServiceRequestModalComponent,
                nzFooter: null,
                nzWrapClassName: 'no-bg no-padding',
                nzClosable: false,
                nzCentered: true,
                nzWidth: '100vw',
                nzStyle: {
                    verticalAlign: 'bottom',
                    maxWidth: '100vw',
                    padding: 0,
                    margin: 0,
                },
                nzComponentParams: {
                    defaultSelect,
                },
                nzOnOk: async comp => {
                    comp.submitting = true;
                    const modalStatus = await this.requestLifoService(comp);
                    comp.submitting = false;
                    return modalStatus;
                },
            });
        } else {
            this.modalService.create({
                nzContent: ServiceRequestModalComponent,
                nzFooter: null,
                nzWidth: '60vw',
                nzMaskClosable: false,
                nzWrapClassName: 'no-bg no-padding',
                nzClosable: false,
                nzCentered: true,
                nzStyle: {
                    maxWidth: '1400px',
                },
                nzComponentParams: {
                    defaultSelect,
                },
                nzOnOk: async comp => {
                    comp.submitting = true;
                    const modalStatus = await this.requestLifoService(comp);
                    comp.submitting = false;
                    return modalStatus;
                },
            });
        }
    }

    private requestLifoService(comp: ServiceRequestModalComponent) {
        return this.requestW3wService({ service_type: comp.selectedTopic, note: comp.notes })
            .then(() => {
                this.messageService.success('Gocha! We will get back to you soon in email.');
            })
            .catch(() => {
                this.messageService.error(null, { nzDuration: 5000 });
                return false;
            });
    }

    private requestW3wService(data: { service_type: string; note: string }) {
        return this.requestService.sendRequest({
            method: 'POST',
            url: '/brand/service_request',
            api: 'admin-api',
            data,
        });
    }
}
