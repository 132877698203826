<div class="select-nft-collection">
    <app-general-modal-header [title]="'Select Tokens'" [isBrandModal]="true" (cancel)="modalRef.triggerCancel()"></app-general-modal-header>

    <section *ngIf="tempSelectedNftTokens?.length" class="selected-box mb-2.5">
        <span class="pr-10" style="color: #fff">Selected: </span>

        <nz-tag *ngFor="let item of tempSelectedNftTokens" [nzMode]="'closeable'" (nzOnClose)="removeCollection(item)"> {{item.name | nzEllipsis:30:'...'}} </nz-tag>
    </section>

    <section>
        <div class="section-content search-box">
            <nz-input-group [nzPrefix]="prefixIconSearch">
                <input #searchInput type="text" nz-input placeholder="Search" class="width-100p" [(ngModel)]="searchVal" />
            </nz-input-group>
            <ng-template #prefixIconSearch>
                <span nz-icon nzType="search"></span>
            </ng-template>
        </div>
    </section>

    <section class="popular-collections">
        <div class="collection-list" appLoading [loading]="fetchingCollectionList" [ngStyle]="{'max-height': screenService.isMobile() ? '200px' : '300px'}">
            <cdk-virtual-scroll-viewport itemSize="40" orientation="vertical" style="width: 100%" [ngStyle]="{height: screenService.isMobile() ? '200px' : '300px'}">
                <div *cdkVirtualFor="let token of tokenList" class="collection-item">
                    <div>
                        <label nz-checkbox [nzValue]="token.id" [nzChecked]="tempSelectedNftTokenIds.has(token.id)" (nzCheckedChange)="checkStatusChange(token,$event)"> </label>
                        <img
                            *ngIf="token.image"
                            [src]="token.image"
                            class="mr-2.5 ml-2.5"
                            style="width: 20px; height: 20px; border-radius: 50%; object-fit: cover; margin-top: -4px"
                        />

                        <span class="overflow-ellipsis" [class.marketplace-link]="!!token.marketplaceLink">
                            {{token.name | nzEllipsis:(screenService.isMobile() ? 25 : 30):'...'}}
                            <span style="color: rgba(211, 218, 226, 0.5); padding-left: 4px">{{token.contractName}}</span>
                        </span>

                        <span *ngIf="token.isVerified" class="verified">
                            <img *ngIf="token.platform === 'opensea'" src="/assets/imgs/admin/opensea-logo.png" />
                            <img *ngIf="token.platform === 'magiceden'" src="/assets/imgs/admin/magiceden.png" />
                            <img *ngIf="token.platform === 'element'" src="/assets/imgs/admin/element.png" />
                            <span class="colorful-text">Verified</span>
                        </span>
                    </div>
                </div>

                <p *ngIf="!tokenList?.length && !fetchingCollectionList" class="mt-2.5 mb-2.5">No token contains "{{searchVal}}"</p>
            </cdk-virtual-scroll-viewport>
        </div>
    </section>

    <footer>
        <button class="cancel" nz-button nzGhost nzShape="round" (click)="modalRef.triggerCancel()">Cancel</button>
        <button nz-button nzShape="round" nzType="primary" (click)="modalRef.triggerOk()">Confirm</button>
    </footer>
</div>
